import {
  AWS_REGIONS,
  DEFAULT_EMR_INSTANCE,
  GP2,
  GP3,
} from '../../../../../../app/constants';
import { generateInstanceName } from '../../../../../../app/utilities/generateInstanceName';
import { CortexComponent } from '../../../../../../models/compute/Ec2';
import { AwsConfig } from '../../../../account/slices/cortexAccountSelectorSlice';
import { calculateDay } from '../../utils';
import { CreateEmrState, EMRForm } from './createEmrInterfaces';
import { FILTERED_EC2_INSTANCE_TYPES } from '../../../../../../app/data/ec2-aws-details';
import { AwsEc2Details } from '../../../../../../app/data/aws-prices-details';
import { AwsEc2Cost } from '../../ec2/slices/createEc2Interfaces';
import getAwsPricePerHour from '../../../../../../app/utilities/getAwsPricePerHour';
import { onlyAllowedInstanceTypes } from '../../components/Type/selectionHelpers';
import { Draft } from '@reduxjs/toolkit';
import moment from 'moment';
import { EMRTemplate } from '../../../../../../models/compute/Template';

export const filterInstances = (
  type: string,
  curGen: boolean,
  isAllowedToCreateLargeWorkflow: boolean
) => {
  let arr: AwsEc2Details[];
  curGen
    ? (arr = FILTERED_EC2_INSTANCE_TYPES.filter(
        (instance) => instance.currentGeneration === 'Yes'
      ))
    : (arr = FILTERED_EC2_INSTANCE_TYPES);
  if (!isAllowedToCreateLargeWorkflow) {
    arr = arr.filter(onlyAllowedInstanceTypes);
  }
  return arr.filter((instance) => instance.class === type);
};

export const addAwsPrices = (ec2: AwsEc2Details) => {
  const ec2CostByRegion: AwsEc2Cost = {};
  AWS_REGIONS.forEach((region) => {
    const regionalCost = {
      [region.name]: getAwsPricePerHour(region.key, 'emr', ec2.type, false),
      [region.key]: getAwsPricePerHour(region.key, 'emr', ec2.type, false),
    };
    Object.assign(ec2CostByRegion, regionalCost);
  });
  return {
    ...ec2,
    cost: ec2CostByRegion,
    id: ec2.type,
  };
};

export const generateInitialEmrForm = (awsConfig: AwsConfig): EMRForm => ({
  acceptCosts: false,
  adminGroup: '',
  applications: ['spark', 'hadoop'],
  associatedBuckets: [],
  associatedData: [],
  autoTerminateDateTime: calculateDay('', 'COMPUTE_TERMINATE', 1).toISOString(),
  bootstrapActions: [],
  configurations: [],
  coreBidPrice: '',
  coreMarketType: 'ON_DEMAND',
  cortexComponents: ['jupyter_hub_lab' as CortexComponent],
  cortexPlaybooks: [],
  customTags: [],
  enableAutoTerminate: true,
  includeSparkJar: false,
  instanceCount: 1,
  iops: 3000,
  lmAccount: awsConfig.account,
  loggingBucket: null,
  loggingPath: '',
  marketTag: '',
  masterInstanceType: DEFAULT_EMR_INSTANCE,
  name: generateInstanceName(),
  profileLoggingUri: '',
  region: awsConfig.region,
  releaseLabel: 'emr-5.36.1',
  rootIops: 150,
  rootVolumeSize: 50,
  rootVolumeType: GP2.key,
  saveTemplate: false,
  secrets: [],
  sendCreateAlert: false,
  slaveInstanceType: DEFAULT_EMR_INSTANCE,
  subMarket: 'DEFAULT',
  /* eslint-disable camelcase */
  tags: {
    lm_sbu: '',
    submarket: '',
  },
  templateDesc: '',
  templateName: '',
  trouxIdEntered: false,
  trouxTagId: '',
  useDefaults: true,
  useHiveGlueMetastore: false,
  userGroup: '',
  userProfile: '',
  useSparkDefaults: true,
  volumeSize: 30,
  volumeType: GP3.key,
});

export const mergeTemplateStateHelper = (
  state: Draft<CreateEmrState>,
  template: EMRTemplate
) => {
  const { emrForm } = state;

  if (template.adminGroup) {
    emrForm.adminGroup = template.adminGroup;
  }
  emrForm.associatedData = template.associatedData;
  emrForm.autoTerminateDateTime = moment(
    template.autoTerminateDateTime
  ).toISOString();
  emrForm.cortexComponents = template.cortexComponents;
  emrForm.tags = template.tags;
  if (template.userGroup) {
    emrForm.userGroup = template.userGroup;
  }
  emrForm.applications = template.applications;
  if (template.bootstrapActions) {
    emrForm.bootstrapActions = template.bootstrapActions;
  }
  emrForm.ebsVolumeSize = template.ebsVolumeSize;
  emrForm.ebsVolumeType = template.ebsVolumeType;
  emrForm.ebsIops = template.ebsIops;
  emrForm.includeSparkJar = template.includeSparkJar;
  emrForm.instanceCount = template.instanceCount;
  emrForm.masterInstanceType = template.masterInstanceType;
  emrForm.name = template.name;
  emrForm.releaseLabel = template.releaseLabel;
  emrForm.rootVolumeSize = template.rootVolumeSize;
  emrForm.sendCreateAlert = template.sendCreateAlert;
  emrForm.slaveInstanceType = template.slaveInstanceType;
  emrForm.tags = template.tags;
  emrForm.useDefaults = template.useDefaults;
  emrForm.useHiveGlueMetastore = template.useHiveGlueMetastore;
  emrForm.userGroup = template.userGroup;
  emrForm.userProfile = template.userProfile;
};
