import React, { lazy } from 'react';
import {
  createSearchParams,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ContainerLoginRedirect = lazy(
  () => import('./member/cloudApps/ContainerizedAuthRedirect')
);
const CreateEc2 = lazy(() => import('./member/aws/compute/ec2/CreateEc2'));
const CreateEmr = lazy(() => import('./member/aws/compute/emr/CreateEmr'));
const CreateEmrServerless = lazy(
  () => import('./member/aws/compute/emrserverless/CreateEmrServerless')
);
const CreateJobRun = lazy(
  () => import('./member/aws/compute/emrserverless/jobRuns/CreateJobRun')
);
const CreateDsvm = lazy(() => import('./member/azure/dsvm/CreateDsvm'));
const CreateRedshift = lazy(
  () => import('./member/aws/compute/redshift/CreateRedshift')
);

const S3Dashboard = lazy(() => import('./member/aws/s3/S3Dashboard'));
const S3Details = lazy(() => import('./member/aws/s3/S3Details'));
const CreateS3 = lazy(() => import('./member/aws/s3/CreateS3'));

const Ec2Dashboard = lazy(
  () => import('./member/aws/compute/ec2/Ec2Dashboard')
);
const Ec2Details = lazy(
  () => import('./member/aws/compute/ec2/details/Ec2Details')
);

const EmrDashboard = lazy(
  () => import('./member/aws/compute/emr/EmrDashboard')
);

const EmrDetails = lazy(
  () => import('./member/aws/compute/emr/details/EmrDetails')
);

const EmrServerlessDashboard = lazy(
  () => import('./member/aws/compute/emrserverless/EmrServerlessDashboard')
);

const EmrServerlessDetails = lazy(
  () =>
    import('./member/aws/compute/emrserverless/details/EmrServerlessDetails')
);

const JobRunDetails = lazy(
  () =>
    import('./member/aws/compute/emrserverless/jobRuns/details/JobRunDetails')
);

const LibertyGPT = lazy(() => import('./member/azure/openAI/beta/LibertyGPT'));

const DsvmDashboard = lazy(() => import('./member/azure/dsvm/DsvmDashboard'));
const DsvmDetails = lazy(
  () => import('./member/azure/dsvm/details/DsvmDetails')
);

const RedshiftDashboard = lazy(
  () => import('./member/aws/compute/redshift/RedshiftDashboard')
);
const ActiveRedshiftDetails = lazy(
  () => import('./member/aws/compute/redshift/details/ActiveRedshiftDetails')
);
const RedshiftSnapshotDetails = lazy(
  () => import('./member/aws/compute/redshift/details/RedshiftSnapshotDetails')
);

const AccountDashboard = lazy(() => import('./member/account/Account'));

const Spend = lazy(() => import('./Spend'));

const JumpLinkOut = lazy(() => import('./JumpLinkOut'));

const InstallationDashboard = lazy(
  () => import('./member/kubernetes/installation/InstallationDashboard')
);
const InstallationDetails = lazy(
  () =>
    import(
      './member/kubernetes/installation/details/ActiveK8InstallationDetails'
    )
);
const CreateInstallation = lazy(
  () => import('./member/kubernetes/installation/create/CreateInstallation')
);

const K8ProfilesDashboard = lazy(
  () => import('./member/kubernetes/profiles/K8CloudProfilesDashboard')
);
const K8ProfileDetails = lazy(
  () =>
    import('./member/kubernetes/profiles/details/ActiveK8CloudProfilesDetails')
);
const CreateCloudProfile = lazy(
  () => import('./member/kubernetes/profiles/create/CreateCloudProfile')
);

const SupportPage = lazy(() => import('../components/appBar/LDLSupport'));
const LDL_USER = process.env.REACT_APP_LDL_USER;

const AccessReport = lazy(() => import('./member/aws/components/AccessReport'));

type PrivateRouteType = {
  isAuthenticated: boolean;
  [x: string]: any;
};

const PrivateRoute = ({ isAuthenticated }: PrivateRouteType) => {
  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: createSearchParams({
            next: window.location.pathname,
            params: window.location.search,
          }).toString(),
        }}
      />
    );
  }
};

const LibertyGPTRoute = () => {
  if (window.location.pathname.includes('azure/chatgpt')) {
    return (
      <Navigate
        to={{
          pathname: '/azure/libertygpt',
        }}
      />
    );
  } else {
    return <LibertyGPT />;
  }
};

const isCortexUser = process.env.REACT_APP_LDL_USER === 'false';
const CortexMemberRoutes = () => {
  const auth = useAuth();

  return (
    <Routes>
      {(auth?.isAuthenticated || !auth?.refreshing) && (
        <Route
          path=""
          element={
            <PrivateRoute isAuthenticated={auth?.isAuthenticated || false} />
          }
        >
          <Route path={'me'} element={<AccountDashboard />} />
          <Route path={'spend'} element={<Spend />} />
          {LDL_USER === 'true' && (
            <Route path={'support'} element={<SupportPage />} />
          )}
          <Route path={'me/report'} element={<AccessReport />} />

          <Route path={'aws/ec2'} element={<Ec2Dashboard />} />
          <Route path={'aws/ec2/create'} element={<CreateEc2 />} />
          <Route path={'aws/ec2/:id'} element={<Ec2Details />} />
          <Route path={'aws/ec2/:id/admin'} element={<Ec2Details />} />

          <Route path={'aws/emr'} element={<EmrDashboard />} />
          <Route path={'aws/emr/create'} element={<CreateEmr />} />

          <Route path={'aws/emr/:id'} element={<EmrDetails />} />
          <Route path={'aws/emr/:id/admin'} element={<EmrDetails />} />

          <Route
            path={'aws/emrserverless'}
            element={<EmrServerlessDashboard />}
          />
          <Route
            path={'aws/emrserverless/applications'}
            element={<EmrServerlessDashboard />}
          />
          <Route
            path={'aws/emrserverless/applications/:id'}
            element={<EmrServerlessDetails />}
          />
          <Route
            path={'aws/emrserverless/applications/:id/:jobRunId'}
            element={<JobRunDetails />}
          />

          <Route
            path={'aws/emrserverless/jobRuns/create/:id'}
            element={<CreateJobRun />}
          />
          <Route
            path={'aws/emrserverless/create'}
            element={<CreateEmrServerless />}
          />

          <Route path={'jump/:alias/*'} element={<JumpLinkOut />} />

          <Route path={'aws/s3'} element={<S3Dashboard />} />
          <Route path={'aws/s3/create'} element={<CreateS3 />} />
          <Route path={'aws/s3/:id'} element={<S3Details />} />

          <Route path={'azure/dsvm'} element={<DsvmDashboard />} />
          <Route path={'azure/dsvm/create'} element={<CreateDsvm />} />
          <Route path={'azure/dsvm/:id'} element={<DsvmDetails />} />
          <Route path={'azure/dsvm/:id/admin'} element={<DsvmDetails />} />
          {isCortexUser && (
            <Route path={'azure/libertygpt'} element={<LibertyGPTRoute />} />
          )}
          {isCortexUser && (
            <Route path={'azure/chatgpt'} element={<LibertyGPTRoute />} />
          )}

          <Route path={'aws/redshift'} element={<RedshiftDashboard />} />
          <Route
            path={'aws/redshift/:id'}
            element={<ActiveRedshiftDetails />}
          />
          <Route
            path={'aws/redshift/:id/admin'}
            element={<ActiveRedshiftDetails />}
          />
          <Route
            path={'aws/redshift/snapshot/:id'}
            element={<RedshiftSnapshotDetails />}
          />
          <Route path={'aws/redshift/create'} element={<CreateRedshift />} />

          <Route
            path={'aws/dashboards'}
            element={
              // @ts-ignore
              <InstallationDashboard {...{ installationType: 'DASHBOARD' }} />
            }
          />
          <Route
            path={'aws/dashboards/:namespace/:id'}
            element={<InstallationDetails />}
          />
          <Route
            path={'aws/dashboards/:namespace'}
            element={
              // @ts-ignore
              <InstallationDashboard {...{ installationType: 'DASHBOARD' }} />
            }
          />
          <Route
            path={'aws/dashboards/:namespace/:id/admin'}
            element={<InstallationDetails />}
          />
          <Route
            path={'aws/notebooks/:namespace/:id/admin'}
            element={<InstallationDetails />}
          />
          <Route
            path={'aws/dashboards/create'}
            element={
              // @ts-ignore
              <CreateInstallation {...{ installationType: 'DASHBOARD' }} />
            }
          />

          <Route
            path={'aws/notebooks'}
            element={
              // @ts-ignore
              <InstallationDashboard {...{ installationType: 'NOTEBOOK' }} />
            }
          />
          <Route
            path={'aws/notebooks/:namespace/:id'}
            element={<InstallationDetails />}
          />
          <Route
            path={'aws/notebooks/:namespace'}
            element={
              // @ts-ignore
              <InstallationDashboard {...{ installationType: 'NOTEBOOK' }} />
            }
          />
          <Route
            path={'aws/notebooks/create'}
            element={
              // @ts-ignore
              <CreateInstallation {...{ installationType: 'NOTEBOOK' }} />
            }
          />

          <Route path={'aws/profiles'} element={<K8ProfilesDashboard />} />
          <Route path={'aws/profiles/:id'} element={<K8ProfileDetails />} />
          <Route
            path={'aws/profiles/create'}
            element={<CreateCloudProfile />}
          />

          <Route
            path={'apps/auth'}
            element={
              // @ts-ignore
              <ContainerLoginRedirect {...{ search: window.location.search }} />
            }
          />
        </Route>
      )}
    </Routes>
  );
};
export default CortexMemberRoutes;
