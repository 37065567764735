import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MenuBar from './components/appBar/MenuBar';
import { CssBaseline } from '@mui/material';
import { CortexRoutes } from './routes/BaseRoutes';
import RefreshToken from './routes/auth/RefreshToken';
import { useAuth } from './hooks/useAuth';
import Footer from './Footer';
import CortexThemeProvider from './CortexThemeProvider';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/error/ErrorBoundary';

const SystemMessage = lazy(() => import('./SystemMessage'));

const App = () => {
  const auth = useAuth();
  const displayAnnouncement =
    process.env.REACT_APP_LDL_USER === 'false' || auth?.isAuthenticated;

  return (
    <div data-testid={'app'} className="Site">
      <CortexThemeProvider>
        <CssBaseline />
        {/* eslint-disable-next-line camelcase */}
        <Router future={{ v7_startTransition: true }}>
          <div className={'Site-content'}>
            <MenuBar />
            {!auth?.isAuthenticated && <RefreshToken />}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<></>}>
                {displayAnnouncement && <SystemMessage />}
              </Suspense>
            </ErrorBoundary>
            <CortexRoutes />
          </div>
          <Footer />
        </Router>
      </CortexThemeProvider>
    </div>
  );
};
export default App;
