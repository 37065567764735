import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { getLabAuthToken } from '../routes/auth/authSlice';
import { CORTEX_URL } from '../app/constants';
import {
  AwsConfig,
  getAccountState,
} from '../routes/member/account/slices/cortexAccountSelectorSlice';
import { Version } from 'streamsaver';
import { cortexRequestSource } from './CortexAxiosApiService';

export const cortexAzureApi = createApi({
  reducerPath: 'cortexAzureApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CORTEX_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getLabAuthToken(getState() as RootState);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      const accountConfig: AwsConfig = getAccountState(getState() as RootState);
      if (accountConfig) {
        headers.set('cortex-lm-account', accountConfig.azureAccount);
        headers.set('cortex-lm-region', accountConfig.azureRegion);
        headers.set('cortex-request-source', cortexRequestSource);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHealth: builder.query<string, void>({
      query: () => 'rest/v1/health',
    }),
    getVersion: builder.query<Version, void>({
      query: () => 'rest/v1/version',
    }),
  }),
});

// DON'T ADD ENDPOINTS DIRECTLY, RATHER, EXTEND
// THIS API TO LEVERAGE CODE SPLITTING - https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const { useGetVersionQuery } = cortexAzureApi;
