import { EC2Form } from './createEc2Interfaces';
import { store } from '../../../../../../app/store';
import { cortexApiInit } from '../../../../../../app/constants';
import { finishJob, jobError } from '../../../../slices/jobsSlice';
import moment from 'moment';
import { CreateEc2Res } from '../../../../../../models/compute/createEc2Response';
import Region from '../../../../../../models/cortex/Region';
import { CortexAPIAccountType } from '../../../../../../services/CortexAxiosApiService';

interface CreateComputeServiceOptions {
  form: EC2Form; // | 'EMRTemplate' | 'RedshiftTemplate'
  region: Region;
  account: CortexAPIAccountType;
  id: string;
}

export class CreateEc2Service {
  private readonly id: string;
  private readonly form: EC2Form;
  private readonly region: Region;
  private readonly account: CortexAPIAccountType;

  constructor(options: CreateComputeServiceOptions) {
    this.form = options.form;
    this.account = options.account;
    this.region = options.region;
    this.id = options.id;
  }

  submit = async () => {
    const dispatch = store.dispatch;
    try {
      const { saveTemplate, templateName, templateDesc } = this.form;
      const query = saveTemplate
        ? {
            templateName,
            templateDesc,
          }
        : {};

      const pollResponse = await cortexApiInit(
        store.getState().authentication.auth?.token,
        this.account,
        this.region
      ).exchange({
        path: 'aws/ec2',
        method: 'POST',
        payload: {
          ...this.form,
          secrets: this.form.secrets.map((secret) => {
            return secret.secretName;
          }),
          autoStopTime: this.form.autoStopTime
            ? moment(this.form.autoStopTime).utc().format('HH:mm')
            : undefined,
          amiId: undefined, //Never use old AMI's
        },
        query: query,
      });

      const res: CreateEc2Res = await pollResponse.data;
      const updateSpec = {
        status: 'SUCCEEDED',
        instanceId: res.reservation.instances[0].instanceId,
        checked: new Date().toISOString(),
      };

      dispatch(
        finishJob({
          id: this.id,
          updateSpec: {
            ...updateSpec,
            finished: true,
            finishedAt: new Date().toISOString(),
          },
        })
      );
    } catch (e) {
      console.error(e);
      let error = e.toString();
      if (e?.response?.data) {
        error = e.response.data;
      }
      dispatch(
        jobError({
          id: this.id,
          updateSpec: {
            error: error,
            status: 'ERROR',
            finished: true,
            finishedAt: new Date().toISOString(),
          },
        })
      );
    }
  };
}
