import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { copyTextToClipboard } from '../../utils';
import { Button, IconButton, Tooltip } from '@mui/material';
import CircularLoader from '../system/CircularLoader';
import { useTheme } from '@mui/material/styles';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface CopyIconProps {
  loading?: boolean;
  copy: boolean;
  icon: any;
}

interface CopyIconButtonProps {
  tooltip: string;
  handleClick: any;
  icon: Element | ReactJSXElement;
  loading?: boolean;
  text?: string;

  [x: string]: any;
}

const CopyIcon = (props: CopyIconProps) => {
  const { loading, copy, icon } = props;
  const theme = useTheme();
  return (
    <>
      {!loading && !copy && icon}
      {!loading && copy && <DoneIcon />}
      {loading && (
        <CircularLoader
          variant={'primary'}
          styles={{
            color: theme.palette.background.default,
            margin: 0,
            width: 20,
            height: 20,
          }}
        />
      )}
    </>
  );
};

export const CopyIconButton = (props: CopyIconButtonProps) => {
  const {
    loading = false,
    tooltip,
    icon,
    handleClick,
    text,
    ...others
  } = props;
  const [copy, setCopy] = React.useState(false);
  const handleTimeout = () => {
    setTimeout(() => {
      setCopy(false);
    }, 1800);
  };

  const actionCallback = async (text: string) => {
    setCopy(true);
    await copyTextToClipboard(text || '');
    handleTimeout();
  };

  const onClick = (e: any) => {
    e.preventDefault();
    handleClick(actionCallback);
  };

  return (
    <>
      {!text && (
        <Tooltip title={tooltip ? tooltip : 'Copy'}>
          <IconButton
            disabled={loading || false}
            aria-label={'copy'}
            onClick={onClick}
            onContextMenu={onClick}
            {...others}
          >
            <CopyIcon copy={copy} icon={icon} />
          </IconButton>
        </Tooltip>
      )}
      {text && (
        <Button
          disabled={loading || false}
          aria-label={'copy'}
          onClick={onClick}
          startIcon={<CopyIcon copy={copy} icon={icon} />}
          {...others}
        >
          {text}
        </Button>
      )}
    </>
  );
};
