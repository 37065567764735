import { Box, Dialog } from '@mui/material';
import CircularLoader from './CircularLoader';
import React from 'react';

export interface LoadingDialogProps {
  open: boolean;
}

export const LoadingDialog = (props: LoadingDialogProps) => {
  return (
    <Dialog aria-labelledby="simple-loading-dialog" open={props.open}>
      <Box m={1}>
        <CircularLoader variant={'primary'} />
      </Box>
    </Dialog>
  );
};
