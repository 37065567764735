import { PaletteOptions } from '@mui/material/styles';
import {
  nord0,
  nord2,
  nord3,
  snow1,
  snow2,
  aurora0,
  aurora1,
  aurora3,
  nord1,
  frost1,
  snow0,
} from './constants';

// THIS IS EXPLICITLY BASED ON THE NORD THEME:
// https://www.nordtheme.com/docs/colors-and-palettes
// I DONT KNOW IF THIS WILL FLY, LICENSE WISE
// https://github.com/arcticicestudio/nord/blob/develop/LICENSE.md
const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: snow0,
  },
  secondary: {
    main: nord2,
    light: nord3,
    dark: nord1,
  },
  error: {
    main: aurora0,
  },
  warning: {
    main: aurora1,
  },
  info: {
    main: frost1,
  },
  success: {
    main: aurora3,
  },
  background: {
    paper: nord0,
    default: nord0,
  },
  text: {
    primary: snow2,
    secondary: snow1,
    disabled: '#D8DEE9',
  },
  menuBar: {
    primaryBgColor: nord0,
    secondaryBgColor: nord3,
    iconButtonColor: nord0,
  },
};

export default darkPalette;
