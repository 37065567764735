import React from 'react';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { useCompact } from '../../hooks/useCompact';
import { ListProps } from '@mui/material/List/List';
import { ListItemButton, ListItemButtonProps } from '@mui/material';

interface CortexListProps extends Omit<ListProps, 'component'> {
  component?: 'ul' | 'ol' | React.ElementType;

  [x: string]: any;
}

export const CortexList = (props: CortexListProps) => {
  const compact = useCompact();
  const { children, ...others } = props;
  return (
    <List dense={compact} {...others}>
      {children}
    </List>
  );
};

interface CortexListItemProps extends Omit<ListItemProps, 'component'> {
  component?: React.ElementType;

  [x: string]: any;
}

export const CortexListItem = (props: CortexListItemProps) => {
  const { children, ...others } = props;
  const compact = useCompact();
  return (
    <ListItem dense={compact} {...others}>
      {children}
    </ListItem>
  );
};

interface CortexListItemButtonProps extends ListItemButtonProps {
  component?: React.ElementType | any;

  [x: string]: any;
}

export const CortexListItemButton = (props: CortexListItemButtonProps) => {
  const { children, ...others } = props;
  const compact = useCompact();
  return (
    <ListItemButton dense={compact} {...others}>
      {children}
    </ListItemButton>
  );
};
