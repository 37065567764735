import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { AlertClasses } from '@mui/material';
import { aurora0, aurora1, aurora3, frost1, nord0 } from './constants';

const DarkAlert: Partial<OverridesStyleRules<keyof AlertClasses>> | undefined =
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiAlert-icon': {
        fontSize: '2rem',
      },
    },
    outlinedError: {
      color: '#F37B86',
      '& .MuiAlert-icon': {
        color: '#F37B86',
      },
    },
    outlinedInfo: {
      color: frost1,
      '& .MuiAlert-icon': {
        color: frost1,
      },
    },
    standardError: {
      backgroundColor: aurora0,
      color: '#130D27',
      '& .MuiAlert-icon': {
        color: nord0,
      },
    },
    standardInfo: {
      backgroundColor: frost1,
      color: nord0,
      '& .MuiAlert-icon': {
        color: nord0,
      },
    },
    standardSuccess: {
      backgroundColor: aurora3,
      color: nord0,
      '& .MuiAlert-icon': {
        color: nord0,
      },
    },
    standardWarning: {
      backgroundColor: aurora1,
      color: nord0,
      '& .MuiAlert-icon': {
        color: nord0,
      },
    },
  };

export default DarkAlert;
