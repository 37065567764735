import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useUser } from '../../hooks/useUser';
import { copyTextToClipboard } from '../../utils';
export const LDLCopyUsername = () => {
  const auth = useAuth();
  const LDL_USER = process.env.REACT_APP_LDL_USER;
  const username = useUser();

  const handleCopy = () => {
    copyTextToClipboard(username?.id || '');
  };

  return (
    <>
      {auth?.isAuthenticated && LDL_USER === 'true' && (
        <>
          <Tooltip title="Copy Username">
            <Button
              sx={[
                { marginRight: 2 },
                { boxShadow: 'none' },
                { '&:hover': { boxShadow: 'none' } },
              ]}
              variant="contained"
              onClick={handleCopy}
            >
              {username?.id}
            </Button>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default LDLCopyUsername;
