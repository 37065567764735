import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './reducerInjector';
import { Reducer } from '@reduxjs/toolkit';
import { CortexStore } from './configureStore';

interface InjectReducerArg {
  key: string;
  reducer: Reducer;
}

export const InjectReducer =
  (reducers: InjectReducerArg[]) => (WrappedComponent: any) => {
    class ReducerInjector extends React.Component {
      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      static displayName = `withReducer(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      constructor(props: any, context: any) {
        super(props, context);
        reducers.forEach((x: InjectReducerArg) => {
          getInjectors(context.store).injectReducer(x.key, x.reducer);
        });
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }
    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };

//eslint-disable-next-line
const useInjectReducer = ({ key, reducer }: any) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    getInjectors(context.store as CortexStore).injectReducer(key, reducer);
    //eslint-disable-next-line
  }, []);
};

export { useInjectReducer };
export default InjectReducer;
