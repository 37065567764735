import io, { Socket } from 'socket.io-client';

let socket: Socket;

export function createSocket() {
  // initialize client
  socket = io(process.env.REACT_APP_LAB_URI || '', {
    transports: ['websocket', 'polling'],
  });

  return socket;
}

export function getSocket() {
  if (!socket) {
    createSocket();
  }
  return socket;
}
