import tokens from '../liberty-ui-tokens/json/tokens.json';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const breakpoint = tokens.props.breakpoint;
const typography = tokens.props.typography;

const lmTypography: TypographyOptions = {
  h1: {
    fontSize: typography.h1.default.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h1.default.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h1.default.weight.default),
    lineHeight: typography.h1.default.lineheight,
    letterSpacing: typography.h1.default.letterspacing,
  },
  h2: {
    fontSize: typography.h2.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h2.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h2.weight.default),
    lineHeight: typography.h2.lineheight,
    letterSpacing: typography.h2.letterspacing,
  },
  h3: {
    fontSize: typography.h3.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h3.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h3.weight.default),
    lineHeight: typography.h3.lineheight,
    letterSpacing: typography.h3.letterspacing,
  },
  h4: {
    fontSize: typography.h4.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h4.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h4.weight.default),
    lineHeight: typography.h4.lineheight,
    letterSpacing: typography.h4.letterspacing,
  },
  h5: {
    fontSize: typography.h5.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h5.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h5.weight.default),
    lineHeight: typography.h5.lineheight,
    letterSpacing: typography.h5.letterspacing,
  },
  h6: {
    fontSize: typography.h6.default.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.h6.default.fontsize.mobile,
    },
    fontWeight: parseInt(typography.h6.default.weight.default),
    lineHeight: typography.h6.default.lineheight,
    letterSpacing: typography.h6.default.letterspacing,
  },
  body1: {
    fontSize: typography.body.default.fontsize.desktop,
    fontWeight: parseInt(typography.body.default.weight.default),
    lineHeight: typography.body.default.lineheight,
    letterSpacing: typography.body.default.letterspacing,
  },
  body2: {
    fontSize: typography.body.article.fontsize.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      fontSize: typography.body.article.fontsize.mobile,
    },
    fontWeight: parseInt(typography.body.article.weight.default),
    lineHeight: typography.body.article.lineheight,
    letterSpacing: typography.body.article.letterspacing,
  },
  button: {
    fontSize: typography.button.fontsize.desktop,
    weight: parseInt(typography.button.weight),
  },
  caption: {
    fontSize: typography.caption.fontsize.desktop,
    fontWeight: parseInt(typography.caption.weight.default),
    lineHeight: typography.caption.lineheight,
    letterSpacing: typography.caption.letterspacing,
  },
  overline: {
    fontSize: typography.disclaimer.fontsize.desktop,
    fontWeight: parseInt(typography.disclaimer.weight.default),
    lineHeight: typography.disclaimer.lineheight,
    letterSpacing: typography.disclaimer.letterspacing,
  },
};

export default lmTypography;
