import {
  uniqueNamesGenerator,
  Config,
  adjectives,
} from 'unique-names-generator';
import { desserts } from '../data/desserts';
import { adjectives as customAdjectives } from '../data/adjectives';
import { scientists } from '../data/scientists';

const dessertsConfig: Config = {
  dictionaries: [adjectives, desserts],
  separator: '-',
  length: 2,
};

const scientistsConfig: Config = {
  dictionaries: [customAdjectives, scientists],
  separator: '-',
  length: 2,
};

const configs = {
  desserts: dessertsConfig,
  scientists: scientistsConfig,
};

export type NameMode = 'desserts' | 'scientists';

export const generateInstanceName = (mode: NameMode = 'scientists') =>
  uniqueNamesGenerator(configs[mode]);
