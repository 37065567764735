import LaunchIcon from '@mui/icons-material/Launch';
import { styled } from '@mui/material/styles';

const LaunchIconTeal = styled(LaunchIcon)(({ theme }: any) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.text.primary
      : theme.palette.secondary.dark,
  fontSize: 'large',
}));
export default LaunchIconTeal;
