import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { store } from '../../../../../../app/store';
import {
  CreateRedshiftState,
  RedshiftForm,
  RedshiftFormField,
} from './createRedshiftInterfaces';
import {
  addAwsPrices,
  generateInitialRedshiftForm,
  setSnapshotStateHelper,
} from './createRedshiftHelpers';
import REDSHIFT_NODE_TYPES from '../../../../../../app/data/redshift-aws-details';
import { AwsConfig } from '../../../../account/slices/cortexAccountSelectorSlice';
import { AssociatedS3Data } from '../../../../../../models/compute/Ec2';
import { Profile } from '../../../../../../models/cortex/Accounts';
import { SnapshotDescribeDetails } from '../../../../../../models/compute/Redshift';

export const redshiftNodeTypes = REDSHIFT_NODE_TYPES.map(addAwsPrices);

const awsConfig: AwsConfig = {
  region: store?.getState()?.cortexAccount?.region || 'US_EAST_1',
  account: store?.getState()?.cortexAccount?.account,
  azureAccount: store?.getState()?.cortexAccount?.azureAccount || 'CORP',
  azureRegion: store?.getState()?.cortexAccount?.azureRegion || 'US_EAST_1',
};

export const initialRedshiftForm: RedshiftForm =
  generateInitialRedshiftForm(awsConfig);

const initialState: CreateRedshiftState = {
  nodeTypes: redshiftNodeTypes,
  redshiftForm: initialRedshiftForm,
  postData: {
    loading: false,
    error: null,
  },
};

export const createRedshiftSlice = createSlice({
  name: 'createRedshift',
  initialState,
  reducers: {
    resetRedshiftState: (state) => {
      state.redshiftForm = initialRedshiftForm;
    },
    setTemplateField: (state, action: PayloadAction<RedshiftFormField>) => {
      const templateField = action.payload.field;
      const templateValue = action.payload.value;
      state.redshiftForm = {
        ...state.redshiftForm,
        [templateField]: templateValue,
      };
    },
    setAssociatedS3Buckets: (
      state,
      action: PayloadAction<AssociatedS3Data[]>
    ) => {
      state.redshiftForm.associatedBuckets = action.payload;
    },
    setAssociatedData: (state, action: PayloadAction<Profile>) => {
      if (action.payload.type === 'CORTEX') {
        state.redshiftForm.associatedData = undefined;
        state.redshiftForm.userProfile = action.payload.projectName;
      } else if (action.payload.type === 'BRANDENBURG') {
        state.redshiftForm.userProfile = undefined;
        state.redshiftForm.associatedData = [
          `${action.payload.type}/${action.payload.projectName}/${action.payload.projectId}`,
        ];
      } else {
        state.redshiftForm.associatedData = undefined;
        state.redshiftForm.userProfile = undefined;
      }
    },
    setSnapshotState: (
      state,
      action: PayloadAction<SnapshotDescribeDetails>
    ) => {
      const snapshot = action.payload;
      setSnapshotStateHelper(state, snapshot);
    },
  },
});
export const {
  resetRedshiftState,
  setTemplateField,
  setAssociatedS3Buckets,
  setAssociatedData,
  setSnapshotState,
} = createRedshiftSlice.actions;

export default createRedshiftSlice.reducer as Reducer<typeof initialState>;
