import React, { useEffect, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { LoadingDialog } from '../system/LoadingDialog';

export const ErrorFallback = (props: { error?: any }) => {
  const [show, setShow] = useState(false);
  const { error } = props;

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 4000);
  }, []);

  return (
    <div data-testid={'error-fallback'} style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
          borderRadius: 1,
        }}
      >
        {!show && (
          <Box maxWidth={350}>
            <LoadingDialog open={!show} />
          </Box>
        )}
        {show && (
          <Box maxWidth={350}>
            <Alert severity={'warning'} variant={'outlined'}>
              Oops, something has gone wrong. Please check your network and
              retry, and if the problem persists contact support.
              <Box m={1} p={1}>
                <Button
                  variant={'outlined'}
                  onClick={() => window.location.reload()}
                >
                  Reload Page
                </Button>
              </Box>
            </Alert>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default ErrorFallback;
