import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CardClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmCard: Partial<OverridesStyleRules<keyof CardClasses>> | undefined = {
  root: {
    backgroundColor: 'inherit',
    border: `1px solid ${palette.props.gray10}`,
    '&.MuiCard-root:hover': {
      borderColor: palette.props.tealMedium,
    },
  },
};

export default LmCard;
