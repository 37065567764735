// Dark Tones
export const nord0 = '#2E3440';
export const nord1 = '#3B4252';
export const nord2 = '#434C5E';
export const nord3 = '#4C566A';

// Light Tones
export const snow0 = '#D8DEE9';
export const snow1 = '#E5E9F0';
export const snow2 = '#ECEFF4';

// Frost Tones
export const frost0 = '#8FBCBB';
export const frost1 = '#88C0D0';
export const frost2 = '#81A1C1';
export const frost3 = '#5E81AC';

// Aurora Tones
export const aurora0 = '#BF616A';
export const aurora1 = '#D08770';
export const aurora2 = '#EBCB8B';
export const aurora3 = '#A3BE8C';
export const aurora4 = '#B48EAD';
