import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { memoize } from 'proxy-memoize';
import type { RootState } from '../../app/store';
import CortexAxiosApiService from '../../services/CortexAxiosApiService';
import {
  createUniqueString,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';
import { ParsedAuthResponse } from './OIDCLogin';

export type ServiceType =
  | 'API_ACCESS'
  | 'TEXT_ANALYTICS'
  | 'WORKBENCH'
  | 'ADMIN'
  | 'WORKBENCH_USER';

export const ADMIN_GROUP =
  process.env.REACT_APP_ADMIN_GROUP || 'gets-cortex-emr-admins';

const CORTEX_LAB_URL = process.env.REACT_APP_CORTEX_LAB_URL || '';

const IDP_AUTH_URL = process.env.REACT_APP_IDP_LOGIN_URL || '';
const IDP_RESP_TYPE = process.env.REACT_APP_IDP_RESP_TYPE || '';
const IDP_SCOPE = process.env.REACT_APP_IDP_SCOPE || '';
const IDP_CLIENT_ID = process.env.REACT_APP_IDP_CLIENT_ID || '';
const IDP_REDIRECT_URL = process.env.REACT_APP_IDP_REDIRECT_URL || '';

let stateToken = getLocalStorageItem('stateToken');

if (!stateToken) {
  stateToken = createUniqueString(5);
  setLocalStorageItem('stateToken', stateToken);
}

export interface User {
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  adGroups: string[] | undefined;
  ldapGroups: string[] | undefined;
  email: string;
  officeLocation: string | undefined;
  isCortexAdmin?: boolean;
  services?: string[];
}

export interface AuthResponse {
  auth: null | {
    token: string;
    user: User;
  };
}

interface AuthenticationState extends AuthResponse {
  open: boolean;
  stateToken?: string | null;
  idpLoginUrl?: string | null;
  loading: boolean;
  error: any;
  refreshing: boolean;
  refreshActive: boolean;
  loggingOut: boolean;
}

export const initialState: AuthenticationState = {
  open: false,
  loading: false,
  error: null,
  auth: null,
  refreshing: true,
  refreshActive: false,
  loggingOut: false,
  stateToken: stateToken,
  idpLoginUrl: `${IDP_AUTH_URL}?client_id=${IDP_CLIENT_ID}&response_type=${IDP_RESP_TYPE}&redirect_uri=${IDP_REDIRECT_URL}&response_mode=fragment&scope=${IDP_SCOPE}&state=${stateToken}:STATE&nonce=${createUniqueString(
    10
  )}`,
};

export interface PostAuthenticationThunk {
  parsed: ParsedAuthResponse;
  cb: any;
}

export const postAuthLogoutThunk = createAsyncThunk(
  'authentication/PostAuthLogoutThunk',
  async (cb: any) => {
    const response = await new CortexAxiosApiService({
      endpoint: CORTEX_LAB_URL,
      isCortexApi: false,
    }).exchange({
      path: `authenticate/logout`,
      method: 'POST',
    });

    setTimeout(() => {
      if (cb) {
        cb();
      }
    }, 300);

    return response.data;
  }
);

export const postAuthRefreshThunk = createAsyncThunk(
  'authentication/PostAuthRefreshThunk',
  async (cb: any, thunkAPI) => {
    try {
      const response = await new CortexAxiosApiService({
        endpoint: CORTEX_LAB_URL,
        isCortexApi: false,
      }).exchange({
        path: `authenticate/refresh`,
        method: 'POST',
      });

      if (response.status === 200) {
        setTimeout(() => {
          thunkAPI.dispatch(postAuthRefreshThunk(null));
        }, 300_000);
      } else {
        thunkAPI.dispatch(postAuthLogoutThunk(null));
      }

      if (cb) {
        cb();
      }

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      thunkAPI.dispatch(postAuthLogoutThunk(null));
      return thunkAPI.rejectWithValue({
        error: 'refreshError',
        message: 'Logging out on failure.',
      });
    }
  }
);

export const postAuthThunk = createAsyncThunk(
  'authentication/PostAuthThunk',
  async (obj: PostAuthenticationThunk, thunkAPI) => {
    try {
      const { parsed, cb } = obj;
      if (!stateToken || !parsed?.state?.startsWith(stateToken)) {
        return thunkAPI.rejectWithValue(
          'An error occurred with your authentication state.'
        );
      }

      const response = await new CortexAxiosApiService({
        endpoint: CORTEX_LAB_URL,
        isCortexApi: false,
      }).exchange({
        path: `authenticate/azure`,
        method: 'POST',
        payload: { auth: parsed },
      });

      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          `Error with status ${response.status} ${response.statusText}`
        );
      }
      const data = await response.data;

      if (cb) {
        setTimeout(() => {
          cb();
          thunkAPI.dispatch(postAuthRefreshThunk(null)); //used to start refresh process
        }, 300);
      }
      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      console.error(error);
      throw thunkAPI.rejectWithValue(error.message);
    }
  }
);

const logoutState = (state: any) => {
  state.refreshing = false;
  state.logginOut = false;
  state.auth = null;
  state.apiAccessToken = null;
  state.refreshActive = false;
  state.cortexApiAccessToken = null;
  state.open = false;
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    clearAuth(state) {
      state.open = false;
      state.error = null;
      state.auth = null;
      state.loggingOut = false;
    },
    startAuth(state) {
      state.open = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAuthThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postAuthThunk.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.error = null;
      state.auth = payload.auth;
      state.open = false;
      heap.identify(
        payload?.auth?.user?.email
          ? payload?.auth?.user?.email
          : payload?.auth?.user?.id
      );
    });
    builder.addCase(postAuthThunk.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(postAuthRefreshThunk.pending, (state) => {
      state.refreshing = true;
    });
    builder.addCase(
      postAuthRefreshThunk.fulfilled,
      (state, { payload }: any) => {
        state.auth = payload.auth;
        state.refreshing = false;
        state.refreshActive = true;
      }
    );
    builder.addCase(postAuthRefreshThunk.rejected, (state) => {
      logoutState(state);
    });
    builder.addCase(postAuthLogoutThunk.pending, (state) => {
      state.loggingOut = true;
      state.open = false;
    });
    builder.addCase(postAuthLogoutThunk.rejected, (state) => {
      logoutState(state);
    });
    builder.addCase(postAuthLogoutThunk.fulfilled, (state) => {
      logoutState(state);
    });
  },
});

export const { clearAuth, startAuth } = authSlice.actions;

//SELECTORS
export const getIdpLoginUrl = (state: RootState) =>
  state.authentication.idpLoginUrl;

export const getUser = (state: RootState): User | null =>
  state.authentication.auth?.user;

export const determineDaeAccess = (state: RootState) => {
  let canUseDae = false;
  if (state.authentication.auth && state.authentication.auth !== null) {
    const adGroups = _.get(state, 'authentication.auth.user.adGroups', []);
    const ldapGroups = _.get(state, 'authentication.auth.user.ldapGroups', []);
    const allGroups = [...adGroups, ...ldapGroups];
    canUseDae =
      allGroups.filter((group) => {
        return (
          group === 'ets_cortex_admins' ||
          group === 'pi_productdesignandmodeling_analyst' ||
          group === 'gets-cortex-emr-admins'
        );
      }).length > 0;
  }
  return canUseDae;
};

export const getLabAuthToken = (state: RootState) =>
  state.authentication.auth?.token;

export const getAuth = (state: RootState) => {
  const labToken = getLabAuthToken(state);
  return {
    cortexLabAccessToken: labToken,
    loading: state.authentication.loading,
    isAuthenticated: !!labToken,
    refreshing: state.authentication.refreshing,
    refreshActive: state.authentication.refreshActive,
  };
};

export const getMemoizedAuthState = memoize((state: RootState) =>
  getAuth(state)
);

export const getAuthPostState = (state: RootState) => {
  return {
    stateToken: state.authentication.stateToken || null,
    open: state.authentication.open,
    loading: state.authentication.loading,
    error: state.authentication.error,
  };
};

export const getMemoizedAuthPostState = memoize((state: RootState) =>
  getAuthPostState(state)
);

export const handleIsCortexAdmin = (groups: string[]) => {
  return groups.includes(ADMIN_GROUP);
};

export default authSlice.reducer as Reducer<typeof initialState>;
