import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { RootState, store } from '../../../../../../app/store';
import { AwsConfig } from '../../../../account/slices/cortexAccountSelectorSlice';
import {
  addAwsPrices,
  generateInitialEmrForm,
  mergeTemplateStateHelper,
} from './createEmrHelpers';
import {
  CreateEmrState,
  EMRForm,
  EmrFormField,
  EmrTemplatePayload,
} from './createEmrInterfaces';
import {
  AssociatedS3Data,
  AssociatedSecrets,
  CortexComponent,
} from '../../../../../../models/compute/Ec2';
import {
  CortexPlaybook,
  CortexPlaybookRecords,
} from '../../../../../../models/compute/Playbooks';
import extendedPlaybooksApi from '../../../../../../services/playbooks/extendedPlaybooksApi';
import { FILTERED_EC2_INSTANCE_TYPES } from '../../../../../../app/data/ec2-aws-details';
import { Profile } from '../../../../../../models/cortex/Accounts';
import { generateInstanceName } from '../../../../../../app/utilities/generateInstanceName';
import { TemplateTab } from '../../ec2/slices/createEc2Interfaces';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../../../utils';
import { Configuration } from '../../../../../../models/compute/Emr';

const DEFAULT_PLAYBOOKS = ['zscaler'];

export const ec2InstanceTypes = FILTERED_EC2_INSTANCE_TYPES.map(addAwsPrices);

const awsConfig: AwsConfig = {
  region: store?.getState()?.cortexAccount?.region || 'US_EAST_1',
  account: store?.getState()?.cortexAccount?.account,
  azureAccount: store?.getState()?.cortexAccount?.azureAccount || 'CORP',
  azureRegion: store?.getState()?.cortexAccount?.azureRegion || 'US_EAST_1',
};

export const initialEmrForm: EMRForm = generateInitialEmrForm(awsConfig);

const initialState: CreateEmrState = {
  instances: ec2InstanceTypes,
  emrForm: initialEmrForm,
  selectedMasterNode: 'c5a.xlarge',
  selectedCoreNode: 'c5a.xlarge',
  playbookRecords: {},
  postData: {
    loading: false,
    error: null,
  },
  configuration: {
    templateTab: getLocalStorageItem(
      'emrTemplateTab',
      'templates'
    ) as TemplateTab,
    grmDataAccessMethod: 'BRANDENBURG',
  },
};

export const createEmrSlice = createSlice({
  name: 'createEmr',
  initialState,
  reducers: {
    resetClusterName(state) {
      state.emrForm.name = generateInstanceName();
    },
    resetClusterState(state) {
      state.emrForm = initialEmrForm;
    },
    resetConfigurations(state) {
      state.emrForm.configurations = initialEmrForm.configurations;
    },
    setSelectedMasterNode: (state, action: PayloadAction<string>) => {
      state.selectedMasterNode = action.payload;
    },
    setSelectedCoreNode: (state, action: PayloadAction<string>) => {
      state.selectedCoreNode = action.payload;
    },
    setReleaseLabel: (state, action: PayloadAction<string>) => {
      state.emrForm.releaseLabel = action.payload;
    },
    setTemplateField: (state, action: PayloadAction<EmrFormField>) => {
      const templateField = action.payload.field;
      const templateValue = action.payload.value;
      state.emrForm = {
        ...state.emrForm,
        [templateField]: templateValue,
      };
    },
    setCortexComponents: (state, action: PayloadAction<CortexComponent[]>) => {
      state.emrForm.cortexComponents = action.payload;
    },
    setCortexPlaybooks: (state, action: PayloadAction<CortexPlaybook[]>) => {
      state.emrForm.cortexPlaybooks = action.payload;
    },
    setCortexPlaybookRecords: (
      state,
      action: PayloadAction<CortexPlaybookRecords>
    ) => {
      state.playbookRecords = action.payload;
    },
    setCortexPlaybookRecordArgs: (
      state,
      action: PayloadAction<{ playbookName: string; args: string }>
    ) => {
      const { playbookName, args } = action.payload;
      state.playbookRecords[playbookName].args = args;
    },
    setConfigurations: (state, action: PayloadAction<Configuration[]>) => {
      state.emrForm.configurations = action.payload;
    },
    selectCortexPlaybookRecord: (
      state,
      action: PayloadAction<{ playbookName: string; selected: boolean }>
    ) => {
      const { playbookName, selected } = action.payload;
      state.playbookRecords[playbookName].selected = selected;
    },
    setAssociatedS3Buckets: (
      state,
      action: PayloadAction<AssociatedS3Data[]>
    ) => {
      state.emrForm.associatedBuckets = action.payload;
    },
    setDaeSelection: (state, action: PayloadAction<string[]>) => {
      state.emrForm.associatedData = action.payload;
    },
    setAssociatedData: (state, action: PayloadAction<Profile>) => {
      if (action.payload.type === 'CORTEX') {
        state.emrForm.associatedData = undefined;
        state.emrForm.userProfile = action.payload.projectName;
      } else if (action.payload.type === 'BRANDENBURG') {
        state.emrForm.userProfile = undefined;
        state.emrForm.associatedData = [
          `${action.payload.type}/${action.payload.projectName}/${action.payload.projectId}`,
        ];
      } else {
        state.emrForm.associatedData = undefined;
        state.emrForm.userProfile = undefined;
      }
    },
    setTemplateTab: (state, action: PayloadAction<TemplateTab>) => {
      const templateTab = action.payload;
      state.configuration.templateTab = templateTab;
      setLocalStorageItem('emrTemplateTab', templateTab);
    },
    setGrmDataAccessMethod: (
      state,
      action: PayloadAction<'BRANDENBURG' | 'DAE'>
    ) => {
      const newDataAccessMethod = action.payload;
      state.emrForm.associatedData = [];
      state.emrForm.userProfile = undefined;
      state.configuration.grmDataAccessMethod = newDataAccessMethod;
    },
    mergeTemplateState: (state, action: PayloadAction<EmrTemplatePayload>) => {
      const template = action.payload.template;
      const availableBuckets = action.payload.availableBuckets;
      mergeTemplateStateHelper(state, template);
      // handle playbooks
      template.cortexPlaybooks.forEach((cortexPlaybook) => {
        state.playbookRecords[cortexPlaybook.playbookName].selected = true;
        state.playbookRecords[cortexPlaybook.playbookName].args =
          cortexPlaybook.args;
      });
      // handle associated s3 buckets
      if (template.associatedBuckets) {
        const associatedS3Buckets: AssociatedS3Data[] =
          template.associatedBuckets.filter((associatedBucket) =>
            availableBuckets.find(
              (availableBucket) =>
                availableBucket.bucket.bucketName ===
                associatedBucket.bucketName
            )
          );
        state.emrForm.associatedBuckets = associatedS3Buckets;
      }
    },
    setAssociatedSecrets: (
      state,
      action: PayloadAction<AssociatedSecrets[]>
    ) => {
      state.emrForm.secrets = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      extendedPlaybooksApi.endpoints.getPlaybooksList.matchFulfilled,
      (state, { payload }) => {
        payload.forEach(
          (playbook) =>
            (state.playbookRecords[playbook.name] = {
              playbookName: playbook.name,
              args: '',
              selected: DEFAULT_PLAYBOOKS.includes(playbook.name),
              installedAtLogin: playbook.installedAtLogin,
              routable: playbook.args.includes('server_name'),
            })
        );
      }
    );
  },
});

export const {
  mergeTemplateState,
  resetClusterName,
  resetClusterState,
  selectCortexPlaybookRecord,
  setAssociatedData,
  setAssociatedS3Buckets,
  setCortexComponents,
  setCortexPlaybookRecordArgs,
  setCortexPlaybookRecords,
  setCortexPlaybooks,
  setDaeSelection,
  setGrmDataAccessMethod,
  setReleaseLabel,
  setSelectedCoreNode,
  setSelectedMasterNode,
  setTemplateField,
  setTemplateTab,
  setConfigurations,
  resetConfigurations,
  setAssociatedSecrets,
} = createEmrSlice.actions;

export const getEmrCreateConfiguration = (state: RootState) =>
  state.createEmr.configuration;

export default createEmrSlice.reducer as Reducer<typeof initialState>;
