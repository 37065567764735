import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { MenuItemClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmMenuItem:
  | Partial<OverridesStyleRules<keyof MenuItemClasses>>
  | undefined = {
  root: {
    '&:hover, &:focus-visible': {
      color: '#FFF',
      backgroundColor: palette.props.tealDark,
    },
    '&.Mui-selected': {
      color: '#FFF',
      backgroundColor: palette.props.blue.dark,
      '&:hover, &:focus': {
        backgroundColor: palette.props.tealDark,
      },
    },
  },
};

export default LmMenuItem;
