import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TableHeadClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmTableHead:
  | Partial<OverridesStyleRules<keyof TableHeadClasses>>
  | undefined = {
  root: {
    '& .MuiTableRow-head > .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    backgroundColor: palette.props.gray04,
  },
};

export default LmTableHead;
