import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TabClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmTab: Partial<OverridesStyleRules<keyof TabClasses>> | undefined = {
  root: {
    color: palette.props.gray78,
    textTransform: 'inherit',
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: 'inherit',
    },
    '&:hover': {
      color: palette.props.grayDark,
    },
    '&:focus': {
      color: palette.props.libertyBlue,
    },
  },
};

export default LmTab;
