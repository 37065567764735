import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { ServiceType } from '../../../auth/authSlice';
import { RootState } from '../../../../app/store';
import { AxiosResponse } from 'axios';
import { CORTEX_LAB_URL, DEFAULT_ERROR_MSG } from '../../../../app/constants';
import CortexAxiosApiService from '../../../../services/CortexAxiosApiService';
import { TokenType } from '../../../../services/LabApiTypes';

export interface CortexApiAccessTokenResponseType {
  userName?: string;
  expires: string | null | undefined;
  services?: ServiceType[] | null | undefined;
  token: string | false | undefined;
}

export interface RotateTokenResponse {
  token: string;
  createDate: string;
}

export interface CortexApiTokenState {
  userToken: {
    loading: boolean;
    error: null | any;
    result: null | CortexApiAccessTokenResponseType;
  };
  tempToken: {
    loading: boolean;
    error: null | any;
    result: null | CortexApiAccessTokenResponseType;
  };
  rotateUserToken: {
    loading: boolean;
    error: null | any;
    result: null | RotateTokenResponse;
  };
}

export const initialState: CortexApiTokenState = {
  userToken: {
    loading: false,
    error: null,
    result: null,
  },
  tempToken: {
    loading: false,
    error: null,
    result: null,
  },
  rotateUserToken: {
    loading: false,
    error: null,
    result: null,
  },
};

const getToken = async (
  token: TokenType,
  variant: string
): Promise<AxiosResponse> => {
  return await new CortexAxiosApiService({
    endpoint: CORTEX_LAB_URL,
    isCortexApi: false,
    token: `Bearer ${token}`,
  }).exchange({
    path: `authenticate/cortex-api/token`,
    method: 'GET',
    payload: { variant },
  });
};

export const rotateToken = async (token: string, userId: string) => {
  return await new CortexAxiosApiService({
    endpoint: CORTEX_LAB_URL,
    isCortexApi: false,
    token: `Cortex ${token}`,
  }).exchange({
    path: `reset-access-token`,
    method: 'GET',
    payload: {
      userId,
    },
  });
};

export const getRotateCortexApiToken = createAsyncThunk(
  'cortexApiToken/PostRotateCortexApiToken',
  async ({ token, userId, cb }: any, thunkAPI) => {
    try {
      const response = await rotateToken(token, userId);
      const data = response.data as RotateTokenResponse;
      if (cb) {
        cb(data);
      }
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getApiAccessTokenThunk = createAsyncThunk(
  'cortexApiToken/GetApiAccessTokenThunk',
  async ({ token, cb }: any, thunkAPI) => {
    try {
      const response = await getToken(token, 'USER');
      if (cb) {
        cb(response.data.apiAccessToken as CortexApiAccessTokenResponseType);
      }
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getTempApiAccessTokenThunk = createAsyncThunk(
  'cortexApiToken/GetTempApiAccessTokenThunk',
  async ({ token, cb }: any, thunkAPI) => {
    try {
      const response = await getToken(token, 'TEMP');
      if (cb) {
        cb(response.data.apiAccessToken as CortexApiAccessTokenResponseType);
      }
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const cortexApiTokenSlice = createSlice({
  name: 'cortexApiToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApiAccessTokenThunk.pending, (state) => {
      state.userToken.loading = true;
    });
    builder.addCase(getApiAccessTokenThunk.rejected, (state, { payload }) => {
      state.userToken.loading = false;
      state.userToken.error = payload;
    });
    builder.addCase(getApiAccessTokenThunk.fulfilled, (state, { payload }) => {
      state.userToken.loading = false;
      state.userToken.error = null;
      state.userToken.result = payload;
    });
    builder.addCase(getTempApiAccessTokenThunk.pending, (state) => {
      state.tempToken.loading = true;
    });
    builder.addCase(
      getTempApiAccessTokenThunk.rejected,
      (state, { payload }) => {
        state.tempToken.loading = false;
        state.tempToken.error = payload;
      }
    );
    builder.addCase(
      getTempApiAccessTokenThunk.fulfilled,
      (state, { payload }) => {
        state.tempToken.loading = false;
        state.tempToken.error = null;
        state.tempToken.result = payload;
      }
    );
    builder.addCase(getRotateCortexApiToken.pending, (state) => {
      state.rotateUserToken.loading = true;
    });
    builder.addCase(getRotateCortexApiToken.rejected, (state, { payload }) => {
      state.rotateUserToken.loading = false;
      state.rotateUserToken.error = payload;
    });
    builder.addCase(getRotateCortexApiToken.fulfilled, (state, { payload }) => {
      state.rotateUserToken.loading = false;
      state.rotateUserToken.error = null;
      state.rotateUserToken.result = payload;
      if (state.userToken.result) {
        const result = new Date(payload.createDate);
        result.setDate(result.getDate() + 10);
        state.userToken.result.token = payload.token;
        state.userToken.result.expires = result.toISOString();
      }
    });
  },
});

export const getCortexApiAccessTokenState = (state: RootState) => {
  return state?.cortexApiToken?.userToken || initialState.userToken;
};
export const getCortexTempApiAccessTokenState = (state: RootState) => {
  return state?.cortexApiToken?.tempToken || initialState.tempToken;
};
export const getCortexRotateApiAccessTokenState = (state: RootState) => {
  return state?.cortexApiToken?.rotateUserToken || initialState.rotateUserToken;
};

export default cortexApiTokenSlice.reducer as Reducer<typeof initialState>;
