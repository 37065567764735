import React from 'react';
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Tooltip,
  useTheme,
} from '@mui/material';
import CopyApiToken from './CopyApiToken';
import { Logout, Person } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { StyledMenu } from './StyledMenu';
import LogOutIcon from '@mui/icons-material/Logout';
import LogInIcon from '@mui/icons-material/Login';
import useWindowSize from '../../hooks/UseWindowSize';
import { useAuth } from '../../hooks/useAuth';
import { useCortexHistory } from '../../hooks/useCortexHistory';
import {
  getIdpLoginUrl,
  postAuthLogoutThunk,
} from '../../routes/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { CortexMenuItem } from '../system/CortexMenu';
import { handlePowerBIExpenseLink } from '../../routes/member/account/SettingsCost';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useUser } from '../../hooks/useUser';
import LDLCopyUsername from './LDLCopyUsername';
import CortexLink from '../system/CortexLink';
import { CortexButton } from '../system/CortexButton';
import palette from '../../themes/liberty-ui-tokens/json/palette.json';
import MenuBarAccountSelector from './MenuBarAccountSelector';
import { getMenuBarAccountSelector } from '../../redux/reducers/applicationSlice';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';

export const MenuBarActions = () => {
  const [width] = useWindowSize();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const loginUrl = useAppSelector((state) => getIdpLoginUrl(state)) || '';
  const menuBarAccountSelector = useAppSelector(getMenuBarAccountSelector);
  const theme = useTheme();
  const navigate = useCortexHistory();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const user = useUser();
  const isMenuOpen = Boolean(menuAnchor);
  const LDL_USER = process.env.REACT_APP_LDL_USER;
  const isLMUser =
    LDL_USER === 'false' ||
    user?.email?.toLowerCase().includes('libertymutual.com');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event?.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const login = () => {
    window.location.href = loginUrl;
  };

  const logout = () => {
    dispatch(postAuthLogoutThunk(() => (window.location.href = '/')));
  };

  return (
    <Grid
      item
      xs={4}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {width > 975 && <LDLCopyUsername />}
      {width > 750 &&
        menuBarAccountSelector === 'visible' &&
        user &&
        LDL_USER !== 'true' && <MenuBarAccountSelector />}
      {width > 500 && <CopyApiToken size={'large'} />}
      {width > 750 && auth?.isAuthenticated && LDL_USER === 'true' && (
        <CortexLink to={'/support'}>
          <CortexButton
            style={{
              boxShadow: 'none',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.primary
                  : palette.props.libertyBlue,
              marginLeft: 3,
            }}
          >
            Support
          </CortexButton>
        </CortexLink>
      )}
      {auth?.isAuthenticated && width > 500 && (
        <IconButton
          onClick={handleClick}
          size="large"
          id={'account-menu'}
          name={'account-menu'}
          aria-label={'account menu'}
        >
          <AccountCircleIcon />
        </IconButton>
      )}
      <StyledMenu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ zIndex: theme.zIndex.modal + 2 }}
      >
        <CortexMenuItem onClick={() => navigate('/me')}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Account
        </CortexMenuItem>
        <CortexMenuItem onClick={() => navigate('/me/report')}>
          <ListItemIcon>
            <MenuBookRoundedIcon fontSize="small" />
          </ListItemIcon>
          Access Report
        </CortexMenuItem>
        {isLMUser && (
          <CortexMenuItem
            onClick={() =>
              window.open(
                handlePowerBIExpenseLink(user?.id || '', user?.email || '')
              )
            }
          >
            <ListItemIcon>
              <AttachMoneyIcon fontSize="small" />
            </ListItemIcon>
            My Cost
          </CortexMenuItem>
        )}
        <CortexMenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Log Out
        </CortexMenuItem>
      </StyledMenu>
      {!auth?.isAuthenticated && width > 500 && (
        <Button
          sx={{
            margin: theme.spacing(0, 2),
            padding: theme.spacing(0, 4),
          }}
          disabled={auth?.refreshing || !loginUrl || auth?.loading}
          color="secondary"
          variant="contained"
          size="small"
          onClick={login}
        >
          Log In
        </Button>
      )}
      {auth?.isAuthenticated && width < 501 && (
        <Tooltip title={'Log Out'}>
          <span>
            <IconButton
              disabled={auth?.refreshing || !loginUrl || auth?.loading}
              aria-label={'Log Out'}
              size="large"
              onClick={logout}
            >
              <LogOutIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!auth?.isAuthenticated && width < 501 && (
        <Tooltip title={'Log In'}>
          <IconButton aria-label={'Log In'} size="large" onClick={login}>
            <LogInIcon />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};
