import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { InputLabelClasses } from '@mui/material';
import { snow1 } from './constants';

const DarkInputLabel:
  | Partial<OverridesStyleRules<keyof InputLabelClasses>>
  | undefined = {
  root: {
    '&.Mui-focused': {
      color: snow1,
    },
  },
};

export default DarkInputLabel;
