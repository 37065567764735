import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { useCortexHistory } from '../../hooks/useCortexHistory';

const StyledCortexLink = styled(Link)(() => ({
  textDecoration: 'none',
}));

interface CortexLinkProps {
  to: string;
  children: any;
  [x: string]: any;
}

export const CortexLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<CortexLinkProps>
>((props, ref) => {
  const navigate = useCortexHistory();
  const theme = useTheme();
  const { children, color = theme.palette.text.primary, to, ...others } = props;
  const handleClick = (e: any) => {
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    e.preventDefault();
    return navigate(to);
  };

  return (
    <StyledCortexLink
      to={to}
      style={{ color, display: 'flex' }}
      {...others}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </StyledCortexLink>
  );
});
export default CortexLink;
