import React from 'react';
import { useThemeDetector } from './hooks/useThemeDetector';
import { cortexTheme } from './themes/cortex';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getAppTheme } from './redux/reducers/applicationSlice';

interface CortexThemeProviderProps {
  children: any;
}

const CortexThemeProvider = (props: CortexThemeProviderProps) => {
  const isDarkTheme = useThemeDetector();
  const userDefinedTheme = useSelector(getAppTheme);

  const appTheme = userDefinedTheme
    ? userDefinedTheme
    : isDarkTheme
    ? 'dark'
    : 'light';

  const theme = cortexTheme({ mode: appTheme });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
export default CortexThemeProvider;
