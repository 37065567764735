import palette from '../liberty-ui-tokens/json/palette.json';
import { PaletteOptions } from '@mui/material/styles';

const lmPalette: PaletteOptions = {
  primary: {
    main: palette.props.libertyYellow,
    contrastText: palette.props.white,
  },
  secondary: {
    main: palette.props.libertyBlue,
    light: palette.props.teal80,
    dark: palette.props.tealDark,
  },
  error: {
    main: palette.props.red.dark,
  },
  warning: {
    main: palette.props.orange.dark,
  },
  info: {
    main: palette.props.blue.dark,
  },
  success: {
    main: palette.props.green.dark,
  },
  background: {
    paper: palette.props.white,
    default: palette.props.gray04,
  },
  menuBar: {
    primaryBgColor: palette.props.white,
    secondaryBgColor: palette.props.gray10,
    iconButtonColor: palette.props.libertyBlue,
  },
};

export default lmPalette;
