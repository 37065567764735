import palette from '../liberty-ui-tokens/json/palette.json';

const LmDataGrid = {
  root: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: palette.props.gray04,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon':
      {
        opacity: 0.9,
      },
    '&.MuiDataGrid-sortIcon': {
      opacity: 1,
      color: palette.props.libertyBlue,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator:last-child':
      {
        display: 'none !important',
      },
  },
};

export default LmDataGrid;
