import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { User, getUser, handleIsCortexAdmin } from '../routes/auth/authSlice';

export const useUser = (): User | null => {
  const auth = useSelector(getUser);
  let isCortexAdmin = false;
  if (auth) {
    isCortexAdmin = handleIsCortexAdmin([
      ...(auth?.adGroups || []),
      ...(auth?.ldapGroups || []),
    ]);
  }
  return useMemo(
    () => (auth ? { ...auth, isCortexAdmin } : null),
    [auth, isCortexAdmin]
  );
};
