import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { RootState } from '../../../../../../app/store';
import { K8InstallationDetails } from '../../../../../../models/kubernetes/K8Installation';

interface K8InstallationDescribeSliceState {
  getK8Installation: {
    loading: boolean;
    error: any;
    result: null | K8InstallationDetails;
  };
}

export const initialState: K8InstallationDescribeSliceState = {
  getK8Installation: {
    loading: false,
    error: null,
    result: null,
  },
};

interface GetK8InstallationThunk {
  installationName: string;
  namespace?: string | null;
}

export const getK8InstallationThunk = createAsyncThunk(
  'k8InstallationDescribe/GetK8InstallationThunk',
  async ({ installationName, namespace }: GetK8InstallationThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const username = state.authentication.auth?.user?.id || '';
      const { account, region } = state.cortexAccount;

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/k8/installation/${
          namespace ? namespace : username
        }/${installationName}`,
        method: 'GET',
        payload: {},
        query: {},
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const k8InstallationDescribeSlice = createSlice({
  name: 'k8InstallationDescribe',
  initialState,
  reducers: {
    updateK8InstallationStatus(state, { payload }) {
      state.getK8Installation.result = {
        ...state.getK8Installation.result,
        podStatus: payload.podStatus,
      } as K8InstallationDetails;
    },
    updateK8Installation(state, { payload }) {
      state.getK8Installation.result = {
        ...state.getK8Installation.result,
        ...payload,
      } as K8InstallationDetails;
    },
    clear(state) {
      state.getK8Installation = initialState.getK8Installation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getK8InstallationThunk.pending, (state) => {
      state.getK8Installation.loading = true;
      state.getK8Installation.error = null;
      state.getK8Installation.result = null;
    });
    builder.addCase(getK8InstallationThunk.fulfilled, (state, { payload }) => {
      state.getK8Installation.loading = false;
      state.getK8Installation.error = null;
      state.getK8Installation.result = payload;
    });
    builder.addCase(getK8InstallationThunk.rejected, (state, { payload }) => {
      state.getK8Installation.loading = false;
      state.getK8Installation.error = payload;
    });
  },
});
export const { updateK8InstallationStatus, updateK8Installation, clear } =
  k8InstallationDescribeSlice.actions;

export const getK8InstallationState = (state: RootState) => {
  return (
    state?.k8InstallationDescribe?.getK8Installation ||
    initialState.getK8Installation
  );
};

export default k8InstallationDescribeSlice.reducer as Reducer<
  typeof initialState
>;
