import CortexThemeOverride from '../CortexThemeOverride';
import LmAlert from './LmAlert';
import LmButton from './LmButton';
import LmIconButton from './LmIconButton';
import LmInputLabel from './LmInputLabel';
import LmLink from './LmLink';
import LmPalette from './LmPalette';
import LmRadio from './LmRadio';
import LmTab from './LmTab';
import LmTabs from './LmTabs';
import LmCard from './LmCard';
import LmStepButton from './LmStepButton';
import LmCheckbox from './LmCheckbox';
import LmSelect from './LmSelect';
import LmOutlinedInput from './LmOutlinedInput';
import LmTableHead from './LmTableHead';
import LmSwitch from './LmSwitch';
import LmMenuItem from './LmMenuItem';
import LmListItem from './LmListItem';
import LmList from './LmList';

const LmTheme: Partial<CortexThemeOverride> = {
  alert: LmAlert,
  button: LmButton,
  iconButton: LmIconButton,
  inputLabel: LmInputLabel,
  link: LmLink,
  palette: LmPalette,
  tab: LmTab,
  tabs: LmTabs,
  radio: LmRadio,
  card: LmCard,
  stepButton: LmStepButton,
  checkbox: LmCheckbox,
  select: LmSelect,
  outlinedInput: LmOutlinedInput,
  tableHead: LmTableHead,
  switch: LmSwitch,
  menuItem: LmMenuItem,
  listItem: LmListItem,
  list: LmList,
};

export default LmTheme;
