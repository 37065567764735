import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../components/error/ErrorBoundary';
import { LoadingDialog } from '../components/system/LoadingDialog';
import CortexMemberRoutes from './MemberRoutes';

const Login = lazy(() => import('./auth/Login'));

const AzureLogin = lazy(() => import('./auth/OIDCLogin'));
const Homepage = lazy(() => import('../routes/Homepage'));
const LDLHomepage = lazy(() => import('../routes/LDLHomepage'));
const Examples = lazy(() => import('../routes/Examples'));
const Health = lazy(() => import('./Health'));
const LDL_USER = process.env.REACT_APP_LDL_USER;
export const CortexRoutes = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingDialog open={true} />}>
        <Routes>
          <Route
            path="/"
            element={LDL_USER === 'false' ? <Homepage /> : <LDLHomepage />}
          />
          <Route path="error" element={<ErrorFallback />} />
          <Route path="examples" element={<Examples />} />
          <Route path="health" element={<Health />} />
          <Route path="login" element={<Login />} />
          <Route
            path={'login/oauth2/code/azure'}
            element={<AzureLogin hash={window.location.hash} />}
          />
          <Route path="/*" element={<CortexMemberRoutes />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};
