import { CortexButton } from '../system/CortexButton';
import { Grid, Link, Menu } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useCortexHistory } from '../../hooks/useCortexHistory';
import MenuItemTeal from './MenuItemTeal';
import CortexLink from '../system/CortexLink';
import palette from '../../themes/liberty-ui-tokens/json/palette.json';
import useWindowSize from '../../hooks/UseWindowSize';
import LaunchIcon from '@mui/icons-material/Launch';
import { useAuth } from '../../hooks/useAuth';

const AZURE_ENABLED = process.env.REACT_APP_AZURE_ENABLED;
const LDL_USER = process.env.REACT_APP_LDL_USER;
const CORTEX_DOCS = process.env.REACT_APP_CORTEX_DOCS_URL;

interface ComputeMenuProps {
  handleCloseMenu: Function;
}

const ComputeMenu = (props: ComputeMenuProps) => {
  const { handleCloseMenu } = props;
  const navigate = useCortexHistory();
  const [width] = useWindowSize();
  const theme = useTheme();

  const [anchorElc, setAnchorElc] = React.useState<null | HTMLElement>(null);
  const [anchorElcApps, setAnchorElcApps] = React.useState<null | HTMLElement>(
    null
  );
  const auth = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElc(event?.currentTarget);
  };

  const handleClickApps = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElcApps(event?.currentTarget);
  };

  const isComputeOpen = Boolean(anchorElc);
  const isAppsOpen = Boolean(anchorElcApps);

  const handleClose = () => {
    setAnchorElc(null);
  };

  const handleCloseApps = () => {
    setAnchorElcApps(null);
  };

  const handleLink = (path: string) => {
    handleClose();
    handleCloseApps();
    navigate(path);
  };

  return (
    <>
      {LDL_USER === 'true' && auth?.isAuthenticated && (
        <Grid item>
          <CortexLink to={'/aws/ec2'}>
            <CortexButton
              onClick={() => handleCloseMenu()}
              style={{
                boxShadow: 'none',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.primary
                    : palette.props.libertyBlue,
              }}
            >
              Compute
            </CortexButton>
          </CortexLink>
        </Grid>
      )}
      {LDL_USER === 'false' && (
        <Grid item>
          <CortexButton
            style={{
              boxShadow: 'none',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.primary
                  : palette.props.libertyBlue,
            }}
            id="compute-menu"
            aria-controls="compute-menu"
            aria-haspopup="true"
            aria-expanded={isComputeOpen ? 'true' : undefined}
            onClick={(e) => {
              handleClick(e);
              handleCloseMenu();
            }}
          >
            Compute
          </CortexButton>
          <Menu
            id="compute menu"
            anchorEl={anchorElc}
            open={isComputeOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'compute-menu',
            }}
            sx={{
              zIndex: theme.zIndex.modal + 2,
              backgroundColor: 'default',
            }}
          >
            <MenuItemTeal onClick={() => handleLink('/aws/ec2')}>
              EC2
            </MenuItemTeal>

            <MenuItemTeal onClick={() => handleLink('/aws/emr')}>
              EMR
            </MenuItemTeal>
            <MenuItemTeal onClick={() => handleLink('/aws/redshift')}>
              Redshift
            </MenuItemTeal>
            {AZURE_ENABLED === 'true' && (
              <MenuItemTeal onClick={() => handleLink('/azure/dsvm')}>
                Azure DSVM
              </MenuItemTeal>
            )}
          </Menu>
        </Grid>
      )}
      {width >= 764 && LDL_USER === 'false' && (
        <Grid item>
          <CortexButton
            style={{
              boxShadow: 'none',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.primary
                  : palette.props.libertyBlue,
            }}
            id="compute-menu"
            aria-controls="compute-menu"
            aria-haspopup="true"
            aria-expanded={isComputeOpen ? 'true' : undefined}
            onClick={(e) => {
              handleClickApps(e);
              handleCloseMenu();
            }}
          >
            Cloud Apps
          </CortexButton>
          <Menu
            id="cloud-apps-menu"
            anchorEl={anchorElcApps}
            open={isAppsOpen}
            onClose={handleCloseApps}
            MenuListProps={{
              'aria-labelledby': 'cloud-apps-menu',
            }}
            sx={{
              zIndex: theme.zIndex.modal + 2,
              backgroundColor: 'default',
            }}
          >
            <MenuItemTeal onClick={() => handleLink('/aws/profiles')}>
              Profiles
            </MenuItemTeal>
            <MenuItemTeal onClick={() => handleLink('/aws/notebooks')}>
              Notebooks
            </MenuItemTeal>
            <MenuItemTeal onClick={() => handleLink('/aws/dashboards')}>
              Dashboards
            </MenuItemTeal>
          </Menu>
        </Grid>
      )}
      {width >= 902 &&
        (LDL_USER === 'false' ||
          (LDL_USER === 'true' && auth?.isAuthenticated)) && (
          <>
            <Grid item>
              <CortexLink to={'/aws/s3'}>
                <CortexButton
                  onClick={() => handleCloseMenu()}
                  style={{
                    boxShadow: 'none',
                    color:
                      theme.palette.mode === 'dark'
                        ? theme.palette.text.primary
                        : palette.props.libertyBlue,
                  }}
                >
                  Storage
                </CortexButton>
              </CortexLink>
            </Grid>
            {LDL_USER === 'true' && (
              <Grid item>
                <Link href="https://gitlab.lmdatalab.com/" target="_blank">
                  <CortexButton
                    style={{
                      boxShadow: 'none',
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.text.primary
                          : palette.props.libertyBlue,
                    }}
                  >
                    GitLab <LaunchIcon />
                  </CortexButton>
                </Link>
              </Grid>
            )}
          </>
        )}
      {width >= 1045 && LDL_USER === 'false' && (
        <Grid item>
          <Link href={`${CORTEX_DOCS}docs/cortex/services`} target="_blank">
            <CortexButton
              style={{
                boxShadow: 'none',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.primary
                    : palette.props.libertyBlue,
              }}
            >
              Docs <LaunchIcon />
            </CortexButton>
          </Link>
        </Grid>
      )}
      {width >= 1220 && AZURE_ENABLED === 'true' && LDL_USER === 'false' && (
        <Grid item>
          <CortexLink to={'/azure/libertygpt'}>
            <CortexButton
              onClick={() => handleCloseMenu()}
              style={{
                boxShadow: 'none',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.primary
                    : palette.props.libertyBlue,
              }}
            >
              LibertyGPT
            </CortexButton>
          </CortexLink>
        </Grid>
      )}
    </>
  );
};
export default ComputeMenu;
