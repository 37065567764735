import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Grid,
  Box,
  Typography,
  List,
  Collapse,
  useTheme,
  Link,
} from '@mui/material';
import { MobileMegaMenuItems } from '../MobileMegaMenuItems';
import { CortexListItemButton, CortexList } from '../system/CortexList';
import LaunchIconTeal from '../system/LaunchIconTeal';
import React from 'react';
import { useCortexHistory } from '../../hooks/useCortexHistory';

const CORTEX_URL = process.env.REACT_APP_CORTEX_URL;
const CORTEX_DOCS = process.env.REACT_APP_CORTEX_DOCS_URL;
const DATA_SCIENCE_SHARED_SERVICES =
  process.env.REACT_APP_DATA_SCIENCE_SHARED_RESOURCES;

interface DrawerLinksProps {
  toggleDrawer: () => void;
}

const DrawerLinks = (props: DrawerLinksProps) => {
  const [airflowOpen, setAirflowOpen] = React.useState(false);
  const navigate = useCortexHistory();
  const theme = useTheme();
  const { toggleDrawer } = props;

  const airFlowClick = () => {
    setAirflowOpen(!airflowOpen);
  };

  const handleLink = (path: string) => {
    toggleDrawer();
    navigate(path);
  };

  return (
    <>
      <Grid container direction={'row'}>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          padding={2}
          component={Box}
          bgcolor={'menuBar.primaryBgColor'}
        >
          <MobileMegaMenuItems handleLink={handleLink} />
          <Grid item mb={3}>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(2, 0, 0, 2) }}
            >
              Data
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={() => handleLink('/aws/s3')}
              >
                S3 Buckets (Cortex)
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={'https://epiphany-ui.us-east-1.paas.lmig.com'}
                target={'_blank'}
                rel={'noopener'}
              >
                GRM Data (Epiphany)
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
          <Grid item>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(0, 0, 0, 2) }}
            >
              Model Development
            </Typography>
            <CortexList>
              <CortexListItemButton onClick={airFlowClick}>
                Airflow
                {airflowOpen ? <ExpandLess /> : <ExpandMore />}
              </CortexListItemButton>
              <Collapse in={airflowOpen} timeout="auto" unmountOnExit>
                <CortexList component="div">
                  <Grid sx={{ margin: theme.spacing(0, 0, 0, 4) }}>
                    <CortexListItemButton
                      component={Link}
                      onClick={toggleDrawer}
                      href={'https://airflow-us.cortex.aws.lmig.com'}
                      target={'_blank'}
                      rel={'noopener'}
                    >
                      Airflow (CORTEX_PROD)
                      <LaunchIconTeal />
                    </CortexListItemButton>
                    <CortexListItemButton
                      component={Link}
                      onClick={toggleDrawer}
                      href={'https://airflow-grs-us.cortex.aws.lmig.com/'}
                      target={'_blank'}
                      rel={'noopener'}
                    >
                      Airflow (GRS_PROD)
                      <LaunchIconTeal />
                    </CortexListItemButton>
                    <CortexListItemButton
                      component={Link}
                      onClick={toggleDrawer}
                      href={'https://airflow-grm-us.cortex.aws.lmig.com/home'}
                      target={'_blank'}
                      rel={'noopener'}
                    >
                      Airflow (USCM_PROD)
                      <LaunchIconTeal />
                    </CortexListItemButton>
                  </Grid>
                </CortexList>
              </Collapse>
              <CortexListItemButton
                component={Link}
                onClick={() => handleLink('/aws/ec2')}
              >
                Compute (Cortex)
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={'https://github.com'}
                target={'_blank'}
                rel={'noopener'}
              >
                Version Control (GitHub)
                <LaunchIconTeal />
              </CortexListItemButton>
            </CortexList>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          padding={2}
          component={Box}
          bgcolor={'menuBar.primaryBgColor'}
        >
          <Grid item mb={3}>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(2, 0, 0, 2) }}
            >
              Model Deployment
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={'https://runway.lmig.com'}
                target={'_blank'}
                rel={'noopener'}
              >
                Runway
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
          <Grid item>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(0, 0, 0, 2) }}
            >
              MLOPs
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={
                  'https://adb-7289315419186213.13.azuredatabricks.net/login.html'
                }
                target={'_blank'}
                rel={'noopener'}
              >
                Managed MLflow
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
          <Grid item>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(0, 0, 0, 2) }}
            >
              Cortex CLI
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={`${CORTEX_DOCS}docs/cortex/Tools/cortex-cli`}
                target={'_blank'}
                rel={'noopener'}
              >
                Cortex CLI documentation
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          padding={2}
          component={Box}
          bgcolor={'menuBar.primaryBgColor'}
        >
          <Grid item mb={3}>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(2, 0, 0, 2) }}
            >
              Developers
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={`${CORTEX_URL}swagger-ui/index.html`}
                target={'_blank'}
                rel={'noopener'}
              >
                API Docs (Swagger UI)
                <LaunchIconTeal />
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={`${CORTEX_DOCS}docs/cortex/services`}
                target={'_blank'}
                rel={'noopener'}
              >
                Cortex Documentation
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
          <Typography variant={'h4'} sx={{ margin: theme.spacing(2, 0, 0, 2) }}>
            Support
          </Typography>
          <List>
            <CortexListItemButton
              component={Link}
              onClick={toggleDrawer}
              href={`${CORTEX_DOCS}docs/cortex/help-and-support`}
              target={'_blank'}
              rel={'noopener'}
            >
              Getting Help and Support for Cortex
              <LaunchIconTeal />
            </CortexListItemButton>
          </List>
        </Grid>
        <Grid
          item
          md={3}
          sm={6}
          xs={12}
          padding={2}
          component={Box}
          bgcolor={theme.palette.background.paper}
        >
          <Grid item mb={3} mt={2}>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(0, 0, 0, 2) }}
            >
              Learn more
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={
                  'https://forge.lmig.com/wiki/display/USCMDSPRG/Data+Science+Corner'
                }
                target={'_blank'}
                rel={'noopener'}
              >
                Data Science Corner (DSPRG)
                <LaunchIconTeal />
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={
                  'https://forge.lmig.com/wiki/display/USCMDSPRG/Getting+Started'
                }
                target={'_blank'}
                rel={'noopener'}
              >
                Getting Started (DSPRG)
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
          <Grid item>
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(0, 0, 0, 2) }}
            >
              Enterprise Data Science
            </Typography>
            <List>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={'https://ai.lmig.com'}
                target={'_blank'}
                rel={'noopener'}
              >
                AI at Liberty Mutual
                <LaunchIconTeal />
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={DATA_SCIENCE_SHARED_SERVICES}
                target={'_blank'}
                rel={'noopener'}
              >
                Data Science Shared Services
                <LaunchIconTeal />
              </CortexListItemButton>
              <CortexListItemButton
                component={Link}
                onClick={toggleDrawer}
                href={
                  'https://forge.lmig.com/wiki/display/CPODS/The+Office+of+Data+Science'
                }
                target={'_blank'}
                rel={'noopener'}
              >
                Office of Data Science
                <LaunchIconTeal />
              </CortexListItemButton>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DrawerLinks;
