import React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';
import { useCompact } from '../../hooks/useCompact';

interface CortexMenuItemProps extends MenuItemProps {
  component?: React.ElementType | any;

  [x: string]: any;
}

export const CortexMenuItem = (props: CortexMenuItemProps) => {
  const { children, ...others } = props;
  const compact = useCompact();
  return (
    <MenuItem dense={compact} {...others}>
      {children}
    </MenuItem>
  );
};
