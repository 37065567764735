import React from 'react';
import { AppBar, Drawer, Grid, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LightLogo from '../../imgs/cortex-horizontal.svg';
import DarkLogo from '../../imgs/cortex-horizontal-white.svg';
import LDL from '../../imgs/LM-Data-Icon.png';
import { CortexLink as RouterLink } from '../system/CortexLink';
import { useTheme } from '@mui/material/styles';
import useWindowSize from '../../hooks/UseWindowSize';
import ComputeMenu from './ComputeMenu';
import { MenuBarActions } from './MenuBarActions';
import DrawerLinks from './DrawerLinks';
import { useLocation } from 'react-router';

const MenuBar = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const toggleDrawer = () => {
    setOpen((old) => !old);
  };

  const [width] = useWindowSize();
  const isDarkTheme = theme.palette.mode === 'dark';

  const LDL_USER = process.env.REACT_APP_LDL_USER;

  if (location.pathname === '/azure/libertygpt') {
    return null;
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ position: 'relative', zIndex: theme.zIndex.modal + 1 }}
      >
        <Toolbar sx={{ minHeight: 60 }}>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item xs={8}>
              <Grid container sx={{ alignItems: 'center' }} spacing={2}>
                {LDL_USER === 'false' && (
                  <>
                    <Grid item>
                      <IconButton
                        edge={'start'}
                        aria-label={'Open drawer'}
                        onClick={toggleDrawer}
                        size="large"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <RouterLink
                    to={'/'}
                    style={{ display: 'flex' }}
                    onClick={toggleDrawer}
                  >
                    {LDL_USER === 'false' && (
                      <img
                        src={isDarkTheme ? DarkLogo : LightLogo}
                        alt={'Cortex'}
                        height={width < 500 ? 20 : 25}
                        data-testid="logo"
                      />
                    )}
                    {LDL_USER === 'true' && (
                      <img
                        src={LDL}
                        alt={'LMData Lab'}
                        height={width < 500 ? 40 : 45}
                        data-testid="logo"
                      />
                    )}
                  </RouterLink>
                </Grid>
                {width > 600 && (
                  <ComputeMenu handleCloseMenu={() => setOpen(false)} />
                )}
              </Grid>
            </Grid>
            <MenuBarActions />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer open={open} anchor={'top'} onClose={toggleDrawer}>
        <Toolbar />
        <DrawerLinks toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
};
export default MenuBar;
