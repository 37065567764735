import palette from '../liberty-ui-tokens/json/palette.json';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { AlertClasses } from '@mui/material';

const LmAlert: Partial<OverridesStyleRules<keyof AlertClasses>> | undefined = {
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAlert-icon': {
      fontSize: '2rem',
    },
  },
  standardError: {
    backgroundColor: palette.props.red.dark,
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  standardInfo: {
    backgroundColor: palette.props.blue.dark,
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  standardSuccess: {
    backgroundColor: palette.props.green.dark,
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  standardWarning: {
    backgroundColor: palette.props.orange.dark,
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
};

export default LmAlert;
