/**
 * Function which sorts storage volumes in order of (EBS Only < Space in calculated GB)
 * Runs as part of the guided ec2 instance selection process.
 * @param {string} a An instance type storage descriptor
 * @param {string} b An instance type storage descriptor
 * @returns {integer} -1/1/0 representing the relative order of two items
 */
export const sortStorage = (a: string, b: string) => {
  //  console.log('SORT OUTPUT: ')
  //  console.log(a)
  //  console.log(b)
  // EBS goes at the top of the list
  if (a === 'EBS only' && b === 'EBS only') {
    return 0;
  } else if (a === 'EBS only' || b === 'EBS only') {
    return a === 'EBS only' ? -1 : 1;
  }

  // Stripping out any non-storage related monikers
  const rgx = /(GB)?(\s*NVMe)?(\s*SSD|\s*HDD)?/g;
  // Calculating storage in GBs
  const storageA = a
    .replace(rgx, '')
    .split(/\s?x\s?/g)
    .reduce((acc, value) => String(parseInt(acc) * parseInt(value)));
  const storageB = b
    .replace(rgx, '')
    .split(/\s?x\s?/g)
    .reduce((acc, value) => String(parseInt(acc) * parseInt(value)));
  if (parseInt(storageA) < parseInt(storageB)) {
    return -1;
  }
  if (parseInt(storageA) > parseInt(storageB)) {
    return 1;
  }
  return 0;
};
