import palette from '../liberty-ui-tokens/json/palette.json';
import { RadioClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { merge } from 'lodash';
import MuiRadioOverride from '../componentBase/MuiRadioOverride';

const LmRadio: Partial<OverridesStyleRules<keyof RadioClasses>> | undefined = {
  root: {
    '&:hover': {
      opacity: 1,
    },
    '&.Mui-checked': {
      color: palette.props.tealDark,
    },
    '&.Mui-focusVisible': {
      backgroundColor: palette.props.tealMedium,
      color: '#FFF',
    },
  },
};

export default merge(LmRadio, MuiRadioOverride);
