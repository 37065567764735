import { COMPUTE_TYPE, cortexApiInit } from '../../../../app/constants';
import { EC2Action } from './ec2/slices/ec2DashboardSlice';
import MomentUtils from '@date-io/moment';
import moment from 'moment/moment';
import {
  AUTO_CONSTANTS,
  AutoConstant,
  InstanceDetails,
  InstanceStateType,
} from '../../../../models/compute/Ec2';
import Region from '../../../../models/cortex/Region';
import { CortexAPIAccountType } from '../../../../services/CortexAxiosApiService';
import { Accounts } from '../../../../models/cortex/Accounts';

export const InstanceState = {
  PENDING: 'PENDING' as InstanceStateType,
  SHUTTING_DOWN: 'SHUTTING_DOWN' as InstanceStateType,
  STOPPING: 'STOPPING' as InstanceStateType,
  STOPPED: 'STOPPED' as InstanceStateType,
  RUNNING: 'RUNNING' as InstanceStateType,
  INITIALIZING: 'INITIALIZING' as InstanceStateType,
  TERMINATING: 'TERMINATING' as InstanceStateType,
  TERMINATED: 'TERMINATED' as InstanceStateType,
  TERMINATED_WITH_ERRORS: 'TERMINATED_WITH_ERRORS' as InstanceStateType,
  INSTALLING_APPS: 'INSTALLING_APPS' as InstanceStateType,
};

export const ec2ActionToStatus = (action: EC2Action): InstanceStateType => {
  switch (action) {
    case 'REBOOT':
      return 'Restarting';
    case 'START':
      return 'Starting';
    case 'STOP':
      return 'Stopping';
    default:
      return 'Terminating';
  }
};

export const stateToStyle = (state: any) => {
  const instanceState = state ? state.toUpperCase() : '';

  switch (instanceState.toUpperCase()) {
    case InstanceState.PENDING:
    case InstanceState.INITIALIZING:
    case InstanceState.INSTALLING_APPS:
    case InstanceState.TERMINATED:
      return 'info';
    case InstanceState.RUNNING:
    case 'OK':
      return 'success';
    case InstanceState.TERMINATING:
    case InstanceState.SHUTTING_DOWN:
    case InstanceState.STOPPING:
      return 'warning';
    case InstanceState.STOPPED:
    case InstanceState.TERMINATED_WITH_ERRORS:
      return 'error';
    default:
      return 'info';
  }
};

export const getState = (instance: InstanceDetails) => {
  if (
    instance.instanceState === 'RUNNING' &&
    instance.instanceStatus === 'INITIALIZING'
  ) {
    return instance.instanceStatus;
  }

  if (isRunning(instance.instanceState) && !instance.appsInstalled) {
    switch (instance.appStatus) {
      case null:
      case undefined:
      case '':
        return 'Refresh Item For State';

      case true:
      case 'true':
        return 'Apps Installed';

      case false:
      case 'false':
        return 'Installing Apps';

      default:
        return instance.appStatus;
    }
  }

  return instance.instanceState;
};

export const isTerminated = (state: InstanceStateType) => {
  return (
    state.toUpperCase() === InstanceState.TERMINATED ||
    state.toUpperCase() === InstanceState.TERMINATED_WITH_ERRORS
  );
};

export const isTerminating = (state: InstanceStateType) => {
  return (
    state.toUpperCase() === InstanceState.TERMINATING ||
    state.toUpperCase() === InstanceState.SHUTTING_DOWN ||
    state === 'ShuttingDown'
  );
};

export const isRunning = (state: InstanceStateType) => {
  return !isTerminated(state) && !isTerminating(state) && !isStopped(state);
};

export const isReady = (instance: InstanceDetails) => {
  return (
    !isTerminated(instance.instanceState) &&
    !isStarting(instance.instanceState) &&
    !isStopping(instance.instanceState) &&
    !isTerminating(instance.instanceState) &&
    instance?.instanceStatus?.toLowerCase() === 'ok' &&
    instance.appsInstalled
  );
};

export const isInstallingApps = (instance: InstanceDetails) => {
  return isReady(instance) && !instance.appsInstalled;
};

export const isNotTerminated = (state: InstanceStateType) => {
  return !isTerminated(state) && !isTerminating(state);
};

export const isStopping = (state: InstanceStateType) => {
  return state.toUpperCase() === InstanceState.STOPPING;
};

export const isStopped = (state: InstanceStateType) => {
  return state.toUpperCase() === InstanceState.STOPPED;
};

export const isInitializing = (state: InstanceStateType) => {
  return state.toUpperCase() === InstanceState.INITIALIZING;
};

export const isPending = (state: InstanceStateType) => {
  return (
    state.toUpperCase() === InstanceState.PENDING ||
    isStopping(state) ||
    isTerminating(state) ||
    isInitializing(state)
  );
};

export const ensureHttps = (url: string) => {
  url = url.replace('http://', 'https://');
  return url.startsWith('https://') ? url : `https://${url}`;
};

export const S3_PREFIX = 's3n://';
export const S3_CORTEX_PREFIX = S3_PREFIX + 'cortex-';
export const extractLogPath = (logUri: string) => {
  let logPath = '';
  let bucketName = '';
  if (logUri) {
    const index = (
      logUri.startsWith(S3_CORTEX_PREFIX) ? S3_CORTEX_PREFIX : S3_PREFIX
    ).length;
    logPath = logUri.slice(index);
    let character = logPath.charAt(0);
    bucketName = '';
    while (character !== '/') {
      bucketName += character;
      logPath = logPath.slice(1);
      character = logPath.charAt(0);
    }
    if (logPath === '/') {
      logPath = '';
    } else {
      logPath = logPath.slice(1);
    }
  }
  return { bucketName, logPath };
};

export const isStarting = (state: InstanceStateType) => {
  return (
    state.toUpperCase() === InstanceState.PENDING ||
    state.toUpperCase() === 'STARTING' ||
    state.toUpperCase() === 'RESTARTING'
  );
};

export const canTerminate = (state: InstanceStateType) => {
  return !isTerminated(state) && state !== InstanceState.TERMINATING;
};

export const handleComputeStateExchange = async (
  computeId: string,
  computeType: COMPUTE_TYPE,
  token: string,
  account: CortexAPIAccountType,
  region: Region,
  isDelete: boolean,
  path: string,
  action: string
) => {
  const query: any = {};
  const payload: any = {};

  if (computeType === 'EC2') {
    query.instanceId = computeId;
  } else if (computeType === 'REDSHIFT_SNAPSHOT') {
    query.snapshotId = computeId;
  } else {
    payload.clusterIds = [computeId];
  }

  return await cortexApiInit(token, account, region).exchange({
    path: isDelete ? path : `${path}/${action.toLocaleLowerCase()}`,
    method: isDelete ? 'DELETE' : 'PUT',
    payload,
    query,
  });
};

export const addDays = (days: number) => {
  return new MomentUtils().addDays(moment(), days);
};

export const addMonths = (date: Date, months: number) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const addDaysToDate = (date: Date, days: number) => {
  return moment(date).add(days, 'd').toDate();
};

export const calculateDay = (
  dateTimeStr: string,
  action: AutoConstant,
  terminateDays: number = 89
) => {
  if (dateTimeStr) {
    return moment(dateTimeStr);
  }
  const day = moment();
  const past = window.localStorage.getItem(action);
  if (past && moment(past).isValid()) {
    const pastDT = moment(past);
    day.set({ h: pastDT.hours(), m: pastDT.minutes(), s: 0, ms: 0 });
  } else {
    day.set({
      h: action === AUTO_CONSTANTS.STOP ? 18 : 23,
      m: action === AUTO_CONSTANTS.STOP ? 0 : 59,
      s: action === AUTO_CONSTANTS.STOP ? 0 : 59,
      ms: 0,
    });
  }
  if (day.isBefore(moment())) {
    day.add(1, 'days');
  }

  if (action === AUTO_CONSTANTS.TERMINATE) {
    day.add(terminateDays, 'days');
  }

  return day;
};

export const getAdvancedComputeGroups = (
  accountName: string,
  accountConfig?: Accounts
) => {
  if (accountConfig && accountConfig.accounts) {
    const accounts = accountConfig.accounts;
    return [
      ...new Set(
        accounts
          .filter((account) => account.account === accountName)
          .map((account) => account.config.advancedComputeGroups || [])
          .reduce((acc: string[], val) => acc.concat(val), [])
      ),
    ];
  }
  return [];
};

export const isAllowedToCreateLargeWorkflowHelper = (
  allUserGroups: string[],
  accountName: string,
  allAccounts?: Accounts
) => {
  const advancedComputeGroups = getAdvancedComputeGroups(
    accountName,
    allAccounts
  );

  return allUserGroups.some((group) => advancedComputeGroups.includes(group));
};

export default InstanceState;
