import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { InputLabelClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmInputLabel:
  | Partial<OverridesStyleRules<keyof InputLabelClasses>>
  | undefined = {
  root: {
    '&.Mui-focused': {
      color: palette.props.libertyBlue,
    },
  },
};

export default LmInputLabel;
