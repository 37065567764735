import { ec2ActionToStatus, handleComputeStateExchange } from '../../utils';
import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  ComputePermissionType,
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { RootState } from '../../../../../../app/store';
import { InstanceDetails, InstanceListener } from 'models/compute/Ec2';
import { updateEc2, updateEc2State } from '../details/slices/ec2DescribeSlice';
import { memoize } from 'proxy-memoize';

export type EC2Action =
  | 'STOP'
  | 'START'
  | 'REBOOT'
  | 'DELETE'
  | 'START/STOP'
  | null;

interface Ec2State {
  computeId: string;
}

interface PutEc2State extends Ec2State {
  action: EC2Action;
  cb?: any;
}

export interface InstanceResponse {
  instance: InstanceDetails;
  ec2Permission: ComputePermissionType;
  listeners: InstanceListener[];
}

export interface InstancesResponse {
  instances: InstanceResponse[];
}

export interface CortexResizeInstanceResponse {
  successful: boolean;
}

interface EC2StateChangeError {
  instanceId: string;
  action: EC2Action;
  error: any;
}

export interface Ec2DashboardSliceState {
  getInstances: {
    loading: boolean;
    error: any;
    result: null | InstancesResponse | InstanceDetails[];
  };
  ec2ChangeStateError?: EC2StateChangeError[];
  ec2ChangingState: {
    loading: boolean;
    error: any;
  };
}

export const initialState: Ec2DashboardSliceState = {
  getInstances: {
    loading: false,
    error: null,
    result: null,
  },
  ec2ChangeStateError: [],
  ec2ChangingState: {
    loading: false,
    error: null,
  },
};

interface GetInstances {
  accessible?: boolean;
  terminated?: boolean;
}

export const getInstancesThunk = createAsyncThunk(
  'ec2Dashboard/GetInstancesThunk',
  async (
    { accessible = false, terminated = false }: GetInstances,
    thunkAPI
  ) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/ec2/list' + (accessible ? '/accessible' : ''),
        method: 'GET',
        payload: {},
        query: {
          showTerminated: terminated,
        },
      });

      const data = await response.data;
      const result: InstanceDetails[] =
        data?.instances?.map((ec2: InstanceResponse) => {
          const ec2Permission = ec2.ec2Permission;
          const listeners = ec2.listeners;
          return {
            ...ec2.instance,
            ec2Permission,
            listeners,
          };
        }) || [];
      return thunkAPI.fulfillWithValue(result as any);
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const changeEC2State = createAsyncThunk(
  'ec2Dashboard/ChangeComputeState',
  async ({ cb, computeId, action }: PutEc2State, thunkAPI) => {
    const instanceState = ec2ActionToStatus(action);
    thunkAPI.dispatch(updateEc2State({ instanceState }));

    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/ec2`;

      const response = await handleComputeStateExchange(
        computeId,
        'EC2',
        state.authentication.auth?.token,
        account,
        region,
        action === 'DELETE',
        path,
        action as string
      );
      const result: any = {
        data: response.data,
        computeId,
      };

      return result;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const reloadEC2StateThunk = createAsyncThunk(
  'ec2Dashboard/ReloadEC2stateThunk',
  async ({ computeId }: Ec2State, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/ec2',
        method: 'GET',
        payload: {},
        query: {
          instanceId: computeId,
          additionalDetails: true,
        },
      });

      const instance = response.data.instance;
      thunkAPI.dispatch(updateEc2(instance));

      return instance;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

const handleChangeStateOnListItem = (
  result: InstanceDetails[],
  metaArgs: any,
  loading: boolean,
  updateState: boolean
) => {
  result.forEach((instance: InstanceDetails) => {
    if (instance.instanceId === metaArgs.computeId) {
      instance.localLoading = loading;
      if (updateState) {
        instance.instanceState = ec2ActionToStatus(metaArgs.action);
      }
    }
    return instance;
  });
};

const handleReloadStateOnListItem = (
  result: InstanceDetails[],
  metaArgs: any,
  loading: boolean,
  details?: InstanceDetails
) => {
  result.forEach((instance: InstanceDetails) => {
    if (instance.instanceId === metaArgs.computeId) {
      instance.localLoading = loading;
      if (details) {
        instance.instanceState = details.instanceState;
        instance.appsInstalled = details.appsInstalled;
        instance.instanceStatus = details.instanceStatus;
        instance.appStatus = details.appStatus;
      }
    }
    return instance;
  });
};

export const ec2DashboardSlice = createSlice({
  name: 'ec2Dashboard',
  initialState,
  reducers: {
    clearEc2StateChangeError(state) {
      state.ec2ChangeStateError = [];
    },
    clearSingularEc2StateChangeError(state, { payload }: any) {
      state.ec2ChangeStateError?.splice(payload, 1);
    },
  },
  extraReducers: (builder) => {
    //List Instances
    builder.addCase(getInstancesThunk.pending, (state) => {
      state.getInstances.loading = true;
      state.getInstances.error = null;
    });
    builder.addCase(getInstancesThunk.fulfilled, (state, { payload }: any) => {
      state.getInstances.loading = false;
      state.getInstances.error = null;
      state.getInstances.result = payload;
    });
    builder.addCase(getInstancesThunk.rejected, (state, { payload }) => {
      state.getInstances.loading = false;
      state.getInstances.error = payload;
    });

    //Refresh Instance
    builder.addCase(reloadEC2StateThunk.pending, (state, action) => {
      const result = (state?.getInstances?.result || []) as InstanceDetails[];
      const metaArgs: any = action.meta.arg;
      handleReloadStateOnListItem(result, metaArgs, true);
      state.getInstances.result = result;
    });
    builder.addCase(
      reloadEC2StateThunk.fulfilled,
      (state, { payload, meta }: any) => {
        const result = (state?.getInstances?.result || []) as InstanceDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false, payload);
        state.getInstances.result = result;
      }
    );
    builder.addCase(reloadEC2StateThunk.rejected, (state, { meta }) => {
      const result = (state?.getInstances?.result || []) as InstanceDetails[];
      const metaArgs: any = meta.arg;
      handleReloadStateOnListItem(result, metaArgs, false);
      state.getInstances.result = result;
    });

    //Change State
    builder.addCase(changeEC2State.pending, (state, action) => {
      const result = (state?.getInstances?.result || []) as InstanceDetails[];
      const metaArgs: any = action.meta.arg;
      state.ec2ChangingState.loading = true;
      state.ec2ChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true, false);
      state.getInstances.result = result;
    });
    builder.addCase(changeEC2State.fulfilled, (state, action) => {
      state.ec2ChangingState.loading = false;
      state.ec2ChangingState.error = false;

      const result = (state?.getInstances?.result || []) as InstanceDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }
      handleChangeStateOnListItem(result, metaArgs, false, true);
      state.getInstances.result = result;
    });
    builder.addCase(changeEC2State.rejected, (state, action) => {
      state.ec2ChangingState.loading = false;
      state.ec2ChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const computeId = metaArgs.computeId;
      const ec2Action = metaArgs.action;

      const set =
        state?.ec2ChangeStateError?.filter(
          (x) =>
            x.instanceId !== computeId ||
            (x.instanceId === computeId && x.action !== ec2Action)
        ) || [];
      set.push({
        instanceId: computeId,
        action: ec2Action,
        error: action.payload,
      });

      state.ec2ChangeStateError = set;
      const result = (state?.getInstances?.result || []) as InstanceDetails[];
      handleChangeStateOnListItem(result, metaArgs, false, false);
    });
  },
});

export const { clearEc2StateChangeError, clearSingularEc2StateChangeError } =
  ec2DashboardSlice.actions;

export const getChangeEc2State = (state: RootState) => {
  return state?.ec2Dashboard?.ec2ChangingState || initialState.ec2ChangingState;
};

export const getInstancesState = (state: RootState) => {
  return state?.ec2Dashboard?.getInstances || initialState.getInstances;
};

export const getEc2StateChangeState = (state: RootState) => {
  return (
    state?.ec2Dashboard?.ec2ChangeStateError || initialState.ec2ChangeStateError
  );
};

export const getMemoizedEc2StateChangeState = memoize((state: RootState) =>
  getEc2StateChangeState(state)
);

export default ec2DashboardSlice.reducer as Reducer<typeof initialState>;
