import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { getLabAuthToken, User } from '../routes/auth/authSlice';
import { CortexApiUsersResponse } from './LabApiTypes';

const CORTEX_LAB_URL = process.env.REACT_APP_CORTEX_LAB_URL;

interface LabApiHealth {
  status: string;
}

export const labApi = createApi({
  reducerPath: 'labApi',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: CORTEX_LAB_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getLabAuthToken(getState() as RootState);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getHealth: builder.query<LabApiHealth, void>({
      query: () => 'health',
    }),
    getMe: builder.query<User, void>({
      query: () => 'authenticate/me',
    }),
    getCortexUsers: builder.query<CortexApiUsersResponse, void>({
      query: () => 'cortex/users',
      providesTags: ['Users'],
    }),
    getDisabledCortexUsers: builder.query<CortexApiUsersResponse, void>({
      query: () => 'cortex/users/disabled',
      providesTags: ['Users'],
    }),
  }),
});

export const {
  useGetHealthQuery,
  useGetMeQuery,
  useGetCortexUsersQuery,
  useGetDisabledCortexUsersQuery,
} = labApi;
