import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { LinkClasses } from '@mui/material';
import { frost0 } from './constants';

const DarkLink: Partial<OverridesStyleRules<keyof LinkClasses>> | undefined = {
  root: {
    color: frost0,
    textDecorationColor: frost0,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
    textDecoration: 'none',
  },
};

export default DarkLink;
