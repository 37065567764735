import palette from '../liberty-ui-tokens/json/palette.json';
import { ButtonClasses } from '@mui/material/Button';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import MuiButtonOverride from '../componentBase/MuiButtonOverride';
import tokens from '../liberty-ui-tokens/json/tokens.json';

const button = tokens.props.color.button;

const lmButton: Partial<OverridesStyleRules<keyof ButtonClasses>> | undefined =
  {
    ...MuiButtonOverride,
    root: {
      boxShadow: '0 3px 0 0 #1a1446',
      '&:hover': {
        boxShadow: '0 3px 0 0 #1a1446',
      },
      '&:active': {
        boxShadow: '0 1px 0 0 #1a1446',
      },
      '&disabled': {
        backgroundColor: button.disabled.background,
        borderColor: button.disabled.border,
        color: button.disabled.text,
      },
      textTransform: 'none',
    },
    outlined: {
      boxShadow: `0 3px 0 0 ${palette.props.gray25}`,
      '&:hover': {
        boxShadow: '0 3px 0 0 #1a1446',
      },
      '&:active': {
        boxShadow: '0 1px 0 0 #1a1446',
      },
    },
    contained: {
      boxShadow: '0 3px 0 0 #06748c',
      '&:hover': {
        boxShadow: '0 3px 0 0 #1a1446',
      },
      '&:active': {
        boxShadow: '0 1px 0 0 #1a1446',
      },
    },
    containedPrimary: {
      color: palette.props.libertyBlue,
      backgroundColor: button.primary.background,
      borderColor: button.primary.border,
      '&:hover': {
        backgroundColor: button.primary.backgroundHover,
        borderColor: button.primary.borderHover,
        color: button.primary.textHover,
      },
      '&:active': {
        backgroundColor: button.primary.backgroundPressed,
        borderColor: button.primary.borderPressed,
        color: button.primary.textPressed,
      },
      '&:focus': {
        backgroundColor: button.primary.backgroundHover,
        borderColor: button.primary.borderHover,
        color: button.primary.textHover,
      },
    },
    containedSecondary: {
      backgroundColor: palette.props.white,
      borderColor: button.primaryInverse.border,
      color: palette.props.libertyBlue,
      boxShadow: '0 3px 0 0 #c0bfc0',
      '&:hover': {
        backgroundColor: palette.props.gray04,
        borderColor: palette.props.gray04,
        color: palette.props.libertyBlue,
      },
      '&:active': {
        backgroundColor: palette.props.gray04,
        borderColor: palette.props.gray04,
        color: palette.props.libertyBlue,
      },
    },
    outlinedPrimary: {
      color: palette.props.libertyBlue,
      backgroundColor: button.secondary.background,
      borderColor: button.secondary.border,
      '&:hover': {
        backgroundColor: button.secondary.backgroundHover,
        borderColor: button.secondary.borderHover,
        color: button.secondary.textHover,
      },
      '&:active': {
        backgroundColor: button.secondary.backgroundPressed,
        borderColor: button.secondary.borderPressed,
        color: button.secondary.textPressed,
      },
      '&:focus': {
        backgroundColor: button.secondary.backgroundHover,
        borderColor: button.secondary.borderHover,
        color: button.secondary.textHover,
      },
    },
    outlinedSecondary: {
      backgroundColor: palette.props.white,
      borderColor: palette.props.libertyBlue,
      color: palette.props.libertyBlue,
      boxShadow: '0 3px 0 0 #c0bfc0',
      '&:hover': {
        backgroundColor: palette.props.gray04,
        borderColor: palette.props.libertyBlue,
        color: palette.props.libertyBlue,
      },
      '&:active': {
        backgroundColor: palette.props.gray04,
        borderColor: palette.props.libertyBlue,
        color: palette.props.libertyBlue,
      },
      '&:focus': {
        backgroundColor: palette.props.gray04,
        borderColor: palette.props.libertyBlue,
        color: palette.props.libertyBlue,
      },
    },
    text: {
      color: palette.props.tealDark,
      boxShadow: `none`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: palette.props.teal10,
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: palette.props.teal80,
        color: palette.props.libertyBlue,
      },
      '&:focus': {
        backgroundColor: palette.props.teal80,
        borderColor: palette.props.libertyBlue,
        color: palette.props.libertyBlue,
      },
    },
  };

export default lmButton;
