import tokens from '../liberty-ui-tokens/json/tokens.json';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { ButtonClasses } from '@mui/material';

const breakpoint = tokens.props.breakpoint;
const typography = tokens.props.typography;

const MuiButtonOverride:
  | Partial<OverridesStyleRules<keyof ButtonClasses>>
  | undefined = {
  root: {
    textTransform: 'inherit',
    fontSize: typography.button.fontsize.desktop,
    fontWeight: parseInt(typography.button.weight),
    paddingLeft: tokens.props.spacing.button.horizontalPadding.extraSmall,
    paddingRight: tokens.props.spacing.button.horizontalPadding.extraSmall,
    width: tokens.props.spacing.button.width.desktop,
    maxWidth: tokens.props.spacing.button.maxWidth.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      width: tokens.props.spacing.button.width.mobile,
      maxWidth: tokens.props.spacing.button.maxWidth.mobile,
    },
    height: tokens.props.spacing.button.height.medium,
  },
  contained: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    textTransform: 'inherit',
    fontSize: typography.button.fontsize.desktop,
    fontWeight: parseInt(typography.button.weight),
    paddingLeft: tokens.props.spacing.button.horizontalPadding.extraSmall,
    paddingRight: tokens.props.spacing.button.horizontalPadding.extraSmall,
    width: tokens.props.spacing.button.width.desktop,
    maxWidth: tokens.props.spacing.button.maxWidth.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      width: tokens.props.spacing.button.width.mobile,
      maxWidth: tokens.props.spacing.button.maxWidth.mobile,
    },
    height: tokens.props.spacing.button.height.medium,
  },
  outlined: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    textTransform: 'inherit',
    fontSize: typography.button.fontsize.desktop,
    fontWeight: parseInt(typography.button.weight),
    paddingLeft: tokens.props.spacing.button.horizontalPadding.extraSmall,
    paddingRight: tokens.props.spacing.button.horizontalPadding.extraSmall,
    width: tokens.props.spacing.button.width.desktop,
    maxWidth: tokens.props.spacing.button.maxWidth.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      width: tokens.props.spacing.button.width.mobile,
      maxWidth: tokens.props.spacing.button.maxWidth.mobile,
    },
    height: tokens.props.spacing.button.height.medium,
  },
  containedSecondary: {},
  containedSizeLarge: {
    height: tokens.props.spacing.button.height.large,
  },
  containedSizeSmall: {
    height: tokens.props.spacing.button.height.small,
  },
  outlinedPrimary: {},
  outlinedSecondary: {
    borderRadius: '4px',
  },
  outlinedSizeLarge: {
    height: tokens.props.spacing.button.height.large,
  },
  outlinedSizeSmall: {
    height: tokens.props.spacing.button.height.small,
  },
  text: {
    textTransform: 'inherit',
    fontSize: typography.button.fontsize.desktop,
    fontWeight: parseInt(typography.button.weight),
    paddingLeft: tokens.props.spacing.button.horizontalPadding.extraSmall,
    paddingRight: tokens.props.spacing.button.horizontalPadding.extraSmall,
    width: tokens.props.spacing.button.width.desktop,
    maxWidth: tokens.props.spacing.button.maxWidth.desktop,
    [`@media (max-width:${breakpoint.sm.max})`]: {
      width: tokens.props.spacing.button.width.mobile,
      maxWidth: tokens.props.spacing.button.maxWidth.mobile,
    },
    height: tokens.props.spacing.button.height.medium,
  },
  textPrimary: {},
  textSecondary: {},
  textSizeLarge: {},
  textSizeSmall: {},
  disabled: {},
};

export default MuiButtonOverride;
