import {
  applyMiddleware,
  compose,
  createStore,
  ReducersMapObject,
} from '@reduxjs/toolkit';
import { labApi } from '../services/labApi';
import { cortexAwsApi } from '../services/cortexAwsApi';
import { cortexAzureApi } from '../services/cortexAzureApi';
import { rootReducer } from './reducers';
import { Action, AnyAction, Middleware, Store, StoreEnhancer } from 'redux';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/src/getDefaultMiddleware';
import { curryGetDefaultMiddleware } from './getDefaultMiddlewares';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

type Middlewares<S> = ReadonlyArray<Middleware<{}, S>>;

export interface EnhancedStore<
  S = any,
  A extends Action = AnyAction | any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  M extends Middlewares<S> = Middlewares<S>
> extends Store<S, A> {
  dispatch: any;
}

export interface CortexStore<
  S = any,
  A extends Action = AnyAction | any,
  M extends Middlewares<S> = Middlewares<S>
> extends EnhancedStore<S, A, M> {
  injectedReducers: ReducersMapObject;
}

export default function configuresStore<
  S = any,
  A extends Action = AnyAction | any,
  M extends Middlewares<S> = [ThunkMiddlewareFor<S>]
>(initialState: any = {}): CortexStore<S, A, M> {
  let composeEnhancers = compose;
  const curriedGetDefaultMiddleware = curryGetDefaultMiddleware<S>();
  const curriedDefault = curriedGetDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ['jobs', 'payload.details.poll'],
      ignoredActionPaths: ['jobs', 'payload.details.poll', 'meta'],
      ignoredActions: [
        'jobs/startFileUploadJob',
        'jobs/fileUploadProgress',
        'jobs/finishFileUpload',
        'authentication/PostAuthRefreshThunk/fulfilled',
        'authentication/PostAuthRefreshThunk/pending',
        'authentication/PostAuthRefreshThunk/rejected',
        'authentication/PostAuthThunk/pending',
        'authentication/PostAuthThunk/fulfilled',
        'authentication/PostAuthLogoutThunk/pending',
        'authentication/PostAuthLogoutThunk/fulfilled',
      ],
    },
  });
  const middleware: any[] = curriedDefault.concat(
    cortexAwsApi.middleware,
    cortexAzureApi.middleware,
    labApi.middleware
  );

  let finalMiddleware = middleware;
  if (typeof finalMiddleware === 'function') {
    // @ts-ignore
    finalMiddleware = finalMiddleware();

    if (!IS_PRODUCTION && !Array.isArray(finalMiddleware)) {
      throw new Error(
        'when using a middleware builder function, an array of middleware must be returned'
      );
    }
  }
  if (
    !IS_PRODUCTION &&
    finalMiddleware.some((item) => typeof item !== 'function')
  ) {
    throw new Error(
      'each middleware provided to configureStore must be a function'
    );
  }

  const enhancers = applyMiddleware(...finalMiddleware);

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    // @ts-ignore
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      /* eslint-disable no-underscore-dangle */
      // @ts-ignore
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }
  }

  // @ts-ignore
  const storeEnhancers: StoreEnhancer[] = [enhancers];
  const composedEnhancer = composeEnhancers(...storeEnhancers) as any;

  const store: CortexStore = createStore(
    rootReducer(),
    initialState,
    composedEnhancer
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer(store.injectedReducers));
    });
  }

  return store;
}
