import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../../app/constants';
import { RootState } from '../../../../../../../app/store';
import {
  RedshiftDetails,
  SnapshotDescribeDetails,
  SnapshotListDetails,
} from '../../../../../../../models/compute/Redshift';
import { clearChangingState } from '../../slices/redshiftDashboard';
import { handleComputeStateExchange } from '../../../utils';

interface RedshiftDescribeSliceState {
  getRedshift: {
    loading: boolean;
    error: any;
    result: null | RedshiftDetails;
  };
  getListSnapshot: {
    loading: boolean;
    error: any;
    result: null | SnapshotListDetails;
  };
  getRedshiftSnapshot: {
    loading: boolean;
    error: any;
    result: null | SnapshotDescribeDetails[];
  };
  deleteSnapshot: {
    loading: boolean;
    error: any;
    result: any;
  };
}

export const initialState: RedshiftDescribeSliceState = {
  getRedshift: {
    loading: false,
    error: null,
    result: null,
  },
  getListSnapshot: {
    loading: false,
    error: null,
    result: null,
  },
  getRedshiftSnapshot: {
    loading: false,
    error: null,
    result: null,
  },
  deleteSnapshot: {
    loading: false,
    error: null,
    result: null,
  },
};

interface GetRedshiftThunk {
  clusterId: string;
}

export const getRedshiftThunk = createAsyncThunk(
  'redshiftDescribe/GetRedshiftThunk',
  async ({ clusterId }: GetRedshiftThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/redshift/describe',
        method: 'GET',
        payload: {},
        query: { clusterId },
      });

      const awsResponse = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/redshift',
        method: 'GET',
        payload: {},
        query: { clusterId },
      });

      return {
        ...response.data,
        awsDetails: awsResponse.data,
        autoTerminateDateTime: response.data.autoTermination || null,
      };
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

interface RedshiftSnapshotListThunk {
  includeAccessible?: boolean;
}
export const getRedshiftSnapshotListThunk = createAsyncThunk(
  'redshiftDescribe/GetSnapshotListThunk',
  // eslint-disable-next-line no-empty-pattern
  async ({ includeAccessible }: RedshiftSnapshotListThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();

      thunkAPI.dispatch(clearChangingState());

      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/redshift/snapshot/list',
        method: 'GET',
        payload: {},
        query: {
          includeAccessible,
        },
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);
interface GetRedshiftSnapshotThunk {
  snapshotId: string;
}
export const getRedshiftSnapshotThunk = createAsyncThunk(
  'redshiftDescribe/GetRedshiftSnapshotThunk',
  async ({ snapshotId }: GetRedshiftSnapshotThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/redshift/snapshot/full',
        method: 'GET',
        payload: {},
        query: { snapshotId },
      });

      return {
        ...response.data,
      };
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const deleteSnapshotThunk = createAsyncThunk(
  'redshiftDescribe/DeleteSnapshotThunk',
  async ({ cb, computeId }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/redshift/snapshot`;

      const response = await handleComputeStateExchange(
        computeId,
        'REDSHIFT_SNAPSHOT',
        state.authentication.auth?.token || '',
        account,
        region,
        true,
        path,
        'DELETED'
      );

      thunkAPI.dispatch(updateSnapshotStatus({ status: 'deleting' }));

      const result: any = {
        data: response.data,
        computeId,
      };

      return result;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const redshiftDescribeSlice = createSlice({
  name: 'redshiftDescribe',
  initialState,
  reducers: {
    updateAwsRedshiftState(state, { payload }) {
      const clusters = [
        ...(state?.getRedshift?.result?.awsDetails?.clusters || []),
      ];
      clusters[0].clusterAvailabilityStatus = payload.clusterAvailabilityStatus;
      clusters[0].clusterStatus = payload.clusterStatus;

      const awsRedshift = {
        ...(state?.getRedshift?.result?.awsDetails || {}),
        clusters,
      };

      state.getRedshift.result = {
        ...state.getRedshift.result,
        status: payload.clusterStatus,
        awsRedshift: {
          ...awsRedshift,
        },
      } as RedshiftDetails;
    },
    updateRedshiftStatus(state, { payload }) {
      state.getRedshift.result = {
        ...state.getRedshift.result,
        status: payload.status,
      } as RedshiftDetails;
    },
    updateRedshift(state, { payload }) {
      state.getRedshift.result = {
        ...state.getRedshift.result,
        ...payload,
      } as RedshiftDetails;
    },
    updateSnapshotStatus(state, { payload }) {
      state.getRedshiftSnapshot.result = {
        ...state.getRedshiftSnapshot.result,
        status: payload.status,
      } as any;
    },
    clearSnapshot(state) {
      state.getRedshiftSnapshot = initialState.getRedshiftSnapshot;
      state.getListSnapshot = initialState.getListSnapshot;
    },
    clearRedshift(state) {
      state.getRedshift = initialState.getRedshift;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRedshiftThunk.pending, (state) => {
      state.getRedshift.loading = true;
      state.getRedshift.error = null;
    });
    builder.addCase(getRedshiftThunk.fulfilled, (state, { payload }) => {
      state.getRedshift.loading = false;
      state.getRedshift.error = null;
      state.getRedshift.result = payload;
    });
    builder.addCase(getRedshiftThunk.rejected, (state, { payload }) => {
      state.getRedshift.loading = false;
      state.getRedshift.error = payload;
    });
    builder.addCase(getRedshiftSnapshotThunk.pending, (state) => {
      state.getRedshiftSnapshot.loading = true;
      state.getRedshiftSnapshot.error = null;
    });
    builder.addCase(
      getRedshiftSnapshotThunk.fulfilled,
      (state, { payload }) => {
        state.getRedshiftSnapshot.loading = false;
        state.getRedshiftSnapshot.error = null;
        state.getRedshiftSnapshot.result = payload;
      }
    );
    builder.addCase(getRedshiftSnapshotThunk.rejected, (state, { payload }) => {
      state.getRedshiftSnapshot.loading = false;
      state.getRedshiftSnapshot.error = payload;
    });
    // List Redshift Snapshots
    builder.addCase(getRedshiftSnapshotListThunk.pending, (state) => {
      state.getListSnapshot.loading = true;
      state.getListSnapshot.error = null;
    });
    builder.addCase(
      getRedshiftSnapshotListThunk.fulfilled,
      (state, { payload }: any) => {
        state.getListSnapshot.loading = false;
        state.getListSnapshot.error = null;
        state.getListSnapshot.result = payload;
      }
    );
    builder.addCase(
      getRedshiftSnapshotListThunk.rejected,
      (state, { payload }) => {
        state.getListSnapshot.loading = false;
        state.getListSnapshot.error = payload;
      }
    );
    builder.addCase(deleteSnapshotThunk.pending, (state) => {
      state.deleteSnapshot.loading = true;
    });
    builder.addCase(
      deleteSnapshotThunk.fulfilled,
      (state, { payload, meta }) => {
        state.deleteSnapshot.loading = false;
        state.deleteSnapshot.error = null;
        const { cb } = meta.arg;
        if (cb) {
          setTimeout(cb, 100);
        }
        state.deleteSnapshot.result = payload;
        updateSnapshotStatus({ status: 'deleted' });
      }
    );
    builder.addCase(deleteSnapshotThunk.rejected, (state, { payload }) => {
      state.deleteSnapshot.loading = false;
      state.deleteSnapshot.error = payload;
    });
  },
});
export const {
  updateRedshiftStatus,
  updateRedshift,
  updateAwsRedshiftState,
  updateSnapshotStatus,
  clearSnapshot,
  clearRedshift,
} = redshiftDescribeSlice.actions;

export const getRedshiftState = (state: RootState) => {
  return state?.redshiftDescribe?.getRedshift || initialState.getRedshift;
};
export const getSnapshotListState = (state: RootState) => {
  return (
    state?.redshiftDescribe?.getListSnapshot || initialState.getListSnapshot
  );
};
export const getRedshiftSnapshotState = (state: RootState) => {
  return (
    state?.redshiftDescribe?.getRedshiftSnapshot ||
    initialState.getRedshiftSnapshot
  );
};

export const deleteSnapshotState = (state: RootState) => {
  return state?.redshiftDescribe?.deleteSnapshot || initialState.deleteSnapshot;
};

export default redshiftDescribeSlice.reducer as Reducer<typeof initialState>;
