import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { IconButtonClasses } from '@mui/material';
import MuiIconButtonOverride from '../componentBase/MuiIconButtonOverride';
import { snow0, frost1 } from './constants';

const DarkIconButton:
  | Partial<OverridesStyleRules<keyof IconButtonClasses>>
  | undefined = {
  ...MuiIconButtonOverride,
  root: {
    color: snow0,
    '&:focus': {
      backgroundColor: `${frost1}50`,
    },
  },
};

export default DarkIconButton;
