import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { CreateEc2Res } from '../../../../../../models/compute/createEc2Response';
import { Template } from '../../../../../../models/compute/Template';
import { createUniqueString } from '../../../../../../utils';
import { finishJob, jobError, startJob } from '../../../../slices/jobsSlice';

interface PostThunkForm extends Template {
  templateName: string;
}

export const ec2PostTemplateThunk = createAsyncThunk(
  'createEc2/Ec2PostTemplateThunk',
  // eslint-disable-next-line no-empty-pattern
  async (form: PostThunkForm, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const id = createUniqueString(10);
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;

      //create job
      await dispatch(
        startJob({
          id,
          jobType: 'CREATE_EC2',
          status: 'QUEUED',
          instanceId: 'i-TBD',
          details: {
            ...form,
            account,
            region,
          },
        })
      );

      const pollResponse = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/template/ec2',
        method: 'POST',
        payload: {},
        query: { templateName: form.templateName },
      });

      const res: CreateEc2Res = await pollResponse.data;
      const updateSpec = {
        status: 'SUCCEEDED',
        instanceId: res.reservation.instances[0].instanceId,
        checked: new Date().toISOString(),
      };

      dispatch(
        finishJob({
          id,
          updateSpec: {
            ...updateSpec,
            finished: true,
            finishedAt: new Date().toISOString(),
          },
        })
      );

      return;
    } catch (e) {
      console.error(e);
      let error = e.toString();
      if (e.response.data) {
        error = e.response.data;
      }
      dispatch(
        jobError({
          id,
          updateSpec: {
            error: error,
            status: 'ERROR',
            finished: true,
            finishedAt: new Date().toISOString(),
          },
        })
      );
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);
