/*
  Current theming is limited to 'default' variants of the liberty mutual components.

  While v5 of MUI will provide the ability to provide custom named components, that feature is not ready for production usage: https://github.com/mui-org/material-ui/issues/15573
*/
import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import typography from './lm/LmTypography';
import LmTheme from './lm';
import DarkTheme from './dark';
import DarkDataGrid from './dark/DarkDataGrid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import LmDataGrid from './lm/LmDataGrid';

interface iThemeOptions {
  mode: 'dark' | 'light';
}

export const cortexTheme = (
  themeOptions: iThemeOptions = { mode: 'light' }
) => {
  const theme = themeOptions.mode === 'light' ? LmTheme : DarkTheme;
  // Datagrid is imported separately because it relies on theme augmentation for customization,
  // not the normal class key you can import for other components.
  const dataGrid = themeOptions.mode === 'light' ? LmDataGrid : DarkDataGrid;

  return createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiAlert: {
        styleOverrides: theme.alert,
      },
      MuiButton: {
        styleOverrides: theme.button,
      },
      MuiIconButton: {
        styleOverrides: theme.iconButton,
      },
      MuiLink: {
        styleOverrides: theme.link,
      },
      MuiTab: {
        styleOverrides: theme.tab,
      },
      MuiTabs: {
        styleOverrides: theme.tabs,
      },
      MuiInputLabel: {
        styleOverrides: theme.inputLabel,
      },
      MuiRadio: {
        styleOverrides: theme.radio,
      },
      MuiDataGrid: {
        styleOverrides: dataGrid,
      },
      MuiCard: {
        styleOverrides: theme.card,
      },
      MuiStepButton: {
        styleOverrides: theme.stepButton,
      },
      MuiCheckbox: {
        styleOverrides: theme.checkbox,
      },
      MuiSelect: {
        styleOverrides: theme.select,
      },
      MuiSwitch: {
        styleOverrides: theme.switch,
      },
      MuiOutlinedInput: {
        styleOverrides: theme.outlinedInput,
      },
      MuiTableHead: {
        styleOverrides: theme.tableHead,
      },
      MuiList: {
        styleOverrides: theme.list,
      },
      MuiListItem: {
        styleOverrides: theme.listItem,
      },
    },
    shadows: Array(25).fill('none') as Shadows,
    palette: theme.palette,
    typography: typography,
  });
};
