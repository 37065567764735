import invariant from 'invariant';
import { isEmpty, isFunction, isString } from 'lodash';

import checkStore from './checkStore';
import { CortexStore } from './configureStore';
import { rootReducer } from './reducers';
import { Reducer } from '@reduxjs/toolkit';

export function injectReducerFactory(store: CortexStore, isValid: boolean) {
  return function injectReducer(key: any, reducer: Reducer) {
    if (!isValid) {
      checkStore(store);
    }

    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      '(app/utils...) injectReducer: Expected `reducer` to be a reducer function'
    );

    if (
      //eslint-disable-next-line
      Reflect.has(store.injectedReducers, key) &&
      store.injectedReducers[key] === reducer
    ) {
      return;
    }

    store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(rootReducer(store.injectedReducers));
  };
}

export default function getInjectors(store: CortexStore) {
  checkStore(store);
  return {
    injectReducer: injectReducerFactory(store, true),
  };
}
