import { useNavigate } from 'react-router-dom';
import React from 'react';

export function useCortexHistory() {
  const history = useNavigate();
  const activeRef = React.useRef(false);
  React.useEffect(() => {
    activeRef.current = true;
  });

  const navigate: any = React.useCallback(
    (path: string, state: any) => {
      if (!activeRef.current) {
        return;
      }
      // @ts-ignore
      if (window.swUpdate) {
        // eslint-disable-next-line no-console
        console.log('updating service worker');
        navigator.serviceWorker.getRegistration().then((reg) => {
          if (reg) {
            reg.unregister().then(() => {
              return (window.location.href = path);
            });
          } else {
            return (window.location.href = path);
          }
        });
      }

      return history(path, state);
    },
    [history]
  );

  return navigate;
}
