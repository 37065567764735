import { AwsPrices } from './aws-prices-details';
const EmrPrices: AwsPrices = {
  lastUpdated: 1713281844453,
  publishedDate: '2024-04-12T20:07:39Z',
  prices: {
    'ap-south-1': {
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'g5.12xlarge': 1.418,
      'g5.16xlarge': 1.024,
      'g5.24xlarge': 2.036,
      'g5.2xlarge': 0.303,
      'g5.48xlarge': 4.072,
      'g5.4xlarge': 0.406,
      'g5.8xlarge': 0.612,
      'g5.xlarge': 0.2515,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
    'ap-southeast-1': {
      'c1.medium': 0.03,
      'c1.xlarge': 0.12,
      'c3.2xlarge': 0.105,
      'c3.4xlarge': 0.21,
      'c3.8xlarge': 0.27,
      'c3.xlarge': 0.053,
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5ad.12xlarge': 0.27,
      'c5ad.16xlarge': 0.27,
      'c5ad.24xlarge': 0.27,
      'c5ad.2xlarge': 0.086,
      'c5ad.4xlarge': 0.172,
      'c5ad.8xlarge': 0.27,
      'c5ad.xlarge': 0.043,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6id.12xlarge': 0.6048,
      'c6id.16xlarge': 0.8064,
      'c6id.24xlarge': 1.2096,
      'c6id.2xlarge': 0.1008,
      'c6id.32xlarge': 1.6128,
      'c6id.4xlarge': 0.2016,
      'c6id.8xlarge': 0.4032,
      'c6id.xlarge': 0.0504,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'd3en.12xlarge': 0.27,
      'd3en.2xlarge': 0.26275,
      'd3en.4xlarge': 0.27,
      'd3en.6xlarge': 0.27,
      'd3en.8xlarge': 0.27,
      'd3en.xlarge': 0.1315,
      'g3.16xlarge': 0.27,
      'g3.4xlarge': 0.27,
      'g3.8xlarge': 0.27,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4g.16xlarge': 1.23552,
      'i4g.2xlarge': 0.15444,
      'i4g.4xlarge': 0.30888,
      'i4g.8xlarge': 0.61776,
      'i4g.xlarge': 0.07722,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'im4gn.16xlarge': 1.4551675,
      'im4gn.2xlarge': 0.181895,
      'im4gn.4xlarge': 0.3637925,
      'im4gn.8xlarge': 0.727585,
      'im4gn.xlarge': 0.0909475,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm1.large': 0.044,
      'm1.medium': 0.022,
      'm1.small': 0.011,
      'm1.xlarge': 0.088,
      'm2.2xlarge': 0.123,
      'm2.4xlarge': 0.246,
      'm2.xlarge': 0.062,
      'm3.2xlarge': 0.14,
      'm3.xlarge': 0.07,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm5dn.12xlarge': 0.27,
      'm5dn.16xlarge': 0.27,
      'm5dn.24xlarge': 0.27,
      'm5dn.2xlarge': 0.136,
      'm5dn.4xlarge': 0.27,
      'm5dn.8xlarge': 0.27,
      'm5dn.xlarge': 0.068,
      'm5n.12xlarge': 0.27,
      'm5n.16xlarge': 0.27,
      'm5n.24xlarge': 0.27,
      'm5n.2xlarge': 0.119,
      'm5n.4xlarge': 0.238,
      'm5n.8xlarge': 0.27,
      'm5n.xlarge': 0.0595,
      'm5zn.12xlarge': 0.27,
      'm5zn.2xlarge': 0.165175,
      'm5zn.3xlarge': 0.24775,
      'm5zn.6xlarge': 0.27,
      'm5zn.xlarge': 0.082575,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm6idn.12xlarge': 0.95472,
      'm6idn.16xlarge': 1.27296,
      'm6idn.24xlarge': 1.90944,
      'm6idn.2xlarge': 0.15912,
      'm6idn.32xlarge': 2.54592,
      'm6idn.4xlarge': 0.31824,
      'm6idn.8xlarge': 0.63648,
      'm6idn.xlarge': 0.07956,
      'm6in.12xlarge': 0.83538,
      'm6in.16xlarge': 1.11384,
      'm6in.24xlarge': 1.67076,
      'm6in.2xlarge': 0.13923,
      'm6in.32xlarge': 2.22768,
      'm6in.4xlarge': 0.27846,
      'm6in.8xlarge': 0.55692,
      'm6in.xlarge': 0.069615,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'p3.16xlarge': 0.27,
      'p3.2xlarge': 0.27,
      'p3.8xlarge': 0.27,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5b.12xlarge': 0.27,
      'r5b.16xlarge': 0.27,
      'r5b.24xlarge': 0.27,
      'r5b.2xlarge': 0.149,
      'r5b.4xlarge': 0.27,
      'r5b.8xlarge': 0.27,
      'r5b.xlarge': 0.0745,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5dn.12xlarge': 0.27,
      'r5dn.16xlarge': 0.27,
      'r5dn.24xlarge': 0.27,
      'r5dn.2xlarge': 0.167,
      'r5dn.4xlarge': 0.27,
      'r5dn.8xlarge': 0.27,
      'r5dn.xlarge': 0.084,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r6idn.12xlarge': 1.17234,
      'r6idn.16xlarge': 1.56312,
      'r6idn.24xlarge': 2.34468,
      'r6idn.2xlarge': 0.19539,
      'r6idn.32xlarge': 3.12624,
      'r6idn.4xlarge': 0.39078,
      'r6idn.8xlarge': 0.78156,
      'r6idn.xlarge': 0.097695,
      'r6in.12xlarge': 1.04598,
      'r6in.16xlarge': 1.39464,
      'r6in.24xlarge': 2.09196,
      'r6in.2xlarge': 0.17433,
      'r6in.32xlarge': 2.78928,
      'r6in.4xlarge': 0.34866,
      'r6in.8xlarge': 0.69732,
      'r6in.xlarge': 0.087165,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
    'eu-west-1': {
      'c1.medium': 0.03,
      'c1.xlarge': 0.12,
      'c3.2xlarge': 0.105,
      'c3.4xlarge': 0.21,
      'c3.8xlarge': 0.27,
      'c3.xlarge': 0.053,
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5ad.12xlarge': 0.27,
      'c5ad.16xlarge': 0.27,
      'c5ad.24xlarge': 0.27,
      'c5ad.2xlarge': 0.086,
      'c5ad.4xlarge': 0.172,
      'c5ad.8xlarge': 0.27,
      'c5ad.xlarge': 0.043,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6id.12xlarge': 0.6048,
      'c6id.16xlarge': 0.8064,
      'c6id.24xlarge': 1.2096,
      'c6id.2xlarge': 0.1008,
      'c6id.32xlarge': 1.6128,
      'c6id.4xlarge': 0.2016,
      'c6id.8xlarge': 0.4032,
      'c6id.xlarge': 0.0504,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7a.12xlarge': 0.61584,
      'c7a.16xlarge': 0.82112,
      'c7a.24xlarge': 1.23168,
      'c7a.2xlarge': 0.10264,
      'c7a.32xlarge': 1.64224,
      'c7a.48xlarge': 2.46336,
      'c7a.4xlarge': 0.20528,
      'c7a.8xlarge': 0.41056,
      'c7a.xlarge': 0.05132,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7gn.12xlarge': 0.7488,
      'c7gn.16xlarge': 0.9984,
      'c7gn.2xlarge': 0.1248,
      'c7gn.4xlarge': 0.2496,
      'c7gn.8xlarge': 0.4992,
      'c7gn.xlarge': 0.0624,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'd3en.12xlarge': 0.27,
      'd3en.2xlarge': 0.26275,
      'd3en.4xlarge': 0.27,
      'd3en.6xlarge': 0.27,
      'd3en.8xlarge': 0.27,
      'd3en.xlarge': 0.1315,
      'g3.16xlarge': 0.27,
      'g3.4xlarge': 0.27,
      'g3.8xlarge': 0.27,
      'g3s.xlarge': 0.188,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'g5.12xlarge': 1.418,
      'g5.16xlarge': 1.024,
      'g5.24xlarge': 2.036,
      'g5.2xlarge': 0.303,
      'g5.48xlarge': 4.072,
      'g5.4xlarge': 0.406,
      'g5.8xlarge': 0.612,
      'g5.xlarge': 0.2515,
      'h1.16xlarge': 0.27,
      'h1.2xlarge': 0.117,
      'h1.4xlarge': 0.234,
      'h1.8xlarge': 0.27,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4g.16xlarge': 1.23552,
      'i4g.2xlarge': 0.15444,
      'i4g.4xlarge': 0.30888,
      'i4g.8xlarge': 0.61776,
      'i4g.xlarge': 0.07722,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'im4gn.16xlarge': 1.4551675,
      'im4gn.2xlarge': 0.181895,
      'im4gn.4xlarge': 0.3637925,
      'im4gn.8xlarge': 0.727585,
      'im4gn.xlarge': 0.0909475,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm1.large': 0.044,
      'm1.medium': 0.022,
      'm1.small': 0.011,
      'm1.xlarge': 0.088,
      'm2.2xlarge': 0.123,
      'm2.4xlarge': 0.246,
      'm2.xlarge': 0.062,
      'm3.2xlarge': 0.14,
      'm3.xlarge': 0.07,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm5dn.12xlarge': 0.27,
      'm5dn.16xlarge': 0.27,
      'm5dn.24xlarge': 0.27,
      'm5dn.2xlarge': 0.136,
      'm5dn.4xlarge': 0.27,
      'm5dn.8xlarge': 0.27,
      'm5dn.xlarge': 0.068,
      'm5n.12xlarge': 0.27,
      'm5n.16xlarge': 0.27,
      'm5n.24xlarge': 0.27,
      'm5n.2xlarge': 0.119,
      'm5n.4xlarge': 0.238,
      'm5n.8xlarge': 0.27,
      'm5n.xlarge': 0.0595,
      'm5zn.12xlarge': 0.27,
      'm5zn.2xlarge': 0.165175,
      'm5zn.3xlarge': 0.24775,
      'm5zn.6xlarge': 0.27,
      'm5zn.xlarge': 0.082575,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm6idn.12xlarge': 0.95472,
      'm6idn.16xlarge': 1.27296,
      'm6idn.24xlarge': 1.90944,
      'm6idn.2xlarge': 0.15912,
      'm6idn.32xlarge': 2.54592,
      'm6idn.4xlarge': 0.31824,
      'm6idn.8xlarge': 0.63648,
      'm6idn.xlarge': 0.07956,
      'm6in.12xlarge': 0.83538,
      'm6in.16xlarge': 1.11384,
      'm6in.24xlarge': 1.67076,
      'm6in.2xlarge': 0.13923,
      'm6in.32xlarge': 2.22768,
      'm6in.4xlarge': 0.27846,
      'm6in.8xlarge': 0.55692,
      'm6in.xlarge': 0.069615,
      'm7a.12xlarge': 0.69552,
      'm7a.16xlarge': 0.92736,
      'm7a.24xlarge': 1.39104,
      'm7a.2xlarge': 0.11592,
      'm7a.32xlarge': 1.85472,
      'm7a.48xlarge': 2.78208,
      'm7a.4xlarge': 0.23184,
      'm7a.8xlarge': 0.46368,
      'm7a.xlarge': 0.05796,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'p3.16xlarge': 0.27,
      'p3.2xlarge': 0.27,
      'p3.8xlarge': 0.27,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5b.12xlarge': 0.27,
      'r5b.16xlarge': 0.27,
      'r5b.24xlarge': 0.27,
      'r5b.2xlarge': 0.149,
      'r5b.4xlarge': 0.27,
      'r5b.8xlarge': 0.27,
      'r5b.xlarge': 0.0745,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5dn.12xlarge': 0.27,
      'r5dn.16xlarge': 0.27,
      'r5dn.24xlarge': 0.27,
      'r5dn.2xlarge': 0.167,
      'r5dn.4xlarge': 0.27,
      'r5dn.8xlarge': 0.27,
      'r5dn.xlarge': 0.084,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r6idn.12xlarge': 1.17234,
      'r6idn.16xlarge': 1.56312,
      'r6idn.24xlarge': 2.34468,
      'r6idn.2xlarge': 0.19539,
      'r6idn.32xlarge': 3.12624,
      'r6idn.4xlarge': 0.39078,
      'r6idn.8xlarge': 0.78156,
      'r6idn.xlarge': 0.097695,
      'r6in.12xlarge': 1.04598,
      'r6in.16xlarge': 1.39464,
      'r6in.24xlarge': 2.09196,
      'r6in.2xlarge': 0.17433,
      'r6in.32xlarge': 2.78928,
      'r6in.4xlarge': 0.34866,
      'r6in.8xlarge': 0.69732,
      'r6in.xlarge': 0.087165,
      'r7a.12xlarge': 0.9129,
      'r7a.16xlarge': 1.2172,
      'r7a.24xlarge': 1.8258,
      'r7a.2xlarge': 0.15215,
      'r7a.32xlarge': 2.4344,
      'r7a.48xlarge': 3.6516,
      'r7a.4xlarge': 0.3043,
      'r7a.8xlarge': 0.6086,
      'r7a.xlarge': 0.076075,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'r7iz.12xlarge': 1.116,
      'r7iz.16xlarge': 1.488,
      'r7iz.2xlarge': 0.186,
      'r7iz.32xlarge': 2.976,
      'r7iz.4xlarge': 0.372,
      'r7iz.8xlarge': 0.744,
      'r7iz.xlarge': 0.093,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2gd.12xlarge': 1.002,
      'x2gd.16xlarge': 1.336,
      'x2gd.2xlarge': 0.167,
      'x2gd.4xlarge': 0.334,
      'x2gd.8xlarge': 0.668,
      'x2gd.xlarge': 0.0835,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
    'us-east-1': {
      'c1.medium': 0.03,
      'c1.xlarge': 0.12,
      'c3.2xlarge': 0.105,
      'c3.4xlarge': 0.21,
      'c3.8xlarge': 0.27,
      'c3.xlarge': 0.053,
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5ad.12xlarge': 0.27,
      'c5ad.16xlarge': 0.27,
      'c5ad.24xlarge': 0.27,
      'c5ad.2xlarge': 0.086,
      'c5ad.4xlarge': 0.172,
      'c5ad.8xlarge': 0.27,
      'c5ad.xlarge': 0.043,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6id.12xlarge': 0.6048,
      'c6id.16xlarge': 0.8064,
      'c6id.24xlarge': 1.2096,
      'c6id.2xlarge': 0.1008,
      'c6id.32xlarge': 1.6128,
      'c6id.4xlarge': 0.2016,
      'c6id.8xlarge': 0.4032,
      'c6id.xlarge': 0.0504,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7a.12xlarge': 0.61584,
      'c7a.16xlarge': 0.82112,
      'c7a.24xlarge': 1.23168,
      'c7a.2xlarge': 0.10264,
      'c7a.32xlarge': 1.64224,
      'c7a.48xlarge': 2.46336,
      'c7a.4xlarge': 0.20528,
      'c7a.8xlarge': 0.41056,
      'c7a.xlarge': 0.05132,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7gn.12xlarge': 0.7488,
      'c7gn.16xlarge': 0.9984,
      'c7gn.2xlarge': 0.1248,
      'c7gn.4xlarge': 0.2496,
      'c7gn.8xlarge': 0.4992,
      'c7gn.xlarge': 0.0624,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'd3en.12xlarge': 0.27,
      'd3en.2xlarge': 0.26275,
      'd3en.4xlarge': 0.27,
      'd3en.6xlarge': 0.27,
      'd3en.8xlarge': 0.27,
      'd3en.xlarge': 0.1315,
      'g3.16xlarge': 0.27,
      'g3.4xlarge': 0.27,
      'g3.8xlarge': 0.27,
      'g3s.xlarge': 0.188,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'g5.12xlarge': 1.418,
      'g5.16xlarge': 1.024,
      'g5.24xlarge': 2.036,
      'g5.2xlarge': 0.303,
      'g5.48xlarge': 4.072,
      'g5.4xlarge': 0.406,
      'g5.8xlarge': 0.612,
      'g5.xlarge': 0.2515,
      'h1.16xlarge': 0.27,
      'h1.2xlarge': 0.117,
      'h1.4xlarge': 0.234,
      'h1.8xlarge': 0.27,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4g.16xlarge': 1.23552,
      'i4g.2xlarge': 0.15444,
      'i4g.4xlarge': 0.30888,
      'i4g.8xlarge': 0.61776,
      'i4g.xlarge': 0.07722,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'im4gn.16xlarge': 1.4551675,
      'im4gn.2xlarge': 0.181895,
      'im4gn.4xlarge': 0.3637925,
      'im4gn.8xlarge': 0.727585,
      'im4gn.xlarge': 0.0909475,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm1.large': 0.044,
      'm1.medium': 0.022,
      'm1.small': 0.011,
      'm1.xlarge': 0.088,
      'm2.2xlarge': 0.123,
      'm2.4xlarge': 0.246,
      'm2.xlarge': 0.062,
      'm3.2xlarge': 0.14,
      'm3.xlarge': 0.07,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm5dn.12xlarge': 0.27,
      'm5dn.16xlarge': 0.27,
      'm5dn.24xlarge': 0.27,
      'm5dn.2xlarge': 0.136,
      'm5dn.4xlarge': 0.27,
      'm5dn.8xlarge': 0.27,
      'm5dn.xlarge': 0.068,
      'm5n.12xlarge': 0.27,
      'm5n.16xlarge': 0.27,
      'm5n.24xlarge': 0.27,
      'm5n.2xlarge': 0.119,
      'm5n.4xlarge': 0.238,
      'm5n.8xlarge': 0.27,
      'm5n.xlarge': 0.0595,
      'm5zn.12xlarge': 0.27,
      'm5zn.2xlarge': 0.165175,
      'm5zn.3xlarge': 0.24775,
      'm5zn.6xlarge': 0.27,
      'm5zn.xlarge': 0.082575,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm6idn.12xlarge': 0.95472,
      'm6idn.16xlarge': 1.27296,
      'm6idn.24xlarge': 1.90944,
      'm6idn.2xlarge': 0.15912,
      'm6idn.32xlarge': 2.54592,
      'm6idn.4xlarge': 0.31824,
      'm6idn.8xlarge': 0.63648,
      'm6idn.xlarge': 0.07956,
      'm6in.12xlarge': 0.83538,
      'm6in.16xlarge': 1.11384,
      'm6in.24xlarge': 1.67076,
      'm6in.2xlarge': 0.13923,
      'm6in.32xlarge': 2.22768,
      'm6in.4xlarge': 0.27846,
      'm6in.8xlarge': 0.55692,
      'm6in.xlarge': 0.069615,
      'm7a.12xlarge': 0.69552,
      'm7a.16xlarge': 0.92736,
      'm7a.24xlarge': 1.39104,
      'm7a.2xlarge': 0.11592,
      'm7a.32xlarge': 1.85472,
      'm7a.48xlarge': 2.78208,
      'm7a.4xlarge': 0.23184,
      'm7a.8xlarge': 0.46368,
      'm7a.xlarge': 0.05796,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'p3.16xlarge': 0.27,
      'p3.2xlarge': 0.27,
      'p3.8xlarge': 0.27,
      'p5.48xlarge': 24.58,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5b.12xlarge': 0.27,
      'r5b.16xlarge': 0.27,
      'r5b.24xlarge': 0.27,
      'r5b.2xlarge': 0.149,
      'r5b.4xlarge': 0.27,
      'r5b.8xlarge': 0.27,
      'r5b.xlarge': 0.0745,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5dn.12xlarge': 0.27,
      'r5dn.16xlarge': 0.27,
      'r5dn.24xlarge': 0.27,
      'r5dn.2xlarge': 0.167,
      'r5dn.4xlarge': 0.27,
      'r5dn.8xlarge': 0.27,
      'r5dn.xlarge': 0.084,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r6idn.12xlarge': 1.17234,
      'r6idn.16xlarge': 1.56312,
      'r6idn.24xlarge': 2.34468,
      'r6idn.2xlarge': 0.19539,
      'r6idn.32xlarge': 3.12624,
      'r6idn.4xlarge': 0.39078,
      'r6idn.8xlarge': 0.78156,
      'r6idn.xlarge': 0.097695,
      'r6in.12xlarge': 1.04598,
      'r6in.16xlarge': 1.39464,
      'r6in.24xlarge': 2.09196,
      'r6in.2xlarge': 0.17433,
      'r6in.32xlarge': 2.78928,
      'r6in.4xlarge': 0.34866,
      'r6in.8xlarge': 0.69732,
      'r6in.xlarge': 0.087165,
      'r7a.12xlarge': 0.9129,
      'r7a.16xlarge': 1.2172,
      'r7a.24xlarge': 1.8258,
      'r7a.2xlarge': 0.15215,
      'r7a.32xlarge': 2.4344,
      'r7a.48xlarge': 3.6516,
      'r7a.4xlarge': 0.3043,
      'r7a.8xlarge': 0.6086,
      'r7a.xlarge': 0.076075,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'r7iz.12xlarge': 1.116,
      'r7iz.16xlarge': 1.488,
      'r7iz.2xlarge': 0.186,
      'r7iz.32xlarge': 2.976,
      'r7iz.4xlarge': 0.372,
      'r7iz.8xlarge': 0.744,
      'r7iz.xlarge': 0.093,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2gd.12xlarge': 1.002,
      'x2gd.16xlarge': 1.336,
      'x2gd.2xlarge': 0.167,
      'x2gd.4xlarge': 0.334,
      'x2gd.8xlarge': 0.668,
      'x2gd.xlarge': 0.0835,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
    'us-east-2': {
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5ad.12xlarge': 0.27,
      'c5ad.16xlarge': 0.27,
      'c5ad.24xlarge': 0.27,
      'c5ad.2xlarge': 0.086,
      'c5ad.4xlarge': 0.172,
      'c5ad.8xlarge': 0.27,
      'c5ad.xlarge': 0.043,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6id.12xlarge': 0.6048,
      'c6id.16xlarge': 0.8064,
      'c6id.24xlarge': 1.2096,
      'c6id.2xlarge': 0.1008,
      'c6id.32xlarge': 1.6128,
      'c6id.4xlarge': 0.2016,
      'c6id.8xlarge': 0.4032,
      'c6id.xlarge': 0.0504,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7a.12xlarge': 0.61584,
      'c7a.16xlarge': 0.82112,
      'c7a.24xlarge': 1.23168,
      'c7a.2xlarge': 0.10264,
      'c7a.32xlarge': 1.64224,
      'c7a.48xlarge': 2.46336,
      'c7a.4xlarge': 0.20528,
      'c7a.8xlarge': 0.41056,
      'c7a.xlarge': 0.05132,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7gn.12xlarge': 0.7488,
      'c7gn.16xlarge': 0.9984,
      'c7gn.2xlarge': 0.1248,
      'c7gn.4xlarge': 0.2496,
      'c7gn.8xlarge': 0.4992,
      'c7gn.xlarge': 0.0624,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'g3.16xlarge': 0.27,
      'g3.4xlarge': 0.27,
      'g3.8xlarge': 0.27,
      'g3s.xlarge': 0.188,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'g5.12xlarge': 1.418,
      'g5.16xlarge': 1.024,
      'g5.24xlarge': 2.036,
      'g5.2xlarge': 0.303,
      'g5.48xlarge': 4.072,
      'g5.4xlarge': 0.406,
      'g5.8xlarge': 0.612,
      'g5.xlarge': 0.2515,
      'h1.16xlarge': 0.27,
      'h1.2xlarge': 0.117,
      'h1.4xlarge': 0.234,
      'h1.8xlarge': 0.27,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4g.16xlarge': 1.23552,
      'i4g.2xlarge': 0.15444,
      'i4g.4xlarge': 0.30888,
      'i4g.8xlarge': 0.61776,
      'i4g.xlarge': 0.07722,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'im4gn.16xlarge': 1.4551675,
      'im4gn.2xlarge': 0.181895,
      'im4gn.4xlarge': 0.3637925,
      'im4gn.8xlarge': 0.727585,
      'im4gn.xlarge': 0.0909475,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm5dn.12xlarge': 0.27,
      'm5dn.16xlarge': 0.27,
      'm5dn.24xlarge': 0.27,
      'm5dn.2xlarge': 0.136,
      'm5dn.4xlarge': 0.27,
      'm5dn.8xlarge': 0.27,
      'm5dn.xlarge': 0.068,
      'm5n.12xlarge': 0.27,
      'm5n.16xlarge': 0.27,
      'm5n.24xlarge': 0.27,
      'm5n.2xlarge': 0.119,
      'm5n.4xlarge': 0.238,
      'm5n.8xlarge': 0.27,
      'm5n.xlarge': 0.0595,
      'm5zn.12xlarge': 0.27,
      'm5zn.2xlarge': 0.165175,
      'm5zn.3xlarge': 0.24775,
      'm5zn.6xlarge': 0.27,
      'm5zn.xlarge': 0.082575,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm6idn.12xlarge': 0.95472,
      'm6idn.16xlarge': 1.27296,
      'm6idn.24xlarge': 1.90944,
      'm6idn.2xlarge': 0.15912,
      'm6idn.32xlarge': 2.54592,
      'm6idn.4xlarge': 0.31824,
      'm6idn.8xlarge': 0.63648,
      'm6idn.xlarge': 0.07956,
      'm6in.12xlarge': 0.83538,
      'm6in.16xlarge': 1.11384,
      'm6in.24xlarge': 1.67076,
      'm6in.2xlarge': 0.13923,
      'm6in.32xlarge': 2.22768,
      'm6in.4xlarge': 0.27846,
      'm6in.8xlarge': 0.55692,
      'm6in.xlarge': 0.069615,
      'm7a.12xlarge': 0.69552,
      'm7a.16xlarge': 0.92736,
      'm7a.24xlarge': 1.39104,
      'm7a.2xlarge': 0.11592,
      'm7a.32xlarge': 1.85472,
      'm7a.48xlarge': 2.78208,
      'm7a.4xlarge': 0.23184,
      'm7a.8xlarge': 0.46368,
      'm7a.xlarge': 0.05796,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'p3.16xlarge': 0.27,
      'p3.2xlarge': 0.27,
      'p3.8xlarge': 0.27,
      'p5.48xlarge': 24.58,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5b.12xlarge': 0.27,
      'r5b.16xlarge': 0.27,
      'r5b.24xlarge': 0.27,
      'r5b.2xlarge': 0.149,
      'r5b.4xlarge': 0.27,
      'r5b.8xlarge': 0.27,
      'r5b.xlarge': 0.0745,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5dn.12xlarge': 0.27,
      'r5dn.16xlarge': 0.27,
      'r5dn.24xlarge': 0.27,
      'r5dn.2xlarge': 0.167,
      'r5dn.4xlarge': 0.27,
      'r5dn.8xlarge': 0.27,
      'r5dn.xlarge': 0.084,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r6idn.12xlarge': 1.17234,
      'r6idn.16xlarge': 1.56312,
      'r6idn.24xlarge': 2.34468,
      'r6idn.2xlarge': 0.19539,
      'r6idn.32xlarge': 3.12624,
      'r6idn.4xlarge': 0.39078,
      'r6idn.8xlarge': 0.78156,
      'r6idn.xlarge': 0.097695,
      'r6in.12xlarge': 1.04598,
      'r6in.16xlarge': 1.39464,
      'r6in.24xlarge': 2.09196,
      'r6in.2xlarge': 0.17433,
      'r6in.32xlarge': 2.78928,
      'r6in.4xlarge': 0.34866,
      'r6in.8xlarge': 0.69732,
      'r6in.xlarge': 0.087165,
      'r7a.12xlarge': 0.9129,
      'r7a.16xlarge': 1.2172,
      'r7a.24xlarge': 1.8258,
      'r7a.2xlarge': 0.15215,
      'r7a.32xlarge': 2.4344,
      'r7a.48xlarge': 3.6516,
      'r7a.4xlarge': 0.3043,
      'r7a.8xlarge': 0.6086,
      'r7a.xlarge': 0.076075,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'r7iz.12xlarge': 1.116,
      'r7iz.16xlarge': 1.488,
      'r7iz.2xlarge': 0.186,
      'r7iz.32xlarge': 2.976,
      'r7iz.4xlarge': 0.372,
      'r7iz.8xlarge': 0.744,
      'r7iz.xlarge': 0.093,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2gd.12xlarge': 1.002,
      'x2gd.16xlarge': 1.336,
      'x2gd.2xlarge': 0.167,
      'x2gd.4xlarge': 0.334,
      'x2gd.8xlarge': 0.668,
      'x2gd.xlarge': 0.0835,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
    'us-west-2': {
      'c1.medium': 0.03,
      'c1.xlarge': 0.12,
      'c3.2xlarge': 0.105,
      'c3.4xlarge': 0.21,
      'c3.8xlarge': 0.27,
      'c3.xlarge': 0.053,
      'c4.2xlarge': 0.105,
      'c4.4xlarge': 0.21,
      'c4.8xlarge': 0.27,
      'c4.large': 0.026,
      'c4.xlarge': 0.052,
      'c5.12xlarge': 0.27,
      'c5.18xlarge': 0.27,
      'c5.24xlarge': 0.27,
      'c5.2xlarge': 0.085,
      'c5.4xlarge': 0.17,
      'c5.9xlarge': 0.27,
      'c5.xlarge': 0.043,
      'c5a.12xlarge': 0.27,
      'c5a.16xlarge': 0.27,
      'c5a.24xlarge': 0.27,
      'c5a.2xlarge': 0.077,
      'c5a.4xlarge': 0.154,
      'c5a.8xlarge': 0.27,
      'c5a.xlarge': 0.0385,
      'c5ad.12xlarge': 0.27,
      'c5ad.16xlarge': 0.27,
      'c5ad.24xlarge': 0.27,
      'c5ad.2xlarge': 0.086,
      'c5ad.4xlarge': 0.172,
      'c5ad.8xlarge': 0.27,
      'c5ad.xlarge': 0.043,
      'c5d.12xlarge': 0.27,
      'c5d.18xlarge': 0.27,
      'c5d.24xlarge': 0.27,
      'c5d.2xlarge': 0.096,
      'c5d.4xlarge': 0.192,
      'c5d.9xlarge': 0.27,
      'c5d.xlarge': 0.048,
      'c5n.18xlarge': 0.27,
      'c5n.2xlarge': 0.108,
      'c5n.4xlarge': 0.216,
      'c5n.9xlarge': 0.27,
      'c5n.xlarge': 0.054,
      'c6a.12xlarge': 0.459,
      'c6a.16xlarge': 0.612,
      'c6a.24xlarge': 0.918,
      'c6a.2xlarge': 0.0765,
      'c6a.32xlarge': 1.224,
      'c6a.48xlarge': 1.836,
      'c6a.4xlarge': 0.153,
      'c6a.8xlarge': 0.306,
      'c6a.xlarge': 0.03825,
      'c6g.12xlarge': 0.408,
      'c6g.16xlarge': 0.544,
      'c6g.2xlarge': 0.068,
      'c6g.4xlarge': 0.136,
      'c6g.8xlarge': 0.272,
      'c6g.xlarge': 0.034,
      'c6gd.12xlarge': 0.4608,
      'c6gd.16xlarge': 0.6144,
      'c6gd.2xlarge': 0.0768,
      'c6gd.4xlarge': 0.1536,
      'c6gd.8xlarge': 0.3072,
      'c6gd.xlarge': 0.0384,
      'c6gn.12xlarge': 0.5184,
      'c6gn.16xlarge': 0.6912,
      'c6gn.2xlarge': 0.0864,
      'c6gn.4xlarge': 0.1728,
      'c6gn.8xlarge': 0.3456,
      'c6gn.xlarge': 0.0432,
      'c6i.12xlarge': 0.51,
      'c6i.16xlarge': 0.68,
      'c6i.24xlarge': 1.02,
      'c6i.2xlarge': 0.085,
      'c6i.32xlarge': 1.36,
      'c6i.4xlarge': 0.17,
      'c6i.8xlarge': 0.34,
      'c6i.xlarge': 0.0425,
      'c6id.12xlarge': 0.6048,
      'c6id.16xlarge': 0.8064,
      'c6id.24xlarge': 1.2096,
      'c6id.2xlarge': 0.1008,
      'c6id.32xlarge': 1.6128,
      'c6id.4xlarge': 0.2016,
      'c6id.8xlarge': 0.4032,
      'c6id.xlarge': 0.0504,
      'c6in.12xlarge': 0.6804,
      'c6in.16xlarge': 0.9072,
      'c6in.24xlarge': 1.3608,
      'c6in.2xlarge': 0.1134,
      'c6in.32xlarge': 1.8144,
      'c6in.4xlarge': 0.2268,
      'c6in.8xlarge': 0.4536,
      'c6in.xlarge': 0.0567,
      'c7a.12xlarge': 0.61584,
      'c7a.16xlarge': 0.82112,
      'c7a.24xlarge': 1.23168,
      'c7a.2xlarge': 0.10264,
      'c7a.32xlarge': 1.64224,
      'c7a.48xlarge': 2.46336,
      'c7a.4xlarge': 0.20528,
      'c7a.8xlarge': 0.41056,
      'c7a.xlarge': 0.05132,
      'c7g.12xlarge': 0.435,
      'c7g.16xlarge': 0.58,
      'c7g.2xlarge': 0.0725,
      'c7g.4xlarge': 0.145,
      'c7g.8xlarge': 0.29,
      'c7g.xlarge': 0.03625,
      'c7gd.12xlarge': 0.544325,
      'c7gd.16xlarge': 0.72575,
      'c7gd.2xlarge': 0.090725,
      'c7gd.4xlarge': 0.18145,
      'c7gd.8xlarge': 0.362875,
      'c7gd.xlarge': 0.04535,
      'c7gn.12xlarge': 0.7488,
      'c7gn.16xlarge': 0.9984,
      'c7gn.2xlarge': 0.1248,
      'c7gn.4xlarge': 0.2496,
      'c7gn.8xlarge': 0.4992,
      'c7gn.xlarge': 0.0624,
      'c7i.12xlarge': 0.5355,
      'c7i.16xlarge': 0.714,
      'c7i.24xlarge': 1.071,
      'c7i.2xlarge': 0.08925,
      'c7i.48xlarge': 2.142,
      'c7i.4xlarge': 0.1785,
      'c7i.8xlarge': 0.357,
      'c7i.xlarge': 0.044625,
      'd2.2xlarge': 0.27,
      'd2.4xlarge': 0.27,
      'd2.8xlarge': 0.27,
      'd2.xlarge': 0.173,
      'd3.2xlarge': 0.24975,
      'd3.4xlarge': 0.27,
      'd3.8xlarge': 0.27,
      'd3.xlarge': 0.12475,
      'd3en.12xlarge': 0.27,
      'd3en.2xlarge': 0.26275,
      'd3en.4xlarge': 0.27,
      'd3en.6xlarge': 0.27,
      'd3en.8xlarge': 0.27,
      'd3en.xlarge': 0.1315,
      'g3.16xlarge': 0.27,
      'g3.4xlarge': 0.27,
      'g3.8xlarge': 0.27,
      'g3s.xlarge': 0.188,
      'g4dn.12xlarge': 0.27,
      'g4dn.16xlarge': 0.27,
      'g4dn.2xlarge': 0.188,
      'g4dn.4xlarge': 0.27,
      'g4dn.8xlarge': 0.27,
      'g4dn.xlarge': 0.132,
      'g5.12xlarge': 1.418,
      'g5.16xlarge': 1.024,
      'g5.24xlarge': 2.036,
      'g5.2xlarge': 0.303,
      'g5.48xlarge': 4.072,
      'g5.4xlarge': 0.406,
      'g5.8xlarge': 0.612,
      'g5.xlarge': 0.2515,
      'h1.16xlarge': 0.27,
      'h1.2xlarge': 0.117,
      'h1.4xlarge': 0.234,
      'h1.8xlarge': 0.27,
      'i2.2xlarge': 0.27,
      'i2.4xlarge': 0.27,
      'i2.8xlarge': 0.27,
      'i2.xlarge': 0.213,
      'i3.16xlarge': 0.27,
      'i3.2xlarge': 0.156,
      'i3.4xlarge': 0.27,
      'i3.8xlarge': 0.27,
      'i3.xlarge': 0.078,
      'i3en.12xlarge': 0.27,
      'i3en.24xlarge': 0.27,
      'i3en.2xlarge': 0.226,
      'i3en.3xlarge': 0.27,
      'i3en.6xlarge': 0.27,
      'i3en.xlarge': 0.113,
      'i4g.16xlarge': 1.23552,
      'i4g.2xlarge': 0.15444,
      'i4g.4xlarge': 0.30888,
      'i4g.8xlarge': 0.61776,
      'i4g.xlarge': 0.07722,
      'i4i.12xlarge': 1.0295,
      'i4i.16xlarge': 1.37275,
      'i4i.24xlarge': 2.0592,
      'i4i.2xlarge': 0.1715,
      'i4i.32xlarge': 2.7456,
      'i4i.4xlarge': 0.34325,
      'i4i.8xlarge': 0.6865,
      'i4i.xlarge': 0.08575,
      'im4gn.16xlarge': 1.4551675,
      'im4gn.2xlarge': 0.181895,
      'im4gn.4xlarge': 0.3637925,
      'im4gn.8xlarge': 0.727585,
      'im4gn.xlarge': 0.0909475,
      'is4gen.2xlarge': 0.28815,
      'is4gen.4xlarge': 0.5763,
      'is4gen.8xlarge': 1.1526,
      'is4gen.xlarge': 0.144075,
      'm1.large': 0.044,
      'm1.medium': 0.022,
      'm1.small': 0.011,
      'm1.xlarge': 0.088,
      'm2.2xlarge': 0.123,
      'm2.4xlarge': 0.246,
      'm2.xlarge': 0.062,
      'm3.2xlarge': 0.14,
      'm3.xlarge': 0.07,
      'm4.10xlarge': 0.27,
      'm4.16xlarge': 0.27,
      'm4.2xlarge': 0.12,
      'm4.4xlarge': 0.24,
      'm4.large': 0.03,
      'm4.xlarge': 0.06,
      'm5.12xlarge': 0.27,
      'm5.16xlarge': 0.27,
      'm5.24xlarge': 0.27,
      'm5.2xlarge': 0.096,
      'm5.4xlarge': 0.192,
      'm5.8xlarge': 0.27,
      'm5.xlarge': 0.048,
      'm5a.12xlarge': 0.27,
      'm5a.16xlarge': 0.27,
      'm5a.24xlarge': 0.27,
      'm5a.2xlarge': 0.086,
      'm5a.4xlarge': 0.172,
      'm5a.8xlarge': 0.27,
      'm5a.xlarge': 0.043,
      'm5ad.12xlarge': 0.27,
      'm5ad.16xlarge': 0.27,
      'm5ad.24xlarge': 0.27,
      'm5ad.2xlarge': 0.103,
      'm5ad.4xlarge': 0.206,
      'm5ad.8xlarge': 0.27,
      'm5ad.xlarge': 0.052,
      'm5d.12xlarge': 0.27,
      'm5d.16xlarge': 0.27,
      'm5d.24xlarge': 0.27,
      'm5d.2xlarge': 0.113,
      'm5d.4xlarge': 0.226,
      'm5d.8xlarge': 0.27,
      'm5d.xlarge': 0.057,
      'm5dn.12xlarge': 0.27,
      'm5dn.16xlarge': 0.27,
      'm5dn.24xlarge': 0.27,
      'm5dn.2xlarge': 0.136,
      'm5dn.4xlarge': 0.27,
      'm5dn.8xlarge': 0.27,
      'm5dn.xlarge': 0.068,
      'm5n.12xlarge': 0.27,
      'm5n.16xlarge': 0.27,
      'm5n.24xlarge': 0.27,
      'm5n.2xlarge': 0.119,
      'm5n.4xlarge': 0.238,
      'm5n.8xlarge': 0.27,
      'm5n.xlarge': 0.0595,
      'm5zn.12xlarge': 0.27,
      'm5zn.2xlarge': 0.165175,
      'm5zn.3xlarge': 0.24775,
      'm5zn.6xlarge': 0.27,
      'm5zn.xlarge': 0.082575,
      'm6a.12xlarge': 0.5184,
      'm6a.16xlarge': 0.6912,
      'm6a.24xlarge': 1.0368,
      'm6a.2xlarge': 0.0864,
      'm6a.32xlarge': 1.3824,
      'm6a.48xlarge': 2.0736,
      'm6a.4xlarge': 0.1728,
      'm6a.8xlarge': 0.3456,
      'm6a.xlarge': 0.0432,
      'm6g.12xlarge': 0.462,
      'm6g.16xlarge': 0.616,
      'm6g.2xlarge': 0.077,
      'm6g.4xlarge': 0.154,
      'm6g.8xlarge': 0.308,
      'm6g.xlarge': 0.039,
      'm6gd.12xlarge': 0.5424,
      'm6gd.16xlarge': 0.7232,
      'm6gd.2xlarge': 0.0904,
      'm6gd.4xlarge': 0.1808,
      'm6gd.8xlarge': 0.3616,
      'm6gd.xlarge': 0.0452,
      'm6i.12xlarge': 0.576,
      'm6i.16xlarge': 0.768,
      'm6i.24xlarge': 1.152,
      'm6i.2xlarge': 0.096,
      'm6i.32xlarge': 1.536,
      'm6i.4xlarge': 0.192,
      'm6i.8xlarge': 0.384,
      'm6i.xlarge': 0.048,
      'm6id.12xlarge': 0.7119,
      'm6id.16xlarge': 0.9492,
      'm6id.24xlarge': 1.4238,
      'm6id.2xlarge': 0.11865,
      'm6id.32xlarge': 1.8984,
      'm6id.4xlarge': 0.2373,
      'm6id.8xlarge': 0.4746,
      'm6id.xlarge': 0.059325,
      'm6idn.12xlarge': 0.95472,
      'm6idn.16xlarge': 1.27296,
      'm6idn.24xlarge': 1.90944,
      'm6idn.2xlarge': 0.15912,
      'm6idn.32xlarge': 2.54592,
      'm6idn.4xlarge': 0.31824,
      'm6idn.8xlarge': 0.63648,
      'm6idn.xlarge': 0.07956,
      'm6in.12xlarge': 0.83538,
      'm6in.16xlarge': 1.11384,
      'm6in.24xlarge': 1.67076,
      'm6in.2xlarge': 0.13923,
      'm6in.32xlarge': 2.22768,
      'm6in.4xlarge': 0.27846,
      'm6in.8xlarge': 0.55692,
      'm6in.xlarge': 0.069615,
      'm7a.12xlarge': 0.69552,
      'm7a.16xlarge': 0.92736,
      'm7a.24xlarge': 1.39104,
      'm7a.2xlarge': 0.11592,
      'm7a.32xlarge': 1.85472,
      'm7a.48xlarge': 2.78208,
      'm7a.4xlarge': 0.23184,
      'm7a.8xlarge': 0.46368,
      'm7a.xlarge': 0.05796,
      'm7g.12xlarge': 0.4896,
      'm7g.16xlarge': 0.6528,
      'm7g.2xlarge': 0.0816,
      'm7g.4xlarge': 0.1632,
      'm7g.8xlarge': 0.3264,
      'm7g.xlarge': 0.0408,
      'm7gd.12xlarge': 0.6407,
      'm7gd.16xlarge': 0.854275,
      'm7gd.2xlarge': 0.106775,
      'm7gd.4xlarge': 0.213575,
      'm7gd.8xlarge': 0.42715,
      'm7gd.xlarge': 0.0534,
      'm7i-flex.2xlarge': 0.09576,
      'm7i-flex.4xlarge': 0.19152,
      'm7i-flex.8xlarge': 0.38304,
      'm7i-flex.xlarge': 0.04788,
      'm7i.12xlarge': 0.6048,
      'm7i.16xlarge': 0.8064,
      'm7i.24xlarge': 1.2096,
      'm7i.2xlarge': 0.1008,
      'm7i.48xlarge': 2.4192,
      'm7i.4xlarge': 0.2016,
      'm7i.8xlarge': 0.4032,
      'm7i.xlarge': 0.0504,
      'p2.16xlarge': 0.27,
      'p2.8xlarge': 0.27,
      'p2.xlarge': 0.225,
      'p3.16xlarge': 0.27,
      'p3.2xlarge': 0.27,
      'p3.8xlarge': 0.27,
      'p5.48xlarge': 24.58,
      'r3.2xlarge': 0.18,
      'r3.4xlarge': 0.27,
      'r3.8xlarge': 0.27,
      'r3.xlarge': 0.09,
      'r4.16xlarge': 0.27,
      'r4.2xlarge': 0.133,
      'r4.4xlarge': 0.266,
      'r4.8xlarge': 0.27,
      'r4.xlarge': 0.067,
      'r5.12xlarge': 0.27,
      'r5.16xlarge': 0.27,
      'r5.24xlarge': 0.27,
      'r5.2xlarge': 0.126,
      'r5.4xlarge': 0.252,
      'r5.8xlarge': 0.27,
      'r5.xlarge': 0.063,
      'r5a.12xlarge': 0.27,
      'r5a.16xlarge': 0.27,
      'r5a.24xlarge': 0.27,
      'r5a.2xlarge': 0.113,
      'r5a.4xlarge': 0.226,
      'r5a.8xlarge': 0.27,
      'r5a.xlarge': 0.057,
      'r5ad.12xlarge': 0.27,
      'r5ad.16xlarge': 0.27,
      'r5ad.24xlarge': 0.27,
      'r5ad.2xlarge': 0.131,
      'r5ad.4xlarge': 0.262,
      'r5ad.8xlarge': 0.27,
      'r5ad.xlarge': 0.066,
      'r5b.12xlarge': 0.27,
      'r5b.16xlarge': 0.27,
      'r5b.24xlarge': 0.27,
      'r5b.2xlarge': 0.149,
      'r5b.4xlarge': 0.27,
      'r5b.8xlarge': 0.27,
      'r5b.xlarge': 0.0745,
      'r5d.12xlarge': 0.27,
      'r5d.16xlarge': 0.27,
      'r5d.24xlarge': 0.27,
      'r5d.2xlarge': 0.144,
      'r5d.4xlarge': 0.27,
      'r5d.8xlarge': 0.27,
      'r5d.xlarge': 0.072,
      'r5dn.12xlarge': 0.27,
      'r5dn.16xlarge': 0.27,
      'r5dn.24xlarge': 0.27,
      'r5dn.2xlarge': 0.167,
      'r5dn.4xlarge': 0.27,
      'r5dn.8xlarge': 0.27,
      'r5dn.xlarge': 0.084,
      'r5n.12xlarge': 0.27,
      'r5n.16xlarge': 0.27,
      'r5n.24xlarge': 0.27,
      'r5n.2xlarge': 0.149,
      'r5n.4xlarge': 0.27,
      'r5n.8xlarge': 0.27,
      'r5n.xlarge': 0.0745,
      'r6a.12xlarge': 0.6804,
      'r6a.16xlarge': 0.9072,
      'r6a.24xlarge': 1.3608,
      'r6a.2xlarge': 0.1134,
      'r6a.32xlarge': 1.8144,
      'r6a.48xlarge': 2.7216,
      'r6a.4xlarge': 0.2268,
      'r6a.8xlarge': 0.4536,
      'r6a.xlarge': 0.0567,
      'r6g.12xlarge': 0.6048,
      'r6g.16xlarge': 0.8064,
      'r6g.2xlarge': 0.1008,
      'r6g.4xlarge': 0.2016,
      'r6g.8xlarge': 0.4032,
      'r6g.xlarge': 0.0504,
      'r6gd.12xlarge': 0.6912,
      'r6gd.16xlarge': 0.9216,
      'r6gd.2xlarge': 0.1152,
      'r6gd.4xlarge': 0.2304,
      'r6gd.8xlarge': 0.4608,
      'r6gd.xlarge': 0.0576,
      'r6i.12xlarge': 0.756,
      'r6i.16xlarge': 1.008,
      'r6i.24xlarge': 1.512,
      'r6i.2xlarge': 0.126,
      'r6i.32xlarge': 2.016,
      'r6i.4xlarge': 0.252,
      'r6i.8xlarge': 0.504,
      'r6i.xlarge': 0.063,
      'r6id.12xlarge': 0.9072,
      'r6id.16xlarge': 1.2096,
      'r6id.24xlarge': 1.8144,
      'r6id.2xlarge': 0.1512,
      'r6id.32xlarge': 2.4192,
      'r6id.4xlarge': 0.3024,
      'r6id.8xlarge': 0.6048,
      'r6id.xlarge': 0.0756,
      'r6idn.12xlarge': 1.17234,
      'r6idn.16xlarge': 1.56312,
      'r6idn.24xlarge': 2.34468,
      'r6idn.2xlarge': 0.19539,
      'r6idn.32xlarge': 3.12624,
      'r6idn.4xlarge': 0.39078,
      'r6idn.8xlarge': 0.78156,
      'r6idn.xlarge': 0.097695,
      'r6in.12xlarge': 1.04598,
      'r6in.16xlarge': 1.39464,
      'r6in.24xlarge': 2.09196,
      'r6in.2xlarge': 0.17433,
      'r6in.32xlarge': 2.78928,
      'r6in.4xlarge': 0.34866,
      'r6in.8xlarge': 0.69732,
      'r6in.xlarge': 0.087165,
      'r7a.12xlarge': 0.9129,
      'r7a.16xlarge': 1.2172,
      'r7a.24xlarge': 1.8258,
      'r7a.2xlarge': 0.15215,
      'r7a.32xlarge': 2.4344,
      'r7a.48xlarge': 3.6516,
      'r7a.4xlarge': 0.3043,
      'r7a.8xlarge': 0.6086,
      'r7a.xlarge': 0.076075,
      'r7g.12xlarge': 0.6426,
      'r7g.16xlarge': 0.8568,
      'r7g.2xlarge': 0.1071,
      'r7g.4xlarge': 0.2142,
      'r7g.8xlarge': 0.4284,
      'r7g.xlarge': 0.05355,
      'r7gd.12xlarge': 0.816475,
      'r7gd.16xlarge': 1.08865,
      'r7gd.2xlarge': 0.136075,
      'r7gd.4xlarge': 0.27215,
      'r7gd.8xlarge': 0.544325,
      'r7gd.xlarge': 0.06805,
      'r7i.12xlarge': 0.7938,
      'r7i.16xlarge': 1.0584,
      'r7i.24xlarge': 1.5876,
      'r7i.2xlarge': 0.1323,
      'r7i.48xlarge': 3.1752,
      'r7i.4xlarge': 0.2646,
      'r7i.8xlarge': 0.5292,
      'r7i.xlarge': 0.06615,
      'r7iz.12xlarge': 1.116,
      'r7iz.16xlarge': 1.488,
      'r7iz.2xlarge': 0.186,
      'r7iz.32xlarge': 2.976,
      'r7iz.4xlarge': 0.372,
      'r7iz.8xlarge': 0.744,
      'r7iz.xlarge': 0.093,
      'x1.16xlarge': 1.66725,
      'x1.32xlarge': 3.3345,
      'x1e.16xlarge': 3.336,
      'x1e.2xlarge': 0.417,
      'x1e.32xlarge': 6.672,
      'x1e.4xlarge': 0.834,
      'x1e.8xlarge': 1.668,
      'x1e.xlarge': 0.2085,
      'x2gd.12xlarge': 1.002,
      'x2gd.16xlarge': 1.336,
      'x2gd.2xlarge': 0.167,
      'x2gd.4xlarge': 0.334,
      'x2gd.8xlarge': 0.668,
      'x2gd.xlarge': 0.0835,
      'x2idn.16xlarge': 1.66725,
      'x2idn.24xlarge': 2.500875,
      'x2idn.32xlarge': 3.3345,
      'x2iedn.16xlarge': 3.3345,
      'x2iedn.24xlarge': 5.00175,
      'x2iedn.2xlarge': 0.4168125,
      'x2iedn.32xlarge': 6.669,
      'x2iedn.4xlarge': 0.833625,
      'x2iedn.8xlarge': 1.66725,
      'x2iedn.xlarge': 0.2084075,
      'z1d.12xlarge': 0.27,
      'z1d.2xlarge': 0.186,
      'z1d.3xlarge': 0.27,
      'z1d.6xlarge': 0.27,
      'z1d.xlarge': 0.093,
    },
  },
};
export default EmrPrices;
