import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { StepButtonClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmStepButton:
  | Partial<OverridesStyleRules<keyof StepButtonClasses>>
  | undefined = {
  root: {
    '& .MuiStepIcon-root': {
      fill: `${palette.props.gray10}`,
    },
    '& .MuiStepIcon-root.Mui-active': {
      fill: `${palette.props.tealMedium}`,
    },
    '& .MuiStepIcon-root.Mui-active > .MuiStepIcon-text': {
      fill: `${palette.props.white}`,
    },
  },
};

export default LmStepButton;
