import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LibertyMutualIcon } from './icons/LibertyMutualIcon';
import { useAuth } from './hooks/useAuth';
import useWindowSize from './hooks/UseWindowSize';
import SocialLinks from './components/social/SocialLinks';
import { useLocation } from 'react-router';

const RELEASE = process.env.REACT_APP_RELEASE_VERSION;
const LDL = process.env.REACT_APP_LDL_USER === 'true';
const SUPPORT_LINK = process.env.REACT_APP_SUPPORT_URL;

export const Footer = () => {
  const location = useLocation();

  const auth = useAuth();
  const theme = useTheme();
  const [width] = useWindowSize();

  const displayFooterInfo = !LDL || auth?.isAuthenticated;
  const versionText = displayFooterInfo ? ` | ${RELEASE} | ` : '';

  if (location.pathname === '/azure/libertygpt') {
    return null;
  }

  return (
    <div>
      <Box
        p={0}
        m={0}
        display={'inline-block'}
        bgcolor={theme.palette.secondary.main}
        width={'100%'}
        minHeight={260}
        style={{ position: 'absolute', zIndex: 700, marginTop: 30 }}
      >
        <Container maxWidth={'lg'} style={{ marginTop: 40 }}>
          <Box m={1} p={1}>
            <Box display="flex" p={1}>
              <LibertyMutualIcon />
              <Box flexGrow={1} />
              {width > 400 && !LDL && <SocialLinks />}
            </Box>
            <Box display="flex" p={1}>
              <Typography style={{ color: 'white', marginRight: 3 }}>
                &copy; {new Date().getFullYear()} Liberty Mutual Group
                {versionText}
                {displayFooterInfo && (
                  <Link
                    style={{ color: 'white' }}
                    href={SUPPORT_LINK}
                    target="_blank"
                  >
                    <u>Support</u>
                  </Link>
                )}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default Footer;
