import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TabClasses } from '@mui/material';
import { snow0, snow2, frost0 } from './constants';

const DarkTab: Partial<OverridesStyleRules<keyof TabClasses>> | undefined = {
  root: {
    color: snow0,
    textTransform: 'inherit',
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: snow2,
    },
    '&:hover': {
      color: frost0,
    },
    '&:focus': {
      color: frost0,
    },
  },
};

export default DarkTab;
