import React from 'react';
import {
  CortexApiAccessTokenResponseType,
  cortexApiTokenSlice,
  getApiAccessTokenThunk,
  getCortexApiAccessTokenState,
  getCortexTempApiAccessTokenState,
  getTempApiAccessTokenThunk,
} from '../../routes/member/account/slices/cortexApiTokenSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { useAuth } from '../../hooks/useAuth';
import { useInjectReducer } from '../../redux/InjectReducer';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockClockIcon from '@mui/icons-material/LockClock';
import HttpsIcon from '@mui/icons-material/Https';
import { CopyIconButton } from '../helpers/CopyIconButton';
import DoneIcon from '@mui/icons-material/Done';
import CircularLoader from '../system/CircularLoader';
import {
  copyTextToClipboard,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';
import { useTheme } from '@mui/material/styles';
import { CortexMenuItem } from '../system/CortexMenu';

type CopyApiTokenType = 'TEMP' | 'USER';
const COPY_API_TOKEN_KEY = 'copyApiTokenType';

interface CopyApiTokenProps {
  variant?: CopyApiTokenType;
  tooltip?: string;
  token?: string | null;

  [x: string]: any;
}

export const CopyApiToken = (props: CopyApiTokenProps) => {
  useInjectReducer({
    key: 'cortexApiToken',
    reducer: cortexApiTokenSlice.reducer,
  });
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    getLocalStorageItem(COPY_API_TOKEN_KEY) === 'USER' ? 1 : 0
  );
  const [copy, setCopy] = React.useState(false);

  const { token } = props;
  const cortexApiAccessTokenState = useAppSelector(
    getCortexApiAccessTokenState
  );
  const cortexApiTempAccessTokenState = useAppSelector(
    getCortexTempApiAccessTokenState
  );

  const handleRequest = async (cb: any, tokenType = 'TEMP') => {
    if (token) {
      return cb(token);
    } else {
      let variant = tokenType;

      if (props?.variant) {
        variant = props.variant;
      }

      dispatch(
        variant === 'TEMP'
          ? getTempApiAccessTokenThunk({
              token: auth?.cortexLabAccessToken,
              variant: variant,
              cb: (data: CortexApiAccessTokenResponseType) =>
                cb(data?.token || ''),
            })
          : getApiAccessTokenThunk({
              token: auth?.cortexLabAccessToken,
              variant: variant,
              cb: (data: CortexApiAccessTokenResponseType) =>
                cb(data?.token || ''),
            })
      );
    }
  };

  const loading =
    cortexApiTempAccessTokenState?.loading ||
    cortexApiAccessTokenState?.loading ||
    false;

  const options = [
    {
      icon: <LockClockIcon />,
      text: 'Single Use Token (15 min)',
      value: 'TEMP',
    },
    { icon: <HttpsIcon />, text: 'User Token (10 day)', value: 'USER' },
  ];

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    setLocalStorageItem(COPY_API_TOKEN_KEY, index === 0 ? 'TEMP' : 'USER');
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleTimeout = () => {
    setTimeout(() => {
      setCopy(false);
    }, 1800);
  };

  const actionCallback = async (text: string) => {
    setCopy(true);
    await copyTextToClipboard(text || '');
    handleTimeout();
  };

  return (
    <>
      {auth?.isAuthenticated && (
        <>
          {props.variant ? (
            <CopyIconButton
              tooltip={props.tooltip || 'Copy API Token'}
              handleClick={handleRequest}
              icon={<VpnKeyIcon />}
              loading={loading}
              {...props}
            />
          ) : (
            <React.Fragment>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
              >
                <Tooltip title={'Copy API Token'}>
                  <Button
                    onClick={async () => {
                      await handleRequest(
                        actionCallback,
                        options[selectedIndex].value
                      );
                    }}
                  >
                    {!loading && !copy && options[selectedIndex].icon}
                    {!loading && copy && <DoneIcon />}
                    {loading && (
                      <CircularLoader
                        variant={'primary'}
                        styles={{
                          color: theme.palette.background.default,
                          margin: 0,
                          width: 20,
                          height: 20,
                        }}
                      />
                    )}
                  </Button>
                </Tooltip>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => {
                    setOpen((prevOpen) => !prevOpen);
                  }}
                  style={{ padding: 0, minWidth: '30px' }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <CortexMenuItem
                              key={option.text}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              <ListItemIcon>{option.icon}</ListItemIcon>
                              <ListItemText>{option.text}</ListItemText>
                            </CortexMenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          )}
        </>
      )}
      {!auth?.isAuthenticated && <></>}
    </>
  );
};
export default CopyApiToken;
