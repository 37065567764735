import { SwitchClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmSwitch: Partial<OverridesStyleRules<keyof SwitchClasses>> | undefined =
  {
    switchBase: {
      color: palette.props.gray25,
      '&.Mui-checked': {
        color: palette.props.tealMedium,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: palette.props.tealMedium,
      },
      '&.Mui-checked + :hover': {
        backgroundColor: palette.props.tealMedium,
      },
      '&.Mui-checked:hover, &:hover, &.Mui-focusVisible': {
        backgroundColor: `${palette.props.tealMedium}40`,
      },
    },
    track: {
      backgroundColor: palette.props.gray25,
    },
  };

export default LmSwitch;
