import { AwsPrices } from './aws-prices-details';
const Ec2Prices: AwsPrices = {
  lastUpdated: 1713282167912,
  publishedDate: '2024-04-08T19:21:54Z',
  prices: {
    'ap-south-1': {
      'a1.2xlarge': 0.204,
      'a1.4xlarge': 0.408,
      'a1.large': 0.051,
      'a1.medium': 0.0255,
      'a1.metal': 0.408,
      'a1.xlarge': 0.102,
      'c4.2xlarge': 0.4,
      'c4.4xlarge': 0.8,
      'c4.8xlarge': 1.6,
      'c4.large': 0.1,
      'c4.xlarge': 0.2,
      'c5.12xlarge': 2.04,
      'c5.18xlarge': 3.06,
      'c5.24xlarge': 4.08,
      'c5.2xlarge': 0.34,
      'c5.4xlarge': 0.68,
      'c5.9xlarge': 1.53,
      'c5.large': 0.085,
      'c5.metal': 4.08,
      'c5.xlarge': 0.17,
      'c5a.12xlarge': 1.128,
      'c5a.16xlarge': 1.504,
      'c5a.24xlarge': 2.256,
      'c5a.2xlarge': 0.188,
      'c5a.4xlarge': 0.376,
      'c5a.8xlarge': 0.752,
      'c5a.large': 0.047,
      'c5a.xlarge': 0.094,
      'c5d.12xlarge': 2.376,
      'c5d.18xlarge': 3.564,
      'c5d.24xlarge': 4.752,
      'c5d.2xlarge': 0.396,
      'c5d.4xlarge': 0.792,
      'c5d.9xlarge': 1.782,
      'c5d.large': 0.099,
      'c5d.metal': 4.752,
      'c5d.xlarge': 0.198,
      'c5n.18xlarge': 3.888,
      'c5n.2xlarge': 0.432,
      'c5n.4xlarge': 0.864,
      'c5n.9xlarge': 1.944,
      'c5n.large': 0.108,
      'c5n.metal': 3.888,
      'c5n.xlarge': 0.216,
      'c6a.12xlarge': 1.122,
      'c6a.16xlarge': 1.496,
      'c6a.24xlarge': 2.244,
      'c6a.2xlarge': 0.187,
      'c6a.32xlarge': 2.992,
      'c6a.48xlarge': 4.488,
      'c6a.4xlarge': 0.374,
      'c6a.8xlarge': 0.748,
      'c6a.large': 0.04675,
      'c6a.metal': 4.488,
      'c6a.xlarge': 0.0935,
      'c6g.12xlarge': 1.0224,
      'c6g.16xlarge': 1.3632,
      'c6g.2xlarge': 0.1704,
      'c6g.4xlarge': 0.3408,
      'c6g.8xlarge': 0.6816,
      'c6g.large': 0.0426,
      'c6g.medium': 0.0213,
      'c6g.metal': 1.3632,
      'c6g.xlarge': 0.0852,
      'c6gd.12xlarge': 1.176,
      'c6gd.16xlarge': 1.568,
      'c6gd.2xlarge': 0.196,
      'c6gd.4xlarge': 0.392,
      'c6gd.8xlarge': 0.784,
      'c6gd.large': 0.049,
      'c6gd.medium': 0.0245,
      'c6gd.metal': 1.568,
      'c6gd.xlarge': 0.098,
      'c6gn.12xlarge': 2.076,
      'c6gn.16xlarge': 2.768,
      'c6gn.2xlarge': 0.346,
      'c6gn.4xlarge': 0.692,
      'c6gn.8xlarge': 1.384,
      'c6gn.large': 0.0865,
      'c6gn.medium': 0.04325,
      'c6gn.xlarge': 0.173,
      'c6i.12xlarge': 2.04,
      'c6i.16xlarge': 2.72,
      'c6i.24xlarge': 4.08,
      'c6i.2xlarge': 0.34,
      'c6i.32xlarge': 5.44,
      'c6i.4xlarge': 0.68,
      'c6i.8xlarge': 1.36,
      'c6i.large': 0.085,
      'c6i.metal': 5.44,
      'c6i.xlarge': 0.17,
      'c6in.12xlarge': 2.7216,
      'c6in.16xlarge': 3.6288,
      'c6in.24xlarge': 5.4432,
      'c6in.2xlarge': 0.4536,
      'c6in.32xlarge': 7.2576,
      'c6in.4xlarge': 0.9072,
      'c6in.8xlarge': 1.8144,
      'c6in.large': 0.1134,
      'c6in.metal': 7.2576,
      'c6in.xlarge': 0.2268,
      'c7g.12xlarge': 1.1779,
      'c7g.16xlarge': 1.5706,
      'c7g.2xlarge': 0.1963,
      'c7g.4xlarge': 0.3926,
      'c7g.8xlarge': 0.7853,
      'c7g.large': 0.0491,
      'c7g.medium': 0.0245,
      'c7g.metal': 1.5706,
      'c7g.xlarge': 0.0982,
      'c7gd.12xlarge': 1.3824,
      'c7gd.16xlarge': 1.8432,
      'c7gd.2xlarge': 0.2304,
      'c7gd.4xlarge': 0.4608,
      'c7gd.8xlarge': 0.9216,
      'c7gd.large': 0.0576,
      'c7gd.medium': 0.0288,
      'c7gd.metal': 1.8432,
      'c7gd.xlarge': 0.1152,
      'c7i.12xlarge': 2.142,
      'c7i.16xlarge': 2.856,
      'c7i.24xlarge': 4.284,
      'c7i.2xlarge': 0.357,
      'c7i.48xlarge': 8.568,
      'c7i.4xlarge': 0.714,
      'c7i.8xlarge': 1.428,
      'c7i.large': 0.08925,
      'c7i.metal-24xl': 4.284,
      'c7i.metal-48xl': 8.568,
      'c7i.xlarge': 0.1785,
      'd2.2xlarge': 1.653,
      'd2.4xlarge': 3.306,
      'd2.8xlarge': 6.612,
      'd2.xlarge': 0.827,
      'd3.2xlarge': 1.047,
      'd3.4xlarge': 2.095,
      'd3.8xlarge': 4.18904,
      'd3.xlarge': 0.524,
      'g4dn.12xlarge': 4.306,
      'g4dn.16xlarge': 4.791,
      'g4dn.2xlarge': 0.828,
      'g4dn.4xlarge': 1.325,
      'g4dn.8xlarge': 2.395,
      'g4dn.metal': 8.612,
      'g4dn.xlarge': 0.579,
      'g5.12xlarge': 6.81091,
      'g5.16xlarge': 4.91846,
      'g5.24xlarge': 9.77928,
      'g5.2xlarge': 1.45536,
      'g5.48xlarge': 19.55855,
      'g5.4xlarge': 1.95009,
      'g5.8xlarge': 2.93955,
      'g5.xlarge': 1.208,
      'i2.2xlarge': 1.933,
      'i2.4xlarge': 3.867,
      'i2.8xlarge': 7.733,
      'i2.xlarge': 0.967,
      'i3.16xlarge': 5.664,
      'i3.2xlarge': 0.708,
      'i3.4xlarge': 1.416,
      'i3.8xlarge': 2.832,
      'i3.large': 0.177,
      'i3.metal': 5.664,
      'i3.xlarge': 0.354,
      'i3en.12xlarge': 6.168,
      'i3en.24xlarge': 12.336,
      'i3en.2xlarge': 1.028,
      'i3en.3xlarge': 1.542,
      'i3en.6xlarge': 3.084,
      'i3en.large': 0.257,
      'i3en.metal': 12.336,
      'i3en.xlarge': 0.514,
      'i4i.12xlarge': 4.673,
      'i4i.16xlarge': 6.23,
      'i4i.24xlarge': 9.3456,
      'i4i.2xlarge': 0.779,
      'i4i.32xlarge': 12.4608,
      'i4i.4xlarge': 1.558,
      'i4i.8xlarge': 3.115,
      'i4i.large': 0.195,
      'i4i.metal': 12.461,
      'i4i.xlarge': 0.389,
      'inf1.24xlarge': 4.965,
      'inf1.2xlarge': 0.381,
      'inf1.6xlarge': 1.241,
      'inf1.xlarge': 0.24,
      'inf2.24xlarge': 8.43782,
      'inf2.48xlarge': 16.87565,
      'inf2.8xlarge': 2.55822,
      'inf2.xlarge': 0.98566,
      'is4gen.2xlarge': 1.31,
      'is4gen.4xlarge': 2.62,
      'is4gen.8xlarge': 5.24,
      'is4gen.large': 0.3275,
      'is4gen.medium': 0.16375,
      'is4gen.xlarge': 0.655,
      'm4.10xlarge': 2.1,
      'm4.16xlarge': 3.36,
      'm4.2xlarge': 0.42,
      'm4.4xlarge': 0.84,
      'm4.large': 0.105,
      'm4.xlarge': 0.21,
      'm5.12xlarge': 2.424,
      'm5.16xlarge': 3.232,
      'm5.24xlarge': 4.848,
      'm5.2xlarge': 0.404,
      'm5.4xlarge': 0.808,
      'm5.8xlarge': 1.616,
      'm5.large': 0.101,
      'm5.metal': 4.848,
      'm5.xlarge': 0.202,
      'm5a.12xlarge': 1.333,
      'm5a.16xlarge': 1.778,
      'm5a.24xlarge': 2.666,
      'm5a.2xlarge': 0.222,
      'm5a.4xlarge': 0.444,
      'm5a.8xlarge': 0.889,
      'm5a.large': 0.056,
      'm5a.xlarge': 0.111,
      'm5ad.12xlarge': 1.61,
      'm5ad.16xlarge': 2.147,
      'm5ad.24xlarge': 3.221,
      'm5ad.2xlarge': 0.268,
      'm5ad.4xlarge': 0.537,
      'm5ad.8xlarge': 1.074,
      'm5ad.large': 0.067,
      'm5ad.xlarge': 0.134,
      'm5d.12xlarge': 2.928,
      'm5d.16xlarge': 3.904,
      'm5d.24xlarge': 5.856,
      'm5d.2xlarge': 0.488,
      'm5d.4xlarge': 0.976,
      'm5d.8xlarge': 1.952,
      'm5d.large': 0.122,
      'm5d.metal': 5.856,
      'm5d.xlarge': 0.244,
      'm6a.12xlarge': 1.3332,
      'm6a.16xlarge': 1.7776,
      'm6a.24xlarge': 2.6664,
      'm6a.2xlarge': 0.2222,
      'm6a.32xlarge': 3.5552,
      'm6a.48xlarge': 5.3328,
      'm6a.4xlarge': 0.4444,
      'm6a.8xlarge': 0.8888,
      'm6a.large': 0.05555,
      'm6a.metal': 5.3328,
      'm6a.xlarge': 0.1111,
      'm6g.12xlarge': 1.2144,
      'm6g.16xlarge': 1.6192,
      'm6g.2xlarge': 0.2024,
      'm6g.4xlarge': 0.4048,
      'm6g.8xlarge': 0.8096,
      'm6g.large': 0.0506,
      'm6g.medium': 0.0253,
      'm6g.metal': 1.6192,
      'm6g.xlarge': 0.1012,
      'm6gd.12xlarge': 1.4496,
      'm6gd.16xlarge': 1.9328,
      'm6gd.2xlarge': 0.2416,
      'm6gd.4xlarge': 0.4832,
      'm6gd.8xlarge': 0.9664,
      'm6gd.large': 0.0604,
      'm6gd.medium': 0.0302,
      'm6gd.metal': 1.9328,
      'm6gd.xlarge': 0.1208,
      'm6i.12xlarge': 2.424,
      'm6i.16xlarge': 3.232,
      'm6i.24xlarge': 4.848,
      'm6i.2xlarge': 0.404,
      'm6i.32xlarge': 6.464,
      'm6i.4xlarge': 0.808,
      'm6i.8xlarge': 1.616,
      'm6i.large': 0.101,
      'm6i.metal': 6.464,
      'm6i.xlarge': 0.202,
      'm6id.12xlarge': 3.0744,
      'm6id.16xlarge': 4.0992,
      'm6id.24xlarge': 6.1488,
      'm6id.2xlarge': 0.5124,
      'm6id.32xlarge': 8.1984,
      'm6id.4xlarge': 1.0248,
      'm6id.8xlarge': 2.0496,
      'm6id.large': 0.1281,
      'm6id.metal': 8.1984,
      'm6id.xlarge': 0.2562,
      'm7g.12xlarge': 1.3997,
      'm7g.16xlarge': 1.8662,
      'm7g.2xlarge': 0.2333,
      'm7g.4xlarge': 0.4666,
      'm7g.8xlarge': 0.9331,
      'm7g.large': 0.0583,
      'm7g.medium': 0.0292,
      'm7g.metal': 1.8662,
      'm7g.xlarge': 0.1166,
      'm7gd.12xlarge': 1.704,
      'm7gd.16xlarge': 2.272,
      'm7gd.2xlarge': 0.284,
      'm7gd.4xlarge': 0.568,
      'm7gd.8xlarge': 1.136,
      'm7gd.large': 0.071,
      'm7gd.medium': 0.0355,
      'm7gd.metal': 2.272,
      'm7gd.xlarge': 0.142,
      'm7i-flex.2xlarge': 0.403,
      'm7i-flex.4xlarge': 0.806,
      'm7i-flex.8xlarge': 1.612,
      'm7i-flex.large': 0.10075,
      'm7i-flex.xlarge': 0.2015,
      'm7i.12xlarge': 2.5452,
      'm7i.16xlarge': 3.3936,
      'm7i.24xlarge': 5.0904,
      'm7i.2xlarge': 0.4242,
      'm7i.48xlarge': 10.1808,
      'm7i.4xlarge': 0.8484,
      'm7i.8xlarge': 1.6968,
      'm7i.large': 0.10605,
      'm7i.metal-24xl': 5.0904,
      'm7i.metal-48xl': 10.1808,
      'm7i.xlarge': 0.2121,
      'p2.16xlarge': 27.488,
      'p2.8xlarge': 13.744,
      'p2.xlarge': 1.718,
      'r3.2xlarge': 0.758,
      'r3.4xlarge': 1.516,
      'r3.8xlarge': 3.032,
      'r3.large': 0.19,
      'r3.xlarge': 0.379,
      'r4.16xlarge': 4.384,
      'r4.2xlarge': 0.548,
      'r4.4xlarge': 1.096,
      'r4.8xlarge': 2.192,
      'r4.large': 0.137,
      'r4.xlarge': 0.274,
      'r5.12xlarge': 3.12,
      'r5.16xlarge': 4.16,
      'r5.24xlarge': 6.24,
      'r5.2xlarge': 0.52,
      'r5.4xlarge': 1.04,
      'r5.8xlarge': 2.08,
      'r5.large': 0.13,
      'r5.metal': 6.24,
      'r5.xlarge': 0.26,
      'r5a.12xlarge': 1.716,
      'r5a.16xlarge': 2.288,
      'r5a.24xlarge': 3.432,
      'r5a.2xlarge': 0.286,
      'r5a.4xlarge': 0.572,
      'r5a.8xlarge': 1.144,
      'r5a.large': 0.072,
      'r5a.xlarge': 0.143,
      'r5ad.12xlarge': 1.993,
      'r5ad.16xlarge': 2.658,
      'r5ad.24xlarge': 3.986,
      'r5ad.2xlarge': 0.332,
      'r5ad.4xlarge': 0.664,
      'r5ad.8xlarge': 1.329,
      'r5ad.large': 0.083,
      'r5ad.xlarge': 0.166,
      'r5d.12xlarge': 3.624,
      'r5d.16xlarge': 4.832,
      'r5d.24xlarge': 7.248,
      'r5d.2xlarge': 0.604,
      'r5d.4xlarge': 1.208,
      'r5d.8xlarge': 2.416,
      'r5d.large': 0.151,
      'r5d.metal': 7.248,
      'r5d.xlarge': 0.302,
      'r5n.12xlarge': 3.672,
      'r5n.16xlarge': 4.896,
      'r5n.24xlarge': 7.344,
      'r5n.2xlarge': 0.612,
      'r5n.4xlarge': 1.224,
      'r5n.8xlarge': 2.448,
      'r5n.large': 0.153,
      'r5n.metal': 7.344,
      'r5n.xlarge': 0.306,
      'r6a.12xlarge': 1.716,
      'r6a.16xlarge': 2.288,
      'r6a.24xlarge': 3.432,
      'r6a.2xlarge': 0.286,
      'r6a.32xlarge': 4.576,
      'r6a.48xlarge': 6.864,
      'r6a.4xlarge': 0.572,
      'r6a.8xlarge': 1.144,
      'r6a.large': 0.0715,
      'r6a.metal': 6.864,
      'r6a.xlarge': 0.143,
      'r6g.12xlarge': 1.56,
      'r6g.16xlarge': 2.08,
      'r6g.2xlarge': 0.26,
      'r6g.4xlarge': 0.52,
      'r6g.8xlarge': 1.04,
      'r6g.large': 0.065,
      'r6g.medium': 0.0325,
      'r6g.metal': 2.08,
      'r6g.xlarge': 0.13,
      'r6gd.12xlarge': 1.7938,
      'r6gd.16xlarge': 2.3917,
      'r6gd.2xlarge': 0.299,
      'r6gd.4xlarge': 0.5979,
      'r6gd.8xlarge': 1.1958,
      'r6gd.large': 0.0747,
      'r6gd.medium': 0.0374,
      'r6gd.metal': 2.3917,
      'r6gd.xlarge': 0.1495,
      'r6i.12xlarge': 3.12,
      'r6i.16xlarge': 4.16,
      'r6i.24xlarge': 6.24,
      'r6i.2xlarge': 0.52,
      'r6i.32xlarge': 8.32,
      'r6i.4xlarge': 1.04,
      'r6i.8xlarge': 2.08,
      'r6i.large': 0.13,
      'r6i.metal': 8.32,
      'r6i.xlarge': 0.26,
      'r6id.12xlarge': 3.8064,
      'r6id.16xlarge': 5.0752,
      'r6id.24xlarge': 7.6128,
      'r6id.2xlarge': 0.6344,
      'r6id.32xlarge': 10.1504,
      'r6id.4xlarge': 1.2688,
      'r6id.8xlarge': 2.5376,
      'r6id.large': 0.1586,
      'r6id.metal': 10.1504,
      'r6id.xlarge': 0.3172,
      'r7g.12xlarge': 1.8019,
      'r7g.16xlarge': 2.4026,
      'r7g.2xlarge': 0.3003,
      'r7g.4xlarge': 0.6006,
      'r7g.8xlarge': 1.2013,
      'r7g.large': 0.0751,
      'r7g.medium': 0.0375,
      'r7g.metal': 2.4026,
      'r7g.xlarge': 0.1502,
      'r7gd.12xlarge': 2.112,
      'r7gd.16xlarge': 2.816,
      'r7gd.2xlarge': 0.352,
      'r7gd.4xlarge': 0.704,
      'r7gd.8xlarge': 1.408,
      'r7gd.large': 0.088,
      'r7gd.medium': 0.044,
      'r7gd.metal': 2.816,
      'r7gd.xlarge': 0.176,
      'r7i.12xlarge': 3.276,
      'r7i.16xlarge': 4.368,
      'r7i.24xlarge': 6.552,
      'r7i.2xlarge': 0.546,
      'r7i.48xlarge': 13.104,
      'r7i.4xlarge': 1.092,
      'r7i.8xlarge': 2.184,
      'r7i.large': 0.1365,
      'r7i.metal-24xl': 6.552,
      'r7i.metal-48xl': 13.104,
      'r7i.xlarge': 0.273,
      't2.2xlarge': 0.3968,
      't2.large': 0.0992,
      't2.medium': 0.0496,
      't2.micro': 0.0124,
      't2.nano': 0.0062,
      't2.small': 0.0248,
      't2.xlarge': 0.1984,
      't3.2xlarge': 0.3584,
      't3.large': 0.0896,
      't3.medium': 0.0448,
      't3.micro': 0.0112,
      't3.nano': 0.0056,
      't3.small': 0.0224,
      't3.xlarge': 0.1792,
      't3a.2xlarge': 0.1971,
      't3a.large': 0.0493,
      't3a.medium': 0.0246,
      't3a.micro': 0.0062,
      't3a.nano': 0.0031,
      't3a.small': 0.0123,
      't3a.xlarge': 0.0986,
      't4g.2xlarge': 0.1792,
      't4g.large': 0.0448,
      't4g.medium': 0.0224,
      't4g.micro': 0.0056,
      't4g.nano': 0.0028,
      't4g.small': 0.0112,
      't4g.xlarge': 0.0896,
      'u-12tb1.112xlarge': 112.667,
      'u-6tb1.112xlarge': 56.333,
      'u-6tb1.56xlarge': 47.87676,
      'x1.16xlarge': 6.881,
      'x1.32xlarge': 13.762,
      'x1e.16xlarge': 13.76,
      'x1e.2xlarge': 1.72,
      'x1e.32xlarge': 27.52,
      'x1e.4xlarge': 3.44,
      'x1e.8xlarge': 6.88,
      'x1e.xlarge': 0.86,
      'x2idn.16xlarge': 6.881,
      'x2idn.24xlarge': 10.3215,
      'x2idn.32xlarge': 13.762,
      'x2idn.metal': 13.762,
      'x2iedn.16xlarge': 13.762,
      'x2iedn.24xlarge': 20.643,
      'x2iedn.2xlarge': 1.72025,
      'x2iedn.32xlarge': 27.524,
      'x2iedn.4xlarge': 3.4405,
      'x2iedn.8xlarge': 6.881,
      'x2iedn.metal': 27.524,
      'x2iedn.xlarge': 0.86013,
      'z1d.12xlarge': 4.704,
      'z1d.2xlarge': 0.784,
      'z1d.3xlarge': 1.176,
      'z1d.6xlarge': 2.352,
      'z1d.large': 0.196,
      'z1d.metal': 4.704,
      'z1d.xlarge': 0.392,
    },
    'ap-southeast-1': {
      'a1.2xlarge': 0.2352,
      'a1.4xlarge': 0.4704,
      'a1.large': 0.0588,
      'a1.medium': 0.0294,
      'a1.metal': 0.47,
      'a1.xlarge': 0.1176,
      'c1.medium': 0.164,
      'c1.xlarge': 0.655,
      'c3.2xlarge': 0.529,
      'c3.4xlarge': 1.058,
      'c3.8xlarge': 2.117,
      'c3.large': 0.132,
      'c3.xlarge': 0.265,
      'c4.2xlarge': 0.462,
      'c4.4xlarge': 0.924,
      'c4.8xlarge': 1.848,
      'c4.large': 0.115,
      'c4.xlarge': 0.231,
      'c5.12xlarge': 2.352,
      'c5.18xlarge': 3.528,
      'c5.24xlarge': 4.704,
      'c5.2xlarge': 0.392,
      'c5.4xlarge': 0.784,
      'c5.9xlarge': 1.764,
      'c5.large': 0.098,
      'c5.metal': 4.704,
      'c5.xlarge': 0.196,
      'c5a.12xlarge': 2.112,
      'c5a.16xlarge': 2.816,
      'c5a.24xlarge': 4.224,
      'c5a.2xlarge': 0.352,
      'c5a.4xlarge': 0.704,
      'c5a.8xlarge': 1.408,
      'c5a.large': 0.088,
      'c5a.xlarge': 0.176,
      'c5ad.12xlarge': 2.424,
      'c5ad.16xlarge': 3.232,
      'c5ad.24xlarge': 4.848,
      'c5ad.2xlarge': 0.404,
      'c5ad.4xlarge': 0.808,
      'c5ad.8xlarge': 1.616,
      'c5ad.large': 0.101,
      'c5ad.xlarge': 0.202,
      'c5d.12xlarge': 2.688,
      'c5d.18xlarge': 4.032,
      'c5d.24xlarge': 5.376,
      'c5d.2xlarge': 0.448,
      'c5d.4xlarge': 0.896,
      'c5d.9xlarge': 2.016,
      'c5d.large': 0.112,
      'c5d.metal': 5.376,
      'c5d.xlarge': 0.224,
      'c5n.18xlarge': 4.464,
      'c5n.2xlarge': 0.496,
      'c5n.4xlarge': 0.992,
      'c5n.9xlarge': 2.232,
      'c5n.large': 0.124,
      'c5n.metal': 4.464,
      'c5n.xlarge': 0.248,
      'c6a.12xlarge': 2.1168,
      'c6a.16xlarge': 2.8224,
      'c6a.24xlarge': 4.2336,
      'c6a.2xlarge': 0.3528,
      'c6a.32xlarge': 5.6448,
      'c6a.48xlarge': 8.4672,
      'c6a.4xlarge': 0.7056,
      'c6a.8xlarge': 1.4112,
      'c6a.large': 0.0882,
      'c6a.metal': 8.4672,
      'c6a.xlarge': 0.1764,
      'c6g.12xlarge': 1.8816,
      'c6g.16xlarge': 2.5088,
      'c6g.2xlarge': 0.3136,
      'c6g.4xlarge': 0.6272,
      'c6g.8xlarge': 1.2544,
      'c6g.large': 0.0784,
      'c6g.medium': 0.0392,
      'c6g.metal': 2.5088,
      'c6g.xlarge': 0.1568,
      'c6gd.12xlarge': 2.16,
      'c6gd.16xlarge': 2.88,
      'c6gd.2xlarge': 0.36,
      'c6gd.4xlarge': 0.72,
      'c6gd.8xlarge': 1.44,
      'c6gd.large': 0.09,
      'c6gd.medium': 0.045,
      'c6gd.metal': 2.88,
      'c6gd.xlarge': 0.18,
      'c6gn.12xlarge': 2.376,
      'c6gn.16xlarge': 3.168,
      'c6gn.2xlarge': 0.396,
      'c6gn.4xlarge': 0.792,
      'c6gn.8xlarge': 1.584,
      'c6gn.large': 0.099,
      'c6gn.medium': 0.0495,
      'c6gn.xlarge': 0.198,
      'c6i.12xlarge': 2.352,
      'c6i.16xlarge': 3.136,
      'c6i.24xlarge': 4.704,
      'c6i.2xlarge': 0.392,
      'c6i.32xlarge': 6.272,
      'c6i.4xlarge': 0.784,
      'c6i.8xlarge': 1.568,
      'c6i.large': 0.098,
      'c6i.metal': 6.272,
      'c6i.xlarge': 0.196,
      'c6id.12xlarge': 2.8224,
      'c6id.16xlarge': 3.7632,
      'c6id.24xlarge': 5.6448,
      'c6id.2xlarge': 0.4704,
      'c6id.32xlarge': 7.5264,
      'c6id.4xlarge': 0.9408,
      'c6id.8xlarge': 1.8816,
      'c6id.large': 0.1176,
      'c6id.metal': 7.5264,
      'c6id.xlarge': 0.2352,
      'c6in.12xlarge': 3.1248,
      'c6in.16xlarge': 4.1664,
      'c6in.24xlarge': 6.2496,
      'c6in.2xlarge': 0.5208,
      'c6in.32xlarge': 8.3328,
      'c6in.4xlarge': 1.0416,
      'c6in.8xlarge': 2.0832,
      'c6in.large': 0.1302,
      'c6in.metal': 8.3328,
      'c6in.xlarge': 0.2604,
      'c7g.12xlarge': 1.9992,
      'c7g.16xlarge': 2.6656,
      'c7g.2xlarge': 0.3332,
      'c7g.4xlarge': 0.6664,
      'c7g.8xlarge': 1.3328,
      'c7g.large': 0.0833,
      'c7g.medium': 0.0417,
      'c7g.metal': 2.6656,
      'c7g.xlarge': 0.1666,
      'c7gd.12xlarge': 2.5402,
      'c7gd.16xlarge': 3.3869,
      'c7gd.2xlarge': 0.4234,
      'c7gd.4xlarge': 0.8467,
      'c7gd.8xlarge': 1.6934,
      'c7gd.large': 0.1058,
      'c7gd.medium': 0.0529,
      'c7gd.metal': 3.3869,
      'c7gd.xlarge': 0.2117,
      'c7i.12xlarge': 2.4696,
      'c7i.16xlarge': 3.2928,
      'c7i.24xlarge': 4.9392,
      'c7i.2xlarge': 0.4116,
      'c7i.48xlarge': 9.8784,
      'c7i.4xlarge': 0.8232,
      'c7i.8xlarge': 1.6464,
      'c7i.large': 0.1029,
      'c7i.metal-24xl': 4.9392,
      'c7i.metal-48xl': 9.8784,
      'c7i.xlarge': 0.2058,
      'd2.2xlarge': 1.74,
      'd2.4xlarge': 3.48,
      'd2.8xlarge': 6.96,
      'd2.xlarge': 0.87,
      'd3.2xlarge': 1.252,
      'd3.4xlarge': 2.504,
      'd3.8xlarge': 5.00808,
      'd3.xlarge': 0.626,
      'd3en.12xlarge': 7.90752,
      'd3en.2xlarge': 1.318,
      'd3en.4xlarge': 2.636,
      'd3en.6xlarge': 3.954,
      'd3en.8xlarge': 5.27168,
      'd3en.xlarge': 0.659,
      'g2.2xlarge': 1,
      'g2.8xlarge': 4,
      'g3.16xlarge': 6.68,
      'g3.4xlarge': 1.67,
      'g3.8xlarge': 3.34,
      'g4dn.12xlarge': 5.474,
      'g4dn.16xlarge': 6.089,
      'g4dn.2xlarge': 1.052,
      'g4dn.4xlarge': 1.685,
      'g4dn.8xlarge': 3.045,
      'g4dn.metal': 10.948,
      'g4dn.xlarge': 0.736,
      'g5g.16xlarge': 3.8395,
      'g5g.2xlarge': 0.778,
      'g5g.4xlarge': 1.1586,
      'g5g.8xlarge': 1.9198,
      'g5g.metal': 3.8395,
      'g5g.xlarge': 0.5877,
      'hpc6a.48xlarge': 3.3205,
      'i2.2xlarge': 2.035,
      'i2.4xlarge': 4.07,
      'i2.8xlarge': 8.14,
      'i2.xlarge': 1.018,
      'i3.16xlarge': 5.984,
      'i3.2xlarge': 0.748,
      'i3.4xlarge': 1.496,
      'i3.8xlarge': 2.992,
      'i3.large': 0.187,
      'i3.metal': 5.984,
      'i3.xlarge': 0.374,
      'i3en.12xlarge': 6.504,
      'i3en.24xlarge': 13.008,
      'i3en.2xlarge': 1.084,
      'i3en.3xlarge': 1.626,
      'i3en.6xlarge': 3.252,
      'i3en.large': 0.271,
      'i3en.metal': 13.008,
      'i3en.xlarge': 0.542,
      'i4g.16xlarge': 5.92416,
      'i4g.2xlarge': 0.74052,
      'i4g.4xlarge': 1.48104,
      'i4g.8xlarge': 2.96208,
      'i4g.large': 0.18513,
      'i4g.xlarge': 0.37026,
      'i4i.12xlarge': 4.937,
      'i4i.16xlarge': 6.582,
      'i4i.24xlarge': 9.8736,
      'i4i.2xlarge': 0.823,
      'i4i.32xlarge': 13.1648,
      'i4i.4xlarge': 1.646,
      'i4i.8xlarge': 3.291,
      'i4i.large': 0.206,
      'i4i.metal': 13.165,
      'i4i.xlarge': 0.411,
      'im4gn.16xlarge': 6.97734,
      'im4gn.2xlarge': 0.87217,
      'im4gn.4xlarge': 1.74434,
      'im4gn.8xlarge': 3.48867,
      'im4gn.large': 0.21804,
      'im4gn.xlarge': 0.43608,
      'inf1.24xlarge': 6.376,
      'inf1.2xlarge': 0.489,
      'inf1.6xlarge': 1.594,
      'inf1.xlarge': 0.308,
      'inf2.24xlarge': 9.08689,
      'inf2.48xlarge': 18.17377,
      'inf2.8xlarge': 2.755,
      'inf2.xlarge': 1.06148,
      'is4gen.2xlarge': 1.3821,
      'is4gen.4xlarge': 2.76421,
      'is4gen.8xlarge': 5.52842,
      'is4gen.large': 0.34553,
      'is4gen.medium': 0.17276,
      'is4gen.xlarge': 0.69105,
      'm1.large': 0.233,
      'm1.medium': 0.117,
      'm1.small': 0.058,
      'm1.xlarge': 0.467,
      'm2.2xlarge': 0.592,
      'm2.4xlarge': 1.183,
      'm2.xlarge': 0.296,
      'm3.2xlarge': 0.784,
      'm3.large': 0.196,
      'm3.medium': 0.098,
      'm3.xlarge': 0.392,
      'm4.10xlarge': 2.5,
      'm4.16xlarge': 4,
      'm4.2xlarge': 0.5,
      'm4.4xlarge': 1,
      'm4.large': 0.125,
      'm4.xlarge': 0.25,
      'm5.12xlarge': 2.88,
      'm5.16xlarge': 3.84,
      'm5.24xlarge': 5.76,
      'm5.2xlarge': 0.48,
      'm5.4xlarge': 0.96,
      'm5.8xlarge': 1.92,
      'm5.large': 0.12,
      'm5.metal': 5.76,
      'm5.xlarge': 0.24,
      'm5a.12xlarge': 2.592,
      'm5a.16xlarge': 3.456,
      'm5a.24xlarge': 5.184,
      'm5a.2xlarge': 0.432,
      'm5a.4xlarge': 0.864,
      'm5a.8xlarge': 1.728,
      'm5a.large': 0.108,
      'm5a.xlarge': 0.216,
      'm5ad.12xlarge': 3.096,
      'm5ad.16xlarge': 4.128,
      'm5ad.24xlarge': 6.192,
      'm5ad.2xlarge': 0.516,
      'm5ad.4xlarge': 1.032,
      'm5ad.8xlarge': 2.064,
      'm5ad.large': 0.129,
      'm5ad.xlarge': 0.258,
      'm5d.12xlarge': 3.384,
      'm5d.16xlarge': 4.512,
      'm5d.24xlarge': 6.768,
      'm5d.2xlarge': 0.564,
      'm5d.4xlarge': 1.128,
      'm5d.8xlarge': 2.256,
      'm5d.large': 0.141,
      'm5d.metal': 6.768,
      'm5d.xlarge': 0.282,
      'm5dn.12xlarge': 4.008,
      'm5dn.16xlarge': 5.344,
      'm5dn.24xlarge': 8.016,
      'm5dn.2xlarge': 0.668,
      'm5dn.4xlarge': 1.336,
      'm5dn.8xlarge': 2.672,
      'm5dn.large': 0.167,
      'm5dn.metal': 8.016,
      'm5dn.xlarge': 0.334,
      'm5n.12xlarge': 3.504,
      'm5n.16xlarge': 4.672,
      'm5n.24xlarge': 7.008,
      'm5n.2xlarge': 0.584,
      'm5n.4xlarge': 1.168,
      'm5n.8xlarge': 2.336,
      'm5n.large': 0.146,
      'm5n.metal': 7.008,
      'm5n.xlarge': 0.292,
      'm5zn.12xlarge': 4.956,
      'm5zn.2xlarge': 0.826,
      'm5zn.3xlarge': 1.239,
      'm5zn.6xlarge': 2.478,
      'm5zn.large': 0.2065,
      'm5zn.metal': 4.956,
      'm5zn.xlarge': 0.413,
      'm6a.12xlarge': 2.592,
      'm6a.16xlarge': 3.456,
      'm6a.24xlarge': 5.184,
      'm6a.2xlarge': 0.432,
      'm6a.32xlarge': 6.912,
      'm6a.48xlarge': 10.368,
      'm6a.4xlarge': 0.864,
      'm6a.8xlarge': 1.728,
      'm6a.large': 0.108,
      'm6a.metal': 10.368,
      'm6a.xlarge': 0.216,
      'm6g.12xlarge': 2.304,
      'm6g.16xlarge': 3.072,
      'm6g.2xlarge': 0.384,
      'm6g.4xlarge': 0.768,
      'm6g.8xlarge': 1.536,
      'm6g.large': 0.096,
      'm6g.medium': 0.048,
      'm6g.metal': 3.072,
      'm6g.xlarge': 0.192,
      'm6gd.12xlarge': 2.712,
      'm6gd.16xlarge': 3.616,
      'm6gd.2xlarge': 0.452,
      'm6gd.4xlarge': 0.904,
      'm6gd.8xlarge': 1.808,
      'm6gd.large': 0.113,
      'm6gd.medium': 0.0565,
      'm6gd.metal': 3.616,
      'm6gd.xlarge': 0.226,
      'm6i.12xlarge': 2.88,
      'm6i.16xlarge': 3.84,
      'm6i.24xlarge': 5.76,
      'm6i.2xlarge': 0.48,
      'm6i.32xlarge': 7.68,
      'm6i.4xlarge': 0.96,
      'm6i.8xlarge': 1.92,
      'm6i.large': 0.12,
      'm6i.metal': 7.68,
      'm6i.xlarge': 0.24,
      'm6id.12xlarge': 3.5532,
      'm6id.16xlarge': 4.7376,
      'm6id.24xlarge': 7.1064,
      'm6id.2xlarge': 0.5922,
      'm6id.32xlarge': 9.4752,
      'm6id.4xlarge': 1.1844,
      'm6id.8xlarge': 2.3688,
      'm6id.large': 0.14805,
      'm6id.metal': 9.4752,
      'm6id.xlarge': 0.2961,
      'm6idn.12xlarge': 4.68936,
      'm6idn.16xlarge': 6.25248,
      'm6idn.24xlarge': 9.37872,
      'm6idn.2xlarge': 0.78156,
      'm6idn.32xlarge': 12.50496,
      'm6idn.4xlarge': 1.56312,
      'm6idn.8xlarge': 3.12624,
      'm6idn.large': 0.19539,
      'm6idn.metal': 12.50496,
      'm6idn.xlarge': 0.39078,
      'm6in.12xlarge': 4.09968,
      'm6in.16xlarge': 5.46624,
      'm6in.24xlarge': 8.19936,
      'm6in.2xlarge': 0.68328,
      'm6in.32xlarge': 10.93248,
      'm6in.4xlarge': 1.36656,
      'm6in.8xlarge': 2.73312,
      'm6in.large': 0.17082,
      'm6in.metal': 10.93248,
      'm6in.xlarge': 0.34164,
      'm7g.12xlarge': 2.448,
      'm7g.16xlarge': 3.264,
      'm7g.2xlarge': 0.408,
      'm7g.4xlarge': 0.816,
      'm7g.8xlarge': 1.632,
      'm7g.large': 0.102,
      'm7g.medium': 0.051,
      'm7g.metal': 3.264,
      'm7g.xlarge': 0.204,
      'm7gd.12xlarge': 3.1979,
      'm7gd.16xlarge': 4.2638,
      'm7gd.2xlarge': 0.533,
      'm7gd.4xlarge': 1.066,
      'm7gd.8xlarge': 2.1319,
      'm7gd.large': 0.1332,
      'm7gd.medium': 0.0666,
      'm7gd.metal': 4.2638,
      'm7gd.xlarge': 0.2665,
      'm7i-flex.2xlarge': 0.4788,
      'm7i-flex.4xlarge': 0.9576,
      'm7i-flex.8xlarge': 1.9152,
      'm7i-flex.large': 0.1197,
      'm7i-flex.xlarge': 0.2394,
      'm7i.12xlarge': 3.024,
      'm7i.16xlarge': 4.032,
      'm7i.24xlarge': 6.048,
      'm7i.2xlarge': 0.504,
      'm7i.48xlarge': 12.096,
      'm7i.4xlarge': 1.008,
      'm7i.8xlarge': 2.016,
      'm7i.large': 0.126,
      'm7i.metal-24xl': 6.048,
      'm7i.metal-48xl': 12.096,
      'm7i.xlarge': 0.252,
      'p2.16xlarge': 27.488,
      'p2.8xlarge': 13.744,
      'p2.xlarge': 1.718,
      'p3.16xlarge': 33.872,
      'p3.2xlarge': 4.234,
      'p3.8xlarge': 16.936,
      'p4d.24xlarge': 39.3271,
      'p4de.24xlarge': 49.159,
      'r3.2xlarge': 0.798,
      'r3.4xlarge': 1.596,
      'r3.8xlarge': 3.192,
      'r3.large': 0.2,
      'r3.xlarge': 0.399,
      'r4.16xlarge': 5.12,
      'r4.2xlarge': 0.64,
      'r4.4xlarge': 1.28,
      'r4.8xlarge': 2.56,
      'r4.large': 0.16,
      'r4.xlarge': 0.32,
      'r5.12xlarge': 3.648,
      'r5.16xlarge': 4.864,
      'r5.24xlarge': 7.296,
      'r5.2xlarge': 0.608,
      'r5.4xlarge': 1.216,
      'r5.8xlarge': 2.432,
      'r5.large': 0.152,
      'r5.metal': 7.296,
      'r5.xlarge': 0.304,
      'r5a.12xlarge': 3.264,
      'r5a.16xlarge': 4.352,
      'r5a.24xlarge': 6.528,
      'r5a.2xlarge': 0.544,
      'r5a.4xlarge': 1.088,
      'r5a.8xlarge': 2.176,
      'r5a.large': 0.136,
      'r5a.xlarge': 0.272,
      'r5ad.12xlarge': 3.816,
      'r5ad.16xlarge': 5.088,
      'r5ad.24xlarge': 7.632,
      'r5ad.2xlarge': 0.636,
      'r5ad.4xlarge': 1.272,
      'r5ad.8xlarge': 2.544,
      'r5ad.large': 0.159,
      'r5ad.xlarge': 0.318,
      'r5b.12xlarge': 4.272,
      'r5b.16xlarge': 5.696,
      'r5b.24xlarge': 8.544,
      'r5b.2xlarge': 0.712,
      'r5b.4xlarge': 1.424,
      'r5b.8xlarge': 2.848,
      'r5b.large': 0.178,
      'r5b.metal': 8.544,
      'r5b.xlarge': 0.356,
      'r5d.12xlarge': 4.176,
      'r5d.16xlarge': 5.568,
      'r5d.24xlarge': 8.352,
      'r5d.2xlarge': 0.696,
      'r5d.4xlarge': 1.392,
      'r5d.8xlarge': 2.784,
      'r5d.large': 0.174,
      'r5d.metal': 8.352,
      'r5d.xlarge': 0.348,
      'r5dn.12xlarge': 4.8,
      'r5dn.16xlarge': 6.4,
      'r5dn.24xlarge': 9.6,
      'r5dn.2xlarge': 0.8,
      'r5dn.4xlarge': 1.6,
      'r5dn.8xlarge': 3.2,
      'r5dn.large': 0.2,
      'r5dn.metal': 9.6,
      'r5dn.xlarge': 0.4,
      'r5n.12xlarge': 4.272,
      'r5n.16xlarge': 5.696,
      'r5n.24xlarge': 8.544,
      'r5n.2xlarge': 0.712,
      'r5n.4xlarge': 1.424,
      'r5n.8xlarge': 2.848,
      'r5n.large': 0.178,
      'r5n.metal': 8.544,
      'r5n.xlarge': 0.356,
      'r6a.12xlarge': 3.2832,
      'r6a.16xlarge': 4.3776,
      'r6a.24xlarge': 6.5664,
      'r6a.2xlarge': 0.5472,
      'r6a.32xlarge': 8.7552,
      'r6a.48xlarge': 13.1328,
      'r6a.4xlarge': 1.0944,
      'r6a.8xlarge': 2.1888,
      'r6a.large': 0.1368,
      'r6a.metal': 13.1328,
      'r6a.xlarge': 0.2736,
      'r6g.12xlarge': 2.9184,
      'r6g.16xlarge': 3.8912,
      'r6g.2xlarge': 0.4864,
      'r6g.4xlarge': 0.9728,
      'r6g.8xlarge': 1.9456,
      'r6g.large': 0.1216,
      'r6g.medium': 0.0608,
      'r6g.metal': 3.8912,
      'r6g.xlarge': 0.2432,
      'r6gd.12xlarge': 3.336,
      'r6gd.16xlarge': 4.448,
      'r6gd.2xlarge': 0.556,
      'r6gd.4xlarge': 1.112,
      'r6gd.8xlarge': 2.224,
      'r6gd.large': 0.139,
      'r6gd.medium': 0.0695,
      'r6gd.metal': 4.448,
      'r6gd.xlarge': 0.278,
      'r6i.12xlarge': 3.648,
      'r6i.16xlarge': 4.864,
      'r6i.24xlarge': 7.296,
      'r6i.2xlarge': 0.608,
      'r6i.32xlarge': 9.728,
      'r6i.4xlarge': 1.216,
      'r6i.8xlarge': 2.432,
      'r6i.large': 0.152,
      'r6i.metal': 9.728,
      'r6i.xlarge': 0.304,
      'r6id.12xlarge': 4.3848,
      'r6id.16xlarge': 5.8464,
      'r6id.24xlarge': 8.7696,
      'r6id.2xlarge': 0.7308,
      'r6id.32xlarge': 11.6928,
      'r6id.4xlarge': 1.4616,
      'r6id.8xlarge': 2.9232,
      'r6id.large': 0.1827,
      'r6id.metal': 11.6928,
      'r6id.xlarge': 0.3654,
      'r6idn.12xlarge': 5.616,
      'r6idn.16xlarge': 7.488,
      'r6idn.24xlarge': 11.232,
      'r6idn.2xlarge': 0.936,
      'r6idn.32xlarge': 14.976,
      'r6idn.4xlarge': 1.872,
      'r6idn.8xlarge': 3.744,
      'r6idn.large': 0.234,
      'r6idn.metal': 14.976,
      'r6idn.xlarge': 0.468,
      'r6in.12xlarge': 4.99824,
      'r6in.16xlarge': 6.66432,
      'r6in.24xlarge': 9.99648,
      'r6in.2xlarge': 0.83304,
      'r6in.32xlarge': 13.32864,
      'r6in.4xlarge': 1.66608,
      'r6in.8xlarge': 3.33216,
      'r6in.large': 0.20826,
      'r6in.metal': 13.32864,
      'r6in.xlarge': 0.41652,
      'r7g.12xlarge': 3.1008,
      'r7g.16xlarge': 4.1344,
      'r7g.2xlarge': 0.5168,
      'r7g.4xlarge': 1.0336,
      'r7g.8xlarge': 2.0672,
      'r7g.large': 0.1292,
      'r7g.medium': 0.0646,
      'r7g.metal': 4.1344,
      'r7g.xlarge': 0.2584,
      'r7gd.12xlarge': 3.9463,
      'r7gd.16xlarge': 5.2618,
      'r7gd.2xlarge': 0.6577,
      'r7gd.4xlarge': 1.3154,
      'r7gd.8xlarge': 2.6309,
      'r7gd.large': 0.1644,
      'r7gd.medium': 0.0822,
      'r7gd.metal': 5.2618,
      'r7gd.xlarge': 0.3289,
      'r7i.12xlarge': 3.8304,
      'r7i.16xlarge': 5.1072,
      'r7i.24xlarge': 7.6608,
      'r7i.2xlarge': 0.6384,
      'r7i.48xlarge': 15.3216,
      'r7i.4xlarge': 1.2768,
      'r7i.8xlarge': 2.5536,
      'r7i.large': 0.1596,
      'r7i.metal-24xl': 7.6608,
      'r7i.metal-48xl': 15.3216,
      'r7i.xlarge': 0.3192,
      't1.micro': 0.02,
      't2.2xlarge': 0.4672,
      't2.large': 0.1168,
      't2.medium': 0.0584,
      't2.micro': 0.0146,
      't2.nano': 0.0073,
      't2.small': 0.0292,
      't2.xlarge': 0.2336,
      't3.2xlarge': 0.4224,
      't3.large': 0.1056,
      't3.medium': 0.0528,
      't3.micro': 0.0132,
      't3.nano': 0.0066,
      't3.small': 0.0264,
      't3.xlarge': 0.2112,
      't3a.2xlarge': 0.3776,
      't3a.large': 0.0944,
      't3a.medium': 0.0472,
      't3a.micro': 0.0118,
      't3a.nano': 0.0059,
      't3a.small': 0.0236,
      't3a.xlarge': 0.1888,
      't4g.2xlarge': 0.3392,
      't4g.large': 0.0848,
      't4g.medium': 0.0424,
      't4g.micro': 0.0106,
      't4g.nano': 0.0053,
      't4g.small': 0.0212,
      't4g.xlarge': 0.1696,
      'u-12tb1.112xlarge': 131.733,
      'u-3tb1.56xlarge': 32.93,
      'u-6tb1.112xlarge': 65.867,
      'u-6tb1.56xlarge': 55.9796,
      'u-9tb1.112xlarge': 98.8,
      'x1.16xlarge': 9.671,
      'x1.32xlarge': 19.341,
      'x1e.16xlarge': 19.344,
      'x1e.2xlarge': 2.418,
      'x1e.32xlarge': 38.688,
      'x1e.4xlarge': 4.836,
      'x1e.8xlarge': 9.672,
      'x1e.xlarge': 1.209,
      'x2idn.16xlarge': 9.6705,
      'x2idn.24xlarge': 14.50575,
      'x2idn.32xlarge': 19.341,
      'x2idn.metal': 19.341,
      'x2iedn.16xlarge': 19.341,
      'x2iedn.24xlarge': 29.0115,
      'x2iedn.2xlarge': 2.41763,
      'x2iedn.32xlarge': 38.682,
      'x2iedn.4xlarge': 4.83525,
      'x2iedn.8xlarge': 9.6705,
      'x2iedn.metal': 38.682,
      'x2iedn.xlarge': 1.20881,
      'z1d.12xlarge': 5.424,
      'z1d.2xlarge': 0.904,
      'z1d.3xlarge': 1.356,
      'z1d.6xlarge': 2.712,
      'z1d.large': 0.226,
      'z1d.metal': 5.424,
      'z1d.xlarge': 0.452,
    },
    'eu-west-1': {
      'a1.2xlarge': 0.2304,
      'a1.4xlarge': 0.4608,
      'a1.large': 0.0576,
      'a1.medium': 0.0288,
      'a1.metal': 0.461,
      'a1.xlarge': 0.1152,
      'c1.medium': 0.148,
      'c1.xlarge': 0.592,
      'c3.2xlarge': 0.478,
      'c3.4xlarge': 0.956,
      'c3.8xlarge': 1.912,
      'c3.large': 0.12,
      'c3.xlarge': 0.239,
      'c4.2xlarge': 0.453,
      'c4.4xlarge': 0.905,
      'c4.8xlarge': 1.811,
      'c4.large': 0.113,
      'c4.xlarge': 0.226,
      'c5.12xlarge': 2.304,
      'c5.18xlarge': 3.456,
      'c5.24xlarge': 4.608,
      'c5.2xlarge': 0.384,
      'c5.4xlarge': 0.768,
      'c5.9xlarge': 1.728,
      'c5.large': 0.096,
      'c5.metal': 4.608,
      'c5.xlarge': 0.192,
      'c5a.12xlarge': 2.064,
      'c5a.16xlarge': 2.752,
      'c5a.24xlarge': 4.128,
      'c5a.2xlarge': 0.344,
      'c5a.4xlarge': 0.688,
      'c5a.8xlarge': 1.376,
      'c5a.large': 0.086,
      'c5a.xlarge': 0.172,
      'c5ad.12xlarge': 2.352,
      'c5ad.16xlarge': 3.136,
      'c5ad.24xlarge': 4.704,
      'c5ad.2xlarge': 0.392,
      'c5ad.4xlarge': 0.784,
      'c5ad.8xlarge': 1.568,
      'c5ad.large': 0.098,
      'c5ad.xlarge': 0.196,
      'c5d.12xlarge': 2.616,
      'c5d.18xlarge': 3.924,
      'c5d.24xlarge': 5.232,
      'c5d.2xlarge': 0.436,
      'c5d.4xlarge': 0.872,
      'c5d.9xlarge': 1.962,
      'c5d.large': 0.109,
      'c5d.metal': 5.232,
      'c5d.xlarge': 0.218,
      'c5n.18xlarge': 4.392,
      'c5n.2xlarge': 0.488,
      'c5n.4xlarge': 0.976,
      'c5n.9xlarge': 2.196,
      'c5n.large': 0.122,
      'c5n.metal': 4.392,
      'c5n.xlarge': 0.244,
      'c6a.12xlarge': 1.96992,
      'c6a.16xlarge': 2.62656,
      'c6a.24xlarge': 3.93984,
      'c6a.2xlarge': 0.32832,
      'c6a.32xlarge': 5.25312,
      'c6a.48xlarge': 7.87968,
      'c6a.4xlarge': 0.65664,
      'c6a.8xlarge': 1.31328,
      'c6a.large': 0.08208,
      'c6a.metal': 7.87968,
      'c6a.xlarge': 0.16416,
      'c6g.12xlarge': 1.751,
      'c6g.16xlarge': 2.3347,
      'c6g.2xlarge': 0.2918,
      'c6g.4xlarge': 0.5837,
      'c6g.8xlarge': 1.1674,
      'c6g.large': 0.073,
      'c6g.medium': 0.0365,
      'c6g.metal': 2.3347,
      'c6g.xlarge': 0.1459,
      'c6gd.12xlarge': 2.0928,
      'c6gd.16xlarge': 2.7904,
      'c6gd.2xlarge': 0.3488,
      'c6gd.4xlarge': 0.6976,
      'c6gd.8xlarge': 1.3952,
      'c6gd.large': 0.0872,
      'c6gd.medium': 0.0436,
      'c6gd.metal': 2.7904,
      'c6gd.xlarge': 0.1744,
      'c6gn.12xlarge': 2.3424,
      'c6gn.16xlarge': 3.1232,
      'c6gn.2xlarge': 0.3904,
      'c6gn.4xlarge': 0.7808,
      'c6gn.8xlarge': 1.5616,
      'c6gn.large': 0.0976,
      'c6gn.medium': 0.0488,
      'c6gn.xlarge': 0.1952,
      'c6i.12xlarge': 2.1888,
      'c6i.16xlarge': 2.9184,
      'c6i.24xlarge': 4.3776,
      'c6i.2xlarge': 0.3648,
      'c6i.32xlarge': 5.8368,
      'c6i.4xlarge': 0.7296,
      'c6i.8xlarge': 1.4592,
      'c6i.large': 0.0912,
      'c6i.metal': 5.8368,
      'c6i.xlarge': 0.1824,
      'c6id.12xlarge': 2.7468,
      'c6id.16xlarge': 3.6624,
      'c6id.24xlarge': 5.4936,
      'c6id.2xlarge': 0.4578,
      'c6id.32xlarge': 7.3248,
      'c6id.4xlarge': 0.9156,
      'c6id.8xlarge': 1.8312,
      'c6id.large': 0.11445,
      'c6id.metal': 7.3248,
      'c6id.xlarge': 0.2289,
      'c6in.12xlarge': 3.0744,
      'c6in.16xlarge': 4.0992,
      'c6in.24xlarge': 6.1488,
      'c6in.2xlarge': 0.5124,
      'c6in.32xlarge': 8.1984,
      'c6in.4xlarge': 1.0248,
      'c6in.8xlarge': 2.0496,
      'c6in.large': 0.1281,
      'c6in.metal': 8.1984,
      'c6in.xlarge': 0.2562,
      'c7a.12xlarge': 2.64288,
      'c7a.16xlarge': 3.52384,
      'c7a.24xlarge': 5.28576,
      'c7a.2xlarge': 0.44048,
      'c7a.32xlarge': 7.04768,
      'c7a.48xlarge': 10.57152,
      'c7a.4xlarge': 0.88096,
      'c7a.8xlarge': 1.76192,
      'c7a.large': 0.11012,
      'c7a.medium': 0.05506,
      'c7a.metal-48xl': 10.57152,
      'c7a.xlarge': 0.22024,
      'c7g.12xlarge': 1.8605,
      'c7g.16xlarge': 2.4806,
      'c7g.2xlarge': 0.3101,
      'c7g.4xlarge': 0.6202,
      'c7g.8xlarge': 1.2403,
      'c7g.large': 0.0775,
      'c7g.medium': 0.0388,
      'c7g.metal': 2.4806,
      'c7g.xlarge': 0.155,
      'c7gd.12xlarge': 2.472,
      'c7gd.16xlarge': 3.296,
      'c7gd.2xlarge': 0.412,
      'c7gd.4xlarge': 0.824,
      'c7gd.8xlarge': 1.648,
      'c7gd.large': 0.103,
      'c7gd.medium': 0.0515,
      'c7gd.metal': 3.296,
      'c7gd.xlarge': 0.206,
      'c7gn.12xlarge': 3.384,
      'c7gn.16xlarge': 4.512,
      'c7gn.2xlarge': 0.564,
      'c7gn.4xlarge': 1.128,
      'c7gn.8xlarge': 2.256,
      'c7gn.large': 0.141,
      'c7gn.medium': 0.0705,
      'c7gn.metal': 4.512,
      'c7gn.xlarge': 0.282,
      'c7i.12xlarge': 2.29824,
      'c7i.16xlarge': 3.06432,
      'c7i.24xlarge': 4.59648,
      'c7i.2xlarge': 0.38304,
      'c7i.48xlarge': 9.19296,
      'c7i.4xlarge': 0.76608,
      'c7i.8xlarge': 1.53216,
      'c7i.large': 0.09576,
      'c7i.metal-24xl': 4.59648,
      'c7i.metal-48xl': 9.19296,
      'c7i.xlarge': 0.19152,
      'cr1.8xlarge': 3.75,
      'd2.2xlarge': 1.47,
      'd2.4xlarge': 2.94,
      'd2.8xlarge': 5.88,
      'd2.xlarge': 0.735,
      'd3.2xlarge': 1.219,
      'd3.4xlarge': 2.437,
      'd3.8xlarge': 4.87448,
      'd3.xlarge': 0.609,
      'd3en.12xlarge': 7.69656,
      'd3en.2xlarge': 1.283,
      'd3en.4xlarge': 2.566,
      'd3en.6xlarge': 3.848,
      'd3en.8xlarge': 5.13104,
      'd3en.xlarge': 0.641,
      'f1.16xlarge': 14.52,
      'f1.2xlarge': 1.815,
      'f1.4xlarge': 3.63,
      'g2.2xlarge': 0.702,
      'g2.8xlarge': 2.808,
      'g3.16xlarge': 4.84,
      'g3.4xlarge': 1.21,
      'g3.8xlarge': 2.42,
      'g3s.xlarge': 0.796,
      'g4ad.16xlarge': 3.872,
      'g4ad.2xlarge': 0.60421,
      'g4ad.4xlarge': 0.968,
      'g4ad.8xlarge': 1.936,
      'g4ad.xlarge': 0.42263,
      'g4dn.12xlarge': 4.362,
      'g4dn.16xlarge': 4.853,
      'g4dn.2xlarge': 0.838,
      'g4dn.4xlarge': 1.342,
      'g4dn.8xlarge': 2.426,
      'g4dn.metal': 8.724,
      'g4dn.xlarge': 0.587,
      'g5.12xlarge': 6.33167,
      'g5.16xlarge': 4.57237,
      'g5.24xlarge': 9.09117,
      'g5.2xlarge': 1.35296,
      'g5.48xlarge': 18.18233,
      'g5.4xlarge': 1.81287,
      'g5.8xlarge': 2.73271,
      'g5.xlarge': 1.123,
      'h1.16xlarge': 4.152,
      'h1.2xlarge': 0.519,
      'h1.4xlarge': 1.038,
      'h1.8xlarge': 2.076,
      'hpc7a.12xlarge': 7.7252,
      'hpc7a.24xlarge': 7.7252,
      'hpc7a.48xlarge': 7.7252,
      'hpc7a.96xlarge': 7.7252,
      'hpc7g.16xlarge': 1.8059,
      'hpc7g.4xlarge': 1.8059,
      'hpc7g.8xlarge': 1.8059,
      'i2.2xlarge': 1.876,
      'i2.4xlarge': 3.751,
      'i2.8xlarge': 7.502,
      'i2.xlarge': 0.938,
      'i3.16xlarge': 5.504,
      'i3.2xlarge': 0.688,
      'i3.4xlarge': 1.376,
      'i3.8xlarge': 2.752,
      'i3.large': 0.172,
      'i3.metal': 5.504,
      'i3.xlarge': 0.344,
      'i3en.12xlarge': 6,
      'i3en.24xlarge': 12,
      'i3en.2xlarge': 1,
      'i3en.3xlarge': 1.5,
      'i3en.6xlarge': 3,
      'i3en.large': 0.25,
      'i3en.metal': 12,
      'i3en.xlarge': 0.5,
      'i4g.16xlarge': 5.44637,
      'i4g.2xlarge': 0.6808,
      'i4g.4xlarge': 1.36159,
      'i4g.8xlarge': 2.72319,
      'i4g.large': 0.1702,
      'i4g.xlarge': 0.3404,
      'i4i.12xlarge': 4.541,
      'i4i.16xlarge': 6.054,
      'i4i.24xlarge': 9.0816,
      'i4i.2xlarge': 0.757,
      'i4i.32xlarge': 12.1088,
      'i4i.4xlarge': 1.514,
      'i4i.8xlarge': 3.027,
      'i4i.large': 0.189,
      'i4i.metal': 12.109,
      'i4i.xlarge': 0.378,
      'im4gn.16xlarge': 6.41766,
      'im4gn.2xlarge': 0.80221,
      'im4gn.4xlarge': 1.60442,
      'im4gn.8xlarge': 3.20883,
      'im4gn.large': 0.20055,
      'im4gn.xlarge': 0.4011,
      'inf1.24xlarge': 5.26,
      'inf1.2xlarge': 0.403,
      'inf1.6xlarge': 1.315,
      'inf1.xlarge': 0.254,
      'inf2.24xlarge': 8.11329,
      'inf2.48xlarge': 16.22658,
      'inf2.8xlarge': 2.45982,
      'inf2.xlarge': 0.94775,
      'is4gen.2xlarge': 1.275,
      'is4gen.4xlarge': 2.55,
      'is4gen.8xlarge': 5.1,
      'is4gen.large': 0.31875,
      'is4gen.medium': 0.15938,
      'is4gen.xlarge': 0.6375,
      'm1.large': 0.19,
      'm1.medium': 0.095,
      'm1.small': 0.047,
      'm1.xlarge': 0.379,
      'm2.2xlarge': 0.55,
      'm2.4xlarge': 1.1,
      'm2.xlarge': 0.275,
      'm3.2xlarge': 0.585,
      'm3.large': 0.146,
      'm3.medium': 0.073,
      'm3.xlarge': 0.293,
      'm4.10xlarge': 2.22,
      'm4.16xlarge': 3.552,
      'm4.2xlarge': 0.444,
      'm4.4xlarge': 0.888,
      'm4.large': 0.111,
      'm4.xlarge': 0.222,
      'm5.12xlarge': 2.568,
      'm5.16xlarge': 3.424,
      'm5.24xlarge': 5.136,
      'm5.2xlarge': 0.428,
      'm5.4xlarge': 0.856,
      'm5.8xlarge': 1.712,
      'm5.large': 0.107,
      'm5.metal': 5.136,
      'm5.xlarge': 0.214,
      'm5a.12xlarge': 2.304,
      'm5a.16xlarge': 3.072,
      'm5a.24xlarge': 4.608,
      'm5a.2xlarge': 0.384,
      'm5a.4xlarge': 0.768,
      'm5a.8xlarge': 1.536,
      'm5a.large': 0.096,
      'm5a.xlarge': 0.192,
      'm5ad.12xlarge': 2.76,
      'm5ad.16xlarge': 3.68,
      'm5ad.24xlarge': 5.52,
      'm5ad.2xlarge': 0.46,
      'm5ad.4xlarge': 0.92,
      'm5ad.8xlarge': 1.84,
      'm5ad.large': 0.115,
      'm5ad.xlarge': 0.23,
      'm5d.12xlarge': 3.024,
      'm5d.16xlarge': 4.032,
      'm5d.24xlarge': 6.048,
      'm5d.2xlarge': 0.504,
      'm5d.4xlarge': 1.008,
      'm5d.8xlarge': 2.016,
      'm5d.large': 0.126,
      'm5d.metal': 6.048,
      'm5d.xlarge': 0.252,
      'm5dn.12xlarge': 3.648,
      'm5dn.16xlarge': 4.864,
      'm5dn.24xlarge': 7.296,
      'm5dn.2xlarge': 0.608,
      'm5dn.4xlarge': 1.216,
      'm5dn.8xlarge': 2.432,
      'm5dn.large': 0.152,
      'm5dn.metal': 7.296,
      'm5dn.xlarge': 0.304,
      'm5n.12xlarge': 3.192,
      'm5n.16xlarge': 4.256,
      'm5n.24xlarge': 6.384,
      'm5n.2xlarge': 0.532,
      'm5n.4xlarge': 1.064,
      'm5n.8xlarge': 2.128,
      'm5n.large': 0.133,
      'm5n.xlarge': 0.266,
      'm5zn.12xlarge': 4.4184,
      'm5zn.2xlarge': 0.7364,
      'm5zn.3xlarge': 1.1046,
      'm5zn.6xlarge': 2.2092,
      'm5zn.large': 0.1841,
      'm5zn.metal': 4.4184,
      'm5zn.xlarge': 0.3682,
      'm6a.12xlarge': 2.3112,
      'm6a.16xlarge': 3.0816,
      'm6a.24xlarge': 4.6224,
      'm6a.2xlarge': 0.3852,
      'm6a.32xlarge': 6.1632,
      'm6a.48xlarge': 9.2448,
      'm6a.4xlarge': 0.7704,
      'm6a.8xlarge': 1.5408,
      'm6a.large': 0.0963,
      'm6a.metal': 9.2448,
      'm6a.xlarge': 0.1926,
      'm6g.12xlarge': 2.064,
      'm6g.16xlarge': 2.752,
      'm6g.2xlarge': 0.344,
      'm6g.4xlarge': 0.688,
      'm6g.8xlarge': 1.376,
      'm6g.large': 0.086,
      'm6g.medium': 0.043,
      'm6g.metal': 2.752,
      'm6g.xlarge': 0.172,
      'm6gd.12xlarge': 2.4192,
      'm6gd.16xlarge': 3.2256,
      'm6gd.2xlarge': 0.4032,
      'm6gd.4xlarge': 0.8064,
      'm6gd.8xlarge': 1.6128,
      'm6gd.large': 0.1008,
      'm6gd.medium': 0.0504,
      'm6gd.metal': 3.2256,
      'm6gd.xlarge': 0.2016,
      'm6i.12xlarge': 2.568,
      'm6i.16xlarge': 3.424,
      'm6i.24xlarge': 5.136,
      'm6i.2xlarge': 0.428,
      'm6i.32xlarge': 6.848,
      'm6i.4xlarge': 0.856,
      'm6i.8xlarge': 1.712,
      'm6i.large': 0.107,
      'm6i.metal': 6.848,
      'm6i.xlarge': 0.214,
      'm6id.12xlarge': 3.1752,
      'm6id.16xlarge': 4.2336,
      'm6id.24xlarge': 6.3504,
      'm6id.2xlarge': 0.5292,
      'm6id.32xlarge': 8.4672,
      'm6id.4xlarge': 1.0584,
      'm6id.8xlarge': 2.1168,
      'm6id.large': 0.1323,
      'm6id.metal': 8.4672,
      'm6id.xlarge': 0.2646,
      'm6idn.12xlarge': 4.26816,
      'm6idn.16xlarge': 5.69088,
      'm6idn.24xlarge': 8.53632,
      'm6idn.2xlarge': 0.71136,
      'm6idn.32xlarge': 11.38176,
      'm6idn.4xlarge': 1.42272,
      'm6idn.8xlarge': 2.84544,
      'm6idn.large': 0.17784,
      'm6idn.metal': 11.38176,
      'm6idn.xlarge': 0.35568,
      'm6in.12xlarge': 3.73464,
      'm6in.16xlarge': 4.97952,
      'm6in.24xlarge': 7.46928,
      'm6in.2xlarge': 0.62244,
      'm6in.32xlarge': 9.95904,
      'm6in.4xlarge': 1.24488,
      'm6in.8xlarge': 2.48976,
      'm6in.large': 0.15561,
      'm6in.metal': 9.95904,
      'm6in.xlarge': 0.31122,
      'm7a.12xlarge': 3.1008,
      'm7a.16xlarge': 4.1344,
      'm7a.24xlarge': 6.2016,
      'm7a.2xlarge': 0.5168,
      'm7a.32xlarge': 8.2688,
      'm7a.48xlarge': 12.4032,
      'm7a.4xlarge': 1.0336,
      'm7a.8xlarge': 2.0672,
      'm7a.large': 0.1292,
      'm7a.medium': 0.0646,
      'm7a.metal-48xl': 12.4032,
      'm7a.xlarge': 0.2584,
      'm7g.12xlarge': 2.1828,
      'm7g.16xlarge': 2.9104,
      'm7g.2xlarge': 0.3638,
      'm7g.4xlarge': 0.7276,
      'm7g.8xlarge': 1.4552,
      'm7g.large': 0.091,
      'm7g.medium': 0.0455,
      'm7g.metal': 2.9104,
      'm7g.xlarge': 0.1819,
      'm7gd.12xlarge': 2.8577,
      'm7gd.16xlarge': 3.8102,
      'm7gd.2xlarge': 0.4763,
      'm7gd.4xlarge': 0.9526,
      'm7gd.8xlarge': 1.9051,
      'm7gd.large': 0.1191,
      'm7gd.medium': 0.0595,
      'm7gd.metal': 3.8102,
      'm7gd.xlarge': 0.2381,
      'm7i-flex.2xlarge': 0.42692,
      'm7i-flex.4xlarge': 0.85384,
      'm7i-flex.8xlarge': 1.70768,
      'm7i-flex.large': 0.10673,
      'm7i-flex.xlarge': 0.21346,
      'm7i.12xlarge': 2.6964,
      'm7i.16xlarge': 3.5952,
      'm7i.24xlarge': 5.3928,
      'm7i.2xlarge': 0.4494,
      'm7i.48xlarge': 10.7856,
      'm7i.4xlarge': 0.8988,
      'm7i.8xlarge': 1.7976,
      'm7i.large': 0.11235,
      'm7i.metal-24xl': 5.3928,
      'm7i.metal-48xl': 10.7856,
      'm7i.xlarge': 0.2247,
      'p2.16xlarge': 15.552,
      'p2.8xlarge': 7.776,
      'p2.xlarge': 0.972,
      'p3.16xlarge': 26.44,
      'p3.2xlarge': 3.305,
      'p3.8xlarge': 13.22,
      'p3dn.24xlarge': 33.711,
      'p4d.24xlarge': 35.39655,
      'r3.2xlarge': 0.741,
      'r3.4xlarge': 1.482,
      'r3.8xlarge': 2.964,
      'r3.large': 0.185,
      'r3.xlarge': 0.371,
      'r4.16xlarge': 4.7424,
      'r4.2xlarge': 0.5928,
      'r4.4xlarge': 1.1856,
      'r4.8xlarge': 2.3712,
      'r4.large': 0.1482,
      'r4.xlarge': 0.2964,
      'r5.12xlarge': 3.384,
      'r5.16xlarge': 4.512,
      'r5.24xlarge': 6.768,
      'r5.2xlarge': 0.564,
      'r5.4xlarge': 1.128,
      'r5.8xlarge': 2.256,
      'r5.large': 0.141,
      'r5.metal': 6.768,
      'r5.xlarge': 0.282,
      'r5a.12xlarge': 3.048,
      'r5a.16xlarge': 4.064,
      'r5a.24xlarge': 6.096,
      'r5a.2xlarge': 0.508,
      'r5a.4xlarge': 1.016,
      'r5a.8xlarge': 2.032,
      'r5a.large': 0.127,
      'r5a.xlarge': 0.254,
      'r5ad.12xlarge': 3.504,
      'r5ad.16xlarge': 4.672,
      'r5ad.24xlarge': 7.008,
      'r5ad.2xlarge': 0.584,
      'r5ad.4xlarge': 1.168,
      'r5ad.8xlarge': 2.336,
      'r5ad.large': 0.146,
      'r5ad.xlarge': 0.292,
      'r5b.12xlarge': 4.008,
      'r5b.16xlarge': 5.344,
      'r5b.24xlarge': 8.016,
      'r5b.2xlarge': 0.668,
      'r5b.4xlarge': 1.336,
      'r5b.8xlarge': 2.672,
      'r5b.large': 0.167,
      'r5b.metal': 8.016,
      'r5b.xlarge': 0.334,
      'r5d.12xlarge': 3.84,
      'r5d.16xlarge': 5.12,
      'r5d.24xlarge': 7.68,
      'r5d.2xlarge': 0.64,
      'r5d.4xlarge': 1.28,
      'r5d.8xlarge': 2.56,
      'r5d.large': 0.16,
      'r5d.metal': 7.68,
      'r5d.xlarge': 0.32,
      'r5dn.12xlarge': 4.464,
      'r5dn.16xlarge': 5.952,
      'r5dn.24xlarge': 8.928,
      'r5dn.2xlarge': 0.744,
      'r5dn.4xlarge': 1.488,
      'r5dn.8xlarge': 2.976,
      'r5dn.large': 0.186,
      'r5dn.metal': 8.928,
      'r5dn.xlarge': 0.372,
      'r5n.12xlarge': 4.008,
      'r5n.16xlarge': 5.344,
      'r5n.24xlarge': 8.016,
      'r5n.2xlarge': 0.668,
      'r5n.4xlarge': 1.336,
      'r5n.8xlarge': 2.672,
      'r5n.large': 0.167,
      'r5n.metal': 8.016,
      'r5n.xlarge': 0.334,
      'r6a.12xlarge': 3.0456,
      'r6a.16xlarge': 4.0608,
      'r6a.24xlarge': 6.0912,
      'r6a.2xlarge': 0.5076,
      'r6a.32xlarge': 8.1216,
      'r6a.48xlarge': 12.1824,
      'r6a.4xlarge': 1.0152,
      'r6a.8xlarge': 2.0304,
      'r6a.large': 0.1269,
      'r6a.metal': 12.1824,
      'r6a.xlarge': 0.2538,
      'r6g.12xlarge': 2.7072,
      'r6g.16xlarge': 3.6096,
      'r6g.2xlarge': 0.4512,
      'r6g.4xlarge': 0.9024,
      'r6g.8xlarge': 1.8048,
      'r6g.large': 0.1128,
      'r6g.medium': 0.0564,
      'r6g.metal': 3.6096,
      'r6g.xlarge': 0.2256,
      'r6gd.12xlarge': 3.072,
      'r6gd.16xlarge': 4.096,
      'r6gd.2xlarge': 0.512,
      'r6gd.4xlarge': 1.024,
      'r6gd.8xlarge': 2.048,
      'r6gd.large': 0.128,
      'r6gd.medium': 0.064,
      'r6gd.metal': 4.096,
      'r6gd.xlarge': 0.256,
      'r6i.12xlarge': 3.384,
      'r6i.16xlarge': 4.512,
      'r6i.24xlarge': 6.768,
      'r6i.2xlarge': 0.564,
      'r6i.32xlarge': 9.024,
      'r6i.4xlarge': 1.128,
      'r6i.8xlarge': 2.256,
      'r6i.large': 0.141,
      'r6i.metal': 9.024,
      'r6i.xlarge': 0.282,
      'r6id.12xlarge': 4.032,
      'r6id.16xlarge': 5.376,
      'r6id.24xlarge': 8.064,
      'r6id.2xlarge': 0.672,
      'r6id.32xlarge': 10.752,
      'r6id.4xlarge': 1.344,
      'r6id.8xlarge': 2.688,
      'r6id.large': 0.168,
      'r6id.metal': 10.752,
      'r6id.xlarge': 0.336,
      'r6idn.12xlarge': 5.22288,
      'r6idn.16xlarge': 6.96384,
      'r6idn.24xlarge': 10.44576,
      'r6idn.2xlarge': 0.87048,
      'r6idn.32xlarge': 13.92768,
      'r6idn.4xlarge': 1.74096,
      'r6idn.8xlarge': 3.48192,
      'r6idn.large': 0.21762,
      'r6idn.metal': 13.92768,
      'r6idn.xlarge': 0.43524,
      'r6in.12xlarge': 4.68936,
      'r6in.16xlarge': 6.25248,
      'r6in.24xlarge': 9.37872,
      'r6in.2xlarge': 0.78156,
      'r6in.32xlarge': 12.50496,
      'r6in.4xlarge': 1.56312,
      'r6in.8xlarge': 3.12624,
      'r6in.large': 0.19539,
      'r6in.metal': 12.50496,
      'r6in.xlarge': 0.39078,
      'r7a.12xlarge': 4.08624,
      'r7a.16xlarge': 5.44832,
      'r7a.24xlarge': 8.17248,
      'r7a.2xlarge': 0.68104,
      'r7a.32xlarge': 10.89664,
      'r7a.48xlarge': 16.34496,
      'r7a.4xlarge': 1.36208,
      'r7a.8xlarge': 2.72416,
      'r7a.large': 0.17026,
      'r7a.medium': 0.08513,
      'r7a.metal-48xl': 16.34496,
      'r7a.xlarge': 0.34052,
      'r7g.12xlarge': 2.8764,
      'r7g.16xlarge': 3.8352,
      'r7g.2xlarge': 0.4794,
      'r7g.4xlarge': 0.9588,
      'r7g.8xlarge': 1.9176,
      'r7g.large': 0.1199,
      'r7g.medium': 0.0599,
      'r7g.metal': 3.8352,
      'r7g.xlarge': 0.2397,
      'r7gd.12xlarge': 3.6288,
      'r7gd.16xlarge': 4.8384,
      'r7gd.2xlarge': 0.6048,
      'r7gd.4xlarge': 1.2096,
      'r7gd.8xlarge': 2.4192,
      'r7gd.large': 0.1512,
      'r7gd.medium': 0.0756,
      'r7gd.metal': 4.8384,
      'r7gd.xlarge': 0.3024,
      'r7i.12xlarge': 3.5532,
      'r7i.16xlarge': 4.7376,
      'r7i.24xlarge': 7.1064,
      'r7i.2xlarge': 0.5922,
      'r7i.48xlarge': 14.2128,
      'r7i.4xlarge': 1.1844,
      'r7i.8xlarge': 2.3688,
      'r7i.large': 0.14805,
      'r7i.metal-24xl': 7.1064,
      'r7i.metal-48xl': 14.2128,
      'r7i.xlarge': 0.2961,
      'r7iz.12xlarge': 4.992,
      'r7iz.16xlarge': 6.656,
      'r7iz.2xlarge': 0.832,
      'r7iz.32xlarge': 13.312,
      'r7iz.4xlarge': 1.664,
      'r7iz.8xlarge': 3.328,
      'r7iz.large': 0.208,
      'r7iz.metal-16xl': 6.656,
      'r7iz.metal-32xl': 13.312,
      'r7iz.xlarge': 0.416,
      't1.micro': 0.02,
      't2.2xlarge': 0.4032,
      't2.large': 0.1008,
      't2.medium': 0.05,
      't2.micro': 0.0126,
      't2.nano': 0.0063,
      't2.small': 0.025,
      't2.xlarge': 0.2016,
      't3.2xlarge': 0.3648,
      't3.large': 0.0912,
      't3.medium': 0.0456,
      't3.micro': 0.0114,
      't3.nano': 0.0057,
      't3.small': 0.0228,
      't3.xlarge': 0.1824,
      't3a.2xlarge': 0.3264,
      't3a.large': 0.0816,
      't3a.medium': 0.0408,
      't3a.micro': 0.0102,
      't3a.nano': 0.0051,
      't3a.small': 0.0204,
      't3a.xlarge': 0.1632,
      't4g.2xlarge': 0.2944,
      't4g.large': 0.0736,
      't4g.medium': 0.0368,
      't4g.micro': 0.0092,
      't4g.nano': 0.0046,
      't4g.small': 0.0184,
      't4g.xlarge': 0.1472,
      'u-12tb1.112xlarge': 122.2,
      'u-18tb1.112xlarge': 183.3,
      'u-3tb1.56xlarge': 30.55,
      'u-6tb1.112xlarge': 61.1,
      'u-6tb1.56xlarge': 51.92818,
      'u-9tb1.112xlarge': 91.65,
      'vt1.24xlarge': 5.87294,
      'vt1.3xlarge': 0.73412,
      'vt1.6xlarge': 1.46824,
      'x1.16xlarge': 8.003,
      'x1.32xlarge': 16.006,
      'x1e.16xlarge': 16,
      'x1e.2xlarge': 2,
      'x1e.32xlarge': 32,
      'x1e.4xlarge': 4,
      'x1e.8xlarge': 8,
      'x1e.xlarge': 1,
      'x2gd.12xlarge': 4.8,
      'x2gd.16xlarge': 6.4,
      'x2gd.2xlarge': 0.8,
      'x2gd.4xlarge': 1.6,
      'x2gd.8xlarge': 3.2,
      'x2gd.large': 0.2,
      'x2gd.medium': 0.1,
      'x2gd.metal': 6.4,
      'x2gd.xlarge': 0.4,
      'x2idn.16xlarge': 8.003,
      'x2idn.24xlarge': 12.0045,
      'x2idn.32xlarge': 16.006,
      'x2idn.metal': 16.006,
      'x2iedn.16xlarge': 16.006,
      'x2iedn.24xlarge': 24.009,
      'x2iedn.2xlarge': 2.00075,
      'x2iedn.32xlarge': 32.012,
      'x2iedn.4xlarge': 4.0015,
      'x2iedn.8xlarge': 8.003,
      'x2iedn.metal': 32.012,
      'x2iedn.xlarge': 1.00038,
      'x2iezn.12xlarge': 12,
      'x2iezn.2xlarge': 2,
      'x2iezn.4xlarge': 4,
      'x2iezn.6xlarge': 6,
      'x2iezn.8xlarge': 8,
      'x2iezn.metal': 12,
      'z1d.12xlarge': 4.992,
      'z1d.2xlarge': 0.832,
      'z1d.3xlarge': 1.248,
      'z1d.6xlarge': 2.496,
      'z1d.large': 0.208,
      'z1d.metal': 4.992,
      'z1d.xlarge': 0.416,
    },
    'us-east-1': {
      'a1.2xlarge': 0.204,
      'a1.4xlarge': 0.408,
      'a1.large': 0.051,
      'a1.medium': 0.0255,
      'a1.metal': 0.408,
      'a1.xlarge': 0.102,
      'c1.medium': 0.13,
      'c1.xlarge': 0.52,
      'c3.2xlarge': 0.42,
      'c3.4xlarge': 0.84,
      'c3.8xlarge': 1.68,
      'c3.large': 0.105,
      'c3.xlarge': 0.21,
      'c4.2xlarge': 0.398,
      'c4.4xlarge': 0.796,
      'c4.8xlarge': 1.591,
      'c4.large': 0.1,
      'c4.xlarge': 0.199,
      'c5.12xlarge': 2.04,
      'c5.18xlarge': 3.06,
      'c5.24xlarge': 4.08,
      'c5.2xlarge': 0.34,
      'c5.4xlarge': 0.68,
      'c5.9xlarge': 1.53,
      'c5.large': 0.085,
      'c5.metal': 4.08,
      'c5.xlarge': 0.17,
      'c5a.12xlarge': 1.848,
      'c5a.16xlarge': 2.464,
      'c5a.24xlarge': 3.696,
      'c5a.2xlarge': 0.308,
      'c5a.4xlarge': 0.616,
      'c5a.8xlarge': 1.232,
      'c5a.large': 0.077,
      'c5a.xlarge': 0.154,
      'c5ad.12xlarge': 2.064,
      'c5ad.16xlarge': 2.752,
      'c5ad.24xlarge': 4.128,
      'c5ad.2xlarge': 0.344,
      'c5ad.4xlarge': 0.688,
      'c5ad.8xlarge': 1.376,
      'c5ad.large': 0.086,
      'c5ad.xlarge': 0.172,
      'c5d.12xlarge': 2.304,
      'c5d.18xlarge': 3.456,
      'c5d.24xlarge': 4.608,
      'c5d.2xlarge': 0.384,
      'c5d.4xlarge': 0.768,
      'c5d.9xlarge': 1.728,
      'c5d.large': 0.096,
      'c5d.metal': 4.608,
      'c5d.xlarge': 0.192,
      'c5n.18xlarge': 3.888,
      'c5n.2xlarge': 0.432,
      'c5n.4xlarge': 0.864,
      'c5n.9xlarge': 1.944,
      'c5n.large': 0.108,
      'c5n.metal': 3.888,
      'c5n.xlarge': 0.216,
      'c6a.12xlarge': 1.836,
      'c6a.16xlarge': 2.448,
      'c6a.24xlarge': 3.672,
      'c6a.2xlarge': 0.306,
      'c6a.32xlarge': 4.896,
      'c6a.48xlarge': 7.344,
      'c6a.4xlarge': 0.612,
      'c6a.8xlarge': 1.224,
      'c6a.large': 0.0765,
      'c6a.metal': 7.344,
      'c6a.xlarge': 0.153,
      'c6g.12xlarge': 1.632,
      'c6g.16xlarge': 2.176,
      'c6g.2xlarge': 0.272,
      'c6g.4xlarge': 0.544,
      'c6g.8xlarge': 1.088,
      'c6g.large': 0.068,
      'c6g.medium': 0.034,
      'c6g.metal': 2.176,
      'c6g.xlarge': 0.136,
      'c6gd.12xlarge': 1.8432,
      'c6gd.16xlarge': 2.4576,
      'c6gd.2xlarge': 0.3072,
      'c6gd.4xlarge': 0.6144,
      'c6gd.8xlarge': 1.2288,
      'c6gd.large': 0.0768,
      'c6gd.medium': 0.0384,
      'c6gd.metal': 2.4576,
      'c6gd.xlarge': 0.1536,
      'c6gn.12xlarge': 2.0736,
      'c6gn.16xlarge': 2.7648,
      'c6gn.2xlarge': 0.3456,
      'c6gn.4xlarge': 0.6912,
      'c6gn.8xlarge': 1.3824,
      'c6gn.large': 0.0864,
      'c6gn.medium': 0.0432,
      'c6gn.xlarge': 0.1728,
      'c6i.12xlarge': 2.04,
      'c6i.16xlarge': 2.72,
      'c6i.24xlarge': 4.08,
      'c6i.2xlarge': 0.34,
      'c6i.32xlarge': 5.44,
      'c6i.4xlarge': 0.68,
      'c6i.8xlarge': 1.36,
      'c6i.large': 0.085,
      'c6i.metal': 5.44,
      'c6i.xlarge': 0.17,
      'c6id.12xlarge': 2.4192,
      'c6id.16xlarge': 3.2256,
      'c6id.24xlarge': 4.8384,
      'c6id.2xlarge': 0.4032,
      'c6id.32xlarge': 6.4512,
      'c6id.4xlarge': 0.8064,
      'c6id.8xlarge': 1.6128,
      'c6id.large': 0.1008,
      'c6id.metal': 6.4512,
      'c6id.xlarge': 0.2016,
      'c6in.12xlarge': 2.7216,
      'c6in.16xlarge': 3.6288,
      'c6in.24xlarge': 5.4432,
      'c6in.2xlarge': 0.4536,
      'c6in.32xlarge': 7.2576,
      'c6in.4xlarge': 0.9072,
      'c6in.8xlarge': 1.8144,
      'c6in.large': 0.1134,
      'c6in.metal': 7.2576,
      'c6in.xlarge': 0.2268,
      'c7a.12xlarge': 2.46336,
      'c7a.16xlarge': 3.28448,
      'c7a.24xlarge': 4.92672,
      'c7a.2xlarge': 0.41056,
      'c7a.32xlarge': 6.56896,
      'c7a.48xlarge': 9.85344,
      'c7a.4xlarge': 0.82112,
      'c7a.8xlarge': 1.64224,
      'c7a.large': 0.10264,
      'c7a.medium': 0.05132,
      'c7a.metal-48xl': 9.85344,
      'c7a.xlarge': 0.20528,
      'c7g.12xlarge': 1.74,
      'c7g.16xlarge': 2.32,
      'c7g.2xlarge': 0.29,
      'c7g.4xlarge': 0.58,
      'c7g.8xlarge': 1.16,
      'c7g.large': 0.0725,
      'c7g.medium': 0.0363,
      'c7g.metal': 2.32,
      'c7g.xlarge': 0.145,
      'c7gd.12xlarge': 2.1773,
      'c7gd.16xlarge': 2.903,
      'c7gd.2xlarge': 0.3629,
      'c7gd.4xlarge': 0.7258,
      'c7gd.8xlarge': 1.4515,
      'c7gd.large': 0.0907,
      'c7gd.medium': 0.0454,
      'c7gd.metal': 2.903,
      'c7gd.xlarge': 0.1814,
      'c7gn.12xlarge': 2.9952,
      'c7gn.16xlarge': 3.9936,
      'c7gn.2xlarge': 0.4992,
      'c7gn.4xlarge': 0.9984,
      'c7gn.8xlarge': 1.9968,
      'c7gn.large': 0.1248,
      'c7gn.medium': 0.0624,
      'c7gn.metal': 3.9936,
      'c7gn.xlarge': 0.2496,
      'c7i.12xlarge': 2.142,
      'c7i.16xlarge': 2.856,
      'c7i.24xlarge': 4.284,
      'c7i.2xlarge': 0.357,
      'c7i.48xlarge': 8.568,
      'c7i.4xlarge': 0.714,
      'c7i.8xlarge': 1.428,
      'c7i.large': 0.08925,
      'c7i.metal-24xl': 4.284,
      'c7i.metal-48xl': 8.568,
      'c7i.xlarge': 0.1785,
      'cr1.8xlarge': 3.5,
      'd2.2xlarge': 1.38,
      'd2.4xlarge': 2.76,
      'd2.8xlarge': 5.52,
      'd2.xlarge': 0.69,
      'd3.2xlarge': 0.999,
      'd3.4xlarge': 1.998,
      'd3.8xlarge': 3.99552,
      'd3.xlarge': 0.499,
      'd3en.12xlarge': 6.30864,
      'd3en.2xlarge': 1.051,
      'd3en.4xlarge': 2.103,
      'd3en.6xlarge': 3.154,
      'd3en.8xlarge': 4.20576,
      'd3en.xlarge': 0.526,
      'dl1.24xlarge': 13.10904,
      'f1.16xlarge': 13.2,
      'f1.2xlarge': 1.65,
      'f1.4xlarge': 3.3,
      'g2.2xlarge': 0.65,
      'g2.8xlarge': 2.6,
      'g3.16xlarge': 4.56,
      'g3.4xlarge': 1.14,
      'g3.8xlarge': 2.28,
      'g3s.xlarge': 0.75,
      'g4ad.16xlarge': 3.468,
      'g4ad.2xlarge': 0.54117,
      'g4ad.4xlarge': 0.867,
      'g4ad.8xlarge': 1.734,
      'g4ad.xlarge': 0.37853,
      'g4dn.12xlarge': 3.912,
      'g4dn.16xlarge': 4.352,
      'g4dn.2xlarge': 0.752,
      'g4dn.4xlarge': 1.204,
      'g4dn.8xlarge': 2.176,
      'g4dn.metal': 7.824,
      'g4dn.xlarge': 0.526,
      'g5.12xlarge': 5.672,
      'g5.16xlarge': 4.096,
      'g5.24xlarge': 8.144,
      'g5.2xlarge': 1.212,
      'g5.48xlarge': 16.288,
      'g5.4xlarge': 1.624,
      'g5.8xlarge': 2.448,
      'g5.xlarge': 1.006,
      'g5g.16xlarge': 2.744,
      'g5g.2xlarge': 0.556,
      'g5g.4xlarge': 0.828,
      'g5g.8xlarge': 1.372,
      'g5g.metal': 2.744,
      'g5g.xlarge': 0.42,
      'g6.12xlarge': 4.6016,
      'g6.16xlarge': 3.3968,
      'g6.24xlarge': 6.6752,
      'g6.2xlarge': 0.9776,
      'g6.48xlarge': 13.3504,
      'g6.4xlarge': 1.3232,
      'g6.8xlarge': 2.0144,
      'g6.xlarge': 0.8048,
      'gr6.4xlarge': 1.5392,
      'gr6.8xlarge': 2.4464,
      'h1.16xlarge': 3.744,
      'h1.2xlarge': 0.468,
      'h1.4xlarge': 0.936,
      'h1.8xlarge': 1.872,
      'hpc7g.16xlarge': 1.6832,
      'hpc7g.4xlarge': 1.6832,
      'hpc7g.8xlarge': 1.6832,
      'i2.2xlarge': 1.705,
      'i2.4xlarge': 3.41,
      'i2.8xlarge': 6.82,
      'i2.xlarge': 0.853,
      'i3.16xlarge': 4.992,
      'i3.2xlarge': 0.624,
      'i3.4xlarge': 1.248,
      'i3.8xlarge': 2.496,
      'i3.large': 0.156,
      'i3.metal': 4.992,
      'i3.xlarge': 0.312,
      'i3en.12xlarge': 5.424,
      'i3en.24xlarge': 10.848,
      'i3en.2xlarge': 0.904,
      'i3en.3xlarge': 1.356,
      'i3en.6xlarge': 2.712,
      'i3en.large': 0.226,
      'i3en.metal': 10.848,
      'i3en.xlarge': 0.452,
      'i4g.16xlarge': 4.94208,
      'i4g.2xlarge': 0.61776,
      'i4g.4xlarge': 1.23552,
      'i4g.8xlarge': 2.47104,
      'i4g.large': 0.15444,
      'i4g.xlarge': 0.30888,
      'i4i.12xlarge': 4.118,
      'i4i.16xlarge': 5.491,
      'i4i.24xlarge': 8.2368,
      'i4i.2xlarge': 0.686,
      'i4i.32xlarge': 10.9824,
      'i4i.4xlarge': 1.373,
      'i4i.8xlarge': 2.746,
      'i4i.large': 0.172,
      'i4i.metal': 10.982,
      'i4i.xlarge': 0.343,
      'im4gn.16xlarge': 5.82067,
      'im4gn.2xlarge': 0.72758,
      'im4gn.4xlarge': 1.45517,
      'im4gn.8xlarge': 2.91034,
      'im4gn.large': 0.1819,
      'im4gn.xlarge': 0.36379,
      'inf1.24xlarge': 4.721,
      'inf1.2xlarge': 0.362,
      'inf1.6xlarge': 1.18,
      'inf1.xlarge': 0.228,
      'inf2.24xlarge': 6.49063,
      'inf2.48xlarge': 12.98127,
      'inf2.8xlarge': 1.96786,
      'inf2.xlarge': 0.7582,
      'is4gen.2xlarge': 1.1526,
      'is4gen.4xlarge': 2.3052,
      'is4gen.8xlarge': 4.6104,
      'is4gen.large': 0.28815,
      'is4gen.medium': 0.14408,
      'is4gen.xlarge': 0.5763,
      'm1.large': 0.175,
      'm1.medium': 0.087,
      'm1.small': 0.044,
      'm1.xlarge': 0.35,
      'm2.2xlarge': 0.49,
      'm2.4xlarge': 0.98,
      'm2.xlarge': 0.245,
      'm3.2xlarge': 0.532,
      'm3.large': 0.133,
      'm3.medium': 0.067,
      'm3.xlarge': 0.266,
      'm4.10xlarge': 2,
      'm4.16xlarge': 3.2,
      'm4.2xlarge': 0.4,
      'm4.4xlarge': 0.8,
      'm4.large': 0.1,
      'm4.xlarge': 0.2,
      'm5.12xlarge': 2.304,
      'm5.16xlarge': 3.072,
      'm5.24xlarge': 4.608,
      'm5.2xlarge': 0.384,
      'm5.4xlarge': 0.768,
      'm5.8xlarge': 1.536,
      'm5.large': 0.096,
      'm5.metal': 4.608,
      'm5.xlarge': 0.192,
      'm5a.12xlarge': 2.064,
      'm5a.16xlarge': 2.752,
      'm5a.24xlarge': 4.128,
      'm5a.2xlarge': 0.344,
      'm5a.4xlarge': 0.688,
      'm5a.8xlarge': 1.376,
      'm5a.large': 0.086,
      'm5a.xlarge': 0.172,
      'm5ad.12xlarge': 2.472,
      'm5ad.16xlarge': 3.296,
      'm5ad.24xlarge': 4.944,
      'm5ad.2xlarge': 0.412,
      'm5ad.4xlarge': 0.824,
      'm5ad.8xlarge': 1.648,
      'm5ad.large': 0.103,
      'm5ad.xlarge': 0.206,
      'm5d.12xlarge': 2.712,
      'm5d.16xlarge': 3.616,
      'm5d.24xlarge': 5.424,
      'm5d.2xlarge': 0.452,
      'm5d.4xlarge': 0.904,
      'm5d.8xlarge': 1.808,
      'm5d.large': 0.113,
      'm5d.metal': 5.424,
      'm5d.xlarge': 0.226,
      'm5dn.12xlarge': 3.264,
      'm5dn.16xlarge': 4.352,
      'm5dn.24xlarge': 6.528,
      'm5dn.2xlarge': 0.544,
      'm5dn.4xlarge': 1.088,
      'm5dn.8xlarge': 2.176,
      'm5dn.large': 0.136,
      'm5dn.metal': 6.528,
      'm5dn.xlarge': 0.272,
      'm5n.12xlarge': 2.856,
      'm5n.16xlarge': 3.808,
      'm5n.24xlarge': 5.712,
      'm5n.2xlarge': 0.476,
      'm5n.4xlarge': 0.952,
      'm5n.8xlarge': 1.904,
      'm5n.large': 0.119,
      'm5n.metal': 5.712,
      'm5n.xlarge': 0.238,
      'm5zn.12xlarge': 3.9641,
      'm5zn.2xlarge': 0.6607,
      'm5zn.3xlarge': 0.991,
      'm5zn.6xlarge': 1.982,
      'm5zn.large': 0.1652,
      'm5zn.metal': 3.9641,
      'm5zn.xlarge': 0.3303,
      'm6a.12xlarge': 2.0736,
      'm6a.16xlarge': 2.7648,
      'm6a.24xlarge': 4.1472,
      'm6a.2xlarge': 0.3456,
      'm6a.32xlarge': 5.5296,
      'm6a.48xlarge': 8.2944,
      'm6a.4xlarge': 0.6912,
      'm6a.8xlarge': 1.3824,
      'm6a.large': 0.0864,
      'm6a.metal': 8.2944,
      'm6a.xlarge': 0.1728,
      'm6g.12xlarge': 1.848,
      'm6g.16xlarge': 2.464,
      'm6g.2xlarge': 0.308,
      'm6g.4xlarge': 0.616,
      'm6g.8xlarge': 1.232,
      'm6g.large': 0.077,
      'm6g.medium': 0.0385,
      'm6g.metal': 2.464,
      'm6g.xlarge': 0.154,
      'm6gd.12xlarge': 2.1696,
      'm6gd.16xlarge': 2.8928,
      'm6gd.2xlarge': 0.3616,
      'm6gd.4xlarge': 0.7232,
      'm6gd.8xlarge': 1.4464,
      'm6gd.large': 0.0904,
      'm6gd.medium': 0.0452,
      'm6gd.metal': 2.8928,
      'm6gd.xlarge': 0.1808,
      'm6i.12xlarge': 2.304,
      'm6i.16xlarge': 3.072,
      'm6i.24xlarge': 4.608,
      'm6i.2xlarge': 0.384,
      'm6i.32xlarge': 6.144,
      'm6i.4xlarge': 0.768,
      'm6i.8xlarge': 1.536,
      'm6i.large': 0.096,
      'm6i.metal': 6.144,
      'm6i.xlarge': 0.192,
      'm6id.12xlarge': 2.8476,
      'm6id.16xlarge': 3.7968,
      'm6id.24xlarge': 5.6952,
      'm6id.2xlarge': 0.4746,
      'm6id.32xlarge': 7.5936,
      'm6id.4xlarge': 0.9492,
      'm6id.8xlarge': 1.8984,
      'm6id.large': 0.11865,
      'm6id.metal': 7.5936,
      'm6id.xlarge': 0.2373,
      'm6idn.12xlarge': 3.81888,
      'm6idn.16xlarge': 5.09184,
      'm6idn.24xlarge': 7.63776,
      'm6idn.2xlarge': 0.63648,
      'm6idn.32xlarge': 10.18368,
      'm6idn.4xlarge': 1.27296,
      'm6idn.8xlarge': 2.54592,
      'm6idn.large': 0.15912,
      'm6idn.metal': 10.18368,
      'm6idn.xlarge': 0.31824,
      'm6in.12xlarge': 3.34152,
      'm6in.16xlarge': 4.45536,
      'm6in.24xlarge': 6.68304,
      'm6in.2xlarge': 0.55692,
      'm6in.32xlarge': 8.91072,
      'm6in.4xlarge': 1.11384,
      'm6in.8xlarge': 2.22768,
      'm6in.large': 0.13923,
      'm6in.metal': 8.91072,
      'm6in.xlarge': 0.27846,
      'm7a.12xlarge': 2.78208,
      'm7a.16xlarge': 3.70944,
      'm7a.24xlarge': 5.56416,
      'm7a.2xlarge': 0.46368,
      'm7a.32xlarge': 7.41888,
      'm7a.48xlarge': 11.12832,
      'm7a.4xlarge': 0.92736,
      'm7a.8xlarge': 1.85472,
      'm7a.large': 0.11592,
      'm7a.medium': 0.05796,
      'm7a.metal-48xl': 11.12832,
      'm7a.xlarge': 0.23184,
      'm7g.12xlarge': 1.9584,
      'm7g.16xlarge': 2.6112,
      'm7g.2xlarge': 0.3264,
      'm7g.4xlarge': 0.6528,
      'm7g.8xlarge': 1.3056,
      'm7g.large': 0.0816,
      'm7g.medium': 0.0408,
      'm7g.metal': 2.6112,
      'm7g.xlarge': 0.1632,
      'm7gd.12xlarge': 2.5628,
      'm7gd.16xlarge': 3.4171,
      'm7gd.2xlarge': 0.4271,
      'm7gd.4xlarge': 0.8543,
      'm7gd.8xlarge': 1.7086,
      'm7gd.large': 0.1068,
      'm7gd.medium': 0.0534,
      'm7gd.metal': 3.4171,
      'm7gd.xlarge': 0.2136,
      'm7i-flex.2xlarge': 0.38304,
      'm7i-flex.4xlarge': 0.76608,
      'm7i-flex.8xlarge': 1.53216,
      'm7i-flex.large': 0.09576,
      'm7i-flex.xlarge': 0.19152,
      'm7i.12xlarge': 2.4192,
      'm7i.16xlarge': 3.2256,
      'm7i.24xlarge': 4.8384,
      'm7i.2xlarge': 0.4032,
      'm7i.48xlarge': 9.6768,
      'm7i.4xlarge': 0.8064,
      'm7i.8xlarge': 1.6128,
      'm7i.large': 0.1008,
      'm7i.metal-24xl': 4.8384,
      'm7i.metal-48xl': 9.6768,
      'm7i.xlarge': 0.2016,
      'p2.16xlarge': 14.4,
      'p2.8xlarge': 7.2,
      'p2.xlarge': 0.9,
      'p3.16xlarge': 24.48,
      'p3.2xlarge': 3.06,
      'p3.8xlarge': 12.24,
      'p3dn.24xlarge': 31.212,
      'p4d.24xlarge': 32.7726,
      'p4de.24xlarge': 40.96575,
      'p5.48xlarge': 98.32,
      'r3.2xlarge': 0.665,
      'r3.4xlarge': 1.33,
      'r3.8xlarge': 2.66,
      'r3.large': 0.166,
      'r3.xlarge': 0.333,
      'r4.16xlarge': 4.256,
      'r4.2xlarge': 0.532,
      'r4.4xlarge': 1.064,
      'r4.8xlarge': 2.128,
      'r4.large': 0.133,
      'r4.xlarge': 0.266,
      'r5.12xlarge': 3.024,
      'r5.16xlarge': 4.032,
      'r5.24xlarge': 6.048,
      'r5.2xlarge': 0.504,
      'r5.4xlarge': 1.008,
      'r5.8xlarge': 2.016,
      'r5.large': 0.126,
      'r5.metal': 6.048,
      'r5.xlarge': 0.252,
      'r5a.12xlarge': 2.712,
      'r5a.16xlarge': 3.616,
      'r5a.24xlarge': 5.424,
      'r5a.2xlarge': 0.452,
      'r5a.4xlarge': 0.904,
      'r5a.8xlarge': 1.808,
      'r5a.large': 0.113,
      'r5a.xlarge': 0.226,
      'r5ad.12xlarge': 3.144,
      'r5ad.16xlarge': 4.192,
      'r5ad.24xlarge': 6.288,
      'r5ad.2xlarge': 0.524,
      'r5ad.4xlarge': 1.048,
      'r5ad.8xlarge': 2.096,
      'r5ad.large': 0.131,
      'r5ad.xlarge': 0.262,
      'r5b.12xlarge': 3.576,
      'r5b.16xlarge': 4.768,
      'r5b.24xlarge': 7.152,
      'r5b.2xlarge': 0.596,
      'r5b.4xlarge': 1.192,
      'r5b.8xlarge': 2.384,
      'r5b.large': 0.149,
      'r5b.metal': 7.152,
      'r5b.xlarge': 0.298,
      'r5d.12xlarge': 3.456,
      'r5d.16xlarge': 4.608,
      'r5d.24xlarge': 6.912,
      'r5d.2xlarge': 0.576,
      'r5d.4xlarge': 1.152,
      'r5d.8xlarge': 2.304,
      'r5d.large': 0.144,
      'r5d.metal': 6.912,
      'r5d.xlarge': 0.288,
      'r5dn.12xlarge': 4.008,
      'r5dn.16xlarge': 5.344,
      'r5dn.24xlarge': 8.016,
      'r5dn.2xlarge': 0.668,
      'r5dn.4xlarge': 1.336,
      'r5dn.8xlarge': 2.672,
      'r5dn.large': 0.167,
      'r5dn.metal': 8.016,
      'r5dn.xlarge': 0.334,
      'r5n.12xlarge': 3.576,
      'r5n.16xlarge': 4.768,
      'r5n.24xlarge': 7.152,
      'r5n.2xlarge': 0.596,
      'r5n.4xlarge': 1.192,
      'r5n.8xlarge': 2.384,
      'r5n.large': 0.149,
      'r5n.metal': 7.152,
      'r5n.xlarge': 0.298,
      'r6a.12xlarge': 2.7216,
      'r6a.16xlarge': 3.6288,
      'r6a.24xlarge': 5.4432,
      'r6a.2xlarge': 0.4536,
      'r6a.32xlarge': 7.2576,
      'r6a.48xlarge': 10.8864,
      'r6a.4xlarge': 0.9072,
      'r6a.8xlarge': 1.8144,
      'r6a.large': 0.1134,
      'r6a.metal': 10.8864,
      'r6a.xlarge': 0.2268,
      'r6g.12xlarge': 2.4192,
      'r6g.16xlarge': 3.2256,
      'r6g.2xlarge': 0.4032,
      'r6g.4xlarge': 0.8064,
      'r6g.8xlarge': 1.6128,
      'r6g.large': 0.1008,
      'r6g.medium': 0.0504,
      'r6g.metal': 3.2256,
      'r6g.xlarge': 0.2016,
      'r6gd.12xlarge': 2.7648,
      'r6gd.16xlarge': 3.6864,
      'r6gd.2xlarge': 0.4608,
      'r6gd.4xlarge': 0.9216,
      'r6gd.8xlarge': 1.8432,
      'r6gd.large': 0.1152,
      'r6gd.medium': 0.0576,
      'r6gd.metal': 3.6864,
      'r6gd.xlarge': 0.2304,
      'r6i.12xlarge': 3.024,
      'r6i.16xlarge': 4.032,
      'r6i.24xlarge': 6.048,
      'r6i.2xlarge': 0.504,
      'r6i.32xlarge': 8.064,
      'r6i.4xlarge': 1.008,
      'r6i.8xlarge': 2.016,
      'r6i.large': 0.126,
      'r6i.metal': 8.064,
      'r6i.xlarge': 0.252,
      'r6id.12xlarge': 3.6288,
      'r6id.16xlarge': 4.8384,
      'r6id.24xlarge': 7.2576,
      'r6id.2xlarge': 0.6048,
      'r6id.32xlarge': 9.6768,
      'r6id.4xlarge': 1.2096,
      'r6id.8xlarge': 2.4192,
      'r6id.large': 0.1512,
      'r6id.metal': 9.6768,
      'r6id.xlarge': 0.3024,
      'r6idn.12xlarge': 4.68936,
      'r6idn.16xlarge': 6.25248,
      'r6idn.24xlarge': 9.37872,
      'r6idn.2xlarge': 0.78156,
      'r6idn.32xlarge': 12.50496,
      'r6idn.4xlarge': 1.56312,
      'r6idn.8xlarge': 3.12624,
      'r6idn.large': 0.19539,
      'r6idn.metal': 12.50496,
      'r6idn.xlarge': 0.39078,
      'r6in.12xlarge': 4.18392,
      'r6in.16xlarge': 5.57856,
      'r6in.24xlarge': 8.36784,
      'r6in.2xlarge': 0.69732,
      'r6in.32xlarge': 11.15712,
      'r6in.4xlarge': 1.39464,
      'r6in.8xlarge': 2.78928,
      'r6in.large': 0.17433,
      'r6in.metal': 11.15712,
      'r6in.xlarge': 0.34866,
      'r7a.12xlarge': 3.6516,
      'r7a.16xlarge': 4.8688,
      'r7a.24xlarge': 7.3032,
      'r7a.2xlarge': 0.6086,
      'r7a.32xlarge': 9.7376,
      'r7a.48xlarge': 14.6064,
      'r7a.4xlarge': 1.2172,
      'r7a.8xlarge': 2.4344,
      'r7a.large': 0.15215,
      'r7a.medium': 0.07608,
      'r7a.metal-48xl': 14.6064,
      'r7a.xlarge': 0.3043,
      'r7g.12xlarge': 2.5704,
      'r7g.16xlarge': 3.4272,
      'r7g.2xlarge': 0.4284,
      'r7g.4xlarge': 0.8568,
      'r7g.8xlarge': 1.7136,
      'r7g.large': 0.1071,
      'r7g.medium': 0.0536,
      'r7g.metal': 3.4272,
      'r7g.xlarge': 0.2142,
      'r7gd.12xlarge': 3.2659,
      'r7gd.16xlarge': 4.3546,
      'r7gd.2xlarge': 0.5443,
      'r7gd.4xlarge': 1.0886,
      'r7gd.8xlarge': 2.1773,
      'r7gd.large': 0.1361,
      'r7gd.medium': 0.068,
      'r7gd.metal': 4.3546,
      'r7gd.xlarge': 0.2722,
      'r7i.12xlarge': 3.1752,
      'r7i.16xlarge': 4.2336,
      'r7i.24xlarge': 6.3504,
      'r7i.2xlarge': 0.5292,
      'r7i.48xlarge': 12.7008,
      'r7i.4xlarge': 1.0584,
      'r7i.8xlarge': 2.1168,
      'r7i.large': 0.1323,
      'r7i.metal-24xl': 6.3504,
      'r7i.metal-48xl': 12.7008,
      'r7i.xlarge': 0.2646,
      'r7iz.12xlarge': 4.464,
      'r7iz.16xlarge': 5.952,
      'r7iz.2xlarge': 0.744,
      'r7iz.32xlarge': 11.904,
      'r7iz.4xlarge': 1.488,
      'r7iz.8xlarge': 2.976,
      'r7iz.large': 0.186,
      'r7iz.metal-16xl': 5.952,
      'r7iz.metal-32xl': 11.904,
      'r7iz.xlarge': 0.372,
      't1.micro': 0.02,
      't2.2xlarge': 0.3712,
      't2.large': 0.0928,
      't2.medium': 0.0464,
      't2.micro': 0.0116,
      't2.nano': 0.0058,
      't2.small': 0.023,
      't2.xlarge': 0.1856,
      't3.2xlarge': 0.3328,
      't3.large': 0.0832,
      't3.medium': 0.0416,
      't3.micro': 0.0104,
      't3.nano': 0.0052,
      't3.small': 0.0208,
      't3.xlarge': 0.1664,
      't3a.2xlarge': 0.3008,
      't3a.large': 0.0752,
      't3a.medium': 0.0376,
      't3a.micro': 0.0094,
      't3a.nano': 0.0047,
      't3a.small': 0.0188,
      't3a.xlarge': 0.1504,
      't4g.2xlarge': 0.2688,
      't4g.large': 0.0672,
      't4g.medium': 0.0336,
      't4g.micro': 0.0084,
      't4g.nano': 0.0042,
      't4g.small': 0.0168,
      't4g.xlarge': 0.1344,
      'trn1.2xlarge': 1.34375,
      'trn1.32xlarge': 21.5,
      'trn1n.32xlarge': 24.78,
      'u-12tb1.112xlarge': 109.2,
      'u-18tb1.112xlarge': 163.8,
      'u-24tb1.112xlarge': 218.4,
      'u-3tb1.56xlarge': 27.3,
      'u-6tb1.112xlarge': 54.6,
      'u-6tb1.56xlarge': 46.40391,
      'u-9tb1.112xlarge': 81.9,
      'vt1.24xlarge': 5.2,
      'vt1.3xlarge': 0.65,
      'vt1.6xlarge': 1.3,
      'x1.16xlarge': 6.669,
      'x1.32xlarge': 13.338,
      'x1e.16xlarge': 13.344,
      'x1e.2xlarge': 1.668,
      'x1e.32xlarge': 26.688,
      'x1e.4xlarge': 3.336,
      'x1e.8xlarge': 6.672,
      'x1e.xlarge': 0.834,
      'x2gd.12xlarge': 4.008,
      'x2gd.16xlarge': 5.344,
      'x2gd.2xlarge': 0.668,
      'x2gd.4xlarge': 1.336,
      'x2gd.8xlarge': 2.672,
      'x2gd.large': 0.167,
      'x2gd.medium': 0.0835,
      'x2gd.metal': 5.344,
      'x2gd.xlarge': 0.334,
      'x2idn.16xlarge': 6.669,
      'x2idn.24xlarge': 10.0035,
      'x2idn.32xlarge': 13.338,
      'x2idn.metal': 13.338,
      'x2iedn.16xlarge': 13.338,
      'x2iedn.24xlarge': 20.007,
      'x2iedn.2xlarge': 1.66725,
      'x2iedn.32xlarge': 26.676,
      'x2iedn.4xlarge': 3.3345,
      'x2iedn.8xlarge': 6.669,
      'x2iedn.metal': 26.676,
      'x2iedn.xlarge': 0.83363,
      'x2iezn.12xlarge': 10.008,
      'x2iezn.2xlarge': 1.668,
      'x2iezn.4xlarge': 3.336,
      'x2iezn.6xlarge': 5.004,
      'x2iezn.8xlarge': 6.672,
      'x2iezn.metal': 10.008,
      'z1d.12xlarge': 4.464,
      'z1d.2xlarge': 0.744,
      'z1d.3xlarge': 1.116,
      'z1d.6xlarge': 2.232,
      'z1d.large': 0.186,
      'z1d.metal': 4.464,
      'z1d.xlarge': 0.372,
    },
    'us-east-2': {
      'a1.2xlarge': 0.204,
      'a1.4xlarge': 0.408,
      'a1.large': 0.051,
      'a1.medium': 0.0255,
      'a1.metal': 0.408,
      'a1.xlarge': 0.102,
      'c4.2xlarge': 0.398,
      'c4.4xlarge': 0.796,
      'c4.8xlarge': 1.591,
      'c4.large': 0.1,
      'c4.xlarge': 0.199,
      'c5.12xlarge': 2.04,
      'c5.18xlarge': 3.06,
      'c5.24xlarge': 4.08,
      'c5.2xlarge': 0.34,
      'c5.4xlarge': 0.68,
      'c5.9xlarge': 1.53,
      'c5.large': 0.085,
      'c5.metal': 4.08,
      'c5.xlarge': 0.17,
      'c5a.12xlarge': 1.848,
      'c5a.16xlarge': 2.464,
      'c5a.24xlarge': 3.696,
      'c5a.2xlarge': 0.308,
      'c5a.4xlarge': 0.616,
      'c5a.8xlarge': 1.232,
      'c5a.large': 0.077,
      'c5a.xlarge': 0.154,
      'c5ad.12xlarge': 2.064,
      'c5ad.16xlarge': 2.752,
      'c5ad.24xlarge': 4.128,
      'c5ad.2xlarge': 0.344,
      'c5ad.4xlarge': 0.688,
      'c5ad.8xlarge': 1.376,
      'c5ad.large': 0.086,
      'c5ad.xlarge': 0.172,
      'c5d.12xlarge': 2.304,
      'c5d.18xlarge': 3.456,
      'c5d.24xlarge': 4.608,
      'c5d.2xlarge': 0.384,
      'c5d.4xlarge': 0.768,
      'c5d.9xlarge': 1.728,
      'c5d.large': 0.096,
      'c5d.metal': 4.608,
      'c5d.xlarge': 0.192,
      'c5n.18xlarge': 3.888,
      'c5n.2xlarge': 0.432,
      'c5n.4xlarge': 0.864,
      'c5n.9xlarge': 1.944,
      'c5n.large': 0.108,
      'c5n.metal': 3.888,
      'c5n.xlarge': 0.216,
      'c6a.12xlarge': 1.836,
      'c6a.16xlarge': 2.448,
      'c6a.24xlarge': 3.672,
      'c6a.2xlarge': 0.306,
      'c6a.32xlarge': 4.896,
      'c6a.48xlarge': 7.344,
      'c6a.4xlarge': 0.612,
      'c6a.8xlarge': 1.224,
      'c6a.large': 0.0765,
      'c6a.metal': 7.344,
      'c6a.xlarge': 0.153,
      'c6g.12xlarge': 1.632,
      'c6g.16xlarge': 2.176,
      'c6g.2xlarge': 0.272,
      'c6g.4xlarge': 0.544,
      'c6g.8xlarge': 1.088,
      'c6g.large': 0.068,
      'c6g.medium': 0.034,
      'c6g.metal': 2.176,
      'c6g.xlarge': 0.136,
      'c6gd.12xlarge': 1.8432,
      'c6gd.16xlarge': 2.4576,
      'c6gd.2xlarge': 0.3072,
      'c6gd.4xlarge': 0.6144,
      'c6gd.8xlarge': 1.2288,
      'c6gd.large': 0.0768,
      'c6gd.medium': 0.0384,
      'c6gd.metal': 2.4576,
      'c6gd.xlarge': 0.1536,
      'c6gn.12xlarge': 2.0736,
      'c6gn.16xlarge': 2.7648,
      'c6gn.2xlarge': 0.3456,
      'c6gn.4xlarge': 0.6912,
      'c6gn.8xlarge': 1.3824,
      'c6gn.large': 0.0864,
      'c6gn.medium': 0.0432,
      'c6gn.xlarge': 0.1728,
      'c6i.12xlarge': 2.04,
      'c6i.16xlarge': 2.72,
      'c6i.24xlarge': 4.08,
      'c6i.2xlarge': 0.34,
      'c6i.32xlarge': 5.44,
      'c6i.4xlarge': 0.68,
      'c6i.8xlarge': 1.36,
      'c6i.large': 0.085,
      'c6i.metal': 5.44,
      'c6i.xlarge': 0.17,
      'c6id.12xlarge': 2.4192,
      'c6id.16xlarge': 3.2256,
      'c6id.24xlarge': 4.8384,
      'c6id.2xlarge': 0.4032,
      'c6id.32xlarge': 6.4512,
      'c6id.4xlarge': 0.8064,
      'c6id.8xlarge': 1.6128,
      'c6id.large': 0.1008,
      'c6id.metal': 6.4512,
      'c6id.xlarge': 0.2016,
      'c6in.12xlarge': 2.7216,
      'c6in.16xlarge': 3.6288,
      'c6in.24xlarge': 5.4432,
      'c6in.2xlarge': 0.4536,
      'c6in.32xlarge': 7.2576,
      'c6in.4xlarge': 0.9072,
      'c6in.8xlarge': 1.8144,
      'c6in.large': 0.1134,
      'c6in.metal': 7.2576,
      'c6in.xlarge': 0.2268,
      'c7a.12xlarge': 2.46336,
      'c7a.16xlarge': 3.28448,
      'c7a.24xlarge': 4.92672,
      'c7a.2xlarge': 0.41056,
      'c7a.32xlarge': 6.56896,
      'c7a.48xlarge': 9.85344,
      'c7a.4xlarge': 0.82112,
      'c7a.8xlarge': 1.64224,
      'c7a.large': 0.10264,
      'c7a.medium': 0.05132,
      'c7a.metal-48xl': 9.85344,
      'c7a.xlarge': 0.20528,
      'c7g.12xlarge': 1.7342,
      'c7g.16xlarge': 2.3123,
      'c7g.2xlarge': 0.289,
      'c7g.4xlarge': 0.5781,
      'c7g.8xlarge': 1.1562,
      'c7g.large': 0.0723,
      'c7g.medium': 0.0361,
      'c7g.metal': 2.3123,
      'c7g.xlarge': 0.1445,
      'c7gd.12xlarge': 2.1773,
      'c7gd.16xlarge': 2.903,
      'c7gd.2xlarge': 0.3629,
      'c7gd.4xlarge': 0.7258,
      'c7gd.8xlarge': 1.4515,
      'c7gd.large': 0.0907,
      'c7gd.medium': 0.0454,
      'c7gd.metal': 2.903,
      'c7gd.xlarge': 0.1814,
      'c7gn.12xlarge': 2.9952,
      'c7gn.16xlarge': 3.9936,
      'c7gn.2xlarge': 0.4992,
      'c7gn.4xlarge': 0.9984,
      'c7gn.8xlarge': 1.9968,
      'c7gn.large': 0.1248,
      'c7gn.medium': 0.0624,
      'c7gn.metal': 3.9936,
      'c7gn.xlarge': 0.2496,
      'c7i.12xlarge': 2.142,
      'c7i.16xlarge': 2.856,
      'c7i.24xlarge': 4.284,
      'c7i.2xlarge': 0.357,
      'c7i.48xlarge': 8.568,
      'c7i.4xlarge': 0.714,
      'c7i.8xlarge': 1.428,
      'c7i.large': 0.08925,
      'c7i.metal-24xl': 4.284,
      'c7i.metal-48xl': 8.568,
      'c7i.xlarge': 0.1785,
      'd2.2xlarge': 1.38,
      'd2.4xlarge': 2.76,
      'd2.8xlarge': 5.52,
      'd2.xlarge': 0.69,
      'd3.2xlarge': 0.999,
      'd3.4xlarge': 1.998,
      'd3.8xlarge': 3.99552,
      'd3.xlarge': 0.499,
      'g3.16xlarge': 4.56,
      'g3.4xlarge': 1.14,
      'g3.8xlarge': 2.28,
      'g3s.xlarge': 0.75,
      'g4ad.16xlarge': 3.468,
      'g4ad.2xlarge': 0.54117,
      'g4ad.4xlarge': 0.867,
      'g4ad.8xlarge': 1.734,
      'g4ad.xlarge': 0.37853,
      'g4dn.12xlarge': 3.912,
      'g4dn.16xlarge': 4.352,
      'g4dn.2xlarge': 0.752,
      'g4dn.4xlarge': 1.204,
      'g4dn.8xlarge': 2.176,
      'g4dn.metal': 7.824,
      'g4dn.xlarge': 0.526,
      'g5.12xlarge': 5.672,
      'g5.16xlarge': 4.096,
      'g5.24xlarge': 8.144,
      'g5.2xlarge': 1.212,
      'g5.48xlarge': 16.288,
      'g5.4xlarge': 1.624,
      'g5.8xlarge': 2.448,
      'g5.xlarge': 1.006,
      'g6.12xlarge': 4.6016,
      'g6.16xlarge': 3.3968,
      'g6.24xlarge': 6.6752,
      'g6.2xlarge': 0.9776,
      'g6.48xlarge': 13.3504,
      'g6.4xlarge': 1.3232,
      'g6.8xlarge': 2.0144,
      'g6.xlarge': 0.8048,
      'gr6.4xlarge': 1.5392,
      'gr6.8xlarge': 2.4464,
      'h1.16xlarge': 3.744,
      'h1.2xlarge': 0.468,
      'h1.4xlarge': 0.936,
      'h1.8xlarge': 1.872,
      'hpc6a.48xlarge': 2.88,
      'hpc6id.32xlarge': 5.7,
      'hpc7a.12xlarge': 7.2,
      'hpc7a.24xlarge': 7.2,
      'hpc7a.48xlarge': 7.2,
      'hpc7a.96xlarge': 7.2,
      'i2.2xlarge': 1.705,
      'i2.4xlarge': 3.41,
      'i2.8xlarge': 6.82,
      'i2.xlarge': 0.853,
      'i3.16xlarge': 4.992,
      'i3.2xlarge': 0.624,
      'i3.4xlarge': 1.248,
      'i3.8xlarge': 2.496,
      'i3.large': 0.156,
      'i3.metal': 4.992,
      'i3.xlarge': 0.312,
      'i3en.12xlarge': 5.424,
      'i3en.24xlarge': 10.848,
      'i3en.2xlarge': 0.904,
      'i3en.3xlarge': 1.356,
      'i3en.6xlarge': 2.712,
      'i3en.large': 0.226,
      'i3en.metal': 10.848,
      'i3en.xlarge': 0.452,
      'i4g.16xlarge': 4.94208,
      'i4g.2xlarge': 0.61776,
      'i4g.4xlarge': 1.23552,
      'i4g.8xlarge': 2.47104,
      'i4g.large': 0.15444,
      'i4g.xlarge': 0.30888,
      'i4i.12xlarge': 4.118,
      'i4i.16xlarge': 5.491,
      'i4i.24xlarge': 8.2368,
      'i4i.2xlarge': 0.686,
      'i4i.32xlarge': 10.9824,
      'i4i.4xlarge': 1.373,
      'i4i.8xlarge': 2.746,
      'i4i.large': 0.172,
      'i4i.metal': 10.982,
      'i4i.xlarge': 0.343,
      'im4gn.16xlarge': 5.82067,
      'im4gn.2xlarge': 0.72758,
      'im4gn.4xlarge': 1.45517,
      'im4gn.8xlarge': 2.91034,
      'im4gn.large': 0.1819,
      'im4gn.xlarge': 0.36379,
      'inf1.24xlarge': 4.721,
      'inf1.2xlarge': 0.362,
      'inf1.6xlarge': 1.18,
      'inf1.xlarge': 0.228,
      'inf2.24xlarge': 6.49063,
      'inf2.48xlarge': 12.98127,
      'inf2.8xlarge': 1.96786,
      'inf2.xlarge': 0.7582,
      'is4gen.2xlarge': 1.1526,
      'is4gen.4xlarge': 2.3052,
      'is4gen.8xlarge': 4.6104,
      'is4gen.large': 0.28815,
      'is4gen.medium': 0.14408,
      'is4gen.xlarge': 0.5763,
      'm4.10xlarge': 2,
      'm4.16xlarge': 3.2,
      'm4.2xlarge': 0.4,
      'm4.4xlarge': 0.8,
      'm4.large': 0.1,
      'm4.xlarge': 0.2,
      'm5.12xlarge': 2.304,
      'm5.16xlarge': 3.072,
      'm5.24xlarge': 4.608,
      'm5.2xlarge': 0.384,
      'm5.4xlarge': 0.768,
      'm5.8xlarge': 1.536,
      'm5.large': 0.096,
      'm5.metal': 4.608,
      'm5.xlarge': 0.192,
      'm5a.12xlarge': 2.064,
      'm5a.16xlarge': 2.752,
      'm5a.24xlarge': 4.128,
      'm5a.2xlarge': 0.344,
      'm5a.4xlarge': 0.688,
      'm5a.8xlarge': 1.376,
      'm5a.large': 0.086,
      'm5a.xlarge': 0.172,
      'm5ad.12xlarge': 2.472,
      'm5ad.16xlarge': 3.296,
      'm5ad.24xlarge': 4.944,
      'm5ad.2xlarge': 0.412,
      'm5ad.4xlarge': 0.824,
      'm5ad.8xlarge': 1.648,
      'm5ad.large': 0.103,
      'm5ad.xlarge': 0.206,
      'm5d.12xlarge': 2.712,
      'm5d.16xlarge': 3.616,
      'm5d.24xlarge': 5.424,
      'm5d.2xlarge': 0.452,
      'm5d.4xlarge': 0.904,
      'm5d.8xlarge': 1.808,
      'm5d.large': 0.113,
      'm5d.metal': 5.424,
      'm5d.xlarge': 0.226,
      'm5dn.12xlarge': 3.264,
      'm5dn.16xlarge': 4.352,
      'm5dn.24xlarge': 6.528,
      'm5dn.2xlarge': 0.544,
      'm5dn.4xlarge': 1.088,
      'm5dn.8xlarge': 2.176,
      'm5dn.large': 0.136,
      'm5dn.metal': 6.528,
      'm5dn.xlarge': 0.272,
      'm5n.12xlarge': 2.856,
      'm5n.16xlarge': 3.808,
      'm5n.24xlarge': 5.712,
      'm5n.2xlarge': 0.476,
      'm5n.4xlarge': 0.952,
      'm5n.8xlarge': 1.904,
      'm5n.large': 0.119,
      'm5n.metal': 5.712,
      'm5n.xlarge': 0.238,
      'm5zn.12xlarge': 3.9641,
      'm5zn.2xlarge': 0.6607,
      'm5zn.3xlarge': 0.991,
      'm5zn.6xlarge': 1.982,
      'm5zn.large': 0.1652,
      'm5zn.metal': 3.9641,
      'm5zn.xlarge': 0.3303,
      'm6a.12xlarge': 2.0736,
      'm6a.16xlarge': 2.7648,
      'm6a.24xlarge': 4.1472,
      'm6a.2xlarge': 0.3456,
      'm6a.32xlarge': 5.5296,
      'm6a.48xlarge': 8.2944,
      'm6a.4xlarge': 0.6912,
      'm6a.8xlarge': 1.3824,
      'm6a.large': 0.0864,
      'm6a.metal': 8.2944,
      'm6a.xlarge': 0.1728,
      'm6g.12xlarge': 1.848,
      'm6g.16xlarge': 2.464,
      'm6g.2xlarge': 0.308,
      'm6g.4xlarge': 0.616,
      'm6g.8xlarge': 1.232,
      'm6g.large': 0.077,
      'm6g.medium': 0.0385,
      'm6g.metal': 2.464,
      'm6g.xlarge': 0.154,
      'm6gd.12xlarge': 2.1696,
      'm6gd.16xlarge': 2.8928,
      'm6gd.2xlarge': 0.3616,
      'm6gd.4xlarge': 0.7232,
      'm6gd.8xlarge': 1.4464,
      'm6gd.large': 0.0904,
      'm6gd.medium': 0.0452,
      'm6gd.metal': 2.8928,
      'm6gd.xlarge': 0.1808,
      'm6i.12xlarge': 2.304,
      'm6i.16xlarge': 3.072,
      'm6i.24xlarge': 4.608,
      'm6i.2xlarge': 0.384,
      'm6i.32xlarge': 6.144,
      'm6i.4xlarge': 0.768,
      'm6i.8xlarge': 1.536,
      'm6i.large': 0.096,
      'm6i.metal': 6.144,
      'm6i.xlarge': 0.192,
      'm6id.12xlarge': 2.8476,
      'm6id.16xlarge': 3.7968,
      'm6id.24xlarge': 5.6952,
      'm6id.2xlarge': 0.4746,
      'm6id.32xlarge': 7.5936,
      'm6id.4xlarge': 0.9492,
      'm6id.8xlarge': 1.8984,
      'm6id.large': 0.11865,
      'm6id.metal': 7.5936,
      'm6id.xlarge': 0.2373,
      'm6idn.12xlarge': 3.81888,
      'm6idn.16xlarge': 5.09184,
      'm6idn.24xlarge': 7.63776,
      'm6idn.2xlarge': 0.63648,
      'm6idn.32xlarge': 10.18368,
      'm6idn.4xlarge': 1.27296,
      'm6idn.8xlarge': 2.54592,
      'm6idn.large': 0.15912,
      'm6idn.metal': 10.18368,
      'm6idn.xlarge': 0.31824,
      'm6in.12xlarge': 3.34152,
      'm6in.16xlarge': 4.45536,
      'm6in.24xlarge': 6.68304,
      'm6in.2xlarge': 0.55692,
      'm6in.32xlarge': 8.91072,
      'm6in.4xlarge': 1.11384,
      'm6in.8xlarge': 2.22768,
      'm6in.large': 0.13923,
      'm6in.metal': 8.91072,
      'm6in.xlarge': 0.27846,
      'm7a.12xlarge': 2.78208,
      'm7a.16xlarge': 3.70944,
      'm7a.24xlarge': 5.56416,
      'm7a.2xlarge': 0.46368,
      'm7a.32xlarge': 7.41888,
      'm7a.48xlarge': 11.12832,
      'm7a.4xlarge': 0.92736,
      'm7a.8xlarge': 1.85472,
      'm7a.large': 0.11592,
      'm7a.medium': 0.05796,
      'm7a.metal-48xl': 11.12832,
      'm7a.xlarge': 0.23184,
      'm7g.12xlarge': 1.9584,
      'm7g.16xlarge': 2.6112,
      'm7g.2xlarge': 0.3264,
      'm7g.4xlarge': 0.6528,
      'm7g.8xlarge': 1.3056,
      'm7g.large': 0.0816,
      'm7g.medium': 0.0408,
      'm7g.metal': 2.6112,
      'm7g.xlarge': 0.1632,
      'm7gd.12xlarge': 2.5628,
      'm7gd.16xlarge': 3.4171,
      'm7gd.2xlarge': 0.4271,
      'm7gd.4xlarge': 0.8543,
      'm7gd.8xlarge': 1.7086,
      'm7gd.large': 0.1068,
      'm7gd.medium': 0.0534,
      'm7gd.metal': 3.4171,
      'm7gd.xlarge': 0.2136,
      'm7i-flex.2xlarge': 0.38304,
      'm7i-flex.4xlarge': 0.76608,
      'm7i-flex.8xlarge': 1.53216,
      'm7i-flex.large': 0.09576,
      'm7i-flex.xlarge': 0.19152,
      'm7i.12xlarge': 2.4192,
      'm7i.16xlarge': 3.2256,
      'm7i.24xlarge': 4.8384,
      'm7i.2xlarge': 0.4032,
      'm7i.48xlarge': 9.6768,
      'm7i.4xlarge': 0.8064,
      'm7i.8xlarge': 1.6128,
      'm7i.large': 0.1008,
      'm7i.metal-24xl': 4.8384,
      'm7i.metal-48xl': 9.6768,
      'm7i.xlarge': 0.2016,
      'p2.16xlarge': 14.4,
      'p2.8xlarge': 7.2,
      'p2.xlarge': 0.9,
      'p3.16xlarge': 24.48,
      'p3.2xlarge': 3.06,
      'p3.8xlarge': 12.24,
      'p4d.24xlarge': 32.7726,
      'p5.48xlarge': 98.32,
      'r3.2xlarge': 0.664,
      'r3.4xlarge': 1.328,
      'r3.8xlarge': 2.656,
      'r3.large': 0.166,
      'r3.xlarge': 0.332,
      'r4.16xlarge': 4.256,
      'r4.2xlarge': 0.532,
      'r4.4xlarge': 1.064,
      'r4.8xlarge': 2.128,
      'r4.large': 0.133,
      'r4.xlarge': 0.266,
      'r5.12xlarge': 3.024,
      'r5.16xlarge': 4.032,
      'r5.24xlarge': 6.048,
      'r5.2xlarge': 0.504,
      'r5.4xlarge': 1.008,
      'r5.8xlarge': 2.016,
      'r5.large': 0.126,
      'r5.metal': 6.048,
      'r5.xlarge': 0.252,
      'r5a.12xlarge': 2.712,
      'r5a.16xlarge': 3.616,
      'r5a.24xlarge': 5.424,
      'r5a.2xlarge': 0.452,
      'r5a.4xlarge': 0.904,
      'r5a.8xlarge': 1.808,
      'r5a.large': 0.113,
      'r5a.xlarge': 0.226,
      'r5ad.12xlarge': 3.144,
      'r5ad.16xlarge': 4.192,
      'r5ad.24xlarge': 6.288,
      'r5ad.2xlarge': 0.524,
      'r5ad.4xlarge': 1.048,
      'r5ad.8xlarge': 2.096,
      'r5ad.large': 0.131,
      'r5ad.xlarge': 0.262,
      'r5b.12xlarge': 3.576,
      'r5b.16xlarge': 4.768,
      'r5b.24xlarge': 7.152,
      'r5b.2xlarge': 0.596,
      'r5b.4xlarge': 1.192,
      'r5b.8xlarge': 2.384,
      'r5b.large': 0.149,
      'r5b.metal': 7.152,
      'r5b.xlarge': 0.298,
      'r5d.12xlarge': 3.456,
      'r5d.16xlarge': 4.608,
      'r5d.24xlarge': 6.912,
      'r5d.2xlarge': 0.576,
      'r5d.4xlarge': 1.152,
      'r5d.8xlarge': 2.304,
      'r5d.large': 0.144,
      'r5d.metal': 6.912,
      'r5d.xlarge': 0.288,
      'r5dn.12xlarge': 4.008,
      'r5dn.16xlarge': 5.344,
      'r5dn.24xlarge': 8.016,
      'r5dn.2xlarge': 0.668,
      'r5dn.4xlarge': 1.336,
      'r5dn.8xlarge': 2.672,
      'r5dn.large': 0.167,
      'r5dn.metal': 8.016,
      'r5dn.xlarge': 0.334,
      'r5n.12xlarge': 3.576,
      'r5n.16xlarge': 4.768,
      'r5n.24xlarge': 7.152,
      'r5n.2xlarge': 0.596,
      'r5n.4xlarge': 1.192,
      'r5n.8xlarge': 2.384,
      'r5n.large': 0.149,
      'r5n.metal': 7.152,
      'r5n.xlarge': 0.298,
      'r6a.12xlarge': 2.7216,
      'r6a.16xlarge': 3.6288,
      'r6a.24xlarge': 5.4432,
      'r6a.2xlarge': 0.4536,
      'r6a.32xlarge': 7.2576,
      'r6a.48xlarge': 10.8864,
      'r6a.4xlarge': 0.9072,
      'r6a.8xlarge': 1.8144,
      'r6a.large': 0.1134,
      'r6a.metal': 10.8864,
      'r6a.xlarge': 0.2268,
      'r6g.12xlarge': 2.4192,
      'r6g.16xlarge': 3.2256,
      'r6g.2xlarge': 0.4032,
      'r6g.4xlarge': 0.8064,
      'r6g.8xlarge': 1.6128,
      'r6g.large': 0.1008,
      'r6g.medium': 0.0504,
      'r6g.metal': 3.2256,
      'r6g.xlarge': 0.2016,
      'r6gd.12xlarge': 2.7648,
      'r6gd.16xlarge': 3.6864,
      'r6gd.2xlarge': 0.4608,
      'r6gd.4xlarge': 0.9216,
      'r6gd.8xlarge': 1.8432,
      'r6gd.large': 0.1152,
      'r6gd.medium': 0.0576,
      'r6gd.metal': 3.6864,
      'r6gd.xlarge': 0.2304,
      'r6i.12xlarge': 3.024,
      'r6i.16xlarge': 4.032,
      'r6i.24xlarge': 6.048,
      'r6i.2xlarge': 0.504,
      'r6i.32xlarge': 8.064,
      'r6i.4xlarge': 1.008,
      'r6i.8xlarge': 2.016,
      'r6i.large': 0.126,
      'r6i.metal': 8.064,
      'r6i.xlarge': 0.252,
      'r6id.12xlarge': 3.6288,
      'r6id.16xlarge': 4.8384,
      'r6id.24xlarge': 7.2576,
      'r6id.2xlarge': 0.6048,
      'r6id.32xlarge': 9.6768,
      'r6id.4xlarge': 1.2096,
      'r6id.8xlarge': 2.4192,
      'r6id.large': 0.1512,
      'r6id.metal': 9.6768,
      'r6id.xlarge': 0.3024,
      'r6idn.12xlarge': 4.68936,
      'r6idn.16xlarge': 6.25248,
      'r6idn.24xlarge': 9.37872,
      'r6idn.2xlarge': 0.78156,
      'r6idn.32xlarge': 12.50496,
      'r6idn.4xlarge': 1.56312,
      'r6idn.8xlarge': 3.12624,
      'r6idn.large': 0.19539,
      'r6idn.metal': 12.50496,
      'r6idn.xlarge': 0.39078,
      'r6in.12xlarge': 4.18392,
      'r6in.16xlarge': 5.57856,
      'r6in.24xlarge': 8.36784,
      'r6in.2xlarge': 0.69732,
      'r6in.32xlarge': 11.15712,
      'r6in.4xlarge': 1.39464,
      'r6in.8xlarge': 2.78928,
      'r6in.large': 0.17433,
      'r6in.metal': 11.15712,
      'r6in.xlarge': 0.34866,
      'r7a.12xlarge': 3.6516,
      'r7a.16xlarge': 4.8688,
      'r7a.24xlarge': 7.3032,
      'r7a.2xlarge': 0.6086,
      'r7a.32xlarge': 9.7376,
      'r7a.48xlarge': 14.6064,
      'r7a.4xlarge': 1.2172,
      'r7a.8xlarge': 2.4344,
      'r7a.large': 0.15215,
      'r7a.medium': 0.07608,
      'r7a.metal-48xl': 14.6064,
      'r7a.xlarge': 0.3043,
      'r7g.12xlarge': 2.5704,
      'r7g.16xlarge': 3.4272,
      'r7g.2xlarge': 0.4284,
      'r7g.4xlarge': 0.8568,
      'r7g.8xlarge': 1.7136,
      'r7g.large': 0.1071,
      'r7g.medium': 0.0536,
      'r7g.metal': 3.4272,
      'r7g.xlarge': 0.2142,
      'r7gd.12xlarge': 3.2659,
      'r7gd.16xlarge': 4.3546,
      'r7gd.2xlarge': 0.5443,
      'r7gd.4xlarge': 1.0886,
      'r7gd.8xlarge': 2.1773,
      'r7gd.large': 0.1361,
      'r7gd.medium': 0.068,
      'r7gd.metal': 4.3546,
      'r7gd.xlarge': 0.2722,
      'r7i.12xlarge': 3.1752,
      'r7i.16xlarge': 4.2336,
      'r7i.24xlarge': 6.3504,
      'r7i.2xlarge': 0.5292,
      'r7i.48xlarge': 12.7008,
      'r7i.4xlarge': 1.0584,
      'r7i.8xlarge': 2.1168,
      'r7i.large': 0.1323,
      'r7i.metal-24xl': 6.3504,
      'r7i.metal-48xl': 12.7008,
      'r7i.xlarge': 0.2646,
      'r7iz.12xlarge': 4.464,
      'r7iz.16xlarge': 5.952,
      'r7iz.2xlarge': 0.744,
      'r7iz.32xlarge': 11.904,
      'r7iz.4xlarge': 1.488,
      'r7iz.8xlarge': 2.976,
      'r7iz.large': 0.186,
      'r7iz.metal-16xl': 5.952,
      'r7iz.metal-32xl': 11.904,
      'r7iz.xlarge': 0.372,
      't2.2xlarge': 0.3712,
      't2.large': 0.0928,
      't2.medium': 0.0464,
      't2.micro': 0.0116,
      't2.nano': 0.0058,
      't2.small': 0.023,
      't2.xlarge': 0.1856,
      't3.2xlarge': 0.3328,
      't3.large': 0.0832,
      't3.medium': 0.0416,
      't3.micro': 0.0104,
      't3.nano': 0.0052,
      't3.small': 0.0208,
      't3.xlarge': 0.1664,
      't3a.2xlarge': 0.3008,
      't3a.large': 0.0752,
      't3a.medium': 0.0376,
      't3a.micro': 0.0094,
      't3a.nano': 0.0047,
      't3a.small': 0.0188,
      't3a.xlarge': 0.1504,
      't4g.2xlarge': 0.2688,
      't4g.large': 0.0672,
      't4g.medium': 0.0336,
      't4g.micro': 0.0084,
      't4g.nano': 0.0042,
      't4g.small': 0.0168,
      't4g.xlarge': 0.1344,
      'trn1.2xlarge': 1.34375,
      'trn1.32xlarge': 21.5,
      'trn1n.32xlarge': 24.78,
      'u-12tb1.112xlarge': 109.2,
      'u-3tb1.56xlarge': 27.3,
      'u-6tb1.112xlarge': 54.6,
      'u-6tb1.56xlarge': 46.40391,
      'u-9tb1.112xlarge': 81.9,
      'x1.16xlarge': 6.669,
      'x1.32xlarge': 13.338,
      'x1e.16xlarge': 13.344,
      'x1e.2xlarge': 1.668,
      'x1e.32xlarge': 26.688,
      'x1e.4xlarge': 3.336,
      'x1e.8xlarge': 6.672,
      'x1e.xlarge': 0.834,
      'x2gd.12xlarge': 4.008,
      'x2gd.16xlarge': 5.344,
      'x2gd.2xlarge': 0.668,
      'x2gd.4xlarge': 1.336,
      'x2gd.8xlarge': 2.672,
      'x2gd.large': 0.167,
      'x2gd.medium': 0.0835,
      'x2gd.metal': 5.344,
      'x2gd.xlarge': 0.334,
      'x2idn.16xlarge': 6.669,
      'x2idn.24xlarge': 10.0035,
      'x2idn.32xlarge': 13.338,
      'x2idn.metal': 13.338,
      'x2iedn.16xlarge': 13.338,
      'x2iedn.24xlarge': 20.007,
      'x2iedn.2xlarge': 1.66725,
      'x2iedn.32xlarge': 26.676,
      'x2iedn.4xlarge': 3.3345,
      'x2iedn.8xlarge': 6.669,
      'x2iedn.metal': 26.676,
      'x2iedn.xlarge': 0.83363,
      'z1d.12xlarge': 4.464,
      'z1d.2xlarge': 0.744,
      'z1d.3xlarge': 1.116,
      'z1d.6xlarge': 2.232,
      'z1d.large': 0.186,
      'z1d.metal': 4.464,
      'z1d.xlarge': 0.372,
    },
    'us-west-2': {
      'a1.2xlarge': 0.204,
      'a1.4xlarge': 0.408,
      'a1.large': 0.051,
      'a1.medium': 0.0255,
      'a1.metal': 0.408,
      'a1.xlarge': 0.102,
      'c1.medium': 0.13,
      'c1.xlarge': 0.52,
      'c3.2xlarge': 0.42,
      'c3.4xlarge': 0.84,
      'c3.8xlarge': 1.68,
      'c3.large': 0.105,
      'c3.xlarge': 0.21,
      'c4.2xlarge': 0.398,
      'c4.4xlarge': 0.796,
      'c4.8xlarge': 1.591,
      'c4.large': 0.1,
      'c4.xlarge': 0.199,
      'c5.12xlarge': 2.04,
      'c5.18xlarge': 3.06,
      'c5.24xlarge': 4.08,
      'c5.2xlarge': 0.34,
      'c5.4xlarge': 0.68,
      'c5.9xlarge': 1.53,
      'c5.large': 0.085,
      'c5.metal': 4.08,
      'c5.xlarge': 0.17,
      'c5a.12xlarge': 1.848,
      'c5a.16xlarge': 2.464,
      'c5a.24xlarge': 3.696,
      'c5a.2xlarge': 0.308,
      'c5a.4xlarge': 0.616,
      'c5a.8xlarge': 1.232,
      'c5a.large': 0.077,
      'c5a.xlarge': 0.154,
      'c5ad.12xlarge': 2.064,
      'c5ad.16xlarge': 2.752,
      'c5ad.24xlarge': 4.128,
      'c5ad.2xlarge': 0.344,
      'c5ad.4xlarge': 0.688,
      'c5ad.8xlarge': 1.376,
      'c5ad.large': 0.086,
      'c5ad.xlarge': 0.172,
      'c5d.12xlarge': 2.304,
      'c5d.18xlarge': 3.456,
      'c5d.24xlarge': 4.608,
      'c5d.2xlarge': 0.384,
      'c5d.4xlarge': 0.768,
      'c5d.9xlarge': 1.728,
      'c5d.large': 0.096,
      'c5d.metal': 4.608,
      'c5d.xlarge': 0.192,
      'c5n.18xlarge': 3.888,
      'c5n.2xlarge': 0.432,
      'c5n.4xlarge': 0.864,
      'c5n.9xlarge': 1.944,
      'c5n.large': 0.108,
      'c5n.metal': 3.888,
      'c5n.xlarge': 0.216,
      'c6a.12xlarge': 1.836,
      'c6a.16xlarge': 2.448,
      'c6a.24xlarge': 3.672,
      'c6a.2xlarge': 0.306,
      'c6a.32xlarge': 4.896,
      'c6a.48xlarge': 7.344,
      'c6a.4xlarge': 0.612,
      'c6a.8xlarge': 1.224,
      'c6a.large': 0.0765,
      'c6a.metal': 7.344,
      'c6a.xlarge': 0.153,
      'c6g.12xlarge': 1.632,
      'c6g.16xlarge': 2.176,
      'c6g.2xlarge': 0.272,
      'c6g.4xlarge': 0.544,
      'c6g.8xlarge': 1.088,
      'c6g.large': 0.068,
      'c6g.medium': 0.034,
      'c6g.metal': 2.176,
      'c6g.xlarge': 0.136,
      'c6gd.12xlarge': 1.8432,
      'c6gd.16xlarge': 2.4576,
      'c6gd.2xlarge': 0.3072,
      'c6gd.4xlarge': 0.6144,
      'c6gd.8xlarge': 1.2288,
      'c6gd.large': 0.0768,
      'c6gd.medium': 0.0384,
      'c6gd.metal': 2.4576,
      'c6gd.xlarge': 0.1536,
      'c6gn.12xlarge': 2.0736,
      'c6gn.16xlarge': 2.7648,
      'c6gn.2xlarge': 0.3456,
      'c6gn.4xlarge': 0.6912,
      'c6gn.8xlarge': 1.3824,
      'c6gn.large': 0.0864,
      'c6gn.medium': 0.0432,
      'c6gn.xlarge': 0.1728,
      'c6i.12xlarge': 2.04,
      'c6i.16xlarge': 2.72,
      'c6i.24xlarge': 4.08,
      'c6i.2xlarge': 0.34,
      'c6i.32xlarge': 5.44,
      'c6i.4xlarge': 0.68,
      'c6i.8xlarge': 1.36,
      'c6i.large': 0.085,
      'c6i.metal': 5.44,
      'c6i.xlarge': 0.17,
      'c6id.12xlarge': 2.4192,
      'c6id.16xlarge': 3.2256,
      'c6id.24xlarge': 4.8384,
      'c6id.2xlarge': 0.4032,
      'c6id.32xlarge': 6.4512,
      'c6id.4xlarge': 0.8064,
      'c6id.8xlarge': 1.6128,
      'c6id.large': 0.1008,
      'c6id.metal': 6.4512,
      'c6id.xlarge': 0.2016,
      'c6in.12xlarge': 2.7216,
      'c6in.16xlarge': 3.6288,
      'c6in.24xlarge': 5.4432,
      'c6in.2xlarge': 0.4536,
      'c6in.32xlarge': 7.2576,
      'c6in.4xlarge': 0.9072,
      'c6in.8xlarge': 1.8144,
      'c6in.large': 0.1134,
      'c6in.metal': 7.2576,
      'c6in.xlarge': 0.2268,
      'c7a.12xlarge': 2.46336,
      'c7a.16xlarge': 3.28448,
      'c7a.24xlarge': 4.92672,
      'c7a.2xlarge': 0.41056,
      'c7a.32xlarge': 6.56896,
      'c7a.48xlarge': 9.85344,
      'c7a.4xlarge': 0.82112,
      'c7a.8xlarge': 1.64224,
      'c7a.large': 0.10264,
      'c7a.medium': 0.05132,
      'c7a.metal-48xl': 9.85344,
      'c7a.xlarge': 0.20528,
      'c7g.12xlarge': 1.74,
      'c7g.16xlarge': 2.32,
      'c7g.2xlarge': 0.29,
      'c7g.4xlarge': 0.58,
      'c7g.8xlarge': 1.16,
      'c7g.large': 0.0725,
      'c7g.medium': 0.0363,
      'c7g.metal': 2.32,
      'c7g.xlarge': 0.145,
      'c7gd.12xlarge': 2.1773,
      'c7gd.16xlarge': 2.903,
      'c7gd.2xlarge': 0.3629,
      'c7gd.4xlarge': 0.7258,
      'c7gd.8xlarge': 1.4515,
      'c7gd.large': 0.0907,
      'c7gd.medium': 0.0454,
      'c7gd.metal': 2.903,
      'c7gd.xlarge': 0.1814,
      'c7gn.12xlarge': 2.9952,
      'c7gn.16xlarge': 3.9936,
      'c7gn.2xlarge': 0.4992,
      'c7gn.4xlarge': 0.9984,
      'c7gn.8xlarge': 1.9968,
      'c7gn.large': 0.1248,
      'c7gn.medium': 0.0624,
      'c7gn.metal': 3.9936,
      'c7gn.xlarge': 0.2496,
      'c7i.12xlarge': 2.142,
      'c7i.16xlarge': 2.856,
      'c7i.24xlarge': 4.284,
      'c7i.2xlarge': 0.357,
      'c7i.48xlarge': 8.568,
      'c7i.4xlarge': 0.714,
      'c7i.8xlarge': 1.428,
      'c7i.large': 0.08925,
      'c7i.metal-24xl': 4.284,
      'c7i.metal-48xl': 8.568,
      'c7i.xlarge': 0.1785,
      'cr1.8xlarge': 3.5,
      'd2.2xlarge': 1.38,
      'd2.4xlarge': 2.76,
      'd2.8xlarge': 5.52,
      'd2.xlarge': 0.69,
      'd3.2xlarge': 0.999,
      'd3.4xlarge': 1.998,
      'd3.8xlarge': 3.99552,
      'd3.xlarge': 0.499,
      'd3en.12xlarge': 6.30864,
      'd3en.2xlarge': 1.051,
      'd3en.4xlarge': 2.103,
      'd3en.6xlarge': 3.154,
      'd3en.8xlarge': 4.20576,
      'd3en.xlarge': 0.526,
      'dl1.24xlarge': 13.10904,
      'dl2q.24xlarge': 8.9194,
      'f1.16xlarge': 13.2,
      'f1.2xlarge': 1.65,
      'f1.4xlarge': 3.3,
      'g2.2xlarge': 0.65,
      'g2.8xlarge': 2.6,
      'g3.16xlarge': 4.56,
      'g3.4xlarge': 1.14,
      'g3.8xlarge': 2.28,
      'g3s.xlarge': 0.75,
      'g4ad.16xlarge': 3.468,
      'g4ad.2xlarge': 0.54117,
      'g4ad.4xlarge': 0.867,
      'g4ad.8xlarge': 1.734,
      'g4ad.xlarge': 0.37853,
      'g4dn.12xlarge': 3.912,
      'g4dn.16xlarge': 4.352,
      'g4dn.2xlarge': 0.752,
      'g4dn.4xlarge': 1.204,
      'g4dn.8xlarge': 2.176,
      'g4dn.metal': 7.824,
      'g4dn.xlarge': 0.526,
      'g5.12xlarge': 5.672,
      'g5.16xlarge': 4.096,
      'g5.24xlarge': 8.144,
      'g5.2xlarge': 1.212,
      'g5.48xlarge': 16.288,
      'g5.4xlarge': 1.624,
      'g5.8xlarge': 2.448,
      'g5.xlarge': 1.006,
      'g5g.16xlarge': 2.744,
      'g5g.2xlarge': 0.556,
      'g5g.4xlarge': 0.828,
      'g5g.8xlarge': 1.372,
      'g5g.metal': 2.744,
      'g5g.xlarge': 0.42,
      'g6.12xlarge': 4.6016,
      'g6.16xlarge': 3.3968,
      'g6.24xlarge': 6.6752,
      'g6.2xlarge': 0.9776,
      'g6.48xlarge': 13.3504,
      'g6.4xlarge': 1.3232,
      'g6.8xlarge': 2.0144,
      'g6.xlarge': 0.8048,
      'gr6.4xlarge': 1.5392,
      'gr6.8xlarge': 2.4464,
      'h1.16xlarge': 3.744,
      'h1.2xlarge': 0.468,
      'h1.4xlarge': 0.936,
      'h1.8xlarge': 1.872,
      'i2.2xlarge': 1.705,
      'i2.4xlarge': 3.41,
      'i2.8xlarge': 6.82,
      'i2.xlarge': 0.853,
      'i3.16xlarge': 4.992,
      'i3.2xlarge': 0.624,
      'i3.4xlarge': 1.248,
      'i3.8xlarge': 2.496,
      'i3.large': 0.156,
      'i3.metal': 4.992,
      'i3.xlarge': 0.312,
      'i3en.12xlarge': 5.424,
      'i3en.24xlarge': 10.848,
      'i3en.2xlarge': 0.904,
      'i3en.3xlarge': 1.356,
      'i3en.6xlarge': 2.712,
      'i3en.large': 0.226,
      'i3en.metal': 10.848,
      'i3en.xlarge': 0.452,
      'i4g.16xlarge': 4.94208,
      'i4g.2xlarge': 0.61776,
      'i4g.4xlarge': 1.23552,
      'i4g.8xlarge': 2.47104,
      'i4g.large': 0.15444,
      'i4g.xlarge': 0.30888,
      'i4i.12xlarge': 4.118,
      'i4i.16xlarge': 5.491,
      'i4i.24xlarge': 8.2368,
      'i4i.2xlarge': 0.686,
      'i4i.32xlarge': 10.9824,
      'i4i.4xlarge': 1.373,
      'i4i.8xlarge': 2.746,
      'i4i.large': 0.172,
      'i4i.metal': 10.982,
      'i4i.xlarge': 0.343,
      'im4gn.16xlarge': 5.82067,
      'im4gn.2xlarge': 0.72758,
      'im4gn.4xlarge': 1.45517,
      'im4gn.8xlarge': 2.91034,
      'im4gn.large': 0.1819,
      'im4gn.xlarge': 0.36379,
      'inf1.24xlarge': 4.721,
      'inf1.2xlarge': 0.362,
      'inf1.6xlarge': 1.18,
      'inf1.xlarge': 0.228,
      'inf2.24xlarge': 6.49063,
      'inf2.48xlarge': 12.98127,
      'inf2.8xlarge': 1.96786,
      'inf2.xlarge': 0.7582,
      'is4gen.2xlarge': 1.1526,
      'is4gen.4xlarge': 2.3052,
      'is4gen.8xlarge': 4.6104,
      'is4gen.large': 0.28815,
      'is4gen.medium': 0.14408,
      'is4gen.xlarge': 0.5763,
      'm1.large': 0.175,
      'm1.medium': 0.087,
      'm1.small': 0.044,
      'm1.xlarge': 0.35,
      'm2.2xlarge': 0.49,
      'm2.4xlarge': 0.98,
      'm2.xlarge': 0.245,
      'm3.2xlarge': 0.532,
      'm3.large': 0.133,
      'm3.medium': 0.067,
      'm3.xlarge': 0.266,
      'm4.10xlarge': 2,
      'm4.16xlarge': 3.2,
      'm4.2xlarge': 0.4,
      'm4.4xlarge': 0.8,
      'm4.large': 0.1,
      'm4.xlarge': 0.2,
      'm5.12xlarge': 2.304,
      'm5.16xlarge': 3.072,
      'm5.24xlarge': 4.608,
      'm5.2xlarge': 0.384,
      'm5.4xlarge': 0.768,
      'm5.8xlarge': 1.536,
      'm5.large': 0.096,
      'm5.metal': 4.608,
      'm5.xlarge': 0.192,
      'm5a.12xlarge': 2.064,
      'm5a.16xlarge': 2.752,
      'm5a.24xlarge': 4.128,
      'm5a.2xlarge': 0.344,
      'm5a.4xlarge': 0.688,
      'm5a.8xlarge': 1.376,
      'm5a.large': 0.086,
      'm5a.xlarge': 0.172,
      'm5ad.12xlarge': 2.472,
      'm5ad.16xlarge': 3.296,
      'm5ad.24xlarge': 4.944,
      'm5ad.2xlarge': 0.412,
      'm5ad.4xlarge': 0.824,
      'm5ad.8xlarge': 1.648,
      'm5ad.large': 0.103,
      'm5ad.xlarge': 0.206,
      'm5d.12xlarge': 2.712,
      'm5d.16xlarge': 3.616,
      'm5d.24xlarge': 5.424,
      'm5d.2xlarge': 0.452,
      'm5d.4xlarge': 0.904,
      'm5d.8xlarge': 1.808,
      'm5d.large': 0.113,
      'm5d.metal': 5.424,
      'm5d.xlarge': 0.226,
      'm5dn.12xlarge': 3.264,
      'm5dn.16xlarge': 4.352,
      'm5dn.24xlarge': 6.528,
      'm5dn.2xlarge': 0.544,
      'm5dn.4xlarge': 1.088,
      'm5dn.8xlarge': 2.176,
      'm5dn.large': 0.136,
      'm5dn.metal': 6.528,
      'm5dn.xlarge': 0.272,
      'm5n.12xlarge': 2.856,
      'm5n.16xlarge': 3.808,
      'm5n.24xlarge': 5.712,
      'm5n.2xlarge': 0.476,
      'm5n.4xlarge': 0.952,
      'm5n.8xlarge': 1.904,
      'm5n.large': 0.119,
      'm5n.metal': 5.712,
      'm5n.xlarge': 0.238,
      'm5zn.12xlarge': 3.9641,
      'm5zn.2xlarge': 0.6607,
      'm5zn.3xlarge': 0.991,
      'm5zn.6xlarge': 1.982,
      'm5zn.large': 0.1652,
      'm5zn.metal': 3.9641,
      'm5zn.xlarge': 0.3303,
      'm6a.12xlarge': 2.0736,
      'm6a.16xlarge': 2.7648,
      'm6a.24xlarge': 4.1472,
      'm6a.2xlarge': 0.3456,
      'm6a.32xlarge': 5.5296,
      'm6a.48xlarge': 8.2944,
      'm6a.4xlarge': 0.6912,
      'm6a.8xlarge': 1.3824,
      'm6a.large': 0.0864,
      'm6a.metal': 8.2944,
      'm6a.xlarge': 0.1728,
      'm6g.12xlarge': 1.848,
      'm6g.16xlarge': 2.464,
      'm6g.2xlarge': 0.308,
      'm6g.4xlarge': 0.616,
      'm6g.8xlarge': 1.232,
      'm6g.large': 0.077,
      'm6g.medium': 0.0385,
      'm6g.metal': 2.464,
      'm6g.xlarge': 0.154,
      'm6gd.12xlarge': 2.1696,
      'm6gd.16xlarge': 2.8928,
      'm6gd.2xlarge': 0.3616,
      'm6gd.4xlarge': 0.7232,
      'm6gd.8xlarge': 1.4464,
      'm6gd.large': 0.0904,
      'm6gd.medium': 0.0452,
      'm6gd.metal': 2.8928,
      'm6gd.xlarge': 0.1808,
      'm6i.12xlarge': 2.304,
      'm6i.16xlarge': 3.072,
      'm6i.24xlarge': 4.608,
      'm6i.2xlarge': 0.384,
      'm6i.32xlarge': 6.144,
      'm6i.4xlarge': 0.768,
      'm6i.8xlarge': 1.536,
      'm6i.large': 0.096,
      'm6i.metal': 6.144,
      'm6i.xlarge': 0.192,
      'm6id.12xlarge': 2.8476,
      'm6id.16xlarge': 3.7968,
      'm6id.24xlarge': 5.6952,
      'm6id.2xlarge': 0.4746,
      'm6id.32xlarge': 7.5936,
      'm6id.4xlarge': 0.9492,
      'm6id.8xlarge': 1.8984,
      'm6id.large': 0.11865,
      'm6id.metal': 7.5936,
      'm6id.xlarge': 0.2373,
      'm6idn.12xlarge': 3.81888,
      'm6idn.16xlarge': 5.09184,
      'm6idn.24xlarge': 7.63776,
      'm6idn.2xlarge': 0.63648,
      'm6idn.32xlarge': 10.18368,
      'm6idn.4xlarge': 1.27296,
      'm6idn.8xlarge': 2.54592,
      'm6idn.large': 0.15912,
      'm6idn.metal': 10.18368,
      'm6idn.xlarge': 0.31824,
      'm6in.12xlarge': 3.34152,
      'm6in.16xlarge': 4.45536,
      'm6in.24xlarge': 6.68304,
      'm6in.2xlarge': 0.55692,
      'm6in.32xlarge': 8.91072,
      'm6in.4xlarge': 1.11384,
      'm6in.8xlarge': 2.22768,
      'm6in.large': 0.13923,
      'm6in.metal': 8.91072,
      'm6in.xlarge': 0.27846,
      'm7a.12xlarge': 2.78208,
      'm7a.16xlarge': 3.70944,
      'm7a.24xlarge': 5.56416,
      'm7a.2xlarge': 0.46368,
      'm7a.32xlarge': 7.41888,
      'm7a.48xlarge': 11.12832,
      'm7a.4xlarge': 0.92736,
      'm7a.8xlarge': 1.85472,
      'm7a.large': 0.11592,
      'm7a.medium': 0.05796,
      'm7a.metal-48xl': 11.12832,
      'm7a.xlarge': 0.23184,
      'm7g.12xlarge': 1.9584,
      'm7g.16xlarge': 2.6112,
      'm7g.2xlarge': 0.3264,
      'm7g.4xlarge': 0.6528,
      'm7g.8xlarge': 1.3056,
      'm7g.large': 0.0816,
      'm7g.medium': 0.0408,
      'm7g.metal': 2.6112,
      'm7g.xlarge': 0.1632,
      'm7gd.12xlarge': 2.5628,
      'm7gd.16xlarge': 3.4171,
      'm7gd.2xlarge': 0.4271,
      'm7gd.4xlarge': 0.8543,
      'm7gd.8xlarge': 1.7086,
      'm7gd.large': 0.1068,
      'm7gd.medium': 0.0534,
      'm7gd.metal': 3.4171,
      'm7gd.xlarge': 0.2136,
      'm7i-flex.2xlarge': 0.38304,
      'm7i-flex.4xlarge': 0.76608,
      'm7i-flex.8xlarge': 1.53216,
      'm7i-flex.large': 0.09576,
      'm7i-flex.xlarge': 0.19152,
      'm7i.12xlarge': 2.4192,
      'm7i.16xlarge': 3.2256,
      'm7i.24xlarge': 4.8384,
      'm7i.2xlarge': 0.4032,
      'm7i.48xlarge': 9.6768,
      'm7i.4xlarge': 0.8064,
      'm7i.8xlarge': 1.6128,
      'm7i.large': 0.1008,
      'm7i.metal-24xl': 4.8384,
      'm7i.metal-48xl': 9.6768,
      'm7i.xlarge': 0.2016,
      'p2.16xlarge': 14.4,
      'p2.8xlarge': 7.2,
      'p2.xlarge': 0.9,
      'p3.16xlarge': 24.48,
      'p3.2xlarge': 3.06,
      'p3.8xlarge': 12.24,
      'p3dn.24xlarge': 31.212,
      'p4d.24xlarge': 32.7726,
      'p4de.24xlarge': 40.96575,
      'p5.48xlarge': 98.32,
      'r3.2xlarge': 0.665,
      'r3.4xlarge': 1.33,
      'r3.8xlarge': 2.66,
      'r3.large': 0.166,
      'r3.xlarge': 0.333,
      'r4.16xlarge': 4.256,
      'r4.2xlarge': 0.532,
      'r4.4xlarge': 1.064,
      'r4.8xlarge': 2.128,
      'r4.large': 0.133,
      'r4.xlarge': 0.266,
      'r5.12xlarge': 3.024,
      'r5.16xlarge': 4.032,
      'r5.24xlarge': 6.048,
      'r5.2xlarge': 0.504,
      'r5.4xlarge': 1.008,
      'r5.8xlarge': 2.016,
      'r5.large': 0.126,
      'r5.metal': 6.048,
      'r5.xlarge': 0.252,
      'r5a.12xlarge': 2.712,
      'r5a.16xlarge': 3.616,
      'r5a.24xlarge': 5.424,
      'r5a.2xlarge': 0.452,
      'r5a.4xlarge': 0.904,
      'r5a.8xlarge': 1.808,
      'r5a.large': 0.113,
      'r5a.xlarge': 0.226,
      'r5ad.12xlarge': 3.144,
      'r5ad.16xlarge': 4.192,
      'r5ad.24xlarge': 6.288,
      'r5ad.2xlarge': 0.524,
      'r5ad.4xlarge': 1.048,
      'r5ad.8xlarge': 2.096,
      'r5ad.large': 0.131,
      'r5ad.xlarge': 0.262,
      'r5b.12xlarge': 3.576,
      'r5b.16xlarge': 4.768,
      'r5b.24xlarge': 7.152,
      'r5b.2xlarge': 0.596,
      'r5b.4xlarge': 1.192,
      'r5b.8xlarge': 2.384,
      'r5b.large': 0.149,
      'r5b.metal': 7.152,
      'r5b.xlarge': 0.298,
      'r5d.12xlarge': 3.456,
      'r5d.16xlarge': 4.608,
      'r5d.24xlarge': 6.912,
      'r5d.2xlarge': 0.576,
      'r5d.4xlarge': 1.152,
      'r5d.8xlarge': 2.304,
      'r5d.large': 0.144,
      'r5d.metal': 6.912,
      'r5d.xlarge': 0.288,
      'r5dn.12xlarge': 4.008,
      'r5dn.16xlarge': 5.344,
      'r5dn.24xlarge': 8.016,
      'r5dn.2xlarge': 0.668,
      'r5dn.4xlarge': 1.336,
      'r5dn.8xlarge': 2.672,
      'r5dn.large': 0.167,
      'r5dn.metal': 8.016,
      'r5dn.xlarge': 0.334,
      'r5n.12xlarge': 3.576,
      'r5n.16xlarge': 4.768,
      'r5n.24xlarge': 7.152,
      'r5n.2xlarge': 0.596,
      'r5n.4xlarge': 1.192,
      'r5n.8xlarge': 2.384,
      'r5n.large': 0.149,
      'r5n.metal': 7.152,
      'r5n.xlarge': 0.298,
      'r6a.12xlarge': 2.7216,
      'r6a.16xlarge': 3.6288,
      'r6a.24xlarge': 5.4432,
      'r6a.2xlarge': 0.4536,
      'r6a.32xlarge': 7.2576,
      'r6a.48xlarge': 10.8864,
      'r6a.4xlarge': 0.9072,
      'r6a.8xlarge': 1.8144,
      'r6a.large': 0.1134,
      'r6a.metal': 10.8864,
      'r6a.xlarge': 0.2268,
      'r6g.12xlarge': 2.4192,
      'r6g.16xlarge': 3.2256,
      'r6g.2xlarge': 0.4032,
      'r6g.4xlarge': 0.8064,
      'r6g.8xlarge': 1.6128,
      'r6g.large': 0.1008,
      'r6g.medium': 0.0504,
      'r6g.metal': 3.2256,
      'r6g.xlarge': 0.2016,
      'r6gd.12xlarge': 2.7648,
      'r6gd.16xlarge': 3.6864,
      'r6gd.2xlarge': 0.4608,
      'r6gd.4xlarge': 0.9216,
      'r6gd.8xlarge': 1.8432,
      'r6gd.large': 0.1152,
      'r6gd.medium': 0.0576,
      'r6gd.metal': 3.6864,
      'r6gd.xlarge': 0.2304,
      'r6i.12xlarge': 3.024,
      'r6i.16xlarge': 4.032,
      'r6i.24xlarge': 6.048,
      'r6i.2xlarge': 0.504,
      'r6i.32xlarge': 8.064,
      'r6i.4xlarge': 1.008,
      'r6i.8xlarge': 2.016,
      'r6i.large': 0.126,
      'r6i.metal': 8.064,
      'r6i.xlarge': 0.252,
      'r6id.12xlarge': 3.6288,
      'r6id.16xlarge': 4.8384,
      'r6id.24xlarge': 7.2576,
      'r6id.2xlarge': 0.6048,
      'r6id.32xlarge': 9.6768,
      'r6id.4xlarge': 1.2096,
      'r6id.8xlarge': 2.4192,
      'r6id.large': 0.1512,
      'r6id.metal': 9.6768,
      'r6id.xlarge': 0.3024,
      'r6idn.12xlarge': 4.68936,
      'r6idn.16xlarge': 6.25248,
      'r6idn.24xlarge': 9.37872,
      'r6idn.2xlarge': 0.78156,
      'r6idn.32xlarge': 12.50496,
      'r6idn.4xlarge': 1.56312,
      'r6idn.8xlarge': 3.12624,
      'r6idn.large': 0.19539,
      'r6idn.metal': 12.50496,
      'r6idn.xlarge': 0.39078,
      'r6in.12xlarge': 4.18392,
      'r6in.16xlarge': 5.57856,
      'r6in.24xlarge': 8.36784,
      'r6in.2xlarge': 0.69732,
      'r6in.32xlarge': 11.15712,
      'r6in.4xlarge': 1.39464,
      'r6in.8xlarge': 2.78928,
      'r6in.large': 0.17433,
      'r6in.metal': 11.15712,
      'r6in.xlarge': 0.34866,
      'r7a.12xlarge': 3.6516,
      'r7a.16xlarge': 4.8688,
      'r7a.24xlarge': 7.3032,
      'r7a.2xlarge': 0.6086,
      'r7a.32xlarge': 9.7376,
      'r7a.48xlarge': 14.6064,
      'r7a.4xlarge': 1.2172,
      'r7a.8xlarge': 2.4344,
      'r7a.large': 0.15215,
      'r7a.medium': 0.07608,
      'r7a.metal-48xl': 14.6064,
      'r7a.xlarge': 0.3043,
      'r7g.12xlarge': 2.5704,
      'r7g.16xlarge': 3.4272,
      'r7g.2xlarge': 0.4284,
      'r7g.4xlarge': 0.8568,
      'r7g.8xlarge': 1.7136,
      'r7g.large': 0.1071,
      'r7g.medium': 0.0536,
      'r7g.metal': 3.4272,
      'r7g.xlarge': 0.2142,
      'r7gd.12xlarge': 3.2659,
      'r7gd.16xlarge': 4.3546,
      'r7gd.2xlarge': 0.5443,
      'r7gd.4xlarge': 1.0886,
      'r7gd.8xlarge': 2.1773,
      'r7gd.large': 0.1361,
      'r7gd.medium': 0.068,
      'r7gd.metal': 4.3546,
      'r7gd.xlarge': 0.2722,
      'r7i.12xlarge': 3.1752,
      'r7i.16xlarge': 4.2336,
      'r7i.24xlarge': 6.3504,
      'r7i.2xlarge': 0.5292,
      'r7i.48xlarge': 12.7008,
      'r7i.4xlarge': 1.0584,
      'r7i.8xlarge': 2.1168,
      'r7i.large': 0.1323,
      'r7i.metal-24xl': 6.3504,
      'r7i.metal-48xl': 12.7008,
      'r7i.xlarge': 0.2646,
      'r7iz.12xlarge': 4.464,
      'r7iz.16xlarge': 5.952,
      'r7iz.2xlarge': 0.744,
      'r7iz.32xlarge': 11.904,
      'r7iz.4xlarge': 1.488,
      'r7iz.8xlarge': 2.976,
      'r7iz.large': 0.186,
      'r7iz.metal-16xl': 5.952,
      'r7iz.metal-32xl': 11.904,
      'r7iz.xlarge': 0.372,
      't1.micro': 0.02,
      't2.2xlarge': 0.3712,
      't2.large': 0.0928,
      't2.medium': 0.0464,
      't2.micro': 0.0116,
      't2.nano': 0.0058,
      't2.small': 0.023,
      't2.xlarge': 0.1856,
      't3.2xlarge': 0.3328,
      't3.large': 0.0832,
      't3.medium': 0.0416,
      't3.micro': 0.0104,
      't3.nano': 0.0052,
      't3.small': 0.0208,
      't3.xlarge': 0.1664,
      't3a.2xlarge': 0.3008,
      't3a.large': 0.0752,
      't3a.medium': 0.0376,
      't3a.micro': 0.0094,
      't3a.nano': 0.0047,
      't3a.small': 0.0188,
      't3a.xlarge': 0.1504,
      't4g.2xlarge': 0.2688,
      't4g.large': 0.0672,
      't4g.medium': 0.0336,
      't4g.micro': 0.0084,
      't4g.nano': 0.0042,
      't4g.small': 0.0168,
      't4g.xlarge': 0.1344,
      'trn1.2xlarge': 1.34375,
      'trn1.32xlarge': 21.5,
      'trn1n.32xlarge': 24.78,
      'u-12tb1.112xlarge': 109.2,
      'u-18tb1.112xlarge': 163.8,
      'u-24tb1.112xlarge': 218.4,
      'u-3tb1.56xlarge': 27.3,
      'u-6tb1.112xlarge': 54.6,
      'u-6tb1.56xlarge': 46.40391,
      'u-9tb1.112xlarge': 81.9,
      'vt1.24xlarge': 5.2,
      'vt1.3xlarge': 0.65,
      'vt1.6xlarge': 1.3,
      'x1.16xlarge': 6.669,
      'x1.32xlarge': 13.338,
      'x1e.16xlarge': 13.344,
      'x1e.2xlarge': 1.668,
      'x1e.32xlarge': 26.688,
      'x1e.4xlarge': 3.336,
      'x1e.8xlarge': 6.672,
      'x1e.xlarge': 0.834,
      'x2gd.12xlarge': 4.008,
      'x2gd.16xlarge': 5.344,
      'x2gd.2xlarge': 0.668,
      'x2gd.4xlarge': 1.336,
      'x2gd.8xlarge': 2.672,
      'x2gd.large': 0.167,
      'x2gd.medium': 0.0835,
      'x2gd.metal': 5.344,
      'x2gd.xlarge': 0.334,
      'x2idn.16xlarge': 6.669,
      'x2idn.24xlarge': 10.0035,
      'x2idn.32xlarge': 13.338,
      'x2idn.metal': 13.338,
      'x2iedn.16xlarge': 13.338,
      'x2iedn.24xlarge': 20.007,
      'x2iedn.2xlarge': 1.66725,
      'x2iedn.32xlarge': 26.676,
      'x2iedn.4xlarge': 3.3345,
      'x2iedn.8xlarge': 6.669,
      'x2iedn.metal': 26.676,
      'x2iedn.xlarge': 0.83363,
      'x2iezn.12xlarge': 10.008,
      'x2iezn.2xlarge': 1.668,
      'x2iezn.4xlarge': 3.336,
      'x2iezn.6xlarge': 5.004,
      'x2iezn.8xlarge': 6.672,
      'x2iezn.metal': 10.008,
      'z1d.12xlarge': 4.464,
      'z1d.2xlarge': 0.744,
      'z1d.3xlarge': 1.116,
      'z1d.6xlarge': 2.232,
      'z1d.large': 0.186,
      'z1d.metal': 4.464,
      'z1d.xlarge': 0.372,
    },
  },
};
export default Ec2Prices;
