import { ComputePermissionType } from '../../app/constants';
import Region from '../cortex/Region';

export type AutoConstant =
  | 'COMPUTE_TERMINATE'
  | 'COMPUTE_START'
  | 'COMPUTE_STOP';

export const AUTO_CONSTANTS = Object.freeze({
  TERMINATE: 'COMPUTE_TERMINATE',
  START: 'COMPUTE_START',
  STOP: 'COMPUTE_STOP',
});

export type InstanceStateType =
  | 'PENDING'
  | 'Pending'
  | 'SHUTTING_DOWN'
  | 'Shutting-down'
  | 'ShuttingDown'
  | 'STOPPING'
  | 'Stopping'
  | 'STOPPED'
  | 'Stopped'
  | 'RUNNING'
  | 'Running'
  | 'Starting'
  | 'Restarting'
  | 'INITIALIZING'
  | 'Initializing'
  | 'TERMINATING'
  | 'Terminating'
  | 'TERMINATED'
  | 'Terminated'
  | 'TERMINATED_WITH_ERRORS'
  | 'Terminated-with-errors'
  | 'INSTALLING_APPS'
  | 'Installing-apps'
  | 'InstallingApps'
  | 'Refresh Item For State';

export interface InstanceApplication {
  '@id': number;
  application: {
    appName: string;
    routable?: string;
    url?: string;
  };

  [x: string]: any;
}

export interface InstanceListener {
  active: boolean;
  appLoadBalancerListener: object;
  cname: string;
  computeId: string;
  id: number;
  instanceId: string;
  order: number;
  service: string;
  stackId: string;
}

export type CortexComponent =
  | 'jupyter_hub_lab'
  | 'rstudio'
  | 'jupyter_hub'
  | 'Sagemaker'
  | 'spark'
  | 'miniconda';

export interface Playbook {
  '@id': number;
  playbookName: string;
  routable: boolean;

  [x: string]: any;
}

export interface Volume {
  '@id': number;
  volumeId: string;
  deviceName: string;
  autoDeleteDateTime?: null | string;
  deleted: boolean;
  detached: boolean;
  deleteOnInstanceTermination: boolean;
  instanceId: string;
}

export interface AssociatedS3Data {
  bucketName: string;
  isRo: boolean;
  ro?: boolean;

  [x: string]: any;
}

export interface AssociatedSecrets {
  secretName: string;
  [x: string]: any;
}

export interface ComputeGroup {
  groupName: string;
}

export interface SecurityGroup {
  groupName: string;
  groupId: string;
}

export interface InstanceDetails {
  instanceName: string;
  instanceId: string;
  lmAccount: string;
  keyName: string;
  roleName: string;
  instanceState: InstanceStateType;
  instanceStatus?: string;
  cnameStackId: string;
  cname: string;
  ipAddress: string;
  privateDns: string;
  instanceOwner: string;
  autoTerminateDateTime: string;
  autoStopDateTime: string;
  alarmName: string;
  ec2InstanceType: string;
  appsInstalled: boolean;
  ownerEmail: string;
  productCodes: [];
  blockDeviceMappings: [];
  elasticGpuAssociations: [];
  elasticInferenceAcceleratorAssociations: [];
  networkInterfaces: [];
  securityGroups: SecurityGroup[];
  tags: [];
  licenses: [];
  region: Region;
  active: boolean;
  master: boolean;
  createDate: string;
  externalDns: string;
  volumes: Volume[];
  associatedBuckets?: AssociatedS3Data[];
  associatedSecrets?: AssociatedSecrets[];
  associatedData?: string[];
  applications?: InstanceApplication[];
  playbooks?: Playbook[];
  appStatus?: null | string | boolean;
  adminGroup?: ComputeGroup;
  userGroup?: ComputeGroup;
  userProfile?: string;
  ebsOptimized?: boolean;
  subnetId?: string;
  placement?: {
    availabilityZone: string;
  };
  sshEnabled?: 'ROOT' | 'USER' | 'UNSET';
  localLoading?: boolean | null; //Local State Management
  permission?: 'OWNER' | 'ADMIN' | 'USER';
  listeners?: InstanceListener[];
}

export interface Instance {
  instance: InstanceDetails;
  listeners: InstanceListener[];
  permissions: ComputePermissionType;
}
