import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  GenericAbortSignal,
  Method,
} from 'axios';
import Region from '../models/cortex/Region';
import { stripObjectOfEmpties } from '../utils';

export const cortexRequestSource = 'CORTEX_LAB_UI';
export type CortexAPIVersionType = 'v1' | 'v2';
export type CortexAPIRegionType = Region;
export type CortexAPIAccountType =
  | 'AZR_CORP'
  | 'CIIT_DEV'
  | 'CIIT_NON_PROD'
  | 'CIIT_PROD'
  | 'CORTEX_NON_PROD'
  | 'CORTEX_PROD'
  | 'ETS'
  | 'GRS'
  | 'GRS_DEV'
  | 'GRS_NON_PROD'
  | 'LMI_NON_PROD'
  | 'LMI_PROD'
  | 'SBI_NON_PROD'
  | 'SBI_PROD'
  | 'USCM'
  | 'USCM_DEV'
  | 'USCM_NON_PROD_IM'
  | 'USCM_PROD_BA'
  | 'WEM'
  | 'WEM_DEV'
  | 'WEM_LEGACY'
  | 'WEM_LEGACY_DEV'
  | 'WEM_LEGACY_NON_PROD'
  | 'WEM_NON_PROD';

export interface CortexAxiosApiOptions {
  endpoint: string;
  account?: CortexAPIAccountType;
  region?: CortexAPIRegionType;
  version?: CortexAPIVersionType;
  token?: string | false;
  isCortexApi?: boolean;
}

export interface CortexAxiosApiExchange {
  path: string;
  method?: Method;
  payload?: any;
  query?: any;
  signal?: GenericAbortSignal;
}

class CortexAxiosApiService {
  private readonly service: AxiosInstance;
  private readonly endpoint: string;
  private readonly account?: CortexAPIAccountType;
  private readonly version?: CortexAPIVersionType = 'v2';
  private readonly region?: CortexAPIRegionType;
  private readonly token: string | undefined | false;
  private readonly isCortexApi: boolean;

  constructor(options: CortexAxiosApiOptions) {
    this.service = axios;

    this.endpoint = options.endpoint;
    this.token = options.token;
    this.account = options.account;
    this.version = options.version || 'v2';
    this.region = options.region;
    this.isCortexApi = options.isCortexApi === true;
  }

  _buildUrl(path: string) {
    let result = `${this.endpoint}${path}`;
    if (this.isCortexApi) {
      result = `${this.endpoint}${this.version}/${path}`;
    }
    return result;
  }

  async exchange({
    path,
    method = 'POST',
    payload,
    query,
    signal,
  }: CortexAxiosApiExchange): Promise<AxiosResponse> {
    if (!path.includes('https://')) {
      path = this._buildUrl(path);
    }

    const headers = {} as any;

    if (this.token) {
      headers['Authorization'] = this.token;
    }

    if (this.isCortexApi) {
      headers['cortex-lm-account'] = this.account;
      headers['cortex-lm-region'] = this.region;
      headers['cortex-request-source'] = cortexRequestSource;
    }

    const config = {
      method,
      url: path,
      responseType: 'json',
      headers,
      withCredentials: true,
    } as AxiosRequestConfig;

    if (signal) {
      config.signal = signal;
    }

    if (
      payload &&
      (method === 'POST' || method === 'PUT' || method === 'DELETE')
    ) {
      config.data = payload;
    } else if ((payload && method === 'GET') || method === 'DELETE') {
      config.params = payload;
    }

    if (query) {
      config.params = { ...config.params, ...query };
    }

    return this.service.request(config);
  }
}

axios.interceptors.request.use(
  (config) => {
    if (config.data) {
      const data = config.data;
      stripObjectOfEmpties(data);
    }
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default CortexAxiosApiService;
