/* AWS region constants */
import Region from '../models/cortex/Region';
import CortexAxiosApiService, {
  CortexAPIAccountType,
  CortexAPIVersionType,
} from '../services/CortexAxiosApiService';

export const US_EAST_1 = 'US_EAST_1' as Region;
export const US_EAST_2 = 'US_EAST_2' as Region;
export const US_WEST_2 = 'US_WEST_2' as Region;
export const EU_WEST_1 = 'EU_WEST_1' as Region;
export const AP_SOUTHEAST_1 = 'AP_SOUTHEAST_1' as Region;
export const AP_SOUTH_1 = 'AP_SOUTH_1' as Region;

export const CORTEX_URL = process.env.REACT_APP_CORTEX_URL || '';
export const CORTEX_LAB_URL = process.env.REACT_APP_CORTEX_LAB_URL || '';
export const DEFAULT_ERROR_MSG =
  'There was an issue with your request, contact support.';

export type ComputePermissionType = 'OWNER' | 'ADMIN' | 'USER';

interface IAwsRegion {
  key: string;
  name: string;
}

export const AWS_REGIONS: IAwsRegion[] = [
  { key: US_EAST_1, name: 'us-east-1' },
  { key: US_EAST_2, name: 'us-east-2' },
  { key: US_WEST_2, name: 'us-west-2' },
  { key: EU_WEST_1, name: 'eu-west-1' },
  { key: AP_SOUTHEAST_1, name: 'ap-southeast-1' },
  { key: AP_SOUTH_1, name: 'ap-south-1' },
];

export const apiInit = (token: string, endpoint: string) => {
  return new CortexAxiosApiService({
    endpoint,
    isCortexApi: false,
    token: `Bearer ${token}`,
  });
};

export const cortexApiInit = (
  token: string,
  account: CortexAPIAccountType,
  region: Region,
  version: CortexAPIVersionType = 'v2'
) => {
  return new CortexAxiosApiService({
    version,
    endpoint: `${CORTEX_URL}rest/`,
    isCortexApi: true,
    token: `Bearer ${token}`,
    account,
    region,
  });
};

export const labApiInit = (token: string) => {
  return new CortexAxiosApiService({
    endpoint: CORTEX_LAB_URL,
    isCortexApi: false,
    token: `Bearer ${token}`,
  });
};

export const publicApiInit = (endpoint: string) => {
  return new CortexAxiosApiService({
    endpoint,
    isCortexApi: false,
  });
};

/* Default Instance Size */
export const DEFAULT_EC2_INSTANCE = 'c6a.xlarge';

export const DEFAULT_EMR_INSTANCE = 'c5a.xlarge';

export const DEFAULT_DSVM_INSTANCE = 'STANDARD_D8_V3';

export const DEFAULT_REDSHIFT_INSTANCE = 'dc2.large';

export const BASIC_MAX_EMR_NODES_COUNT = 3;
export const MAX_EMR_NODES_COUNT = 50;

/* Storage Types */
export interface StorageType {
  key: string;
  type: string;
  description: string;
  verbiage: string;
  useCases: string[];
}

export const GP2 = {
  key: 'Gp2',
  type: 'ssd',
  description: 'GP2 - General Purpose SSD',
  verbiage: 'Provides a balance of price and performance.',
  useCases: [
    'Low-latency interactive apps',
    'Development and test environments',
  ],
};
export const GP3 = {
  key: 'Gp3',
  type: 'ssd',
  description: 'GP3 - General Purpose SSD',
  verbiage:
    'Lowest cost SSD volume that balances price performance for a wide variety of transactional workloads. We recommend these volumes for most workloads.',
  useCases: [
    'Low-latency interactive apps',
    'Development and test environments',
  ],
};
export const IO1 = {
  key: 'Io1',
  type: 'ssd',
  description: 'IO1 - Provisioned IOPS SSD',
  verbiage:
    'Provisioned IOPS SSD (io1) volumes are designed to meet the needs of I/O-intensive workloads, particularly database workloads, that are sensitive to storage performance and consistency. ',
  useCases: ['I/O intensive workloads', 'Transactional workloads'],
};

export const ST1 = {
  key: 'St1',
  type: 'hdd',
  description: 'ST1 - Throughput Optimized HDD',
  verbiage:
    'Throughput Optimized HDD (st1) volumes provide low-cost magnetic storage that defines performance in terms of throughput rather than IOPS.',
  useCases: [
    'Large, sequential workloads.',
    'EMR, ETL, data warehouses, log processing.',
  ],
};

export const SC1 = {
  key: 'Sc1',
  type: 'hdd',
  description: 'SC1 - Cold HDD',
  verbiage:
    'Cold HDD (sc1) volumes provide low-cost magnetic storage that defines performance in terms of throughput rather than IOPS.',
  useCases: [
    'Large, sequential cold-data workloads.',
    'Infrequent access to data.',
    'Cost saving, inexpensive block storage.',
  ],
};

export const STORAGE_TYPES: StorageType[] = [GP3, GP2, IO1, ST1, SC1];

export type BillingType = 'ec2' | 'emr' | 'redshift' | 'dsvm' | 'ebs';

export type COMPUTE_TYPE =
  | 'EC2'
  | 'EMR'
  | 'EMR_SERVERLESS'
  | 'EMR_SERVERLESS_JOB'
  | 'REDSHIFT'
  | 'REDSHIFT_SNAPSHOT'
  | 'DSVM'
  | 'ELASTIC_SEARCH'
  | 'K8_INSTALLATION'
  | 'K8_PROFILE';
