import { RadioClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { merge } from 'lodash';
import MuiRadioOverride from '../componentBase/MuiRadioOverride';
import { snow0 } from './constants';

const DarkRadio: Partial<OverridesStyleRules<keyof RadioClasses>> | undefined =
  {
    root: {
      '&.Mui-checked': {
        color: snow0,
      },
    },
  };

export default merge(DarkRadio, MuiRadioOverride);
