import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { getLabAuthToken } from '../routes/auth/authSlice';
import { CORTEX_URL } from '../app/constants';
import {
  AwsConfig,
  getAccountState,
} from '../routes/member/account/slices/cortexAccountSelectorSlice';
import { Version } from 'streamsaver';
import { cortexRequestSource } from './CortexAxiosApiService';

interface TelemetryAPI {
  service: string;
  resourceId: string;
  token: string;
  namespace?: string;
}

export const cortexAwsApi = createApi({
  reducerPath: 'cortexApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CORTEX_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getLabAuthToken(getState() as RootState);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      const awsConfig: AwsConfig = getAccountState(getState() as RootState);
      if (awsConfig) {
        if (!headers.get('cortex-lm-account')) {
          headers.set('cortex-lm-account', awsConfig.account);
        }
        if (!headers.get('cortex-lm-region')) {
          headers.set('cortex-lm-region', awsConfig.region);
        }
      }
      headers.set('cortex-request-source', cortexRequestSource);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHealth: builder.query<string, void>({
      query: () => 'rest/v1/health',
    }),
    getVersion: builder.query<Version, void>({
      query: () => 'rest/v1/version',
    }),
    getTelemetry: builder.mutation<String, TelemetryAPI>({
      query: ({ service, resourceId, token, namespace }) => {
        if (namespace) {
          return {
            url: `rest/v2/telemetry/${service}/${namespace}/${resourceId}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        }
        return {
          url: `rest/v2/telemetry/${service}/${resourceId}`,
          headers: { Authorization: `Bearer ${token}` },
        };
      },
    }),
  }),
});

// DON'T ADD ENDPOINTS DIRECTLY, RATHER, EXTEND
// THIS API TO LEVERAGE CODE SPLITTING - https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const { useGetVersionQuery, useGetTelemetryMutation } = cortexAwsApi;
