import { snow0, nord2 } from './constants';

const DarkDataGrid = {
  root: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: nord2,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon':
      {
        opacity: 1,
      },
    '&.MuiDataGrid-sortIcon': {
      opacity: 1,
      color: snow0,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator:last-child':
      {
        display: 'none !important',
      },
  },
};

export default DarkDataGrid;
