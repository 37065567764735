import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../../app/constants';
import { RootState } from '../../../../../../../app/store';
import {
  CortexResizeInstanceResponse,
  InstanceResponse,
} from '../../slices/ec2DashboardSlice';
import { InstanceDetails } from '../../../../../../../models/compute/Ec2';

interface Ec2DescribeInstanceSliceState {
  getInstance: {
    loading: boolean;
    error: any;
    result: null | InstanceResponse | InstanceDetails;
  };
  putResizeInstance: {
    loading: boolean;
    error: any;
    result: null | CortexResizeInstanceResponse;
  };
}

export const initialState: Ec2DescribeInstanceSliceState = {
  getInstance: {
    loading: false,
    error: null,
    result: null,
  },
  putResizeInstance: {
    loading: false,
    error: null,
    result: null,
  },
};

interface GetInstance {
  instanceId: string;
  instanceType?: string;
}

export const getInstanceThunk = createAsyncThunk(
  'ec2Describe/GetInstanceThunk',
  async ({ instanceId }: GetInstance, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/ec2',
        method: 'GET',
        payload: {},
        query: {
          instanceId,
          additionalDetails: true,
        },
      });
      return {
        ...response.data?.instance,
        permission: response.data?.ec2Permission,
        listeners: response.data?.listeners,
      };
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const putResizeInstanceThunk = createAsyncThunk(
  'ec2DescribeSlice/PutResizeInstanceThunk',
  async ({ instanceId, instanceType }: GetInstance, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/ec2/resizeInstanceType',
        method: 'PUT',
        payload: {},
        query: { instanceId, instanceType },
      });
      const data = await response?.data;

      return {
        ...data,
      };
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const ec2DescribeSlice = createSlice({
  name: 'ec2Describe',
  initialState,
  reducers: {
    clearResizeInstance(state) {
      state.putResizeInstance = initialState.putResizeInstance;
    },
    updateEc2State(state, { payload }) {
      state.getInstance.result = {
        ...state.getInstance.result,
        instanceState: payload.instanceState,
      } as any;
    },
    updateEc2(state, { payload }) {
      state.getInstance.result = {
        ...state.getInstance.result,
        ...payload,
      } as any;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstanceThunk.pending, (state) => {
      state.getInstance.loading = true;
      state.getInstance.error = null;
    });
    builder.addCase(getInstanceThunk.fulfilled, (state, { payload }) => {
      state.getInstance.loading = false;
      state.getInstance.error = null;
      state.getInstance.result = payload;
    });
    builder.addCase(getInstanceThunk.rejected, (state, { payload }) => {
      state.getInstance.loading = false;
      state.getInstance.error = payload;
    });
    builder.addCase(putResizeInstanceThunk.pending, (state) => {
      state.putResizeInstance.loading = true;
      state.putResizeInstance.error = null;
    });
    builder.addCase(putResizeInstanceThunk.fulfilled, (state, { payload }) => {
      state.putResizeInstance.loading = false;
      state.putResizeInstance.error = null;
      state.putResizeInstance.result = payload;
    });
    builder.addCase(putResizeInstanceThunk.rejected, (state, { payload }) => {
      state.putResizeInstance.loading = false;
      state.putResizeInstance.error = payload;
    });
  },
});
export const { clearResizeInstance, updateEc2State, updateEc2 } =
  ec2DescribeSlice.actions;

export const getInstanceState = (state: RootState) => {
  return state?.ec2Describe?.getInstance || initialState.getInstance;
};

export const getPutResizeInstanceState = (state: RootState) => {
  return (
    state?.ec2Describe?.putResizeInstance || initialState.putResizeInstance
  );
};

export default ec2DescribeSlice.reducer as Reducer<typeof initialState>;
