import { RadioClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import tokens from '../liberty-ui-tokens/json/tokens.json';
const typography = tokens.props.typography;

const MuiRadioOverride:
  | Partial<OverridesStyleRules<keyof RadioClasses>>
  | undefined = {
  root: {
    '&.Mui-checked + span': {
      fontWeight: typography.body.default.weight.bold,
    },
  },
};

export default MuiRadioOverride;
