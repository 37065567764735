import React, { useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import { CortexFab } from '../../../components/system/CortexFab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CortexIconButton } from '../../../components/system/CortexIconButton';
import useWindowSize from '../../../hooks/UseWindowSize';
import { useUser } from '../../../hooks/useUser';

const POWER_BI_REPORT = process.env.REACT_APP_COST_REPORT_LINK;
const isCortexUser = process.env.REACT_APP_LDL_USER === 'false';
export const handlePowerBIExpenseLink = (username: string, email: string) => {
  return encodeURI(
    `${POWER_BI_REPORT}&filter=AWS~2FOwner_x0020_Tag in ('${username}', '${email}')`
  );
};

interface SettingsCostProps {
  userId?: string;
  email?: string;
}

export const SettingsCost = (props: SettingsCostProps) => {
  const [width] = useWindowSize();
  const [powerBi, setPowerBI] = React.useState('');
  const { userId, email } = props;
  const user = useUser();
  const isLMUser =
    isCortexUser || user?.email?.toLowerCase().includes('libertymutual.com');

  useEffect(() => {
    if (user && !powerBi) {
      setPowerBI(
        handlePowerBIExpenseLink(
          userId ? userId : user.id,
          email ? email : user.email
        )
      );
    }
  }, [email, powerBi, user, userId]);

  return (
    <>
      {POWER_BI_REPORT && isLMUser && (
        <Box sx={{ p: 1 }}>
          {width > 500 && (
            <CortexFab variant="extended" onClick={() => window.open(powerBi)}>
              <AttachMoneyIcon />
              My Cost
            </CortexFab>
          )}
          {width < 501 && (
            <Tooltip title={'My Cost Report'}>
              <CortexIconButton
                onClick={() => window.open(powerBi)}
                sx={{ ml: 2 }}
              >
                <AttachMoneyIcon
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                />
              </CortexIconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
