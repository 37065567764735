import React from 'react';
import { Button, Grid, Link, List, Typography, useTheme } from '@mui/material';
import useWindowSize from '../hooks/UseWindowSize';
import { CortexListItemButton } from './system/CortexList';

const AZURE_ENABLED = process.env.REACT_APP_AZURE_ENABLED === 'true';

interface MobileMegaMenuItemsProps {
  handleLink: any;
}

export const MobileMegaMenuItems = (props: MobileMegaMenuItemsProps) => {
  const { handleLink } = props;
  const [width] = useWindowSize();
  const theme = useTheme();
  return (
    <>
      {width <= 600 && (
        <Grid item mb={3}>
          <Button
            component={Link}
            variant={'text'}
            onClick={() => handleLink('/me')}
          >
            <Typography
              variant={'h4'}
              sx={{ margin: theme.spacing(2, 0, 0, 1) }}
            >
              Account
            </Typography>
          </Button>
        </Grid>
      )}
      {width < 760 && (
        <Grid item mb={3}>
          <Typography variant={'h4'} sx={{ margin: theme.spacing(2, 0, 0, 2) }}>
            Compute
          </Typography>
          <List>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/ec2')}
            >
              EC2
            </CortexListItemButton>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/emr')}
            >
              EMR
            </CortexListItemButton>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/redshift')}
            >
              Redshift
            </CortexListItemButton>
            {AZURE_ENABLED && (
              <CortexListItemButton
                component={Link}
                onClick={() => handleLink('/azure/dsvm')}
              >
                Azure DSVM
              </CortexListItemButton>
            )}
          </List>
        </Grid>
      )}
      {width < 896 && (
        <Grid item mb={3}>
          <Typography variant={'h4'} sx={{ margin: theme.spacing(2, 0, 0, 2) }}>
            Cloud Apps
          </Typography>
          <List>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/profiles')}
            >
              Profiles
            </CortexListItemButton>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/notebooks')}
            >
              Notebooks
            </CortexListItemButton>
            <CortexListItemButton
              component={Link}
              onClick={() => handleLink('/aws/dashboards')}
            >
              Dashboards
            </CortexListItemButton>
            {AZURE_ENABLED && (
              <CortexListItemButton
                component={Link}
                onClick={() => handleLink('/azure/libertygpt')}
              >
                LibertyGPT
              </CortexListItemButton>
            )}
          </List>
        </Grid>
      )}
    </>
  );
};
