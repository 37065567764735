import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { RootState } from '../../../../../../app/store';
import {
  ClusterStatusType,
  RedshiftDetails,
} from '../../../../../../models/compute/Redshift';
import {
  updateAwsRedshiftState,
  updateRedshiftStatus,
} from '../details/slices/redshiftDescribeSlice';

export type RedshiftAction = 'TERMINATE' | 'PAUSE' | 'RESUME' | null;

interface RedshiftStateChangeError {
  clusterId: string;
  action: RedshiftAction;
}

export interface RedshiftDashboardSliceState {
  getRedshift: {
    loading: boolean;
    error: any;
    result: null | RedshiftDetails[];
  };
  redshiftChangeStateError?: RedshiftStateChangeError[];
  redshiftChangingState: {
    loading: boolean;
    error: any;
  };
}

export const initialState: RedshiftDashboardSliceState = {
  getRedshift: {
    loading: false,
    error: null,
    result: null,
  },
  redshiftChangeStateError: [],
  redshiftChangingState: {
    loading: false,
    error: null,
  },
};

interface RedshiftThunk {
  clusterId: string;

  snapshotDescription?: string;
  snapshotRetentionPeriod?: number;
  saveSnapshot?: boolean;
  cb?: any;
  action?: 'pause' | 'resume';
}

export const terminateRedshiftThunk = createAsyncThunk(
  'redshiftDashboard/TerminateRedshiftThunk',
  async (
    {
      cb,
      clusterId,
      snapshotRetentionPeriod,
      snapshotDescription,
      saveSnapshot,
    }: RedshiftThunk,
    thunkAPI
  ) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/redshift`;
      const token = state.authentication.auth?.token || '';

      const payload = {};
      const query = {
        clusterId,
        snapshotDescription,
        snapshotRetentionPeriod,
        saveSnapshot: Boolean(saveSnapshot),
      };
      const response = await cortexApiInit(token, account, region).exchange({
        path: `${path}`,
        method: 'DELETE',
        payload,
        query,
      });

      thunkAPI.dispatch(updateRedshiftStatus({ status: 'deleting' }));
      thunkAPI.dispatch(
        updateAwsRedshiftState({
          clusterAvailabilityStatus: 'Modifying',
          clusterStatus: 'deleting',
        })
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const stopStartRedshiftThunk = createAsyncThunk(
  'redshiftDashboard/StopStartRedshiftThunk',
  async ({ cb, clusterId, action }: RedshiftThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/redshift/state/${action}`;
      const token = state.authentication.auth?.token || '';

      const payload = {};
      const query = {
        clusterId,
      };
      const response = await cortexApiInit(token, account, region).exchange({
        path: `${path}`,
        method: 'POST',
        payload,
        query,
      });

      thunkAPI.dispatch(updateRedshiftStatus({ status: 'pausing' }));
      thunkAPI.dispatch(
        updateAwsRedshiftState({
          clusterAvailabilityStatus: 'Modifying',
          clusterStatus: 'pausing',
        })
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const reloadRedshiftStateThunk = createAsyncThunk(
  'redshiftDashboard/ReloadRedshiftStateThunk',
  async ({ clusterId }: RedshiftThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/redshift/describe`,
        method: 'GET',
        payload: {},
        query: {
          clusterId,
        },
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getRedshiftsThunk = createAsyncThunk(
  'redshiftDashboard/GetRedshiftInstancesThunk',
  // eslint-disable-next-line no-empty-pattern
  async (thunkArgs: {}, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();

      thunkAPI.dispatch(clearChangingState());

      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/redshift/list',
        method: 'GET',
        payload: {},
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

const handleChangeStateOnItem = (
  instance: RedshiftDetails,
  metaArgs: any,
  loading: boolean,
  updateVmState?: ClusterStatusType
) => {
  if (instance.clusterId === metaArgs.clusterId) {
    instance.localLoading = loading;
    if (updateVmState) {
      instance.status = updateVmState;
    }
  }
  return instance;
};

const handleChangeStateOnListItem = (
  result: RedshiftDetails[],
  metaArgs: any,
  loading: boolean,
  updateVmState?: ClusterStatusType
) => {
  result.forEach((instance: RedshiftDetails) => {
    handleChangeStateOnItem(instance, metaArgs, loading, updateVmState);
  });
};

const handleReloadStateOnListItem = (
  result: RedshiftDetails[],
  metaArgs: any,
  loading: boolean,
  details?: RedshiftDetails
) => {
  result.forEach((redshift: RedshiftDetails) => {
    if (redshift.clusterId === metaArgs.clusterId) {
      redshift.localLoading = loading;
      if (details) {
        redshift.status = details.status || 'available';
      }
    }
    return redshift;
  });
};

export const redshiftDashboardSlice = createSlice({
  name: 'redshiftDashboard',
  initialState,
  reducers: {
    clearRedshift(state) {
      state.getRedshift = initialState.getRedshift;
      state.redshiftChangingState = initialState.redshiftChangingState;
      state.redshiftChangeStateError = initialState.redshiftChangeStateError;
    },
    clearChangingState(state) {
      state.redshiftChangingState = initialState.redshiftChangingState;
      state.redshiftChangeStateError = initialState.redshiftChangeStateError;
    },
  },
  extraReducers: (builder) => {
    //List Cluster
    builder.addCase(getRedshiftsThunk.pending, (state) => {
      state.getRedshift.loading = true;
      state.getRedshift.error = null;
    });
    builder.addCase(getRedshiftsThunk.fulfilled, (state, { payload }: any) => {
      state.getRedshift.loading = false;
      state.getRedshift.error = null;
      state.getRedshift.result = payload;
    });
    builder.addCase(getRedshiftsThunk.rejected, (state, { payload }) => {
      state.getRedshift.loading = false;
      state.getRedshift.error = payload;
    });

    //Refresh Clusters
    builder.addCase(reloadRedshiftStateThunk.pending, (state, action) => {
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = action.meta.arg;
      handleReloadStateOnListItem(result, metaArgs, true);
      state.getRedshift.result = result;
    });
    builder.addCase(
      reloadRedshiftStateThunk.fulfilled,
      (state, { payload, meta }: any) => {
        const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false, payload);
        state.getRedshift.result = result;
      }
    );
    builder.addCase(reloadRedshiftStateThunk.rejected, (state, { meta }) => {
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = meta.arg;
      handleReloadStateOnListItem(result, metaArgs, false);
      state.getRedshift.result = result;
    });

    //Delete
    builder.addCase(terminateRedshiftThunk.pending, (state, action) => {
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = action.meta.arg;
      state.redshiftChangingState.loading = true;
      state.redshiftChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true);
      state.getRedshift.result = result;
    });
    builder.addCase(terminateRedshiftThunk.fulfilled, (state, action) => {
      state.redshiftChangingState.loading = false;
      state.redshiftChangingState.error = false;

      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }

      handleChangeStateOnListItem(result, metaArgs, false, 'deleting');
      state.getRedshift.result = result;
    });
    builder.addCase(terminateRedshiftThunk.rejected, (state, action) => {
      state.redshiftChangingState.loading = false;
      state.redshiftChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const clusterId = metaArgs.clusterId;

      const set =
        state?.redshiftChangeStateError?.filter(
          (x) => x.clusterId === clusterId
        ) || [];
      set.push({
        clusterId: clusterId,
        action: 'TERMINATE',
      });

      state.redshiftChangeStateError = set;
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      handleChangeStateOnListItem(result, metaArgs, false);
    });

    //Change State
    builder.addCase(stopStartRedshiftThunk.pending, (state, action) => {
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = action.meta.arg;
      state.redshiftChangingState.loading = true;
      state.redshiftChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true);
      state.getRedshift.result = result;
    });
    builder.addCase(stopStartRedshiftThunk.fulfilled, (state, { meta }) => {
      state.redshiftChangingState.loading = false;
      state.redshiftChangingState.error = false;

      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      const metaArgs: any = meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }

      handleChangeStateOnListItem(result, metaArgs, false, 'pausing');
      state.getRedshift.result = result;
    });
    builder.addCase(stopStartRedshiftThunk.rejected, (state, action) => {
      state.redshiftChangingState.loading = false;
      state.redshiftChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const clusterId = metaArgs.clusterId;

      const set =
        state?.redshiftChangeStateError?.filter(
          (x) => x.clusterId === clusterId
        ) || [];
      set.push({
        clusterId: clusterId,
        action: metaArgs.action === 'pause' ? 'PAUSE' : 'RESUME',
      });

      state.redshiftChangeStateError = set;
      const result = (state?.getRedshift?.result || []) as RedshiftDetails[];
      handleChangeStateOnListItem(result, metaArgs, false);
    });
  },
});

export const { clearRedshift, clearChangingState } =
  redshiftDashboardSlice.actions;

export const getChangeRedshiftState = (state: RootState) => {
  return (
    state?.redshiftDashboard?.redshiftChangingState ||
    initialState.redshiftChangingState
  );
};
export const getRedshiftState = (state: RootState) => {
  return state?.redshiftDashboard?.getRedshift || initialState.getRedshift;
};

export default redshiftDashboardSlice.reducer as Reducer<typeof initialState>;
