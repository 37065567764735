import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  ComputePermissionType,
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { RootState } from '../../../../../../app/store';
import {
  ClusterDetails,
  ClusterStateType,
} from '../../../../../../models/compute/Emr';
import { InstanceApplication } from '../../../../../../models/compute/Ec2';
import { handleComputeStateExchange } from '../../utils';

export interface ClusterResponse {
  cluster: ClusterDetails;
  permission: ComputePermissionType;
}

export interface ClusterStatus {
  clusterId: string;
  state: ClusterStateType;
  applicationsReady: boolean;
}

interface EMRStateChangeError {
  instanceId: string;
}

export interface EmrDashboardSliceState {
  getClusters: {
    loading: boolean;
    error: any;
    result: null | ClusterResponse[] | ClusterDetails[];
  };
  emrChangeStateError?: EMRStateChangeError[];
  emrChangingState: {
    loading: boolean;
    error: any;
  };
  getClusterStatusUpdates: null | ClusterStatus[];
}

export const initialState: EmrDashboardSliceState = {
  getClusters: {
    loading: false,
    error: null,
    result: null,
  },
  emrChangeStateError: [],
  emrChangingState: {
    loading: false,
    error: null,
  },
  getClusterStatusUpdates: [],
};

interface GetClusters {
  accessible?: boolean;
  terminated?: boolean;
}

export const mapApplications = (cluster: ClusterDetails) => {
  let applications: InstanceApplication[] = [];
  if (cluster.applications && cluster.applications.length > 0) {
    applications = cluster.applications.map(
      (app, index) =>
        ({
          '@id': index,
          application: {
            appName: app,
          },
        } as InstanceApplication)
    );
  }
  return applications;
};

export const terminateEMRThunk = createAsyncThunk(
  'emrDashboard/ChangeComputeState',
  async ({ cb, computeId }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/emr`;

      const response = await handleComputeStateExchange(
        computeId,
        'EMR',
        state.authentication.auth?.token || '',
        account,
        region,
        true,
        path,
        'TERMINATING'
      );
      const result: any = {
        data: response.data,
        computeId,
      };

      return result;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const reloadEMRStateThunk = createAsyncThunk(
  'emrDashboard/ReloadEMRStateThunk',
  async ({ computeId }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/emr',
        method: 'GET',
        payload: {},
        query: {
          clusterId: computeId,
          additionalDetails: true,
        },
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getClustersThunk = createAsyncThunk(
  'emrDashboard/GetClustersThunk',
  async ({ accessible = false }: GetClusters, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/emr/list' + (accessible ? '/accessibleClusters' : ''),
        method: 'GET',
        payload: {
          listActiveOnly: accessible ? undefined : true,
        },
      });

      const data = await response.data;
      let result;

      if (accessible) {
        result =
          data?.map((emr: ClusterResponse) => {
            const permission = emr.permission;
            return {
              ...emr.cluster,
              applications: mapApplications(emr.cluster),
              permission,
            };
          }) || [];
      } else {
        result =
          data?.map((emr: ClusterDetails) => {
            const permission = 'OWNER';
            return {
              ...emr,
              applications: mapApplications(emr),
              permission,
            };
          }) || [];
      }

      // Update statuses for listed clusters
      result = result.map((cluster: any) => {
        const updatedStatus = getClusterStatusUpdatesById(
          state,
          cluster.clusterId
        );
        return {
          ...cluster,
          clusterState: updatedStatus?.state || cluster.clusterState,
          applicationsReady: updatedStatus?.applicationsReady || false,
        };
      });

      return thunkAPI.fulfillWithValue(result as any);
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

const handleChangeStateOnListItem = (
  result: ClusterDetails[],
  metaArgs: any,
  loading: boolean,
  updateState: boolean
) => {
  result.forEach((instance: ClusterDetails) => {
    if (instance.clusterId === metaArgs.computeId) {
      instance.localLoading = loading;
      if (updateState) {
        instance.clusterState = 'TERMINATING';
        if (instance?.cluster?.status) {
          instance.cluster.status.state = 'TERMINATING';
        }
      }
    }
    return instance;
  });
};

const handleReloadStateOnListItem = (
  result: ClusterDetails[],
  metaArgs: any,
  loading: boolean,
  details?: ClusterDetails
) => {
  result.forEach((cluster: ClusterDetails) => {
    if (cluster.clusterId === metaArgs.computeId) {
      cluster.localLoading = loading;
      if (details) {
        cluster.cluster = details.cluster;
        cluster.clusterState = details.clusterState;
        cluster.zuulUrl = details.zuulUrl;
        cluster.cname = details.cname;
      }
    }
    return cluster;
  });
};

export const handleUpdateStatusForCluster = (
  state: any,
  clusterId: string,
  clusterStatus: string,
  applicationsReady: boolean
) => {
  const clusterStatusList = state?.getClusterStatusUpdates ?? [];
  const index = clusterStatusList.findIndex(
    (cluster: ClusterStatus) => cluster.clusterId === clusterId
  );
  const clusterData = {
    clusterId: clusterId,
    state: clusterStatus,
    applicationsReady: applicationsReady,
  };

  if (index !== -1) {
    clusterStatusList[index] = clusterData;
  } else {
    clusterStatusList.push(clusterData);
  }

  state.getClusterStatusUpdates = clusterStatusList;
};

export const emrDashboardSlice = createSlice({
  name: 'emrDashboard',
  initialState,
  reducers: {
    clearClusters(state) {
      state.getClusters = initialState.getClusters;
    },
    clearClusterStatusUpdates(state) {
      state.getClusterStatusUpdates = initialState.getClusterStatusUpdates;
    },
  },
  extraReducers: (builder) => {
    //List Cluster
    builder.addCase(getClustersThunk.pending, (state) => {
      state.getClusters.loading = true;
      state.getClusters.error = null;
    });
    builder.addCase(getClustersThunk.fulfilled, (state, { payload }: any) => {
      state.getClusters.loading = false;
      state.getClusters.error = null;
      state.getClusters.result = payload;
    });
    builder.addCase(getClustersThunk.rejected, (state, { payload }) => {
      state.getClusters.loading = false;
      state.getClusters.error = payload;
    });

    //Refresh Clusters
    builder.addCase(reloadEMRStateThunk.pending, (state, action) => {
      const result = (state?.getClusters?.result || []) as ClusterDetails[];
      const metaArgs: any = action.meta.arg;
      handleReloadStateOnListItem(result, metaArgs, true);
      state.getClusters.result = result;
    });
    builder.addCase(
      reloadEMRStateThunk.fulfilled,
      (state, { payload, meta }: any) => {
        const result = (state?.getClusters?.result || []) as ClusterDetails[];
        const metaArgs: any = meta.arg;
        handleUpdateStatusForCluster(
          state,
          metaArgs.computeId,
          payload.cluster.status.state,
          payload.cluster.applicationsReady
        );
        handleReloadStateOnListItem(result, metaArgs, false, payload);
        state.getClusters.result = result;
      }
    );
    builder.addCase(reloadEMRStateThunk.rejected, (state, { meta }) => {
      const result = (state?.getClusters?.result || []) as ClusterDetails[];
      const metaArgs: any = meta.arg;
      handleReloadStateOnListItem(result, metaArgs, false);
      state.getClusters.result = result;
    });

    //Change State
    builder.addCase(terminateEMRThunk.pending, (state, action) => {
      const result = (state?.getClusters?.result || []) as ClusterDetails[];
      const metaArgs: any = action.meta.arg;
      state.emrChangingState.loading = true;
      state.emrChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true, false);
      state.getClusters.result = result;
    });
    builder.addCase(terminateEMRThunk.fulfilled, (state, action) => {
      state.emrChangingState.loading = false;
      state.emrChangingState.error = false;

      const result = (state?.getClusters?.result || []) as ClusterDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }
      handleChangeStateOnListItem(result, metaArgs, false, true);
      handleUpdateStatusForCluster(
        state,
        metaArgs.computeId,
        'TERMINATING',
        false
      );
      state.getClusters.result = result;
    });
    builder.addCase(terminateEMRThunk.rejected, (state, action) => {
      state.emrChangingState.loading = false;
      state.emrChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const computeId = metaArgs.computeId;

      const set =
        state?.emrChangeStateError?.filter((x) => x.instanceId === computeId) ||
        [];
      set.push({
        instanceId: computeId,
      });

      state.emrChangeStateError = set;
      const result = (state?.getClusters?.result || []) as ClusterDetails[];
      handleChangeStateOnListItem(result, metaArgs, false, false);
    });
  },
});
export const { clearClusters, clearClusterStatusUpdates } =
  emrDashboardSlice.actions;

export const getChangeEmrState = (state: RootState) => {
  return state?.emrDashboard?.emrChangingState || initialState.emrChangingState;
};
export const getClustersState = (state: RootState) => {
  return state?.emrDashboard?.getClusters || initialState.getClusters;
};

export const getClusterStatusUpdates = (state: RootState) => {
  return (
    state?.emrDashboard?.getClusterStatusUpdates ||
    initialState.getClusterStatusUpdates
  );
};

export const getClusterStatusUpdatesById = (
  state: RootState,
  computeId: string
) => {
  const statusUpdates = getClusterStatusUpdates(state);
  const status: ClusterStatus = statusUpdates.find(
    (cluster: any) => cluster.clusterId === computeId
  );

  return status || initialState.getClusterStatusUpdates;
};
export default emrDashboardSlice.reducer as Reducer<typeof initialState>;
