import { AwsRedshiftDetails } from './aws-prices-details';

export const RedshiftDetails: AwsRedshiftDetails[] = [
  {
    type: 'dc2.large',
    class: 'dc',
    vCPUs: 2,
    ram: 15,
    storageType: 'SSD',
    capacity: '0.16',
    io: '0.6',
  },
  {
    type: 'dc2.8xlarge',
    class: 'dc',
    vCPUs: 32,
    ram: 244,
    storageType: 'SSD',
    capacity: '2.56',
    io: '7.5',
  },
  {
    type: 'ra3.xlplus',
    class: 'ra',
    vCPUs: 4,
    ram: 32,
    storageType: 'SSD',
    capacity: '4',
    io: '0.65',
  },
  {
    type: 'ra3.4xlarge',
    class: 'ra',
    vCPUs: 12,
    ram: 96,
    storageType: 'SSD',
    capacity: '128',
    io: '2',
  },
  {
    type: 'ra3.16xlarge',
    class: 'ra',
    vCPUs: 48,
    ram: 384,
    storageType: 'SSD',
    capacity: '128',
    io: '8',
  },
];

export default RedshiftDetails;
