import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import {
  cortexApiInit,
  DEFAULT_ERROR_MSG,
} from '../../../../../../app/constants';
import { RootState } from '../../../../../../app/store';
import { K8CloudProfileDetails } from '../../../../../../models/kubernetes/K8CloudProfile';
import { CortexAPIAccountType } from '../../../../../../services/CortexAxiosApiService';
import Region from '../../../../../../models/cortex/Region';
import { STS } from '../../../../../../models/storage/Sts';

interface K8CloudProfileDescribeSliceState {
  getK8CloudProfile: {
    loading: boolean;
    error: any;
    result: null | K8CloudProfileDetails;
  };
  stsData: {
    loading: boolean;
    error: any;
    result: null | STS;
  };
}

export const initialState: K8CloudProfileDescribeSliceState = {
  getK8CloudProfile: {
    loading: false,
    error: null,
    result: null,
  },
  stsData: {
    loading: false,
    error: null,
    result: null,
  },
};

interface GetK8CloudProfileThunk {
  profileName: string;
}

interface K8STSGetThunk {
  duration: number;
  format: string | 'STANDARD' | 'LINUX_CLI' | 'WINDOWS_CLI';
  account: CortexAPIAccountType;
  region: Region;
  name: string;
}

export const k8STSGetThunk = createAsyncThunk(
  'k8CloudProfileDescribe/K8STSGetThunk',
  async (
    {
      name,
      duration = 15,
      format = 'STANDARD',
      account,
      region,
    }: K8STSGetThunk,
    thunkAPI
  ) => {
    try {
      const state: any = thunkAPI.getState();
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/k8/profile/sts/${name}`,
        method: 'POST',
        payload: {},
        query: {
          duration: duration * 60,
          sTSCredentialsFormat: format,
        },
      });
      return await response?.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getK8CloudProfileThunk = createAsyncThunk(
  'k8CloudProfileDescribe/GetK8CloudProfileThunk',
  async ({ profileName }: GetK8CloudProfileThunk, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/k8/profile/${profileName}`,
        method: 'GET',
        payload: {},
        query: {},
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const k8CloudProfileDescribeSlice = createSlice({
  name: 'k8CloudProfileDescribe',
  initialState,
  reducers: {
    clearSTS(state) {
      state.stsData = initialState.stsData;
    },
    updateK8CloudProfileStatus(state, { payload }) {
      state.getK8CloudProfile.result = {
        ...state.getK8CloudProfile.result,
        profileState: payload.profileState,
      } as K8CloudProfileDetails;
    },
    updateK8CloudProfile(state, { payload }) {
      state.getK8CloudProfile.result = {
        ...state.getK8CloudProfile.result,
        ...payload,
      } as K8CloudProfileDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getK8CloudProfileThunk.pending, (state) => {
      state.getK8CloudProfile.loading = true;
      state.getK8CloudProfile.error = null;
    });
    builder.addCase(getK8CloudProfileThunk.fulfilled, (state, { payload }) => {
      state.getK8CloudProfile.loading = false;
      state.getK8CloudProfile.error = null;
      state.getK8CloudProfile.result = payload;
    });
    builder.addCase(getK8CloudProfileThunk.rejected, (state, { payload }) => {
      state.getK8CloudProfile.loading = false;
      state.getK8CloudProfile.error = payload;
    });

    //STS
    builder.addCase(k8STSGetThunk.pending, (state) => {
      state.stsData.loading = true;
      state.stsData.error = null;
    });
    builder.addCase(k8STSGetThunk.fulfilled, (state, { payload }) => {
      state.stsData.loading = false;
      state.stsData.error = null;
      state.stsData.result = payload;
    });
    builder.addCase(k8STSGetThunk.rejected, (state, { payload }) => {
      state.stsData.loading = false;
      state.stsData.error = payload;
    });
  },
});
export const { clearSTS, updateK8CloudProfileStatus, updateK8CloudProfile } =
  k8CloudProfileDescribeSlice.actions;

export const getK8CloudProfileState = (state: RootState) => {
  return (
    state?.k8CloudProfileDescribe?.getK8CloudProfile ||
    initialState.getK8CloudProfile
  );
};

export const getK8STSState = (state: RootState) => {
  return state?.k8CloudProfileDescribe?.stsData || initialState.stsData;
};

export default k8CloudProfileDescribeSlice.reducer as Reducer<
  typeof initialState
>;
