import { createSlice, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import update from 'immutability-helper';
import { AlertColor } from '@mui/material';

export function getIndexByProp(array: any[], prop: any, value: any) {
  for (let i = 0, l = array.length; i < l; i++) {
    if (array[i][prop] === value) {
      return i;
    }
  }
  return -1;
}

export const jobStatusToSeverityColor = (status: string) => {
  let severity: AlertColor = 'info';
  if (status === 'FAILED' || status === 'ERROR') {
    severity = 'error';
  } else if (status === 'SUCCEEDED') {
    severity = 'success';
  } else if (status === 'CANCELLED') {
    severity = 'warning';
  }
  return severity;
};

export type JobType =
  | 'FILE_UPLOAD'
  | 'ATHENA'
  | 'CREATE_EC2'
  | 'CREATE_S3'
  | 'CREATE_REDSHIFT'
  | 'CREATE_DSVM'
  | 'CREATE_K8_DASHBOARD'
  | 'CREATE_K8_NOTEBOOK'
  | 'CREATE_K8_PROFILE'
  | 'CREATE_EMR'
  | 'CREATE_EMR_SERVERLESS'
  | 'CREATE_EMR_SERVERLESS_JOB';

export type JobID = string | number;

interface Jobs {
  jobs: any[];
}

export const initialState: Jobs = {
  jobs: [],
};

interface StartFileUploadJob {
  payload: {
    id: JobID;
    upload: any;
  };
}

interface FileUploadProgress {
  payload: {
    id: JobID;
    progress: any;
  };
}

interface JobState {
  payload: {
    id: JobID;
    updateSpec: any;
  };
}

interface Job {
  payload: {
    id: JobID;
    jobType: JobType;
    details: any;
    status?: string;
    [x: string]: any;
  };
}

interface FileUpload {
  payload: {
    id: JobID;
  };
}

// eslint-disable-next-line no-shadow
function doUpdateJob(jobs: any[], id: JobID, updateSpec: any) {
  const index = getIndexByProp(jobs, 'id', id);
  // Ignore attempts to update a job which doesn't exist
  if (index === -1) {
    console.warn('Unable to update job', id, updateSpec);
    return jobs;
  }
  return update(jobs, {
    [index]: { $merge: updateSpec },
  });
}

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    clearJobs(state) {
      state.jobs = initialState.jobs;
    },
    clearFileUploadJobs(state) {
      state.jobs = state.jobs.filter(
        (job) => job.type !== ('FILE_UPLOAD' as JobType)
      );
    },
    clearJob(state, { payload }: FileUpload) {
      state.jobs = state.jobs.filter((job) => job.id !== payload.id);
    },
    startJob(state, { payload }: Job) {
      const { id, details, status, jobType, ...others } = payload;
      state.jobs = [
        {
          id,
          cancelled: false,
          error: null,
          finished: false,
          finishedAt: null,
          progress: 0,
          startedAt: new Date().toISOString(),
          type: jobType,
          details: details,
          status: status,
          ...others,
        },
      ].concat(state.jobs);
    },
    cancelJob(state, { payload }: FileUpload) {
      state.jobs = doUpdateJob(state.jobs, payload.id, {
        cancelled: true,
        finished: true,
        finishedAt: new Date().toISOString(),
        status: 'CANCELLED',
      });
    },
    startFileUploadJob(state, { payload }: StartFileUploadJob) {
      state.jobs = [
        {
          id: payload.id,
          status: 'PENDING',
          cancelled: false,
          error: null,
          finished: false,
          finishedAt: null,
          progress: 0,
          startedAt: new Date().toISOString(),
          type: 'FILE_UPLOAD',
          upload: payload.upload,
        },
      ].concat(state.jobs);
    },
    fileUploadProgress(state, { payload }: FileUploadProgress) {
      state.jobs = doUpdateJob(state.jobs, payload.id, {
        status: 'UPLOADING',
        progress: payload.progress,
      });
    },
    updateJob(state, { payload }) {
      state.jobs = doUpdateJob(state.jobs, payload.id, payload.updateSpec);
    },
    finishJob(state, { payload }: JobState) {
      state.jobs = doUpdateJob(state.jobs, payload.id, payload.updateSpec);
    },
    jobError(state, { payload }: JobState) {
      state.jobs = doUpdateJob(state.jobs, payload.id, payload.updateSpec);
    },
  },
});

export const {
  clearJobs,
  clearFileUploadJobs,
  startFileUploadJob,
  finishJob,
  fileUploadProgress,
  jobError,
  cancelJob,
  clearJob,
  startJob,
  updateJob,
} = jobsSlice.actions;

export const getJobsState = (state: RootState) => {
  return state?.jobs?.jobs || initialState.jobs;
};

export const filterJobsStateByType = (jobs: [], jobType: JobType) => {
  return jobs?.filter((job: any) => job.type === jobType) || initialState.jobs;
};

export default jobsSlice.reducer as Reducer<typeof initialState>;
