import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { LinkClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmLink: Partial<OverridesStyleRules<keyof LinkClasses>> | undefined = {
  root: {
    color: palette.props.tealDark,
    textDecorationColor: palette.props.tealDark,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
    textDecoration: 'none',
  },
};

export default LmLink;
