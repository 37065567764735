import { styled } from '@mui/material/styles';
import { CortexMenuItem } from '../system/CortexMenu';

export const MenuItemTeal = styled(CortexMenuItem)(({ theme }: any) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.text.primary
      : theme.palette.secondary.dark,
  textDecoration: 'none',
  marginRight: 2.0,
  backgroundColor: 'default',
}));
export default MenuItemTeal;
