import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { cortexApiInit, DEFAULT_ERROR_MSG } from '../../../../../app/constants';
import { RootState } from '../../../../../app/store';

export interface CortexApiSecretResponseType {
  name: string;
  arn: string;
  versionId: string;
  replicationStatus: string;
  sdkResponseMetadata: any;
  sdkHttpMetadata: any;
  expirationDateTime: any;
}

interface SecretSliceStateComponent {
  loading: boolean;
  error?: any;
  result?: CortexApiSecretResponseType[] | null;
}

interface SecretSliceState {
  getSecrets: SecretSliceStateComponent;
  postSecret: SecretSliceStateComponent;
  putSecret: SecretSliceStateComponent;
  deleteSecret: SecretSliceStateComponent;
}

const initialState: SecretSliceState = {
  deleteSecret: {
    loading: false,
    error: null,
    result: null,
  },
  putSecret: {
    loading: false,
    error: null,
    result: null,
  },
  getSecrets: {
    loading: false,
    error: null,
    result: null,
  },
  postSecret: {
    loading: false,
    error: null,
    result: null,
  },
};

export const deleteSecretThunk = createAsyncThunk(
  'secrets/DeleteSecretThunk',
  async ({ secretName, cb }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/secrets',
        method: 'DELETE',
        query: { secretName },
      });
      const data = await response?.data;
      if (cb) {
        cb(data);
      }
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const postSecretThunk = createAsyncThunk(
  'secrets/PostSecretThunk',
  async ({ name, description, secretContent }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/secrets',
        method: 'POST',
        query: { name, description, secretContent },
      });
      return await response?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const putSecretThunk = createAsyncThunk(
  'secrets/PutSecretThunk',
  async ({ name, secretContent }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/secrets/' + name,
        method: 'PUT',
        query: { name, secretContent },
      });
      return await response?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getSecretsThunk = createAsyncThunk(
  'secrets/GetSecretsThunk',
  async ({ cb }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/secrets',
        method: 'GET',
      });
      const data = await response?.data;
      if (cb) {
        cb(data);
      }
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const secretSlice = createSlice({
  name: 'secrets',
  initialState,
  reducers: {
    clearSecretState(state) {
      state.postSecret.loading = false;
      state.postSecret.error = null;
      state.postSecret.result = null;
      state.putSecret.result = null;
      state.putSecret.loading = false;
      state.putSecret.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSecretsThunk.pending, (state) => {
      state.getSecrets.loading = true;
    });
    builder.addCase(getSecretsThunk.fulfilled, (state, { payload }: any) => {
      state.getSecrets.loading = false;
      state.getSecrets.error = null;
      state.getSecrets.result = payload;
    });
    builder.addCase(getSecretsThunk.rejected, (state, { payload }: any) => {
      state.getSecrets.loading = false;
      state.getSecrets.error = payload;
    });
    builder.addCase(postSecretThunk.pending, (state) => {
      state.postSecret.loading = true;
    });
    builder.addCase(postSecretThunk.fulfilled, (state, { payload }: any) => {
      state.postSecret.loading = false;
      state.postSecret.error = null;
      state.postSecret.result = payload;
    });
    builder.addCase(postSecretThunk.rejected, (state, { payload }: any) => {
      state.postSecret.loading = false;
      state.postSecret.error = payload;
    });
    builder.addCase(putSecretThunk.pending, (state) => {
      state.putSecret.loading = true;
    });
    builder.addCase(putSecretThunk.fulfilled, (state, { payload }: any) => {
      state.putSecret.loading = false;
      state.putSecret.error = null;
      state.putSecret.result = payload;
    });
    builder.addCase(putSecretThunk.rejected, (state, { payload }: any) => {
      state.putSecret.loading = false;
      state.putSecret.error = payload;
    });
    builder.addCase(deleteSecretThunk.pending, (state) => {
      state.deleteSecret.loading = true;
    });
    builder.addCase(deleteSecretThunk.fulfilled, (state, { payload }: any) => {
      state.deleteSecret.loading = false;
      state.deleteSecret.error = null;
      state.getSecrets.result = payload;
    });
    builder.addCase(deleteSecretThunk.rejected, (state, { payload }: any) => {
      state.deleteSecret.loading = false;
      state.deleteSecret.error = payload;
    });
  },
});

export const { clearSecretState } = secretSlice.actions;

export const getSecretState = (state: RootState) => {
  return state?.secrets?.getSecrets || initialState.getSecrets;
};

export const getPostSecretState = (state: RootState) => {
  return state?.secrets?.postSecret || initialState.postSecret;
};

export const getPutSecretState = (state: RootState) => {
  return state?.secrets?.putSecret || initialState.putSecret;
};

export const getDeleteSecretState = (state: RootState) => {
  return state?.secrets?.deleteSecret || initialState.deleteSecret;
};

export default secretSlice.reducer as Reducer<typeof initialState>;
