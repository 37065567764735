import { AwsEc2Details } from '../../../../../../app/data/aws-prices-details';
import { compareStorage } from '../../../../../../app/utilities/compareStorage';
import { sortStorage } from '../../../../../../app/utilities/sortStorage';
import { AwsEc2DetailsWithCost } from '../../ec2/slices/createEc2Interfaces';

export const createDescriptors = (
  computeDescriptors: string[],
  label: string
) =>
  computeDescriptors.map((compute, index) => ({
    value: index,
    label: `${compute}${label}`,
  }));

export const createSetOfInstanceCores = (
  instances: AwsEc2DetailsWithCost[]
) => {
  return [
    ...new Set(
      instances
        .map((instance: AwsEc2Details) => instance.vCPUs)
        .sort((a, b) => parseInt(a) - parseInt(b))
    ),
  ];
};

export const createSetOfInstanceMemory = (
  instances: AwsEc2DetailsWithCost[]
) => {
  return [
    ...new Set(
      instances
        .map((instance) => instance.ram.replace(/\s*\w*$/g, ''))
        .sort((a, b) => parseInt(a) - parseInt(b))
    ),
  ];
};

export const createSetOfInstanceStorage = (
  instances: AwsEc2DetailsWithCost[]
) => {
  return [
    ...new Set(
      instances
        .map((instance) => {
          const storage = instance.storage
            .replace(/ SSD|GB/g, '')
            .replace(/ x /g, 'x');
          return storage;
        })
        .sort(sortStorage)
    ),
  ];
};

interface requiredFilterData {
  cores: number;
  instanceCores: string[];
  instanceMemory: string[];
  instances: AwsEc2DetailsWithCost[];
  instanceStorage: string[];
  memory: number;
  region: string;
  storage: number;
}
const allowedTypes = ['small', 'medium', 'xlarge', '2xlarge'];

export const onlyAllowedInstanceTypes = (
  instance: AwsEc2DetailsWithCost | AwsEc2Details
) => {
  const type = instance.type.split('.')[1];

  return allowedTypes.includes(type);
};

export const filterInstances = (
  isAllowedToCreateLargeWorkflow: boolean,
  data: requiredFilterData
) => {
  const allowedInstanceTypes = isAllowedToCreateLargeWorkflow
    ? data.instances
    : data.instances.filter(onlyAllowedInstanceTypes);

  return allowedInstanceTypes
    .reduce((accumulator: AwsEc2DetailsWithCost[], instance) => {
      const coreFilter =
        parseInt(instance.vCPUs) >= parseInt(data.instanceCores[data.cores]);
      const memoryFilter =
        parseFloat(instance.ram) >= parseInt(data.instanceMemory[data.memory]);
      const storageFilter = compareStorage(
        instance.storage,
        data.instanceStorage[data.storage]
      );
      if (
        coreFilter &&
        memoryFilter &&
        storageFilter &&
        instance.cost[data.region]
      ) {
        accumulator.push(instance);
      }
      return accumulator;
    }, [])
    .sort(
      (a: AwsEc2DetailsWithCost, b: AwsEc2DetailsWithCost) =>
        (a.cost[data.region] as number) - (b.cost[data.region] as number)
    );
};
