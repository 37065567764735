import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CheckboxClasses } from '@mui/material';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmCheckbox:
  | Partial<OverridesStyleRules<keyof CheckboxClasses>>
  | undefined = {
  root: {
    '&.Mui-checked': {
      color: palette.props.tealMedium,
    },
  },
};

export default LmCheckbox;
