import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import Icon from '@mdi/react';
import {
  mdiJira,
  mdiMicrosoftTeams,
  mdiSlack,
  mdiStackOverflow,
} from '@mdi/js';

const SUPPORT_LINK = process.env.REACT_APP_SUPPORT_URL;
const SLACK_LINK = process.env.REACT_APP_SLACK_URL;
const TEAMS_LINK = process.env.REACT_APP_TEAMS_URL;
const STACK_LINK = process.env.REACT_APP_STACK_URL;

export const SocialLinks = () => {
  return (
    <>
      {STACK_LINK && (
        <Box p={1}>
          <Tooltip title={'Cloud Forge Q&A'} aria-label={'Cloud Forge Q&A'}>
            <IconButton href={STACK_LINK} size="large">
              <Icon
                path={mdiStackOverflow}
                size={1.5}
                style={{ color: 'white' }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {SLACK_LINK && (
        <Box p={1}>
          <Tooltip title={'Slack Community'} aria-label={'Slack Community'}>
            <IconButton href={SLACK_LINK} size="large">
              <Icon style={{ color: 'white' }} path={mdiSlack} size={1.5} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {TEAMS_LINK && (
        <Box p={1}>
          <Tooltip title={'Teams Community'} aria-label={'Teams Community'}>
            <IconButton href={TEAMS_LINK} size="large">
              <Icon
                path={mdiMicrosoftTeams}
                size={1.5}
                style={{ color: 'white' }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {SUPPORT_LINK && (
        <Box p={1}>
          <Tooltip title={'Jira Support'} aria-label={'Jira Support'}>
            <IconButton href={SUPPORT_LINK} size="large">
              <Icon path={mdiJira} size={1.5} style={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
export default SocialLinks;
