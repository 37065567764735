import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { StepButtonClasses } from '@mui/material';
import { frost0, nord0, nord2 } from './constants';

const DarkStepButton:
  | Partial<OverridesStyleRules<keyof StepButtonClasses>>
  | undefined = {
  root: {
    '& .MuiStepIcon-root': {
      fill: `${nord2}`,
    },
    '& .MuiStepIcon-root.Mui-active': {
      fill: `${frost0}`,
    },
    '& .MuiStepIcon-root.Mui-active > .MuiStepIcon-text': {
      fill: `${nord0}`,
    },
  },
};

export default DarkStepButton;
