import CortexThemeOverride from '../CortexThemeOverride';
import DarkButton from './DarkButton';
import DarkIconButton from './DarkIconButton';
import DarkInputLabel from './DarkInputLabel';
import DarkLink from './DarkLink';
import DarkPalette from './DarkPalette';
import DarkTab from './DarkTab';
import DarkTabs from './DarkTabs';
import DarkAlert from './DarkAlert';
import DarkRadio from './DarkRadio';
import DarkCard from './DarkCard';
import DarkStepButton from './DarkStepButton';
import DarkCheckbox from './DarkCheckbox';
import DarkSelect from './DarkSelect';
import DarkOutlinedInput from './DarkOutlinedInput';
import DarkTableHead from './DarkTableHead';
import DarkSwitch from './DarkSwitch';

const DarkTheme: Partial<CortexThemeOverride> = {
  alert: DarkAlert,
  button: DarkButton,
  iconButton: DarkIconButton,
  inputLabel: DarkInputLabel,
  link: DarkLink,
  palette: DarkPalette,
  tab: DarkTab,
  tabs: DarkTabs,
  radio: DarkRadio,
  card: DarkCard,
  stepButton: DarkStepButton,
  checkbox: DarkCheckbox,
  select: DarkSelect,
  outlinedInput: DarkOutlinedInput,
  tableHead: DarkTableHead,
  switch: DarkSwitch,
};

export default DarkTheme;
