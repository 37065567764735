import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CardClasses } from '@mui/material';
import { frost0, nord0 } from './constants';
const DarkCard: Partial<OverridesStyleRules<keyof CardClasses>> | undefined = {
  root: {
    backgroundColor: `${nord0}`,
    border: `1px solid #515151`,
    backgroundImage: 'none',
    '&.MuiCard-root:hover': {
      borderColor: frost0,
    },
  },
};

export default DarkCard;
