import { sortStorage } from './sortStorage';

/**
 * Comparator function which returns a boolean indicating whether or not
 * a given storage value falls in the provided range
 * e.g. "EBS Only" < "1x75 NVMe" < "2x2000 HDD"
 * Runs as part of the guided ec2 instance selection process.
 * @param {string} storage An instance type storage descriptor
 * @param {string} lowerBound An instance type storage descriptor
 * @returns {boolean} representing whether or not the provided descriptor falls in range
 */
export const compareStorage = (storage: string, lowerBound: string) => {
  const compareLower = sortStorage(storage, lowerBound);
  const compare = compareLower >= 0;
  return compare;
};
