import { cortexAwsApi } from '../cortexAwsApi';
import Playbook, { PlaybookCompute } from '../../models/compute/Playbooks';

const extendedPlaybooksApi = cortexAwsApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlaybooksList: builder.query<Playbook[], PlaybookCompute>({
      query: (computeType) => ({
        url: 'rest/v2/aws/compute/playbooks/list',
        method: 'GET',
        params: { type: computeType },
      }),
    }),
    getPlaybook: builder.query<Playbook, void>({
      query: (playbookName) => ({
        url: `rest/v2/aws/compute/playbooks?playbookName=${playbookName}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPlaybooksListQuery, useGetPlaybookQuery } =
  extendedPlaybooksApi;

export default extendedPlaybooksApi;
