import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { cortexApiInit, DEFAULT_ERROR_MSG } from '../../../../app/constants';
import { RootState } from '../../../../app/store';
import {
  K8InstallationDetails,
  K8InstallationState,
  K8InstallationType,
} from '../../../../models/kubernetes/K8Installation';
import { updateK8InstallationStatus } from '../installation/details/slices/k8InstallationDescribeSlice';

export type K8InstallationAction = 'TERMINATE' | 'STOP' | 'START' | null;

interface K8InstallationStateChangeError {
  installationName: string;
  action: K8InstallationAction;
  error?: any;
}

export interface K8InstallationDashboardSliceState {
  getInstallation: {
    loading: boolean;
    error: any;
    result: null | K8InstallationDetails[];
    installationType: K8InstallationType | null;
  };
  k8InstallationChangeStateError?: K8InstallationStateChangeError[];
  k8InstallationChangingState: {
    loading: boolean;
    error: any;
  };
}

export const initialState: K8InstallationDashboardSliceState = {
  getInstallation: {
    loading: false,
    error: null,
    result: null,
    installationType: null,
  },
  k8InstallationChangeStateError: [],
  k8InstallationChangingState: {
    loading: false,
    error: null,
  },
};

interface K8InstallationThunk {
  installationName: string;
  namespace?: string | null;
  action?: K8InstallationAction;
  cb?: any;
}

export const terminateK8InstallationThunk = createAsyncThunk(
  'k8InstallationDashboard/TerminateK8InstallationThunk',
  async (
    { cb, installationName, namespace }: K8InstallationThunk,
    thunkAPI
  ) => {
    try {
      const state: any = thunkAPI.getState();
      const token = state.authentication.auth?.token || '';
      const username = state.authentication.auth?.user?.id || '';
      const { account, region } = state.cortexAccount;
      const path = `aws/k8/installation/${
        namespace ? namespace : username
      }/${installationName}`;

      const payload = {};
      const query = {};
      const response = await cortexApiInit(token, account, region).exchange({
        path: `${path}`,
        method: 'DELETE',
        payload,
        query,
      });

      thunkAPI.dispatch(
        updateK8InstallationStatus({ podStatus: 'Terminating' })
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const stopStartK8InstallationThunk = createAsyncThunk(
  'k8InstallationDashboard/StopStartK8InstallationThunk',
  async (
    { cb, installationName, namespace, action }: K8InstallationThunk,
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(
        updateK8InstallationStatus({
          podStatus: action === 'START' ? 'STARTING' : 'STOPPING',
        })
      );
      const state: any = thunkAPI.getState();
      const username = state.authentication.auth?.user?.id || '';
      const { account, region } = state.cortexAccount;
      const path = `aws/k8/installation/${
        namespace ? namespace : username
      }/${installationName}/${action?.toLowerCase()}`;
      const token = state.authentication.auth?.token || '';

      const payload = {};
      const query = {};
      const response = await cortexApiInit(token, account, region).exchange({
        path: `${path}`,
        method: 'PUT',
        payload,
        query,
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const reloadK8InstallationStateThunk = createAsyncThunk(
  'k8InstallationDashboard/ReloadK8InstallationStateThunk',
  async ({ installationName, namespace }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const username = state.authentication.auth?.user?.id || '';

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/k8/installation/${
          namespace ? namespace : username
        }/${installationName}`,
        method: 'GET',
        payload: {},
        query: {},
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

interface GetK8Installation {
  installationType: K8InstallationType;
}

export const getK8InstallationsThunk = createAsyncThunk(
  'k8InstallationDashboard/GetK8InstallationInstancesThunk',
  // eslint-disable-next-line no-empty-pattern
  async ({ installationType }: GetK8Installation, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/k8/installation',
        method: 'GET',
        payload: { installationType },
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

const handleChangeStateOnListItem = (
  result: K8InstallationDetails[],
  metaArgs: any,
  loading: boolean,
  updateVmState?: K8InstallationState
) => {
  result.forEach((instance: K8InstallationDetails) => {
    if (instance.name === metaArgs.installationName) {
      instance.localLoading = loading;
      if (updateVmState) {
        instance.podStatus = updateVmState;
      }
    }
    return instance;
  });
};

const handleReloadStateOnListItem = (
  result: K8InstallationDetails[],
  metaArgs: any,
  loading: boolean,
  details?: K8InstallationDetails
) => {
  result.forEach((k8Installation: K8InstallationDetails) => {
    if (k8Installation.name === metaArgs.installationName) {
      k8Installation.localLoading = loading;
      if (details) {
        k8Installation.podStatus = details.podStatus;
        k8Installation.cname = details.cname;
      }
    }
    return k8Installation;
  });
};

export const k8InstallationDashboardSlice = createSlice({
  name: 'k8InstallationDashboard',
  initialState,
  reducers: {
    clearK8Installation: (state) => {
      state.getInstallation = initialState.getInstallation;
    },
    clearK8Error: (state, { payload }) => {
      state.k8InstallationChangeStateError =
        state?.k8InstallationChangeStateError?.filter(
          (x) => payload.installationName !== x.installationName
        ) || [];
    },
  },
  extraReducers: (builder) => {
    //List Cluster
    builder.addCase(getK8InstallationsThunk.pending, (state) => {
      state.getInstallation.loading = true;
      state.getInstallation.error = null;
    });
    builder.addCase(
      getK8InstallationsThunk.fulfilled,
      (state, { payload, meta }: any) => {
        state.getInstallation.loading = false;
        state.getInstallation.error = null;
        state.getInstallation.result = payload;
        state.getInstallation.installationType = meta.arg.installationType;
      }
    );
    builder.addCase(
      getK8InstallationsThunk.rejected,
      (state, { payload, meta }) => {
        state.getInstallation.loading = false;
        state.getInstallation.error = payload;
        state.getInstallation.installationType = meta.arg.installationType;
      }
    );

    //Refresh Clusters
    builder.addCase(reloadK8InstallationStateThunk.pending, (state, action) => {
      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      const metaArgs: any = action.meta.arg;
      handleReloadStateOnListItem(result, metaArgs, true);
      state.getInstallation.result = result;
    });
    builder.addCase(
      reloadK8InstallationStateThunk.fulfilled,
      (state, { payload, meta }: any) => {
        const result = (state?.getInstallation?.result ||
          []) as K8InstallationDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false, payload);
        state.getInstallation.result = result;
      }
    );
    builder.addCase(
      reloadK8InstallationStateThunk.rejected,
      (state, { meta }) => {
        const result = (state?.getInstallation?.result ||
          []) as K8InstallationDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false);
        state.getInstallation.result = result;
      }
    );

    //Change State
    builder.addCase(stopStartK8InstallationThunk.pending, (state, action) => {
      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      const metaArgs: any = action.meta.arg;
      state.k8InstallationChangingState.loading = true;
      state.k8InstallationChangingState.error = false;

      let updatedState: K8InstallationState = 'STOPPING';
      if (metaArgs.action === 'START') {
        updatedState = 'STARTING';
      }

      handleChangeStateOnListItem(result, metaArgs, true, updatedState);
      state.getInstallation.result = result;
    });
    builder.addCase(stopStartK8InstallationThunk.fulfilled, (state, action) => {
      state.k8InstallationChangingState.loading = false;
      state.k8InstallationChangingState.error = false;

      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }

      let updatedState: K8InstallationState = 'STOPPED';
      if (metaArgs.action === 'START') {
        updatedState = 'PENDING';
      }

      handleChangeStateOnListItem(result, metaArgs, false, updatedState);
      state.getInstallation.result = result;
    });
    builder.addCase(stopStartK8InstallationThunk.rejected, (state, action) => {
      state.k8InstallationChangingState.loading = false;
      state.k8InstallationChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const installationName = metaArgs.installationName;
      const k8Action = metaArgs.action;

      const set =
        state?.k8InstallationChangeStateError?.filter(
          (x) => installationName !== x.installationName
        ) || [];

      set.push({
        installationName: installationName,
        action: k8Action,
        error: action.payload,
      });

      state.k8InstallationChangeStateError = set;
      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      handleChangeStateOnListItem(result, metaArgs, false);
    });
    //Terminate
    builder.addCase(terminateK8InstallationThunk.pending, (state, action) => {
      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      const metaArgs: any = action.meta.arg;
      state.k8InstallationChangingState.loading = true;
      state.k8InstallationChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true);
      state.getInstallation.result = result;
    });
    builder.addCase(terminateK8InstallationThunk.fulfilled, (state, action) => {
      state.k8InstallationChangingState.loading = false;
      state.k8InstallationChangingState.error = false;

      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }

      handleChangeStateOnListItem(result, metaArgs, false, 'TERMINATING');
      state.getInstallation.result = result;
    });
    builder.addCase(terminateK8InstallationThunk.rejected, (state, action) => {
      state.k8InstallationChangingState.loading = false;
      state.k8InstallationChangingState.error = action.payload;

      const metaArgs: any = action.meta.arg;
      const installationName = metaArgs.installationName;

      const set =
        state?.k8InstallationChangeStateError?.filter(
          (x) => x.installationName === installationName
        ) || [];
      set.push({
        installationName: installationName,
        action: 'TERMINATE',
        error: action.payload,
      });

      state.k8InstallationChangeStateError = set;
      const result = (state?.getInstallation?.result ||
        []) as K8InstallationDetails[];
      handleChangeStateOnListItem(result, metaArgs, false);
    });
  },
});

export const { clearK8Installation, clearK8Error } =
  k8InstallationDashboardSlice.actions;

export const getChangeK8InstallationState = (state: RootState) => {
  return (
    state?.k8InstallationDashboard?.k8InstallationChangingState ||
    initialState.k8InstallationChangingState
  );
};
export const getInstallationState = (state: RootState) => {
  return (
    state?.k8InstallationDashboard?.getInstallation ||
    initialState.getInstallation
  );
};

export const getInstallationsErrorState = (state: RootState) => {
  return (
    state?.k8InstallationDashboard?.k8InstallationChangeStateError ||
    initialState.k8InstallationChangeStateError
  );
};

export default k8InstallationDashboardSlice.reducer as Reducer<
  typeof initialState
>;
