import { combineReducers, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { authSlice } from '../../routes/auth/authSlice';
import { cortexAccountSelectorSlice } from '../../routes/member/account/slices/cortexAccountSelectorSlice';
import { labApi } from '../../services/labApi';
import { cortexAwsApi } from '../../services/cortexAwsApi';
import { cortexAzureApi } from '../../services/cortexAzureApi';
import { applicationSlice } from './applicationSlice';

export const rootReducer = (asyncReducers?: any): Reducer => {
  const appReducer = combineReducers({
    application: applicationSlice.reducer,
    authentication: authSlice.reducer,
    cortexAccount: cortexAccountSelectorSlice.reducer,
    [labApi.reducerPath]: labApi.reducer,
    [cortexAwsApi.reducerPath]: cortexAwsApi.reducer,
    [cortexAzureApi.reducerPath]: cortexAzureApi.reducer,
    ...asyncReducers,
  });

  return (state?: ReducersMapObject, action?: any) => {
    if (
      action?.type === 'authentication/PostAuthLogoutThunk/fulfilled' ||
      action?.type === 'authentication/PostAuthLogoutThunk/rejected'
    ) {
      state = undefined; // if a user has logged out, lets blow away any state still in redux store.
    }
    // @ts-ignore
    return appReducer(state, action);
  };
};
