import { createSlice, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';

export type CompactModeType = 'compact' | 'default';
export type AppThemeType = 'light' | 'dark' | 'system';
export type MenuBarAccountSelectorType = 'visible' | 'hidden';

interface ApplicationState {
  appTheme: AppThemeType | null;
  compactMode: CompactModeType;
  appThemePreference: AppThemeType;
  betaFeatures: MenuBarAccountSelectorType;
  menuBarAccountSelector: MenuBarAccountSelectorType;
  showEstimatedCost: boolean;
}

const prefersDarkMode = () => {
  return window?.matchMedia('(prefers-color-scheme: dark)').matches || false;
};

const getColorScheme = () => {
  let theme = getLocalStorageItem('theme') as AppThemeType;
  if (!theme) {
    theme = prefersDarkMode() ? 'dark' : 'light';
  }
  return theme;
};

export const initialState: ApplicationState = {
  appTheme: getColorScheme(),
  appThemePreference:
    (getLocalStorageItem('theme') as AppThemeType) || 'system',
  compactMode:
    (getLocalStorageItem('compactMode') as CompactModeType) ?? 'default',
  betaFeatures:
    (getLocalStorageItem('betaFeatures') as MenuBarAccountSelectorType) ??
    'hidden',
  showEstimatedCost: getLocalStorageItem('showEstimatedCost') === 'true',
  menuBarAccountSelector:
    (getLocalStorageItem(
      'menuBarAccountSelector'
    ) as MenuBarAccountSelectorType) ?? 'visible',
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setAppTheme(state, { payload }) {
      let theme = payload;
      state.appThemePreference = theme;
      if (theme === 'system') {
        removeLocalStorageItem('theme');
        if (prefersDarkMode()) {
          theme = 'dark';
        } else {
          theme = 'light';
        }
      } else {
        setLocalStorageItem('theme', theme);
      }
      state.appTheme = theme;
    },
    setCompactMode(state, { payload }) {
      const mode = payload;
      setLocalStorageItem('compactMode', mode);
      state.compactMode = mode;
    },
    setMenuBarAccountSelector(state, { payload }) {
      const mode = payload;
      setLocalStorageItem('menuBarAccountSelector', mode);
      state.menuBarAccountSelector = mode;
    },
    setBetaFeatures(state, { payload }) {
      const mode = payload;
      setLocalStorageItem('betaFeatures', mode);
      state.betaFeatures = mode;
    },
    setShowEstimatedCost(state, { payload }) {
      const show = payload;
      setLocalStorageItem('showEstimatedCost', show);
      state.showEstimatedCost = show;
    },
  },
});

export const {
  setAppTheme,
  setCompactMode,
  setMenuBarAccountSelector,
  setBetaFeatures,
  setShowEstimatedCost,
} = applicationSlice.actions;

export const getAppTheme = (state: RootState) => {
  return state?.application?.appTheme || initialState.appTheme;
};

export const getCompactMode = (state: RootState) => {
  return state?.application?.compactMode;
};

export const getMenuBarAccountSelector = (
  state: RootState
): MenuBarAccountSelectorType => {
  return state?.application?.menuBarAccountSelector;
};

export const getAppThemePreference = (state: RootState) => {
  return (
    state?.application?.appThemePreference || initialState.appThemePreference
  );
};

export const getAppBetaFeature = (state: RootState) => {
  return state?.application?.betaFeatures || initialState.betaFeatures;
};

export const getShowEstimatedCost = (state: RootState) => {
  return Boolean(state?.application?.showEstimatedCost);
};

export default applicationSlice.reducer as Reducer<typeof initialState>;
