import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { cortexApiInit, DEFAULT_ERROR_MSG } from '../../../../app/constants';
import { RootState } from '../../../../app/store';
import {
  K8CloudProfileDetails,
  K8CloudProfileState,
} from '../../../../models/kubernetes/K8CloudProfile';
import { updateK8CloudProfileStatus } from '../profiles/details/slices/k8CloudProfileDescribeSlice';

export type K8CloudProfileAction = 'TERMINATE' | null;

interface K8CloudProfileStateChangeError {
  profileName: string;
  action: K8CloudProfileAction;
}

export interface K8CloudProfileDashboardSliceState {
  getCloudProfile: {
    loading: boolean;
    error: any;
    result: null | K8CloudProfileDetails[];
  };
  k8CloudProfileChangeStateError?: K8CloudProfileStateChangeError[];
  k8CloudProfileChangingState: {
    loading: boolean;
    error: any;
  };
}

export const initialState: K8CloudProfileDashboardSliceState = {
  getCloudProfile: {
    loading: false,
    error: null,
    result: null,
  },
  k8CloudProfileChangeStateError: [],
  k8CloudProfileChangingState: {
    loading: false,
    error: null,
  },
};

interface K8CloudProfileThunk {
  profileName: string;
  cb?: any;
}

export const terminateK8CloudProfileThunk = createAsyncThunk(
  'k8CloudProfileDashboard/TerminateK8CloudProfileThunk',
  async ({ cb, profileName }: K8CloudProfileThunk, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        updateK8CloudProfileStatus({ profileState: 'TERMINATING' })
      );
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const path = `aws/k8/profile/${profileName}`;
      const token = state.authentication.auth?.token || '';

      const payload = {};
      const query = {};
      const response = await cortexApiInit(token, account, region).exchange({
        path: `${path}`,
        method: 'DELETE',
        payload,
        query,
      });

      return response.data;
    } catch (e) {
      console.error(e);
      thunkAPI.dispatch(
        updateK8CloudProfileStatus({ profileState: 'AVAILABLE' })
      );

      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const reloadK8CloudProfileStateThunk = createAsyncThunk(
  'k8CloudProfileDashboard/ReloadK8CloudProfileStateThunk',
  async ({ profileName }: any, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;

      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: `aws/k8/profile/${profileName}`,
        method: 'GET',
        payload: {},
        query: {},
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const getK8CloudProfilesThunk = createAsyncThunk(
  'k8CloudProfileDashboard/GetK8CloudProfileInstancesThunk',
  // eslint-disable-next-line no-empty-pattern
  async (thunkArgs: {}, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/k8/profile',
        method: 'GET',
        payload: {},
      });

      return response.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

const handleChangeStateOnListItem = (
  result: K8CloudProfileDetails[],
  metaArgs: any,
  loading: boolean,
  newState?: K8CloudProfileState
) => {
  result.forEach((profile: K8CloudProfileDetails) => {
    if (profile.name === metaArgs.profileName) {
      profile.localLoading = loading;
      if (newState) {
        profile.profileState = newState;
      }
    }
    return profile;
  });
};

const handleReloadStateOnListItem = (
  result: K8CloudProfileDetails[],
  metaArgs: any,
  loading: boolean,
  details?: K8CloudProfileDetails
) => {
  result.forEach((k8CloudProfile: K8CloudProfileDetails) => {
    if (k8CloudProfile.name === metaArgs.profileName) {
      k8CloudProfile.localLoading = loading;
      if (details) {
        k8CloudProfile.profileState = details.profileState;
      }
    }
    return k8CloudProfile;
  });
};

export const k8CloudProfileDashboardSlice = createSlice({
  name: 'k8CloudProfileDashboard',
  initialState,
  reducers: {
    clearK8CloudProfile(state) {
      state.getCloudProfile = initialState.getCloudProfile;
    },
  },
  extraReducers: (builder) => {
    //List Cluster
    builder.addCase(getK8CloudProfilesThunk.pending, (state) => {
      state.getCloudProfile.loading = true;
      state.getCloudProfile.error = null;
    });
    builder.addCase(
      getK8CloudProfilesThunk.fulfilled,
      (state, { payload }: any) => {
        state.getCloudProfile.loading = false;
        state.getCloudProfile.error = null;
        state.getCloudProfile.result = payload;
      }
    );
    builder.addCase(getK8CloudProfilesThunk.rejected, (state, { payload }) => {
      state.getCloudProfile.loading = false;
      state.getCloudProfile.error = payload;
    });

    //Refresh Clusters
    builder.addCase(reloadK8CloudProfileStateThunk.pending, (state, action) => {
      const result = (state?.getCloudProfile?.result ||
        []) as K8CloudProfileDetails[];
      const metaArgs: any = action.meta.arg;
      handleReloadStateOnListItem(result, metaArgs, true);
      state.getCloudProfile.result = result;
    });
    builder.addCase(
      reloadK8CloudProfileStateThunk.fulfilled,
      (state, { payload, meta }: any) => {
        const result = (state?.getCloudProfile?.result ||
          []) as K8CloudProfileDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false, payload);
        state.getCloudProfile.result = result;
      }
    );
    builder.addCase(
      reloadK8CloudProfileStateThunk.rejected,
      (state, { meta }) => {
        const result = (state?.getCloudProfile?.result ||
          []) as K8CloudProfileDetails[];
        const metaArgs: any = meta.arg;
        handleReloadStateOnListItem(result, metaArgs, false);
        state.getCloudProfile.result = result;
      }
    );

    //Change State
    builder.addCase(terminateK8CloudProfileThunk.pending, (state, action) => {
      const result = (state?.getCloudProfile?.result ||
        []) as K8CloudProfileDetails[];
      const metaArgs: any = action.meta.arg;
      state.k8CloudProfileChangingState.loading = true;
      state.k8CloudProfileChangingState.error = false;

      handleChangeStateOnListItem(result, metaArgs, true);
      state.getCloudProfile.result = result;
    });
    builder.addCase(terminateK8CloudProfileThunk.fulfilled, (state, action) => {
      state.k8CloudProfileChangingState.loading = false;
      state.k8CloudProfileChangingState.error = false;

      const result = (state?.getCloudProfile?.result ||
        []) as K8CloudProfileDetails[];
      const metaArgs: any = action.meta.arg;
      const { cb } = metaArgs;
      if (cb) {
        setTimeout(cb, 100);
      }

      state.getCloudProfile.result = result.filter(
        (x) => x.name !== metaArgs.profileName
      );
    });
    builder.addCase(
      terminateK8CloudProfileThunk.rejected,
      (state, { meta, payload }) => {
        state.k8CloudProfileChangingState.loading = false;
        state.k8CloudProfileChangingState.error = payload || DEFAULT_ERROR_MSG;

        const metaArgs: any = meta.arg;
        const profileName = metaArgs.profileName;

        const set =
          state?.k8CloudProfileChangeStateError?.filter(
            (x) => x.profileName === profileName
          ) || [];

        set.push({
          profileName,
          action: 'TERMINATE',
        });

        state.k8CloudProfileChangeStateError = set;
      }
    );
  },
});

export const { clearK8CloudProfile } = k8CloudProfileDashboardSlice.actions;

export const getChangeK8CloudProfileState = (state: RootState) => {
  return (
    state?.k8CloudProfileDashboard?.k8CloudProfileChangingState ||
    initialState.k8CloudProfileChangingState
  );
};
export const getCloudProfileState = (state: RootState) => {
  return (
    state?.k8CloudProfileDashboard?.getCloudProfile ||
    initialState.getCloudProfile
  );
};

export default k8CloudProfileDashboardSlice.reducer as Reducer<
  typeof initialState
>;
