import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { ButtonProps } from '@mui/material/Button/Button';
import tokens from '../../themes/liberty-ui-tokens/json/tokens.json';

const button = tokens.props.color.button;

interface RouterButtonProps extends Omit<ButtonProps, 'component'> {
  component?: object;
  to?: string;
}

export const CortexButton = styled(Button)<RouterButtonProps>(() => ({
  textTransform: 'none',
  '&:focus': {
    backgroundColor: button.primary.backgroundHover,
    borderColor: button.primary.borderHover,
    color: button.primary.textHover,
  },
}));
