import ec2AwsPrices from '../data/ec2-aws-prices';
import emrAwsPrices from '../data/emr-aws-prices';
import ebsAwsPrices from '../data/ebs-aws-prices';
import redshiftAwsPrices from '../data/redshift-aws-prices';
import round from './round';
import { BillingType } from '../constants';

/**
 * Grabs the price of a single instance with the given specifications.
 * @param region AWS region, `us-east-1`
 * @param computeType service type, `emr`
 * @param instanceType EC2 type, `m4.xlarge`
 * @param roundNumber boolean, whether to automatically round. Use `false` if this number will be further calculated
 */
export default function getAwsPricePerHour(
  region: string,
  computeType: BillingType,
  instanceType: string,
  roundNumber: boolean
) {
  if (!region || !computeType || !instanceType) {
    console.debug(
      'Unable to compute price with given variables: region [%s], computeType [%s], instanceType [%s]',
      region,
      computeType,
      instanceType
    );
    return;
  }
  const regionName = region.replace(/_/g, '-').toLowerCase(); // `US_EAST_1` to `us-east-1`
  const price = ((type) => {
    switch (type) {
      case 'ec2':
        return ec2AwsPrices.prices[regionName][instanceType];
      case 'emr':
        return (
          emrAwsPrices.prices[regionName][instanceType] +
          ec2AwsPrices.prices[regionName][instanceType]
        );
      case 'redshift':
        return redshiftAwsPrices.prices[regionName][instanceType];
      case 'ebs':
        return ebsAwsPrices.prices[regionName][instanceType];
      default:
        return ec2AwsPrices.prices[regionName][instanceType];
    }
  })(computeType);
  return price && roundNumber ? round(price) : price;
}
