import type { AnyAction, Middleware } from 'redux';
import type { ThunkMiddleware } from 'redux-thunk';
import thunkMiddleware from 'redux-thunk';
import {
  createImmutableStateInvariantMiddleware,
  createSerializableStateInvariantMiddleware,
  Dispatch,
  ImmutableStateInvariantMiddlewareOptions,
  MiddlewareArray,
  SerializableStateInvariantMiddlewareOptions,
} from '@reduxjs/toolkit';

function isBoolean(x: any): x is boolean {
  return typeof x === 'boolean';
}

interface ThunkOptions<E = any> {
  extraArgument: E;
}

interface GetDefaultMiddlewareOptions {
  thunk?: boolean | ThunkOptions;
  immutableCheck?: boolean | any;
  serializableCheck?: boolean | any;
}

export type ThunkMiddlewareFor<
  S,
  O extends GetDefaultMiddlewareOptions = {}
> = O extends {
  thunk: false;
}
  ? never
  : O extends { thunk: { extraArgument: infer E } }
  ? ThunkMiddleware<S, AnyAction, E>
  :
      | ThunkMiddleware<S, AnyAction, null> //The ThunkMiddleware with a `null` ExtraArgument is here to provide backwards-compatibility.
      | ThunkMiddleware<S, AnyAction>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CurriedGetDefaultMiddleware<S = any> = <
  O extends Partial<GetDefaultMiddlewareOptions> = {
    thunk: true;
    immutableCheck: true;
    serializableCheck: true;
  }
>(
  options?: O
) => MiddlewareArray<Middleware<any, any, Dispatch<AnyAction>>[]>;

export function curryGetDefaultMiddleware<
  S = any
>(): CurriedGetDefaultMiddleware<S> {
  return function curriedGetDefaultMiddleware(options) {
    return getDefaultMiddleware(options);
  };
}

/**
 * Returns any array containing the default middleware installed by
 * `configureStore()`. Useful if you want to configure your store with a custom
 * `middleware` array but still keep the default set.
 *
 * @return The default middleware used by `configureStore()`.
 *
 * @public
 *
 * @deprecated Prefer to use the callback notation for the `middleware` option in `configureStore`
 * to access a pre-typed `getDefaultMiddleware` instead.
 */
export function getDefaultMiddleware<
  S = any,
  O extends Partial<GetDefaultMiddlewareOptions> = {
    thunk: true;
    immutableCheck: true;
    serializableCheck: true;
  }
>(
  options: O = {} as O
): MiddlewareArray<Middleware<any, any, Dispatch<AnyAction>>[]> {
  const {
    thunk = true,
    immutableCheck = true,
    serializableCheck = true,
  } = options;

  const middlewareArray: Middleware<{}, S>[] = new MiddlewareArray();

  if (thunk) {
    if (isBoolean(thunk)) {
      middlewareArray.push(thunkMiddleware);
    } else {
      middlewareArray.push(
        thunkMiddleware.withExtraArgument(thunk.extraArgument)
      );
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    if (immutableCheck) {
      /* PROD_START_REMOVE_UMD */
      let immutableOptions: ImmutableStateInvariantMiddlewareOptions = {};

      if (!isBoolean(immutableCheck)) {
        immutableOptions = immutableCheck;
      }

      middlewareArray.unshift(
        createImmutableStateInvariantMiddleware(immutableOptions)
      );
      /* PROD_STOP_REMOVE_UMD */
    }

    if (serializableCheck) {
      let serializableOptions: SerializableStateInvariantMiddlewareOptions = {};

      if (!isBoolean(serializableCheck)) {
        serializableOptions = serializableCheck;
      }

      middlewareArray.push(
        createSerializableStateInvariantMiddleware(serializableOptions)
      );
    }
  }

  return middlewareArray as any;
}
