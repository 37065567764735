import { createAsyncThunk } from '@reduxjs/toolkit';
import produce from 'immer';
import { DEFAULT_ERROR_MSG } from '../../../../../../app/constants';
import { CortexPlaybookRecords } from '../../../../../../models/compute/Playbooks';
import {
  createUniqueString,
  setLocalStorageItem,
} from '../../../../../../utils';
import { startJob } from '../../../../slices/jobsSlice';
import { EC2Form } from './createEc2Interfaces';
import { CreateEc2Service } from './createEc2Service';
const IS_DATALAB: boolean = process.env.REACT_APP_LDL_USER === 'true';

export const assembleCreateEc2Payload = (
  ec2Form: EC2Form,
  playbookRecords: CortexPlaybookRecords
) => {
  return produce(ec2Form, (draftEc2Form) => {
    draftEc2Form.cortexPlaybooks = Object.keys(playbookRecords)
      .filter((playbookName) => playbookRecords[playbookName].selected)
      .map((playbookName) => ({
        playbookName,
        args: playbookRecords[playbookName].args,
        installedAtLogin: playbookRecords[playbookName].installedAtLogin,
      }));
    if (IS_DATALAB) {
      draftEc2Form.tags = ec2Form.tags || {};
      // eslint-disable-next-line camelcase
      draftEc2Form.tags.lm_sbu = 'ETS';
      draftEc2Form.tags.submarket = '';
    }
  });
};

export const ec2PostThunk = createAsyncThunk(
  'createEc2/Ec2PostThunk',
  async (thunkArgs: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const id = createUniqueString(10);
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const {
        ec2Form,
        playbookRecords,
      }: { ec2Form: EC2Form; playbookRecords: CortexPlaybookRecords } =
        state.createEc2;

      const form = assembleCreateEc2Payload(ec2Form, playbookRecords);
      setLocalStorageItem(
        'startInstancePreference',
        String(form.startInstanceOnCreation || false)
      );

      //create job
      dispatch(
        startJob({
          id,
          jobType: 'CREATE_EC2',
          status: 'QUEUED',
          instanceId: 'i-TBD',
          details: {
            ...form,
            account,
            region,
          },
        })
      );

      //Submit
      await new CreateEc2Service({
        account,
        region,
        form,
        id,
      }).submit();

      return;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);
