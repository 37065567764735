import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { getLocalStorageItem, setLocalStorageItem } from '../../../../utils';
import { Account, Accounts } from '../../../../models/cortex/Accounts';
import { handleIsCortexAdmin, User } from '../../../auth/authSlice';
import Region from '../../../../models/cortex/Region';

const LOCAL_STORAGE_ACCOUNT_KEY = 'selectedAccount';
const LOCAL_STORAGE_AZR_ACCOUNT_KEY = 'selectedAzrAccount';
const LOCAL_STORAGE_REGION_KEY = 'selectedRegion';
const LOCAL_STORAGE_AZR_REGION_KEY = 'selectedAzrRegion';

export const DEFAULT_AWS_ACCOUNT =
  (process.env.REACT_APP_DEFAULT_AWS_ACCOUNT as string) || 'CORTEX_PROD';
export const DEFAULT_AZURE_ACCOUNT =
  (process.env.REACT_APP_DEFAULT_AZURE_ACCOUNT as string) || 'AZR_CORP';
const DEFAULT_REGION = 'US_EAST_1';

export interface AwsDefaultConfig {
  accountConfig?: Accounts;
  user?: User;
  computeProvider?: ComputeProvider;
}

export type ComputeProvider = 'AWS' | 'AZURE' | 'GCP' | 'K8';

export interface AwsConfig {
  account: string;
  region: Region;
  azureAccount: string;
  azureRegion: Region;
}

export const getAllowedAccounts = (
  accountConfig?: Accounts,
  user?: User
): Account[] => {
  if (accountConfig && user) {
    const { ldapGroups, adGroups } = user;

    if (handleIsCortexAdmin([...(ldapGroups || []), ...(adGroups || [])])) {
      return accountConfig.accounts;
    }

    return accountConfig.accounts.filter((account) => {
      const { adminGroup, permittedGroups } = account.config;
      const accountGroups = [adminGroup, ...permittedGroups];
      return accountGroups.some(
        (group) => ldapGroups?.includes(group) || adGroups?.includes(group)
      );
    });
  }
  return [];
};

export const getAccountDefaultRegion = (
  allowedAccounts: Account[],
  activeAccount: string,
  activeRegion: Region
): Region => {
  const selectedAccount =
    allowedAccounts.filter((x) => x.account === activeAccount)[0] || null;

  if (selectedAccount?.config) {
    const { permittedRegions } = selectedAccount.config;
    if (!permittedRegions.includes(activeRegion)) {
      return permittedRegions[0] || DEFAULT_AWS_ACCOUNT;
    }
  }
  return activeRegion || DEFAULT_REGION;
};

export const getDefaultAccount = (
  allowedAccounts: Account[],
  activeAccount: string
): string => {
  let accountToSet;
  if (
    allowedAccounts.map((account) => account.account).includes(activeAccount)
  ) {
    accountToSet = activeAccount;
  } else {
    const marketAccounts = allowedAccounts.filter(
      (acct) => acct.market !== 'ETS' && acct.market !== 'CORP'
    );
    if (marketAccounts.length > 0) {
      accountToSet = marketAccounts[0].account;
    } else if (allowedAccounts.length > 0) {
      accountToSet = allowedAccounts[0].account;
    } else {
      accountToSet = DEFAULT_AWS_ACCOUNT;
    }
  }
  return accountToSet;
};

const defaultAccount = getLocalStorageItem(LOCAL_STORAGE_ACCOUNT_KEY);
const defaultRegion = getLocalStorageItem(LOCAL_STORAGE_REGION_KEY, '');
const defaultAzrAccount = getLocalStorageItem(LOCAL_STORAGE_AZR_ACCOUNT_KEY);
const defaultAzrRegion = getLocalStorageItem(LOCAL_STORAGE_AZR_REGION_KEY, '');

const initialState = {
  account: defaultAccount ? defaultAccount : DEFAULT_AWS_ACCOUNT,
  region: defaultRegion ? defaultRegion : DEFAULT_REGION,
  azureAccount:
    defaultAzrAccount && defaultAzrRegion?.startsWith('AZR')
      ? defaultAzrAccount
      : DEFAULT_AZURE_ACCOUNT,
  azureRegion: defaultAzrRegion ? defaultAzrAccount : DEFAULT_REGION,
};

export const cortexAccountSelectorSlice = createSlice({
  name: 'cortexAccount',
  initialState,
  reducers: {
    setDefaultAccount(state, action: PayloadAction<AwsDefaultConfig>) {
      const { accountConfig, user, computeProvider = 'AWS' } = action.payload;
      if (accountConfig && user) {
        const allowedAccounts = getAllowedAccounts(accountConfig, user);
        const account = getDefaultAccount(allowedAccounts, state.account);

        if (computeProvider === 'AWS') {
          state.account = account;
          state.region = getAccountDefaultRegion(
            allowedAccounts,
            account,
            state.region as Region
          );
          setLocalStorageItem(LOCAL_STORAGE_ACCOUNT_KEY, state.account);
          setLocalStorageItem(LOCAL_STORAGE_REGION_KEY, state.region);
        } else {
          state.azureAccount = account;
          state.azureRegion = getAccountDefaultRegion(
            allowedAccounts,
            account,
            state.region as Region
          );
        }
      }
    },
    setAccount(state, action: PayloadAction<any>) {
      const {
        account,
        actDefaultRegion = DEFAULT_REGION,
        cloudProvider = 'AWS',
      } = action.payload;

      if (cloudProvider === 'AWS') {
        setLocalStorageItem(
          LOCAL_STORAGE_ACCOUNT_KEY,
          account || DEFAULT_AWS_ACCOUNT
        );

        setLocalStorageItem(LOCAL_STORAGE_REGION_KEY, actDefaultRegion);
        state.account = account || DEFAULT_AWS_ACCOUNT;
        state.region = actDefaultRegion;
      } else {
        setLocalStorageItem(
          LOCAL_STORAGE_AZR_ACCOUNT_KEY,
          account || DEFAULT_AWS_ACCOUNT
        );

        setLocalStorageItem(LOCAL_STORAGE_AZR_REGION_KEY, actDefaultRegion);
        state.azureAccount = account || DEFAULT_AZURE_ACCOUNT;
        state.azureRegion = actDefaultRegion;
      }
    },
    setRegion(state, action: PayloadAction<any>) {
      const { region, cloudProvider = 'AWS' } = action.payload;
      if (cloudProvider === 'AWS') {
        setLocalStorageItem(LOCAL_STORAGE_REGION_KEY, region || DEFAULT_REGION);
        state.region = region || DEFAULT_REGION;
      } else {
        setLocalStorageItem(
          LOCAL_STORAGE_AZR_REGION_KEY,
          region || DEFAULT_REGION
        );
        state.azureRegion = region || DEFAULT_REGION;
      }
    },
  },
});

export const { setRegion, setDefaultAccount, setAccount } =
  cortexAccountSelectorSlice.actions;

export const getAccountState = (state: RootState) => {
  return state.cortexAccount;
};

export const getPermittedGroupsByAccount = (
  allAccounts: Accounts,
  accountName: string
) => {
  return (
    allAccounts.accounts.find((a: Account) => a.account === accountName)?.config
      ?.permittedGroups || []
  );
};

export const getAccountActiveGroup = (
  groups: string[],
  permittedGroups: string[]
) => {
  const groupSet = new Set(groups);
  return permittedGroups.find((g) => groupSet.has(g));
};

export default cortexAccountSelectorSlice.reducer as Reducer<
  typeof initialState
>;
