import { OverridesStyleRules } from '@mui/material/styles/overrides';
import palette from '../liberty-ui-tokens/json/palette.json';
import { TabsClasses } from '@mui/material';

const LmTabs: Partial<OverridesStyleRules<keyof TabsClasses>> | undefined = {
  indicator: {
    backgroundColor: palette.props.tealMedium,
  },
};

export default LmTabs;
