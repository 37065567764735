import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { ButtonClasses } from '@mui/material';
import MuiButtonOverride from '../componentBase/MuiButtonOverride';
import {
  nord0,
  nord2,
  nord3,
  snow0,
  frost0,
  frost1,
  frost2,
  frost3,
} from './constants';

const DarkButton:
  | Partial<OverridesStyleRules<keyof ButtonClasses>>
  | undefined = {
  ...MuiButtonOverride,
  root: {
    textTransform: 'none',
    boxShadow: `0 3px 0 0 ${snow0}`,
    '&:hover': {
      boxShadow: `0 3px 0 0 ${frost3}`,
    },
    '&:active': {
      boxShadow: `0 1px 0 0 ${frost3}`,
    },
    '&:disabled': {
      backgroundColor: nord2,
      borderColor: snow0,
      color: snow0,
    },
  },
  outlined: {
    boxShadow: `0 3px 0 0 ${snow0}`,
    '&:hover': {
      boxShadow: `0 3px 0 0 ${frost3}`,
    },
    '&:active': {
      boxShadow: `0 1px 0 0 ${frost3}`,
    },
  },
  contained: {
    boxShadow: `0 3px 0 0 ${frost0}`,
    '&:hover': {
      boxShadow: `0 3px 0 0 ${frost3}`,
    },
    '&:active': {
      boxShadow: `0 1px 0 0 ${frost3}`,
    },
  },
  containedPrimary: {
    color: nord0,
    backgroundColor: frost1,
    borderColor: frost1,
    '&:hover': {
      backgroundColor: frost3,
      borderColor: frost1,
      color: nord0,
    },
    '&:active': {
      backgroundColor: frost2,
      borderColor: frost2,
      color: nord0,
    },
    '&:focus': {
      backgroundColor: frost2,
      borderColor: frost2,
      color: nord0,
    },
  },
  containedSecondary: {
    backgroundColor: snow0,
    borderColor: snow0,
    color: nord0,
    boxShadow: `0 3px 0 0 ${frost2}`,
    '&:hover': {
      backgroundColor: snow0,
      borderColor: snow0,
      color: nord0,
    },
    '&:active': {
      backgroundColor: snow0,
      borderColor: snow0,
      color: nord0,
    },
  },
  outlinedPrimary: {
    color: nord0,
    backgroundColor: snow0,
    borderColor: nord3,
    '&:hover': {
      backgroundColor: frost1,
      borderColor: nord3,
      color: nord0,
    },
    '&:active': {
      backgroundColor: frost1,
      borderColor: nord3,
      color: nord0,
    },
    '&:focus': {
      backgroundColor: frost1,
      borderColor: nord3,
      color: nord0,
    },
  },
  outlinedSecondary: {
    color: nord0,
    backgroundColor: snow0,
    borderColor: nord3,
    boxShadow: `0 3px 0 0 ${snow0}`,
    '&:hover': {
      backgroundColor: snow0,
      borderColor: nord3,
      color: nord0,
      boxShadow: `0 3px 0 0 ${frost2}`,
    },
    '&:active': {
      backgroundColor: snow0,
      borderColor: nord3,
      color: nord0,
    },
    '&:focus': {
      backgroundColor: snow0,
      borderColor: nord3,
      color: nord0,
      boxShadow: `0 3px 0 0 ${frost2}`,
    },
  },
  text: {
    color: snow0,
    boxShadow: `none`,
    '&:hover': {
      boxShadow: 'none',
      color: snow0,
      backgroundColor: nord2,
    },
    '&:active': {
      boxShadow: 'none',
      color: snow0,
      backgroundColor: nord3,
    },
    '&:focus': {
      boxShadow: 'none',
      color: snow0,
      backgroundColor: nord2,
    },
  },
};

export default DarkButton;
