import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TabsClasses } from '@mui/material';
import { frost1 } from './constants';

const DarkTabs: Partial<OverridesStyleRules<keyof TabsClasses>> | undefined = {
  indicator: {
    backgroundColor: frost1,
  },
};

export default DarkTabs;
