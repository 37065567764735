import React, { useEffect } from 'react';
import { postAuthRefreshThunk } from './authSlice';
import { useAppDispatch } from '../../hooks/store';
import { useAuth } from '../../hooks/useAuth';
import { LoadingDialog } from '../../components/system/LoadingDialog';
import { useCortexHistory } from '../../hooks/useCortexHistory';

export const RefreshToken = () => {
  const dispatch = useAppDispatch();
  const navigate = useCortexHistory();
  const auth = useAuth();

  useEffect(() => {
    if (!auth?.refreshActive) {
      dispatch(
        postAuthRefreshThunk(() =>
          setTimeout(() => {
            navigate(
              window.location.pathname +
                window.location.search +
                window.location.hash
            );
          }, 300)
        )
      );
    }
  }, [auth?.refreshActive, dispatch, navigate]);
  return (
    <LoadingDialog
      open={(!auth?.isAuthenticated && auth?.refreshing) || false}
    />
  );
};
export default RefreshToken;
