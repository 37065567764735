import { cortexAwsApi } from '../cortexAwsApi';
import {
  Accounts,
  Kubernetes,
  Markets,
  Profile,
} from '../../models/cortex/Accounts';
import { AwsConfig } from '../../routes/member/account/slices/cortexAccountSelectorSlice';

const extendedAccountsApi = cortexAwsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<Accounts, void>({
      query: () => 'rest/v2/aws/accounts',
    }),
    getKubernetes: builder.query<Kubernetes[], void>({
      query: () => 'rest/v2/aws/accounts/kubernetes',
    }),
    getAzureAccounts: builder.query<Accounts, void>({
      query: () => 'rest/v2/azure/accounts',
    }),
    getMarkets: builder.query<Markets, AwsConfig>({
      query: (awsConfig) => ({
        url: 'rest/v2/aws/markets',
      }),
    }),
    getProfiles: builder.query<Profile[], AwsConfig>({
      query: (awsConfig) => ({
        url: 'rest/v2/aws/accounts/profiles',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccountsQuery,
  useGetMarketsQuery,
  useGetProfilesQuery,
  useGetAzureAccountsQuery,
  useGetKubernetesQuery,
} = extendedAccountsApi;

export default extendedAccountsApi;
