import { SelectClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

const DarkSelect:
  | Partial<OverridesStyleRules<keyof SelectClasses>>
  | undefined = {
  standard: {
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
};

export default DarkSelect;
