export const desserts: string[] = [
  'abnabat',
  'alexandertorte',
  'allahabadi-cake',
  'allerheiligenstriezel',
  'amandine',
  'angel-cake',
  'angel-food-cake',
  'apple-cake',
  'apple-strudel',
  'arany-galuska',
  'azerbaijani-pakhlava',
  'babka',
  'bakewell-pudding',
  'baklava',
  'bakpia',
  'bakpia-pathok',
  'banbury-cake',
  'banitsa',
  'banket',
  'battenberg-cake',
  'baumkuchen',
  'bear-claw',
  'belekoy',
  'belokranjska-povitica',
  'berlingozzo',
  'besan-barfi',
  'bika-ambon',
  'birnbrot',
  'birthday-cake',
  'bizcocho',
  'bizcocho',
  'blachindla',
  'black-bun',
  'black-forest-cake',
  'blackout-cake',
  'blondie',
  'bolo-rei',
  'bonbon',
  'bougatsa',
  'bourbon-ball',
  'boyoz',
  'briouat',
  'brittle',
  'brownie',
  'bruttiboni',
  'bubble-gum',
  'buccellato',
  'buckeye-candy',
  'buckwheat-gateau',
  'bulk-confectionery',
  'bulla-cake',
  'bundt-cake',
  'butter-cake',
  'butterkuchen',
  'butterscotch',
  'cajeta',
  'cake-balls',
  'cake-pop',
  'calisson',
  'candied-fruit',
  'candy',
  'candy-apple',
  'candy-cane',
  'candy-cigarette',
  'candy-corn',
  'candy-pumpkin',
  'carac',
  'caramel',
  'caramel-apple',
  'caramel-corn',
  'caraway-seed-cake',
  'carrot-cake',
  'cassata',
  'castella',
  'cezerye',
  'chantilly-cake',
  'charlotte',
  'chasan',
  'cheesecake',
  'chelsea-bun',
  'chewing-gum',
  'chiffon-cake',
  'chikki',
  'choco-pie',
  'chocolate',
  'chocolate-bar',
  'chocolate-cake',
  'chocolate-coated-peanut',
  'chocolate-covered-coffee-bean',
  'chocolate-covered-raisin',
  'chocolate-truffle',
  'chokladboll',
  'chongyang-cake',
  'chorley-cake',
  'chouquette',
  'choux-pastry',
  'christmas-cake',
  'churchkhela',
  'churro',
  'cinnamon-roll',
  'cocadas',
  'coconut-cake',
  'coconut-candy',
  'coffee-cake',
  'comfit',
  'cordial',
  'cotton-candy',
  'coussin-de-lyon',
  'cream-horn',
  'croline',
  'cronut',
  'cuban-pastry',
  'cuberdon',
  'cupcake',
  'cupcone',
  'dabby-doughs',
  'dacquoise',
  'danish-pastry',
  'depression-cake',
  'dirt-cake',
  'divinity',
  'djevrek',
  'doberge-cake',
  'dobos-torte',
  'dodol',
  'dominostein',
  'donauwelle',
  'dulce-de-leche',
  'dundee-cake',
  'eccles-cake',
  'eccles-cake',
  'edible-ink-printing',
  'eggies',
  'eierschecke',
  'erotic-cake',
  'fa-gao',
  'fat-rascal',
  'fazuelos',
  'fig-roll',
  'financier',
  'fish-shaped-pastry',
  'flaky-pastry',
  'flaons',
  'flies-graveyard',
  'flourless-chocolate-cake',
  'foam-cake',
  'fondant',
  'frankfurter-kranz',
  'friand',
  'frog-cake',
  'fruit-sours',
  'fruitcake',
  'fudge',
  'funing-big-cake',
  'gajak',
  'garash-cake',
  'gaz',
  'genoa-cake',
  'genoise',
  'geplak',
  'german-chocolate-cake',
  'gibanica',
  'gibraltar-rock',
  'gingerbread',
  'gingerbread-house',
  'glaze',
  'gobstopper',
  'gooey-butter-cake',
  'gozinaki',
  'gugelhupf',
  'gulab-jamun',
  'gundain',
  'gyeongju-bread',
  'haddekuche',
  'halva',
  'hanukkah-gelt',
  'happy-cake',
  'hard-candy',
  'heavy-cake',
  'heong-peng',
  'herman-cake',
  'honey-bun',
  'hot-milk-cake',
  'hummingbird-cake',
  'ice-cream-cake',
  'imagawayaki',
  'imarti',
  'inipit',
  'ischoklad',
  'jachnun',
  'jaffa-cakes',
  'jalebi',
  'jelly-bean',
  'joffre-cake',
  'jordan-almonds',
  'kaju-katli',
  'kakinada-khaja',
  'kalathappam',
  'kamarcut',
  'kanafeh',
  'karah-parshad',
  'kek-lapis-sarawak',
  'kentucky-jam-cake',
  'kesaria-peda',
  'kettle-corn',
  'king-cake',
  'kitchener-bun',
  'kladdkaka',
  'konfyt',
  'kornigou',
  'kosereva',
  'kouign-amann',
  'kransekake',
  'krempita',
  'kringle',
  'kuchen',
  'kue-cubit',
  'kyiv-cake',
  'laddu',
  'ladyfinger',
  'lakhamari',
  'lamington',
  'lane-cake',
  'lardy-cake',
  'lattice',
  'layer-cake',
  'leipziger-lerche',
  'lekach',
  'lemon-drop',
  'linzer-torte',
  'liquorice',
  'liquorice-allsorts',
  'london-cheesecake',
  'madeira-cake',
  'madeleine',
  'makroudh',
  'malsouka',
  'malt-loaf',
  'mampostial',
  'mandelkubb',
  'manjar',
  'mantecadas',
  'maple-sugar',
  'maple-taffy',
  'marble-cake',
  'marshmallow',
  'marshmallow-creme',
  'marzipan',
  'masan',
  'mazurek',
  'mendiant',
  'miguelitos',
  'milhoja',
  'milk-chocolate',
  'milk-cream-strudel',
  'mint',
  'misri',
  'modjeska',
  'molten-chocolate-cake',
  'mooncake',
  'moorkop',
  'moustalevria',
  'mozartkugel',
  'noghl',
  'nonpareils',
  'nougat',
  'oblaat',
  'ontbijtkoek',
  'opera',
  'orange-jelly-candy',
  'pan-dulce',
  'pandan-cake',
  'panettone',
  'panforte',
  'panpepato',
  'papanași',
  'paper-wrapped-cake',
  'parkin',
  'parrozzo',
  'pashmak',
  'pastel',
  'pastiera',
  'pastila',
  'pastille',
  'pastry-heart',
  'peanut-butter-cup',
  'pecan-log-roll',
  'penuche',
  'pepero',
  'persipan',
  'petit-four',
  'phyllo',
  'pinca',
  'pineapple-cake',
  'pionono',
  'pirate-coins',
  'polkagris',
  'pontefract-cake',
  'poolaki',
  'pound-cake',
  'prekmurska-gibanica',
  'pretzel',
  'princess-cake',
  'prinzregententorte',
  'profiterole',
  'ptasie-mleczko',
  'puff-pastry',
  'punsch-roll',
  'punschkrapfen',
  'punschkrapfen',
  'put-chai-ko',
  'qottab',
  'queen-cake',
  'queijadinha',
  'quesito',
  'rab-cake',
  'red-bean-cake',
  'red-velvet-cake',
  'remonce',
  'reuzenbol',
  'ribbon-candy',
  'rock',
  'rock-cake',
  'rock-candy',
  'rocky-road',
  'rosca-de-reyes',
  'roti-tissue',
  'royal-icing',
  'roze-koek',
  'rum-baba',
  'rum-ball',
  'rum-cake',
  'ruske-kape',
  'russian-candy',
  'sachertorte',
  'sad-cake',
  'salt-water-taffy',
  'salty-liquorice',
  'sandesh',
  'schnecken',
  'schneeball',
  'semla',
  'sesame-seed-cake',
  'sesame-seed-candy',
  'sfouf',
  'sheet-cake',
  'shortcrust-pastry',
  'simnel-cake',
  'singori',
  'snack-cake',
  'sno-balls',
  'sohan',
  'song-gao',
  'soor-ploom',
  'sou',
  'spanische-windtorte',
  'spekkoek',
  'spice-cake',
  'sponge-cake',
  'sprinkles',
  'spritzkuchen',
  'stack-cake',
  'stick-candy',
  'stollen',
  'strela-candy',
  'streusel',
  'streuselkuchen',
  'strudel',
  'stutenkerl',
  'succade',
  'sufganiyah',
  'sugar-cake',
  'sugar-mice',
  'sugar-paste',
  'sugar-plum',
  'suikerboon',
  'sukhdi',
  'suncake',
  'sweetheart-cake',
  'swiss-roll',
  'szaloncukor',
  'tableting',
  'tahini-roll',
  'tahinopita',
  'taiyaki',
  'tarasari',
  'tarta-de-santiago',
  'tea-loaf',
  'teacake',
  'teja',
  'tiffin',
  'tin-roof-pie',
  'tipsy-cake',
  'tiramisu',
  'toaster-pastry',
  'torpedo-dessert',
  'torta-alla-monferrina',
  'torta-caprese',
  'torta-de-nata',
  'torta-maria-luisa',
  'torta-tre-monti',
  'torte',
  'tortell',
  'tortita-negra',
  'tottenham-cake',
  'tres-leches-cake',
  'tu',
  'tula-pryanik',
  'tunis-cake',
  'turkish-delight',
  'turnover',
  'turrón',
  'upside-down-cake',
  'utap',
  'vatrushka',
  'vetkoek',
  'vol-au-vent',
  'wacky-cake',
  'wafer',
  'waffle',
  'wedding-cake',
  'welsh-cake',
  'white-sugar-sponge-cake',
  'wine-cake',
  'yule-log',
  'yurla',
  'zeeuwse-bolus',
  'zefir',
  'zuger-kirschtorte',
  'zwetschgenkuchen',
];
