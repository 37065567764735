import _ from 'lodash';
import { store } from '../../../../app/store';
import { resetInstanceState } from '../../aws/compute/ec2/slices/createEc2Slice';
import { resetClusterState } from '../../aws/compute/emr/slices/createEmrSlice';
import { resetRedshiftState } from '../../aws/compute/redshift/slices/createRedshiftSlice';
import { clearS3Dashboard } from '../../aws/s3/slices/s3DashboardSlice';

export const resetApplicationState = () => {
  // Working through resetting submission state
  const reduxState = store?.getState();
  if (_.has(reduxState, 'createEc2')) {
    store.dispatch(resetInstanceState());
  }
  if (_.has(reduxState, 'createEmr')) {
    store.dispatch(resetClusterState());
  }
  if (_.has(reduxState, 'createRedshift')) {
    store.dispatch(resetRedshiftState());
  }
  if (_.has(reduxState, 's3Dashboard')) {
    store.dispatch(clearS3Dashboard());
  }
};
