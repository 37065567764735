import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

export interface CircularLoaderProps {
  variant: 'primary' | 'secondary';
  styles?: any;
  size?: number;
}

function CircularLoader(props: CircularLoaderProps) {
  const theme = useTheme();
  const { variant, styles, size = 40, ...rest } = props;
  return (
    <div data-testid={'circular-loader'}>
      {variant === 'primary' && (
        <CircularProgress
          size={size}
          style={
            styles
              ? styles
              : { display: 'inline-block', margin: theme.spacing(2) }
          }
          {...rest}
        />
      )}
      {variant === 'secondary' && (
        <CircularProgress
          color="secondary"
          style={
            styles ? styles : { display: 'inline', margin: theme.spacing(2) }
          }
        />
      )}
    </div>
  );
}

export default CircularLoader;
