import {
  AwsRedshiftCost,
  CreateRedshiftState,
  RedshiftForm,
} from './createRedshiftInterfaces';
import { AwsConfig } from '../../../../account/slices/cortexAccountSelectorSlice';
import { CortexAPIAccountType } from '../../../../../../services/CortexAxiosApiService';
import { calculateDay } from '../../utils';
import {
  AWS_REGIONS,
  DEFAULT_REDSHIFT_INSTANCE,
} from '../../../../../../app/constants';
import { AwsRedshiftDetails } from '../../../../../../app/data/aws-prices-details';
import getAwsPricePerHour from '../../../../../../app/utilities/getAwsPricePerHour';
import REDSHIFT_NODE_TYPES from '../../../../../../app/data/redshift-aws-details';
import { Draft } from '@reduxjs/toolkit';
import { SnapshotDescribeDetails } from '../../../../../../models/compute/Redshift';

export const REDSHIFT_NODE_CLASSES = {
  DENSE_COMPUTE: 'Dense Compute',
  DENSE_STORAGE: 'Dense Storage',
};

export const generateInitialRedshiftForm = (
  awsConfig: AwsConfig
): RedshiftForm => ({
  lmAccount: awsConfig.account as CortexAPIAccountType,
  region: awsConfig.region,
  snapshotId: null,
  adminGroup: '',
  enableAutoTermination: true,
  associatedBuckets: [],
  associatedData: [],
  dbName: '',
  nodeType: DEFAULT_REDSHIFT_INSTANCE,
  nodeCount: 1,
  restorableNodeTypes: REDSHIFT_NODE_TYPES.map((node) => node.type),
  // eslint-disable-next-line camelcase
  tags: { lm_sbu: '', submarket: '' },
  acceptCosts: false,
  trouxIDEntered: false,
  userGroup: '',
  secrets: [],
  autoTerminateDate: calculateDay('', 'COMPUTE_TERMINATE', 1).toISOString(),
  userProfile: '',
});

export const addAwsPrices = (redshift: AwsRedshiftDetails) => {
  const redshiftCostByRegion: AwsRedshiftCost = {};
  AWS_REGIONS.forEach((region) => {
    const regionalCost = {
      [region.name]: getAwsPricePerHour(
        region.key,
        'redshift',
        redshift.type,
        false
      ),
      [region.key]: getAwsPricePerHour(
        region.key,
        'redshift',
        redshift.type,
        false
      ),
    };
    Object.assign(redshiftCostByRegion, regionalCost);
  });
  return {
    ...redshift,
    cost: redshiftCostByRegion,
    id: redshift.type,
  };
};

export const filterInstances = (type: string) => {
  return REDSHIFT_NODE_TYPES.filter((node) => node.class === type);
};

export const setSnapshotStateHelper = (
  state: Draft<CreateRedshiftState>,
  snapshot: SnapshotDescribeDetails
) => {
  const { redshiftForm } = state;
  redshiftForm.snapshotId = snapshot.snapshotId;
  redshiftForm.dbName = snapshot.dbName;
  redshiftForm.nodeType = snapshot.nodeType;
  redshiftForm.nodeCount = snapshot.numberOfNodes;
  redshiftForm.region = snapshot.region;
};
