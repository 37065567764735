import { createAsyncThunk, createSlice, Reducer } from '@reduxjs/toolkit';
import { cortexApiInit, DEFAULT_ERROR_MSG } from '../../../../../app/constants';
import { RootState } from '../../../../../app/store';
import { S3 } from '../../../../../models/storage/S3';
import { memoize } from 'proxy-memoize';

interface S3DashboardState {
  data: {
    loading: boolean;
    error: any;
    result: null | S3[];
  };
}

export const initialState: S3DashboardState = {
  data: {
    loading: false,
    error: null,
    result: null,
  },
};

export const s3GetAccessibleThunk = createAsyncThunk(
  's3Dashboard/S3GetAccessibleThunk',
  async (thunkArgs: {}, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const { account, region } = state.cortexAccount;
      const response = await cortexApiInit(
        state.authentication.auth?.token,
        account,
        region
      ).exchange({
        path: 'aws/s3/buckets/list/accessible',
        method: 'GET',
        payload: {},
        query: {},
      });
      return await response?.data;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e?.response?.data || DEFAULT_ERROR_MSG);
    }
  }
);

export const s3DashboardSlice = createSlice({
  name: 's3Dashboard',
  initialState,
  reducers: {
    clearS3Dashboard(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(s3GetAccessibleThunk.pending, (state) => {
      state.data.loading = true;
      state.data.error = null;
    });
    builder.addCase(s3GetAccessibleThunk.fulfilled, (state, { payload }) => {
      state.data.loading = false;
      state.data.error = null;
      state.data.result = payload;
    });
    builder.addCase(s3GetAccessibleThunk.rejected, (state, { payload }) => {
      state.data.loading = false;
      state.data.error = payload;
    });
  },
});

export const { clearS3Dashboard } = s3DashboardSlice.actions;

export const getS3AccessibleState = (state: RootState) => {
  return state?.s3Dashboard?.data || initialState.data;
};

export const getMemoizedS3AccessibleState = memoize((state: RootState) =>
  getS3AccessibleState(state)
);

export default s3DashboardSlice.reducer as Reducer<typeof initialState>;
