import { AwsEc2Details } from '../../../../../../app/data/aws-prices-details';
import {
  AwsEc2Cost,
  AwsEc2DetailsWithCost,
  CreateEc2State,
} from './createEc2Interfaces';
import {
  AWS_REGIONS,
  BillingType,
  COMPUTE_TYPE,
  DEFAULT_EC2_INSTANCE,
  GP3,
} from '../../../../../../app/constants';
import getAwsPricePerHour from '../../../../../../app/utilities/getAwsPricePerHour';
import { generateInstanceName } from '../../../../../../app/utilities/generateInstanceName';
import { calculateDay } from '../../utils';
import { CortexComponent } from '../../../../../../models/compute/Ec2';
import Region from '../../../../../../models/cortex/Region';
import moment from 'moment';
import { Template } from '../../../../../../models/compute/Template';
import { Draft } from '@reduxjs/toolkit';
import { DsvmInstanceType } from '../../../../../../models/compute/Dsvm';

export const EC2_INSTANCE_CLASSES = {
  GENERAL_PURPOSE: 'General purpose',
  COMPUTE_OPTIMIZED: 'Compute optimized',
  MEMORY_OPTIMIZED: 'Memory optimized',
  GPU: 'GPU instance',
  STORAGE_OPTIMIZED: 'Storage optimized',
};

export const filterDeprecatedAndSsdInstances = (ec2: AwsEc2DetailsWithCost) => {
  const instanceType = ec2.id.split('.')[0];
  const isI3 = instanceType.includes('i3');
  const isI2 = instanceType.includes('i2');
  const isSsd = filterBySsd(instanceType);
  return !isI3 && !isI2 && !isSsd;
};

export const filterBySsd = (instanceType: string) =>
  /^\w+\d+.*d.*/i.test(instanceType);

export const filterInstancesWithGreaterThanTwoRam = (ec2: AwsEc2Details) => {
  return parseInt(ec2.ram.split(' ')[0]) > 2;
};

export const filterArmAmis = (ec2: AwsEc2Details) => {
  return !ec2.processor.includes('Graviton');
};

export const onlyShowAllowList = (ec2: AwsEc2Details) => {
  return ec2.allowList;
};

export const addDsvmPrices = (dsvm: DsvmInstanceType): DsvmInstanceType => {
  const cost = {
    'us-east-1': dsvm.instancePrice,
    US_EAST_1: dsvm.instancePrice,
  };
  return {
    ...dsvm,
    cost,
    type: dsvm.instanceType,
    id: dsvm.instanceType,
  };
};

export const addAwsPrices = (ec2: AwsEc2Details, computeType: COMPUTE_TYPE) => {
  const ec2CostByRegion: AwsEc2Cost = {};
  const billingType = computeType.toLowerCase() as BillingType;
  AWS_REGIONS.forEach((region) => {
    const regionalCost = {
      [region.name]: getAwsPricePerHour(
        region.key,
        billingType,
        ec2.type,
        false
      ),
      [region.key]: getAwsPricePerHour(
        region.key,
        billingType,
        ec2.type,
        false
      ),
    };
    Object.assign(ec2CostByRegion, regionalCost);
  });
  return {
    ...ec2,
    cost: ec2CostByRegion,
    id: ec2.type,
  };
};

export const generateInitialEc2Form = (region: Region) => ({
  name: generateInstanceName(),
  instanceType: DEFAULT_EC2_INSTANCE,
  startInstanceOnCreation: true,
  enableIntelligentStop: false,
  masterInstanceType: DEFAULT_EC2_INSTANCE,
  marketType: '',
  bidPrice: '',
  autoStopTime: calculateDay('', 'COMPUTE_STOP').toISOString(),
  autoTerminateDateTime: calculateDay(
    '',
    'COMPUTE_TERMINATE',
    89
  ).toISOString(),
  associatedBuckets: [],
  associatedData: [],
  adminGroup: '',
  userGroup: '',
  secrets: [],
  cortexComponents: ['jupyter_hub_lab' as CortexComponent],
  cortexPlaybooks: [],
  /* eslint-disable camelcase */
  tags: {
    lm_sbu: '',
    submarket: '',
  },
  region,
  volumeType: GP3.key,
  rootVolumeType: GP3.key,
  volumeSize: 30,
  rootVolumeSize: 50,
  iops: 3000,
  rootIops: 3000,
  attachEbs: true,
  ebsOptimized: false,
  securityGroups: [],
  deleteEBSOnTermination: true,
  amiId: '',
  subnetId: '',
  userProfile: '',
  acceptCosts: false,
  saveTemplate: false,
  templateName: '',
  templateDesc: '',
  ubuntu: false,
});

/* Converts auto start and auto stop times into non UTC moments that can be used again to recreate the instance */
export const templateMoments = (autoTime: string) => {
  const hourMinuteSplit = autoTime.split(':');
  const day = moment();
  const hour = parseInt(hourMinuteSplit[0]) - 5;
  day.set({
    h: hour < 0 ? 24 - Math.abs(hour) : hour,
    m: parseInt(hourMinuteSplit[1]),
    s: 0,
    ms: 0,
  });
  return day;
};

export const mergeTemplateStateHelper = (
  state: Draft<CreateEc2State>,
  template: Template
) => {
  const { ec2Form } = state;

  if (template.adminGroup) {
    ec2Form.adminGroup = template.adminGroup;
  }
  // Query buckets and pass in
  // ec2Form.associatedBuckets = template.associatedBuckets;
  ec2Form.associatedData = template.associatedData;
  if (template.autoStartTime) {
    ec2Form.autoStartTime = templateMoments(
      template.autoStartTime
    ).toISOString();
  }
  if (template.autoStopTime) {
    ec2Form.autoStopTime = templateMoments(template.autoStopTime).toISOString();
  }
  ec2Form.autoTerminateDateTime = moment(
    template.autoTerminateDateTime
  ).toISOString();
  ec2Form.commands = template.commands;
  ec2Form.cortexComponents = template.cortexComponents;
  // set playbooks based on values in the parallel data structure
  // ec2Form.cortexPlaybooks = template.cortexPlaybooks
  ec2Form.deleteEBSOnTermination = template.deleteEBSOnTermination;
  ec2Form.ebsOptimized = template.ebsOptimized;
  ec2Form.ec2SparkConfiguration = template.ec2SparkConfiguration;
  ec2Form.instanceType = template.instanceType;
  ec2Form.iops = template.iops;
  ec2Form.keyName = template.keyName;
  ec2Form.masterInstanceType = template.masterInstanceType;
  ec2Form.mfa = template.mfa;
  ec2Form.name = template.name;
  ec2Form.nodeCount = template.nodeCount;
  ec2Form.region = template.region;
  ec2Form.rootIops = template.rootIops;
  ec2Form.rootVolumeSize = template.rootVolumeSize;
  ec2Form.rootVolumeType = template.rootVolumeType;
  ec2Form.sagemakerPrefix = template.sagemakerPrefix;
  ec2Form.storedScripts = template.storedScripts;
  ec2Form.tags = template.tags;
  if (template.userGroup) {
    ec2Form.userGroup = template.userGroup;
  }
  ec2Form.volumeSize = template.volumeSize;
  ec2Form.volumeType = template.volumeType;
};
