import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { CheckboxClasses } from '@mui/material';
import { snow2 } from './constants';

const DarkCheckbox:
  | Partial<OverridesStyleRules<keyof CheckboxClasses>>
  | undefined = {
  root: {},
  colorPrimary: {
    '&.Mui-checked': {
      color: snow2,
    },
  },
};

export default DarkCheckbox;
