import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { ListClasses } from '@mui/material';

const LmList: Partial<OverridesStyleRules<keyof ListClasses>> | undefined = {
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
};

export default LmList;
