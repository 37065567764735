import MuiIconButtonOverride from '../componentBase/MuiIconButtonOverride';
import palette from '../liberty-ui-tokens/json/palette.json';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { IconButtonClasses } from '@mui/material';

const LmIconButton:
  | Partial<OverridesStyleRules<keyof IconButtonClasses>>
  | undefined = {
  root: {
    ...MuiIconButtonOverride,
    color: palette.props.grayDark,
    '&:focus': {
      backgroundColor: `${palette.props.tealDark}50`,
    },
  },
};

export default LmIconButton;
