import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { ListItemClasses } from '@mui/material';

const LmListItem:
  | Partial<OverridesStyleRules<keyof ListItemClasses>>
  | undefined = {
  root: {
    '&.Mui-selected': {
      backgroundColor: '#0000000a',
      '&:hover': {
        backgroundColor: '#0000000f',
      },
    },
    '&.Mui-selected > .MuiListItemIcon-root': {
      color: '#000',
      backgroundColor: 'transparent',
    },
  },
};

export default LmListItem;
