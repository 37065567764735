import { OutlinedInputClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import palette from '../liberty-ui-tokens/json/palette.json';

const LmOutlinedInput:
  | Partial<OverridesStyleRules<keyof OutlinedInputClasses>>
  | undefined = {
  root: {
    '&.MuiOutlinedInput-root.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline':
      {
        borderColor: palette.props.tealDark,
      },
  },
};

export default LmOutlinedInput;
