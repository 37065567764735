import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TableHeadClasses } from '@mui/material';
import { nord1 } from './constants';

const DarkTableHead:
  | Partial<OverridesStyleRules<keyof TableHeadClasses>>
  | undefined = {
  root: {
    '& .MuiTableRow-head > .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    backgroundColor: nord1,
  },
};

export default DarkTableHead;
