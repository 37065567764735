import { SelectClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import palette from '../liberty-ui-tokens/json/palette.json';
const LmSelect: Partial<OverridesStyleRules<keyof SelectClasses>> | undefined =
  {
    outlined: {
      borderColor: palette.props.tealDark,
    },
    standard: {
      '&:focus': {
        backgroundColor: 'inherit',
      },
    },
  };

export default LmSelect;
