import cryptoRandomString from 'crypto-random-string';
import copy from 'copy-to-clipboard';
import moment from 'moment-timezone';
import { ChronologyDate } from './types/Chronology';

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const setLocalStorageItem = (key: string, document: string) => {
  localStorage.setItem(key, window.btoa(document));
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const getLocalStorageItem = (
  key: string,
  defaultValue?: string
): string | null => {
  let result = localStorage.getItem(key);
  if (result) {
    try {
      result = window.atob(result || '');
    } catch (e) {
      result = null;
    }
  }
  return defaultValue ? result || defaultValue : result;
};

export const setSessionStorageItem = (key: string, document: string) => {
  sessionStorage.setItem(key, window.btoa(document));
};

export const removeSessionStorageItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string): string | null => {
  let result = sessionStorage.getItem(key);
  if (result) {
    try {
      result = window.atob(result || '');
    } catch (e) {
      result = null;
    }
  }
  return result;
};

export const createUniqueString = (length: number): string => {
  return cryptoRandomString({ length: length });
};

export const copyTextToClipboard = (text: string) => {
  return new Promise(async (resolve) => {
    copy(text);
    return resolve(true);
  });
};

export const formatUTCTimeToLocaleDate = (
  dateTime?: string | null,
  includeTime?: boolean
) => {
  if (!dateTime) {
    return 'disabled';
  }
  const date = new Date(dateTime);
  return (
    date.toLocaleDateString() +
    (includeTime ? ` ${date.toLocaleTimeString('en-US')}` : '')
  );
};

export function formatRemainingTime(
  endDate: string,
  startDate: string = new Date().toISOString()
) {
  const start = moment(startDate).local();
  const end = moment(endDate).local();
  return moment.duration(end.diff(start)).asMilliseconds();
}

export function humanizeDuration(diff: number) {
  return moment.duration(diff).humanize(true);
}

export const capitalizeFirstLetter = (str: string) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const chronDateToISO = (chronDate: ChronologyDate) => {
  return new Date(
    Date.UTC(
      chronDate.year,
      chronDate.monthValue - 1,
      chronDate.dayOfMonth,
      chronDate.hour,
      chronDate.minute,
      chronDate.second,
      0
    )
  ).toISOString();
};
export const numDaysBetween = (d1: Date, d2: Date) => {
  const diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

export const stripObjectOfEmpties = (data: any) => {
  Object.keys(data).forEach(
    (k) =>
      data[k] == null ||
      data[k] === undefined ||
      (data[k] === '' && delete data[k])
  );
};

export const is2xxResponse = (status: number) => {
  return status === 200 || status === 201 || status === 202 || status === 204; //common 2xx status
};

export function downloadFile({
  contents,
  filename,
  type = 'application/octet-stream',
}: any) {
  if (!(contents instanceof window.Blob)) {
    contents = contents.replace(/\n/g, '\r\n');
    contents = new window.Blob([contents], { type });
  }
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(contents, filename);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(contents);
    link.download = filename;
    const event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      'click',
      true,
      true,
      window,
      1,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    link.dispatchEvent(event);
  }
}
