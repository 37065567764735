import { OutlinedInputClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { snow0, snow2 } from './constants';

const DarkOutlinedInput:
  | Partial<OverridesStyleRules<keyof OutlinedInputClasses>>
  | undefined = {
  root: {
    'fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: snow0,
    },
    '&.MuiOutlinedInput-root.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline':
      {
        borderColor: snow2,
      },
  },
};

export default DarkOutlinedInput;
