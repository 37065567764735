import { SwitchClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { snow0, frost0 } from './constants';

const DarkSwitch:
  | Partial<OverridesStyleRules<keyof SwitchClasses>>
  | undefined = {
  switchBase: {
    color: snow0,
    '&.Mui-checked': {
      color: frost0,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: frost0,
    },
    '&.Mui-checked + :hover': {
      backgroundColor: `${frost0}40`,
    },
    '&.Mui-checked:hover, &:hover, &.Mui-focusVisible': {
      backgroundColor: `${frost0}40`,
    },
  },
  track: {
    backgroundColor: snow0,
  },
};

export default DarkSwitch;
